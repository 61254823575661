import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import Dropzone from "react-dropzone";
import Modal from '@mawesi-sas/mws_react_component_modal';
import Input from '@mawesi-sas/mws_react_component_input';
import { toast } from "react-toastify";

import {
  displayModalEdit,
  almacenarFuente,
  massiveUploadFiles,
  showModalConfirmAction
} from "../actions";

class ModalEdit extends Component {
  handleCloseModal = e => {
    const modal = document.getElementById("modalEdit");
    modal.classList.remove("fadeIn");
    modal.classList.add("fadeOut");

    const modalDialog = document.querySelector(
      `#modalEdit .mws-modal-dialog`
    );
    modalDialog.classList.remove("zoomIn");
    modalDialog.classList.add("zoomOut");
    setTimeout(() => this.props.displayModalEdit(false), 300);
  };

  handleOnDrop = files => {
    this.props.massiveUploadFiles(files);
  };

  handleOnRejected = files => {
    files.forEach(x => {
      toast.warn(`El archivo ${x.name} no es del tipo esperado`, {
        bodyClassName: "toastify-content toastify-warning"
      });
    });
  };

  modalContent = (modalEdit, boxActions, saving) => {
    return (
      <form
        id="form"
        onSubmit={e => {
          e.preventDefault();
          this.props.almacenarFuente('edit');
        }}
        noValidate
      >
        <div className="buttonstrip">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <div style={{ flex: 1 }}>
              <button className="box-button" title="Guardar" disabled={saving}>
                <FontAwesome name="floppy-o" tag="i" />
              </button>
            </div>
          </div>
        </div>
        <div className="modal-dialog" style={{ width: "90%" }}>
          <div>
            <div className="dropzone1" style={{ marginBottom: "15px" }}>
                        <Dropzone onDropAccepted={this.handleOnDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Arrastre los archivos a esta área o haga click para seleccionarlos</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">Nombre</label>
                <Input
                  type="text"
                  reducer="fuentes"
                  path="modalEdit.data.NOMBRE"
                  maxLength={100}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">Descripción</label>
                <Input
                  type="textarea"
                  reducer="fuentes"
                  path="modalEdit.data.DESCRIPCION"
                  rows="3"
                  maxLength={1000}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">Carpeta</label>
                <Input
                  type="select"
                  reducer="fuentes"
                  path="modalEdit.data.CARPETA"
                  options={this.props.folders}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  render() {
    const { modalEdit, saving } = this.props;
    if (!modalEdit.get("show")) return null;

    const boxActions = [];

    return (
      <Modal
        ancho={this.props.width < this.props.height ? "75%" : "45%"}
        mostrar={modalEdit.get("show")}
        contenido={this.modalContent(modalEdit, boxActions, saving)}
        titulo={modalEdit.get("title")}
        nombre="modalEdit"
        onCerrarModal={e => this.handleCloseModal(e)}
      />
    );
  }
}

const mapStateToProps = (state, onwProps) => {
  return {
    modalEdit: state.fuentes.get("modalEdit"),
    folders: state.fuentes.get("folders"),
    saving: state.fuentes.get("saving"),
    width: state.layout.get('width'),
    height: state.layout.get('height')
  };
};

const mapDispatchToProps = {
  displayModalEdit,
  almacenarFuente,
  massiveUploadFiles,
  showModalConfirmAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEdit);
