import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import Modal from '@mawesi-sas/mws_react_component_modal'
import Input from '@mawesi-sas/mws_react_component_input'
import BoxButton from '../../Layout/components/BoxButton'

import {
    displayModalCreate,
    adicionarConfiguracion,
    eliminarConfiguracion,
    almacenarParametro,
    resetAfterTipoCampo,
} from '../actions';
import { modifyInputs, showModalConfirmAction } from '../../Layout/actions';

class ModalCreate extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalCreate')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalCreate .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')
        setTimeout(() => this.props.displayModalCreate(false), 300)
    }

    modalContent = (boxActions, modalCreate, saving) => {
        return (
            <form id="form" onSubmit={e => { e.preventDefault(); this.props.almacenarParametro() }} noValidate>
                <div className="buttonstrip">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1 }}>
                            <button className="box-button" title="Guardar" disabled={saving} ><FontAwesome name="floppy-o" tag="i" /></button>
                        </div>
                    </div>
                </div>
                <div className="modal-dialog" style={{ width: '90%' }}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="control-label">Nombre</label>
                                <Input type="text" reducer="parametros" path="modalCreate.data.NOMBRE" disabled={modalCreate.get('mode') === 'edit'} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="control-label">Categoría</label>
                                <Input type="text" reducer="parametros" path="modalCreate.data.CATEGORIA" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="control-label">Descripción</label>
                                <Input type="textarea" reducer="parametros" path="modalCreate.data.DESCRIPCION" />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className="buttonstrip">
                                    <div><span className="modal-box-title">Configuración</span></div>
                                    <div>{boxActions}</div>
                                </div>
                                <div className="box-content" style={{ height: '158px', overflow: 'auto', background: '#f5f5f5' }}>
                                    <table className="table table-condensed table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '25px' }}></th>
                                                <th>Nombre</th>
                                                <th>Descripción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                modalCreate.getIn(['data', 'CONFIGURACIONES']).map((x, n) => {
                                                    const path = `modalCreate.data.CONFIGURACIONES.${n}`
                                                    return (
                                                        <tr key={`tr6[${x.get('ID') || x.get('GUID')}]`} className={x.get('SELECTED') ? 'info' : ''}>
                                                            <td><Input type="checkbox" reducer="parametros" path={`${path}.SELECTED`} /></td>
                                                            <td><Input type="text" reducer="parametros" path={`${path}.NOMBRE`} /></td>
                                                            <td><Input type="text" reducer="parametros" path={`${path}.DESCRIPCION`} /></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        const { modalCreate, saving } = this.props
        if (!modalCreate.get('show')) return null

        const boxActions = [
            <BoxButton key="ba[0][0]" name="plus-circle" onClick={e => { e.preventDefault(); this.props.adicionarConfiguracion() }} title="Nueva" />,
            <BoxButton key="ba[0][1]" name="trash" onClick={e => { e.preventDefault(); this.props.showModalConfirmAction(true, 'Está seguro que desea eliminar los registros seleccionados?', () => this.props.eliminarConfiguracion()) }} title="Eliminar" disabled={modalCreate.getIn(['data', 'CONFIGURACIONES']).filter(x => x.get('SELECTED')).count() === 0} danger />,
        ]

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "83%" : "43%"}
                mostrar={modalCreate.get('show')}
                contenido={this.modalContent(boxActions, modalCreate, saving)}
                titulo={modalCreate.get('title')}
                nombre="modalCreate"
                onCerrarModal={(e) => this.handleCloseModal(e)}
            />

        )
    }
}

const mapStateToProps = (state, onwProps) => {
    return {
        modalCreate: state.parametros.get('modalCreate'),
        parametros: state.parametros.get('parametros'),
        saving: state.parametros.get('saving'),
        width: state.layout.get('width'),
        height: state.layout.get('height')
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayModalCreate: (show) => dispatch(displayModalCreate(show)),
        adicionarConfiguracion: () => dispatch(adicionarConfiguracion()),
        eliminarConfiguracion: () => dispatch(eliminarConfiguracion()),
        almacenarParametro: () => dispatch(almacenarParametro()),
        resetAfterTipoCampo: (path) => dispatch(resetAfterTipoCampo(path)),
        modifyInputs: (reducer, path, value) => dispatch(modifyInputs(reducer, path, value)),
        showModalConfirmAction: (show, message, action) => dispatch(showModalConfirmAction(show, message, action)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreate)
