import * as types from '../../types'
import Immutable from 'immutable'

const INITIAL_STATE = Immutable.fromJS({
    username: "",
    name: "",
    lastname: "",
    email: "",
    password: "",
    empresa: "",
    termsConditions: false,
    policyPrivate: false
})

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.SIGNUP_CHANGE_USERNAME_FROM_EMAIL:
            let email = state.get('email')
            email = email.split("@")
            if (email.length > 1 && !state.get('username')) {
                state = state.set('username', email[0])
            }
            return state
        default:
            return state
    }
}