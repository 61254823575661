//#region SIGNIN
export const SIGNIN_INICIAR_SESION = 'SIGNIN_INICIAR_SESION';
export const SIGNIN_CERRAR_SESION = 'SIGNIN_CERRAR_SESION';
export const SIGNIN_ASIGNAR_USUARIO_SESION = 'SIGNIN_ASIGNAR_USUARIO_SESION';
export const SIGNIN_PROCESANDO = 'SIGNIN_PROCESANDO';
//#endregion

//#region SIGNUP
export const SIGNUP_CHANGE_USERNAME_FROM_EMAIL = 'SIGNUP_CHANGE_USERNAME_FROM_EMAIL';
//#endregion

//#region RECOVERY_PASSWORD
export const RECOVERY_PASSWORD_PROCESANDO = 'RECOVERY_PASSWORD_PROCESANDO';
//#endregion

//#region LAYOUT
export const LAYOUT_MOSTRAR_MENSAJE = 'LAYOUT_MOSTRAR_MENSAJE'
export const LAYOUT_LIMPIAR_MENSAJES = 'LAYOUT_LIMPIAR_MENSAJES'
export const LAYOUT_EXPANDIR_CONTRAER_MODULO = 'LAYOUT_EXPANDIR_CONTRAER_MODULO'
export const LAYOUT_MOSTRAR_MODAL_ERROR = 'LAYOUT_MOSTRAR_MODAL_ERROR'
export const LAYOUT_MOSTRAR_MODAL_CAMBIO_CONTRASENA = 'LAYOUT_MOSTRAR_MODAL_CAMBIO_CONTRASENA'
export const LAYOUT_POBLAR_MODULOS = 'LAYOUT_POBLAR_MODULOS'
export const LAYOUT_MOSTRAR_MODAL_CONFIRMACION_ACCION = 'LAYOUT_MOSTRAR_MODAL_CONFIRMACION_ACCION'
export const LAYOUT_IS_BUSY = 'LAYOUT_IS_BUSY'
export const LAYOUT_MOSTRAR_MODAL_SELECTOR = 'LAYOUT_MOSTRAR_MODAL_SELECTOR'
export const LAYOUT_MODIFICAR_PAGINA_MODAL_SELECTOR = 'LAYOUT_MODIFICAR_PAGINA_MODAL_SELECTOR'
export const LAYOUT_REINICIAR_LISTA_MODAL_SELECTOR = 'LAYOUT_REINICIAR_LISTA_MODAL_SELECTOR'
export const LAYOUT_ACTUALIZAR_LISTADO_SELECTOR = 'LAYOUT_ACTUALIZAR_LISTADO_SELECTOR'
export const LAYOUT_MOSTRAR_LOADING_SELECTOR = 'LAYOUT_MOSTRAR_LOADING_SELECTOR'
export const LAYOUT_MOSTRAR_MODAL_ESPERA = 'LAYOUT_MOSTRAR_MODAL_ESPERA'
export const LAYOUT_SET_DIMENSION = 'LAYOUT_SET_DIMENSION'
export const LAYOUT_MOSTRAR_MODAL_POLITICAS = 'LAYOUT_MOSTRAR_MODAL_POLITICAS'
//#endregion 

//#region MAESTRAS
export const MAESTRAS_POBLAR_MAESTRAS = 'MAESTRAS_POBLAR_MAESTRAS'
export const MAESTRAS_DISPLAY_MODAL_CREACION = 'MAESTRAS_DISPLAY_MODAL_CREACION'
export const MAESTRAS_MODIFICAR_INPUTS = 'MAESTRAS_MODIFICAR_INPUTS'
export const MAESTRAS_ADICIONAR_CONFIGURACION = 'MAESTRAS_ADICIONAR_CONFIGURACION'
export const MAESTRAS_ELIMINAR_CONFIGURACIONES = 'MAESTRAS_ELIMINAR_CONFIGURACIONES'
export const MAESTRAS_ELIMINAR_CONFIGURACION = 'MAESTRAS_ELIMINAR_CONFIGURACION'
export const MAESTRAS_ACTUALIZAR_MAESTRA = 'MAESTRAS_ACTUALIZAR_MAESTRA'
export const MAESTRAS_ACTUALIZAR_MAESTRAS = 'MAESTRAS_ACTUALIZAR_MAESTRAS'
export const MAESTRAS_SELECCIONAR_MAESTRA = 'MAESTRAS_SELECCIONAR_MAESTRA'
export const MAESTRAS_ADICIONAR_DETALLE = 'MAESTRAS_ADICIONAR_DETALLE'
export const MAESTRAS_ELIMINAR_DETALLE = 'MAESTRAS_ELIMINAR_DETALLE'
export const MAESTRAS_ELIMINAR_DETALLES = 'MAESTRAS_ELIMINAR_DETALLES'
export const MAESTRAS_ACTUALIZAR_DETALLES_MAESTRA = 'MAESTRAS_ACTUALIZAR_DETALLES_MAESTRA'
export const MAESTRAS_ELIMINAR_MAESTRAS = 'MAESTRAS_ELIMINAR_MAESTRAS'
export const MAESTRAS_MODIFICAR_FILTRO = 'MAESTRAS_MODIFICAR_FILTRO'
export const MAESTRAS_ORDENAR_COLUMNA = 'MAESTRAS_ORDENAR_COLUMNA'
export const MAESTRAS_GUARDANDO = 'MAESTRAS_GUARDANDO'
//#endregion

//#region VARIABLES
export const VARIABLES_POBLAR_VARIABLES = 'VARIABLES_POBLAR_VARIABLES'
export const VARIABLES_DISPLAY_MODAL_CREACION = 'VARIABLES_DISPLAY_MODAL_CREACION'
export const VARIABLES_MODIFICAR_INPUTS = 'VARIABLES_MODIFICAR_INPUTS'
export const VARIABLES_SELECCIONAR_VARIABLE = 'VARIABLES_SELECCIONAR_VARIABLE'
export const VARIABLES_ACTUALIZAR_VARIABLE = 'VARIABLES_ACTUALIZAR_VARIABLE'
export const VARIABLES_ELIMINAR_VARIABLE = 'VARIABLES_ELIMINAR_VARIABLE'
export const VARIABLES_CONMUTAR_SELECCION_VARIABLES = 'VARIABLES_CONMUTAR_SELECCION_VARIABLES'
export const VARIABLES_MODIFICAR_FILTRO = 'VARIABLES_MODIFICAR_FILTRO'
export const VARIABLES_ORDENAR_COLUMNA = 'VARIABLES_ORDENAR_COLUMNA'
export const VARIABLES_GUARDANDO = 'VARIABLES_GUARDANDO'
//#endregion

//#region SECCIONES
export const SECCIONES_POBLAR_SECCIONES = 'SECCIONES_POBLAR_SECCIONES'
export const SECCIONES_DISPLAY_MODAL_CREACION = 'SECCIONES_DISPLAY_MODAL_CREACION'
export const SECCIONES_MODIFICAR_INPUTS = 'SECCIONES_MODIFICAR_INPUTS'
export const SECCIONES_SELECCIONAR_SECCION = 'SECCIONES_SELECCIONAR_SECCION'
export const SECCIONES_ACTUALIZAR_SECCION = 'SECCIONES_ACTUALIZAR_SECCION'
export const SECCIONES_ELIMINAR_SECCION = 'SECCIONES_ELIMINAR_SECCION'
export const SECCIONES_CONMUTAR_SELECCION_SECCIONES = 'SECCIONES_CONMUTAR_SELECCION_SECCIONES'
export const SECCIONES_MODIFICAR_FILTRO = 'SECCIONES_MODIFICAR_FILTRO'
export const SECCIONES_ORDENAR_COLUMNA = 'SECCIONES_ORDENAR_COLUMNA'
export const SECCIONES_GUARDANDO = 'SECCIONES_GUARDANDO'
//#endregion

//#region Formularios
export const FORMULARIOS_POBLAR_FORMULARIOS = 'FORMULARIOS_POBLAR_FORMULARIOS'
export const FORMULARIOS_DISPLAY_MODAL_CREACION = 'FORMULARIOS_DISPLAY_MODAL_CREACION'
export const FORMULARIOS_MODIFICAR_INPUTS = 'FORMULARIOS_MODIFICAR_INPUTS'
export const FORMULARIOS_ACTUALIZAR_ESTRUCTURA = 'FORMULARIOS_ACTUALIZAR_ESTRUCTURA'
export const FORMULARIOS_SELECCIONAR_ESTRUCTURA = 'FORMULARIOS_SELECCIONAR_ESTRUCTURA'
export const FORMULARIOS_POBLAR_VARIABLES = 'FORMULARIOS_POBLAR_VARIABLES'
export const FORMULARIOS_POBLAR_SECCIONES = 'FORMULARIOS_POBLAR_SECCIONES'
export const FORMULARIOS_SELECCIONAR_SECCION_ESTRUCTURA = 'FORMULARIOS_SELECCIONAR_SECCION_ESTRUCTURA'
export const FORMULARIOS_ADICIONAR_VARIABLE_SECCION = 'FORMULARIOS_ADICIONAR_VARIABLE_SECCION'
export const FORMULARIOS_SELECCIONAR_VARIABLE_ESTRUCTURA = 'FORMULARIOS_SELECCIONAR_VARIABLE_ESTRUCTURA'
export const FORMULARIOS_REMOVER_VARIABLE_ESTRUCTURA = 'FORMULARIOS_REMOVER_VARIABLE_ESTRUCTURA'
export const FORMULARIOS_REORDERNAR_VARIABLES = 'FORMULARIOS_REORDERNAR_VARIABLES'
export const FORMULARIOS_REORDERNAR_SECCIONES = 'FORMULARIOS_REORDERNAR_SECCIONES'
export const FORMULARIOS_DISPLAY_MODAL_CLONACION = 'FORMULARIOS_DISPLAY_MODAL_CLONACION'
export const FORMULARIOS_ELIMINAR_ESTRUCTUDA = 'FORMULARIOS_ELIMINAR_ESTRUCTUDA'
export const FORMULARIOS_GUARDANDO = 'FORMULARIOS_GUARDANDO'
//#endregion

//#region PARAMETROS
export const PARAMETROS_POBLAR_PARAMETROS = 'PARAMETROS_POBLAR_PARAMETROS'
export const PARAMETROS_DISPLAY_MODAL_CREACION = 'PARAMETROS_DISPLAY_MODAL_CREACION'
export const PARAMETROS_MODIFICAR_INPUTS = 'PARAMETROS_MODIFICAR_INPUTS'
export const PARAMETROS_ADICIONAR_CONFIGURACION = 'PARAMETROS_ADICIONAR_CONFIGURACION'
export const PARAMETROS_ELIMINAR_CONFIGURACIONES = 'PARAMETROS_ELIMINAR_CONFIGURACIONES'
export const PARAMETROS_ELIMINAR_CONFIGURACION = 'PARAMETROS_ELIMINAR_CONFIGURACION'
export const PARAMETROS_ACTUALIZAR_PARAMETRO = 'PARAMETROS_ACTUALIZAR_PARAMETRO'
export const PARAMETROS_ACTUALIZAR_PARAMETROS = 'PARAMETROS_ACTUALIZAR_PARAMETROS'
export const PARAMETROS_SELECCIONAR_PARAMETRO = 'PARAMETROS_SELECCIONAR_PARAMETRO'
export const PARAMETROS_ADICIONAR_DETALLE = 'PARAMETROS_ADICIONAR_DETALLE'
export const PARAMETROS_ELIMINAR_DETALLE = 'PARAMETROS_ELIMINAR_DETALLE'
export const PARAMETROS_ELIMINAR_DETALLES = 'PARAMETROS_ELIMINAR_DETALLES'
export const PARAMETROS_ACTUALIZAR_DETALLES_PARAMETRO = 'PARAMETROS_ACTUALIZAR_DETALLES_PARAMETRO'
export const PARAMETROS_ELIMINAR_PARAMETROS = 'PARAMETROS_ELIMINAR_PARAMETROS'
export const PARAMETROS_REINICIAR_ESTADO = 'PARAMETROS_REINICIAR_ESTADO'
export const PARAMETROS_MODIFICAR_FILTRO = 'PARAMETROS_MODIFICAR_FILTRO'
export const PARAMETROS_ORDENAR_COLUMNA = 'PARAMETROS_ORDENAR_COLUMNA'
export const PARAMETROS_GUARDANDO = 'PARAMETROS_GUARDANDO'
//#endregion

//#region BITACORA
export const BITACORA_POBLAR_BITACORA = 'BITACORA_POBLAR_BITACORA'
export const BITACORA_REINICIAR_ESTADO = 'BITACORA_REINICIAR_ESTADO'
export const BITACORA_ACTUALIZAR_MOSTRAR_REGISTROS = 'BITACORA_ACTUALIZAR_MOSTRAR_REGISTROS'
export const BITACORA_MOSTRAR_MODAL_CRITERIOS_FILTRADO = 'BITACORA_MOSTRAR_MODAL_CRITERIOS_FILTRADO'
export const BITACORA_ADICIONAR_CRITERIO_FILTRADO = 'BITACORA_ADICIONAR_CRITERIO_FILTRADO'
export const BITACORA_ELIMINAR_CRITERIO_FILTRADO = 'BITACORA_ELIMINAR_CRITERIO_FILTRADO'
export const BITACORA_CONMUTAR_SELECCION_CRITERIO_FILTRADO = 'BITACORA_CONMUTAR_SELECCION_CRITERIO_FILTRADO'
export const BITACORA_MODIFICAR_CRITERIO_FILTRADO = 'BITACORA_MODIFICAR_CRITERIO_FILTRADO'
export const BITACORA_APLICAR_CRITERIOS_FILTRADO = 'BITACORA_APLICAR_CRITERIOS_FILTRADO'
export const BITACORA_MODIFICAR_INPUTS = 'BITACORA_MODIFICAR_INPUTS'
export const BITACORA_ORDENAR_TABLA = 'BITACORA_ORDENAR_TABLA'
//#endregion

//#region CARGUE_MASIVO
export const CARGUEMASIVO_REINICIAR_ESTADO = 'CARGUEMASIVO_REINICIAR_ESTADO'
export const CARGUEMASIVO_MOSTRAR_MODAL_DESCARGA = 'CARGUEMASIVO_MOSTRAR_MODAL_DESCARGA'
export const CARGUEMASIVO_MODIFICAR_INPUTS = 'CARGUEMASIVO_MODIFICAR_INPUTS'
export const CARGUEMASIVO_MOSTRAR_MODAL_CARGA = 'CARGUEMASIVO_MOSTRAR_MODAL_CARGA'
export const CARGUEMASIVO_ELIMINAR_CRITERIO_FILTRADO = 'CARGUEMASIVO_ELIMINAR_CRITERIO_FILTRADO'
export const CARGUEMASIVO_CONMUTAR_SELECCION_CRITERIO_FILTRADO = 'CARGUEMASIVO_CONMUTAR_SELECCION_CRITERIO_FILTRADO'
export const CARGUEMASIVO_MODIFICAR_CRITERIO_FILTRADO = 'CARGUEMASIVO_MODIFICAR_CRITERIO_FILTRADO'
export const CARGUEMASIVO_APLICAR_CRITERIOS_FILTRADO = 'CARGUEMASIVO_APLICAR_CRITERIOS_FILTRADO'
export const CARGUEMASIVO_POBLAR_CARGUEMASIVO = 'CARGUEMASIVO_POBLAR_CARGUEMASIVO'
export const CARGUEMASIVO_ACTUALIZAR_MOSTRAR_REGISTROS = 'CARGUEMASIVO_ACTUALIZAR_MOSTRAR_REGISTROS'
export const CARGUEMASIVO_MOSTRAR_MODAL_CRITERIOS_FILTRADO = 'CARGUEMASIVO_MOSTRAR_MODAL_CRITERIOS_FILTRADO'
export const CARGUEMASIVO_ADICIONAR_CRITERIO_FILTRADO = 'CARGUEMASIVO_ADICIONAR_CRITERIO_FILTRADO'
export const CARGUEMASIVO_DESCARGA_GUARDANDO = 'CARGUEMASIVO_DESCARGA_GUARDANDO'
export const CARGUEMASIVO_ORDENAR_TABLA = 'CARGUEMASIVO_ORDENAR_TABLA'
export const CARGUEMASIVO_POBLAR_OPCION_FORMULARIOS = 'CARGUEMASIVO_POBLAR_OPCION_FORMULARIOS'
//#endregion

//#region REGISTROS_FORMULARIOS
export const REGISTROS_POBLAR_REGISTROS = 'REGISTROS_POBLAR_REGISTROS'
export const REGISTROS_DISPLAY_MODAL_CREACION = 'REGISTROS_DISPLAY_MODAL_CREACION'
export const REGISTROS_MODIFICAR_INPUTS = 'REGISTROS_MODIFICAR_INPUTS'
export const REGISTROS_SELECCIONAR_ARTICULO = 'REGISTROS_SELECCIONAR_ARTICULO'
export const REGISTROS_ACTUALIZAR_ARTICULO = 'REGISTROS_ACTUALIZAR_ARTICULO'
export const REGISTROS_ELIMINAR_ARTICULO = 'REGISTROS_ELIMINAR_ARTICULO'
export const REGISTROS_CONMUTAR_SELECCION_REGISTROS = 'REGISTROS_CONMUTAR_SELECCION_REGISTROS'
export const REGISTROS_POBLAR_REGISTROS_DELTA = 'REGISTROS_POBLAR_REGISTROS_DELTA'
export const REGISTROS_REINICIAR_ESTADO = 'REGISTROS_REINICIAR_ESTADO'
export const REGISTROS_GUARDANDO = 'REGISTROS_GUARDANDO'
export const REGISTROS_MOSTRAR_MODAL_DESCARGA = 'REGISTROS_MOSTRAR_MODAL_DESCARGA'
export const REGISTROS_ACTUALIZAR_MOSTRAR_REGISTROS = 'REGISTROS_ACTUALIZAR_MOSTRAR_REGISTROS'
export const REGISTROS_CAMBIAR_PAGINA = 'REGISTROS_CAMBIAR_PAGINA'
export const REGISTROS_FILTRAR_REGISTROS = 'REGISTROS_FILTRAR_REGISTROS'
export const REGISTROS_DESCARGA_GUARDANDO = 'REGISTROS_DESCARGA_GUARDANDO'
export const REGISTROS_ORDENAR_REGISTROS = 'REGISTROS_ORDENAR_REGISTROS'
export const REGISTROS_ACTUALIZAR_ESTADO_ARTICULO = 'REGISTROS_ACTUALIZAR_ESTADO_ARTICULO'
export const REGISTROS_LLENADO_FORMULARIOS = 'REGISTROS_LLENADO_FORMULARIOS'
export const REGISTROS_MOSTRAR_MODAL_CRITERIOS_FILTRADO = 'REGISTROS_MOSTRAR_MODAL_CRITERIOS_FILTRADO'
export const REGISTROS_ADICIONAR_CRITERIO_FILTRADO = 'REGISTROS_ADICIONAR_CRITERIO_FILTRADO'
export const REGISTROS_ELIMINAR_CRITERIO_FILTRADO = 'REGISTROS_ELIMINAR_CRITERIO_FILTRADO'
export const REGISTROS_CONMUTAR_SELECCION_CRITERIO_FILTRADO = 'REGISTROS_CONMUTAR_SELECCION_CRITERIO_FILTRADO'
export const REGISTROS_MODIFICAR_CRITERIO_FILTRADO = 'REGISTROS_MODIFICAR_CRITERIO_FILTRADO'
export const REGISTROS_MODIFICAR_FILTRADO = 'REGISTROS_MODIFICAR_FILTRADO'
export const REGISTROS_MODIFICAR_OPTIONS = 'REGISTROS_MODIFICAR_OPTIONS'
export const REGISTROS_LOADING_OPTIONS = 'REGISTROS_LOADING_OPTIONS'
export const REGISTROS_BORRAR_FILTROS = 'REGISTROS_BORRAR_FILTROS'
export const REGISTROS_APLICAR_CRITERIOS_FILTRADO = 'REGISTROS_APLICAR_CRITERIOS_FILTRADO'
export const REGISTROS_POBLAR_SCRIPTS = 'REGISTROS_POBLAR_SCRIPTS'
export const REGISTROS_DISPLAY_MODAL_RESULTADO_SCRIPT = 'REGISTROS_DISPLAY_MODAL_RESULTADO_SCRIPT'
export const REGISTROS_MOSTRAR_MODAL_IMAGEN = 'REGISTROS_MOSTRAR_MODAL_IMAGEN'
//#endregion

//#region CREAR_REGISTRO
export const CREAR_REGISTRO_POBLAR_REGISTRO = 'CREAR_REGISTRO_POBLAR_REGISTRO'
export const CREAR_REGISTRO_SELECCION_TAB = 'CREAR_REGISTRO_SELECCION_TAB'
export const CREAR_REGISTRO_MODIFICAR_INPUTS = 'CREAR_REGISTRO_MODIFICAR_INPUTS'
export const CREAR_REGISTRO_REINICIAR_ESTADO = 'CREAR_REGISTRO_REINICIAR_ESTADO'
export const CREAR_REGISTRO_ACTUALIZAR_ARTICULO = 'CREAR_REGISTRO_ACTUALIZAR_ARTICULO'
export const CREAR_REGISTRO_DISPLAY_MODAL_CREACION = 'CREAR_REGISTRO_DISPLAY_MODAL_CREACION'
export const CREAR_REGISTRO_DESHABILITAR_BOTON_GUARDAR = 'CREAR_REGISTRO_DESHABILITAR_BOTON_GUARDAR'
export const CREAR_REGISTRO_REINICIAR_VALOR_VARIABLE_DEPENDIENTE = 'CREAR_REGISTRO_REINICIAR_VALOR_VARIABLE_DEPENDIENTE'
//#endregion

//#region USUARIOS
export const USUARIOS_ACTUALIZAR_USUARIO = 'USUARIOS_ACTUALIZAR_USUARIO'
export const USUARIOS_POBLAR_USUARIOS = 'USUARIOS_POBLAR_USUARIOS'
export const USUARIOS_DISPLAY_MODAL_CREACION = 'USUARIOS_DISPLAY_MODAL_CREACION'
export const USUARIOS_CHANGE_USERNAME_FROM_EMAIL = 'USUARIOS_CHANGE_USERNAME_FROM_EMAIL'
export const USUARIOS_ACTUALIZAR_SECCION = 'USUARIOS_ACTUALIZAR_SECCION'
export const USUARIOS_MODIFICAR_FILTRO = 'USUARIOS_MODIFICAR_FILTRO'
export const USUARIOS_ORDENAR_COLUMNA = 'USUARIOS_ORDENAR_COLUMNA'
export const USUARIOS_GUARDANDO = 'USUARIOS_GUARDANDO'
export const USUARIOS_ACTUALIZAR_MOSTRAR_REGISTROS = 'USUARIOS_ACTUALIZAR_MOSTRAR_REGISTROS'
//#endregion

//#region ROLES
export const ROLES_POBLAR_ROLES = 'ROLES_POBLAR_ROLES'
export const ROLES_SELECCIONAR_ROL = 'ROLES_SELECCIONAR_ROL'
export const ROLES_ADICIONAR_ROL = 'ROLES_ADICIONAR_ROL'
export const ROLES_PERMISOS_FORMULARIOS_OBTENIDOS = 'ROLES_PERMISOS_FORMULARIOS_OBTENIDOS'
export const ROLES_ADICIONAR_ELIMINAR_PERMISOS_ROL = 'ROLES_ADICIONAR_ELIMINAR_PERMISOS_ROL'
export const ROLES_ADICIONAR_ELIMINAR_FORMULARIOS_ROL = 'ROLES_ADICIONAR_ELIMINAR_FORMULARIOS_ROL'
export const ROLES_ACTUALIZAR_ROLES = 'ROLES_ACTUALIZAR_ROLES'
//#endregion


//#region SCRIPTS
export const SCRIPTS_POBLAR_SCRIPTS = 'SCRIPTS_POBLAR_SCRIPTS'
export const SCRIPTS_DISPLAY_MODAL_CREACION = 'SCRIPTS_DISPLAY_MODAL_CREACION'
export const SCRIPTS_MODIFICAR_INPUTS = 'SCRIPTS_MODIFICAR_INPUTS'
export const SCRIPTS_SELECCIONAR_SCRIPT = 'SCRIPTS_SELECCIONAR_SCRIPT'
export const SCRIPTS_ACTUALIZAR_SCRIPT = 'SCRIPTS_ACTUALIZAR_SCRIPT'
export const SCRIPTS_ELIMINAR_SCRIPT = 'SCRIPTS_ELIMINAR_SCRIPT'
export const SCRIPTS_CONMUTAR_SELECCION_SCRIPTS = 'SCRIPTS_CONMUTAR_SELECCION_SCRIPTS'
export const SCRIPTS_MODIFICAR_FILTRO = 'SCRIPTS_MODIFICAR_FILTRO'
export const SCRIPTS_ORDENAR_COLUMNA = 'SCRIPTS_ORDENAR_COLUMNA'
export const SCRIPTS_GUARDANDO = 'SCRIPTS_GUARDANDO'
export const SCRIPTS_CARGAR_SCRIPT = 'SCRIPTS_CARGAR_SCRIPT'
export const SCRIPTS_DISPLAY_MODAL_RESULTADO_SCRIPT = 'SCRIPTS_DISPLAY_MODAL_RESULTADO_SCRIPT'
export const SCRIPTS_ADICIONAR_ARGUMENTO = 'SCRIPTS_ADICIONAR_ARGUMENTO'
export const SCRIPTS_MODIFICAR_ARGUMENTO = 'SCRIPTS_MODIFICAR_ARGUMENTO'
export const SCRIPTS_ELIMINAR_ARGUMENTOS = 'SCRIPTS_ELIMINAR_ARGUMENTOS'
//#endregion


//#region FUENTES
export const FUENTES_POBLAR_FUENTES = 'FUENTES_POBLAR_FUENTES'
export const FUENTES_OBTENER_CARPETAS = 'FUENTES_OBTENER_CARPETAS'
export const FUENTES_DISPLAY_MODAL_CREACION = 'FUENTES_DISPLAY_MODAL_CREACION'
export const FUENTES_AGREGAR_ARCHIVO = 'FUENTES_AGREGAR_ARCHIVO'
export const FUENTES_ELIMINAR_ARCHIVO = 'FUENTES_ELIMINAR_ARCHIVO'
export const FUENTES_DISPLAY_MODAL_EDITAR = 'FUENTES_DISPLAY_MODAL_EDITAR'
export const FUENTES_DISPLAY_MODAL_CREACION_FOLDER = 'FUENTES_DISPLAY_MODAL_CREACION_FOLDER'
export const FUENTES_MODIFICAR_INPUTS = 'FUENTES_MODIFICAR_INPUTS'
export const FUENTES_SELECCIONAR_FUENTE = 'FUENTES_SELECCIONAR_FUENTE'
export const FUENTES_ACTUALIZAR_FUENTE = 'FUENTES_ACTUALIZAR_FUENTE'
export const FUENTES_ELIMINAR_FUENTE = 'FUENTES_ELIMINAR_FUENTE'
export const FUENTES_CONMUTAR_SELECCION_FUENTES = 'FUENTES_CONMUTAR_SELECCION_FUENTES'
export const FUENTES_MODIFICAR_FILTRO = 'FUENTES_MODIFICAR_FILTRO'
export const FUENTES_ORDENAR_COLUMNA = 'FUENTES_ORDENAR_COLUMNA'
export const FUENTES_GUARDANDO = 'FUENTES_GUARDANDO'
export const FUENTES_CARGAR_FUENTE = 'FUENTES_CARGAR_FUENTE'
export const FUENTES_UPDATE_TREE_FUENTES = 'FUENTES_UPDATE_TREE_FUENTES'
//#endregion

//#region HOME
export const HOME_RESET_STATE = 'HOME_RESET_STATE'
export const HOME_POBLAR_SCRIPTS = 'HOME_POBLAR_SCRIPTS'
export const HOME_SELECCIONAR_SCRIPT = 'HOME_SELECCIONAR_SCRIPT'
export const HOME_OBTENER_IFRAME_URL = 'HOME_OBTENER_IFRAME_URL'
export const HOME_MODIFICAR_INPUTS = 'HOME_MODIFICAR_INPUTS'
export const HOME_SETEAR_LOADING = 'HOME_SETEAR_LOADING'

//#endregion