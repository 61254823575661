import React, { Component } from 'react'
import ShowRecords from './ShowRecords'

export default class ButtonStrip extends Component {
    render() {
        return (

            <div>
                <div className="buttonstrip">
                    <div className="buttonstrip-options">
                        {this.props.actions}
                        {
                            (this.props.searchBar &&
                                <div className="searchbar-buttonstrip" style={{ marginLeft: '3px' }}>
                                    <input className="form-control input-sm" type="search" placeholder="&#xf002; Búsqueda por nombre" value={this.props.filtro} onChange={e => this.props.searchBar(e.target.value)} />
                                </div>
                            )
                        }
                    </div>
                    <div className="buttonstrip-records">
                        {
                            (!!this.props.showRecords && !!this.props.totalRecords &&
                                <ShowRecords showRecords={this.props.showRecords} totalRecords={this.props.totalRecords} changeRecordsToShow={this.props.changeRecordsToShow} />)
                        }
                        {
                            (this.props.showRecords < this.props.totalRecords && <div>{this.props.pagination}</div>)
                        }
                    </div>
                </div>
                {
                    this.props.formRequest && <div>
                        <div className="separator"></div>
                        {this.props.formRequest}
                    </div>
                }
            </div>
        )
    }
}




