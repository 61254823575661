import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import numeral from 'numeral'

import ButtonStrip from '../../Layout/components/ButtonStrip'
import BoxButton from '../../Layout/components/BoxButton'
import BoxSection from '../../Layout/components/BoxSection'

import {
    agregarEliminarPermiso,
    almacenarRolPermisosFormularios
} from '../actions'

import { findValues } from '../index'

export const tableColumns = [
    { ID: 1, CAPTION: 'Nombre', VALUE: 'NOM_OBJETO', TYPE: 'string', FORMAT: '', WIDTH: '70%' },
]

class DetalleRoles extends Component {
    render() {
        const { selected, objetos, rol, saving } = this.props
        if (!selected) {
            return (
                <div style={{ padding: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: '1.5em', color: 'rgb(187,187,187)', fontStyle: 'italic', fontWeight: '100' }}><FontAwesome name="info-circle" /> Seleccione un rol para modificar sus permisos</p>
                </div>
            )
        }

        const buttons = [
            <BoxButton key={`action[1][0]`} name="floppy-o" title="Guardar" onClick={() => this.props.almacenarRolPermisosFormularios()} disabled={saving} />
        ]
        const records = objetos.valueSeq().toList();

        return (
            <div className="col-md-12 col-sm-12 col-xs-12" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div className="widget" style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: '0' }}>
                    <BoxSection title={`Permisos - Rol - ${rol.get('NOMBRE')}`} stretch>
                        <div style={{ padding: "0% 1%" }}>
                            <ButtonStrip
                                actions={buttons}
                            />
                        </div>
                        <div className="table-scrolled">
                            <table className="table table-condensed table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th style={{ width: '25px' }}></th>
                                        {
                                            tableColumns.map(x => {
                                                return (
                                                    <th key={`th1[${x.ID}]`}>{x.CAPTION}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        records.count() > 0 ? records.map((a, b) => {
                                            const checked = !!rol.getIn(`PERMISOS.${a.get('COD_OBJETO')}`.split('.'))
                                            return (
                                                <tr key={`tr2[${a.get('COD_OBJETO')}]`}>
                                                    <td key="td1[0][0]">
                                                        <input type="checkbox" checked={checked} onChange={(e) => this.props.agregarEliminarPermiso(a, e.target.checked)} />
                                                    </td>
                                                    {
                                                        tableColumns.map((c, d) => {
                                                            let alignment = 'left'
                                                            let value = findValues(c, a)
                                                            if (c.TYPE) {
                                                                switch (c.TYPE) {
                                                                    case 'datetime':
                                                                        value = moment(value).format(c.FORMAT)
                                                                        alignment = 'right'
                                                                        break
                                                                    case 'bool':
                                                                        if (typeof value === 'string') value = parseInt(value, 10)
                                                                        value = <div style={{ textAlign: 'center', fontSize: '1.1em' }}><i className={`fa fa-${value ? 'check-' : ''}square`} /></div>
                                                                        break
                                                                    case 'number':
                                                                        value = numeral(value).format(c.Format)
                                                                        alignment = 'right'
                                                                        break
                                                                    default:
                                                                }
                                                            }
                                                            const style = { textAlign: alignment }

                                                            return (
                                                                <td key={`td1[${a.get('COD_OBJETO')}][${c.ID}]`} style={style}>{value}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        }) : <tr><td colSpan={tableColumns.length + 1} style={{ textAlign: 'center' }}>No existen registros</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </BoxSection>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.roles.get('selected'),
        objetos: state.roles.get('objetos'),
        saving: state.roles.get('saving'),
        rol: state.roles.getIn(['roles', String(state.roles.get('selected'))])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        agregarEliminarPermiso: (permiso, checked) => dispatch(agregarEliminarPermiso(permiso, checked)),
        almacenarRolPermisosFormularios: () => dispatch(almacenarRolPermisosFormularios())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetalleRoles)
