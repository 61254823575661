import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";

import Modal from '@mawesi-sas/mws_react_component_modal';
import { List } from "immutable";

import {
    showHideModalSelector,
    getDataFromServer,
    clearPagesModalSelector,
    addTitleIfOverflow
} from "../actions";
import { Input } from '@mawesi-sas/mws_react_component_input';

const WAIT_INTERVAL = 1500;
const ENTER_KEY = 13;

class ModalSelectorFromList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.modalSelector.get("filter")
        };
    }

    handleSelectFromSelector = (e, obj) => {
        this.props.modalSelector.get("action")(obj);
        this.setState({ value: "" });
        this.props.showHideModalSelector(false);
    };

    handleOnScroll = e => {
        const page = this.props.modalSelector.get("data").count() / 100;
        if (page >= 1) {
            const table = ReactDOM.findDOMNode(this.table);
            const tableHeight = table.clientHeight - 297;
            const scrollTop = e.target.scrollTop;
            if (scrollTop >= tableHeight * 0.8) {
                const loadPage = Math.floor(page) + 1;
                this.props.getDataFromServer(loadPage);
            }
        }
    };

    handleOnChange = value => {
        clearTimeout(this.timer);
        this.setState({ value });
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    };

    handleOnKeyDown = key => {
        if (key.keyCode === ENTER_KEY) {
            clearTimeout(this.timer);
            this.triggerChange();
        }
    };

    triggerChange = () => {
        this.props.clearPagesModalSelector();
        this.props.getDataFromServer(1);
    };

    modalContent = () => {
        const { modalSelector } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div style={{ marginBottom: "10px" }}>
                            <Input
                                type="text"
                                reducer="layout"
                                path="modalSelector.filter"
                                afterChange={this.handleOnChange}
                                onKeyDown={this.handleOnKeyDown}
                                placeholder="Busqueda..."
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12" style={{ position: "relative" }}>
                        {modalSelector.get("loading") && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <span>
                                    <FontAwesome name="refresh" spin size="3x" />
                                </span>
                            </div>
                        )}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                height: "300px",
                                overflowX: "hidden",
                                marginBottom: "20px",
                                border: "1px solid #ddd"
                            }}
                            onScroll={e => this.handleOnScroll(e)}
                        >
                            <table
                                ref={ref => (this.table = ref)}
                                className="table table-bordered table-condensed table-striped table-hover nowrap"
                                style={{ marginBottom: 0, fontSize: "11px" }}
                            >
                                <thead>
                                    <tr>
                                        {modalSelector.get("fields").map((x, n) => {
                                            return (
                                                <th width={`${x.get("width")}`} key={`th4[${n}]`}>
                                                    {x.get("caption")}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (modalSelector.get('data').count() > 0) ? (modalSelector.get('data') || List([]))
                                            .map((x, n) => {
                                                const tds = modalSelector.get('fields').map((y, n1) => {
                                                    return (
                                                        <td key={`td4[${n}][${n1}]`} onMouseEnter={e => addTitleIfOverflow(e.target)}>{x.get(y.get('value'))}</td>
                                                    )
                                                })
                                                return (
                                                    <tr key={`tr4[${n}]`} style={{ cursor: 'pointer' }} onClick={(e) => { this.handleSelectFromSelector(e, x.toJS()) }}>
                                                        {tds}
                                                    </tr>
                                                )
                                            }) : <tr>
                                                <td colSpan={modalSelector.get('fields').count()} style={{ textAlign: 'center' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '270px' }}>
                                                        <span></span>
                                                        <span><FontAwesome name="refresh" spin size='3x' /></span>
                                                        <span></span>
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { modalSelector } = this.props;
        return (
            <Modal
                ancho="600px"
                mostrar={modalSelector.get("show")}
                contenido={this.modalContent()}
                titulo={modalSelector.get("title")}
                nombre="modal_selector"
                onCerrarModal={() => this.props.showHideModalSelector(false)}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        modalSelector: state.layout.get("modalSelector")
    };
};

const mapDispatchToProps = {
    showHideModalSelector,
    getDataFromServer,
    clearPagesModalSelector
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalSelectorFromList);
