import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Modal from '@mawesi-sas/mws_react_component_modal'
import { Input } from '@mawesi-sas/mws_react_component_input'


import {
    showHideModalPoliticas,
    acceptPolicies
} from '../actions'


class ModalPoliticas extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalPoliticas')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalPoliticas .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')

        setTimeout(() => this.props.showHideModalPoliticas(false), 300)
    }

    modalContent = (modalPoliticas) => {
        return (
            <div className="modal-dialog" style={{ width: '90%' }}>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Input type="checkbox" reducer="layout" path="modalPoliticas.acepta_terminos_condiciones" />
                        <span>He le&iacute;do y acepto los <a href="/static/terminos-y-condiciones.pdf" target="_blank">t&eacute;rminos y condiciones</a></span>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Input type="checkbox" reducer="layout" path="modalPoliticas.acepta_politica_privacidad" />
                        <span>He le&iacute;do y acepto la <a href="https://www.lonjacali.org/wp-content/uploads/2019/09/AVISO-DE-PRIVACIDAD-LONJACALI.pdf" target="_blank">pol&iacute;tica de privacidad</a></span>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { modalPoliticas } = this.props
        if (!modalPoliticas.get('show')) return null

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "50%" : "30%"}
                mostrar={modalPoliticas.get('show')}
                contenido={this.modalContent(modalPoliticas)}
                titulo={"Aviso de política de privacidad y de términos y condiciones"}
                nombre="modalPoliticas"
                botones={[
                    <button key="accept"
                    className="btn btn-default"
                    disabled={!modalPoliticas.get('acepta_terminos_condiciones') || !modalPoliticas.get('acepta_politica_privacidad')}
                    onClick={() => this.props.acceptPolicies()}>Aceptar</button>
                ]}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modalPoliticas: state.layout.get('modalPoliticas'),
        width: state.layout.get('width'),
        height: state.layout.get('height'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showHideModalPoliticas: (state) => dispatch(showHideModalPoliticas(state)),
        acceptPolicies: () => dispatch(acceptPolicies())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPoliticas)