import * as types from '../../types'
import { API_URL } from '../../utils/constants'
import { createAxiosInstance } from '../../utils/helpers'
import { displayMessages, setSavingState } from '../Layout/actions'
import objectifyArray from 'objectify-array'

export function obtenerUsuarios(page = 1) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const size = getState().usuarios.get('size')

        const fn1 = () => axios.get(`${API_URL}/account`, {
            params: {page, size}
        })
        const fn2 = () => axios.get(`${API_URL}/roles`)

        Promise.all([fn1(), fn2()])
            .then((res) => {
                const usuarios = objectifyArray(res[0].data.registros, { by: ['ID'], recursive: true })
                const roles = objectifyArray(res[1].data, { by: ['ID'], recursive: true })

                const fn3 = d => d({ type: types.USUARIOS_POBLAR_USUARIOS, payload: { usuarios, roles, total: res[0].data.total, page } })
                fn3(dispatch)
            })
            .catch((err) => {
                console.log(err)
                displayMessages(dispatch, err)
            })
    }
}

export function displayModalCreate(show, title, mode, id = null) {
    return (dispatch) => {
        dispatch({ type: types.USUARIOS_DISPLAY_MODAL_CREACION, payload: { show, title, mode, id } })
    }
}

export function assingUsernameFromEmail() {
    return {
        type: types.USUARIOS_CHANGE_USERNAME_FROM_EMAIL
    }
}

export function actualizarMostrarRegistros(records) {
    return (dispatch) => {
        dispatch({
            type: types.USUARIOS_ACTUALIZAR_MOSTRAR_REGISTROS,
            payload: records
        })
        dispatch(obtenerUsuarios())
    }
}

export function almacenarUsuario() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('usuarios', true))
        let data = getState().usuarios.getIn(['modalCreate', 'data'])
        const mode = getState().usuarios.getIn(['modalCreate', 'mode'])

        if (mode === 'create') {
            axios.post(`${API_URL}/account`, data)
                .then(res => {
                    const id = res.data.registros[0].ID
                    const usuario = objectifyArray([res.data.registros[0]], { by: ['ID'], recursive: true })
                    dispatch({ type: types.USUARIOS_ACTUALIZAR_USUARIO, payload: usuario[id] })
                    dispatch(displayModalCreate(false))
                    dispatch(setSavingState('usuarios', false))
                    displayMessages(dispatch, { success: 'El usuario ha sido creado exitosamente' })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('usuarios', false))
                    displayMessages(dispatch, err)
                })
        } else if (mode === 'edit') {
            axios.put(`${API_URL}/account/${data.get('ID')}`, data)
                .then(res => {
                    const usuario = objectifyArray([res.data.registros[0]], { by: ['ID'], recursive: true })
                    dispatch({ type: types.USUARIOS_ACTUALIZAR_USUARIO, payload: usuario[data.get('ID')] })
                    dispatch(displayModalCreate(false))
                    dispatch(setSavingState('usuarios', false))
                    displayMessages(dispatch, { success: 'El usuario ha sido actualizado exitosamente' })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('usuarios', false))
                    displayMessages(dispatch, err)
                })
        }
    }
}

export function filtrarUsuario(termino) {
    return (dispatch) => {
        dispatch({ type: types.USUARIOS_MODIFICAR_FILTRO, payload: termino })
    }
}

export function ordenarPor(columna) {
    return (dispatch) => {
        dispatch({ type: types.USUARIOS_ORDENAR_COLUMNA, payload: columna })
    }
}