import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from '@mawesi-sas/mws_react_component_modal';

import { displayModalScriptResponse } from "../actions";

class ModalScriptResponse extends Component {
  handleCloseModal = e => {
    const modal = document.getElementById("modalScriptResponse");
    modal.classList.remove("fadeIn");
    modal.classList.add("fadeOut");

    const modalDialog = document.querySelector(
      "#modalScriptResponse .mws-modal-dialog"
    );
    modalDialog.classList.remove("zoomIn");
    modalDialog.classList.add("zoomOut");
    setTimeout(
      () =>
        this.props.displayModalScriptResponse(
          false,
          null,
          this.props.modalScriptResponse.get("pid")
        ),
      300
    );
  };

  modalContent = modalScriptResponse => {
    return (
      <div style={{ height: "100%" }}>
        <div
          style={{ height: "100%" }}
          dangerouslySetInnerHTML={{
            __html: modalScriptResponse.getIn("data".split("."))
          }}
        ></div>
      </div>
    );
  };

  render() {
    const { modalScriptResponse } = this.props;
    if (!modalScriptResponse.get("show")) return null;

    return (
      <Modal
        alto="90%"
        ancho="90%"
        mostrar={modalScriptResponse.get("show")}
        contenido={this.modalContent(modalScriptResponse)}
        titulo={"Resultado de la ejecución del script"}
        nombre="modalScriptResponse"
        onCerrarModal={e => this.handleCloseModal(e)}
        maximizable={true}
        maximized={true}
      />
    );
  }
}

const mapStateToProps = (state, onwProps) => {
  return {
    modalScriptResponse: state.registros.get("modalScriptResponse")
  };
};

const mapDispatchToProps = {
  displayModalScriptResponse
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalScriptResponse);
