import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from '@mawesi-sas/mws_react_component_modal'

import { displayModalImagen } from "../actions";

class ModalImagen extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('ModalImagen')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        setTimeout(() => this.props.displayModalImagen(false), 300)
    }

    render() {
        const { ModalImagen } = this.props
        if (!ModalImagen.get('show')) return null

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "60%" : "40%"}
                mostrar={ModalImagen.get('show')}
                contenido={<img src={ModalImagen.get('imageSrc')} alt="imagen" width="100%"/>}
                titulo={ModalImagen.get('title')}
                nombre="ModalImagen"
                onCerrarModal={(e) => this.handleCloseModal(e)}
                botones={[]}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ModalImagen: state.registros.get('modalImagen'),
        width: state.layout.get('width'),
        height: state.layout.get('height')
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayModalImagen: (show) => dispatch(displayModalImagen(show)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalImagen)