import React, { Component } from 'react'
import { connect } from 'react-redux'
import numeral from 'numeral'
import { getColorForPercentage } from '../../../utils/helpers'

class Pie extends Component {
    componentDidMount() {
        const { seccion, completado } = this.props
        this.drawPie(seccion, completado)
    }

    componentDidUpdate() {
        const { seccion, completado } = this.props
        this.drawPie(seccion, completado)
    }

    drawPie = (seccion, completado) => {
        var canvas = document.getElementById(`pie[${seccion}]`)
        var ctx = canvas.getContext("2d")

        var angle = Math.PI * 2
        var beginAngle = 0
        var endAngle = 0
        beginAngle = endAngle
        endAngle = endAngle + angle
        ctx.beginPath()
        ctx.fillStyle = '#ccc'
        ctx.moveTo(6, 6)
        ctx.arc(6, 6, 5, beginAngle, endAngle)
        ctx.fill()

        angle = Math.PI * (completado * 2)
        beginAngle = 0
        endAngle = 0
        beginAngle = endAngle
        endAngle = endAngle + angle
        ctx.beginPath()
        ctx.fillStyle = getColorForPercentage(completado)
        ctx.moveTo(6, 6)
        ctx.arc(6, 6, 5, beginAngle, endAngle)
        ctx.fill()
    }

	render() {
        const { seccion, completado } = this.props
        return (
            <div className="pie-indicador">
                <canvas id={ `pie[${seccion}]` } width="12" height="12" title={`${numeral(completado).format('0%')} completado`}></canvas>
            </div>
		)
	}
}

function mapStateToProps(state, ownProps) {
    return {
        completado: state.crearregistro.getIn(['secciones', String(ownProps.seccion), 'COMPLETADO']),
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pie)