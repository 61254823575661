import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import Modal from '@mawesi-sas/mws_react_component_modal'

import {
    showHideModalMsgBox
} from '../../Layout/actions'

class ModalMsgBox extends Component {
    renderMessage = (width, message) => {
        if (width === 500) {
            return (
                <div className="row">
                    <div className="col-sm-2" style={{ textAlign: 'right' }}>
                        <FontAwesome style={{ color: '#C00000', fontSize: '3em' }} name="exclamation-circle" />
                    </div>
                    <div className="col-sm-10" style={{ whiteSpace: 'pre-line', maxHeight: '200px', overflow: 'scroll', fontSize: '14px', fontWeight: '300' }}>
                        <div>{ message }</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="row">
                    <div className="col-sm-12" style={{ whiteSpace: 'pre-line', maxHeight: '600px', overflow: 'scroll' }}>
                        <div dangerouslySetInnerHTML={{__html: message}} />
                    </div>
                </div>
            )
        }
    }

    render() {
        const { modalMsgBox } = this.props
        if (!modalMsgBox.get('show')) {
            return null
        }

        let width = 500
        const message = modalMsgBox.get('message')
        return (
            <Modal
                mostrar={modalMsgBox.get('show')}
                contenido={this.renderMessage(width, message)}
                titulo='Ha ocurrido un error'
                nombre='msgbox'
                onCerrarModal={() => this.props.showHideModalMsgBox(false)}
            />
        )
    }
}

const mapStateToProps = (state, onwProps) => {
    return {
        modalMsgBox: state.layout.get('msgBox')
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showHideModalMsgBox: () => dispatch(showHideModalMsgBox())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMsgBox)
