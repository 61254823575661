import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { Modal } from '@mawesi-sas/mws_react_component_modal';

class ModalWait extends Component {
    modalContent = () => {
        return (
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="col-md-2 col-sm-2 col-xs-12">
                        <span><FontAwesome name="spinner" spin size='3x' /></span>
                    </div>
                    <div className="col-md-10 col-sm-10 col-xs-12">
                        <span>Por favor espere mientras se procesa la información.</span>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { waiting } = this.props
        if (!waiting) return null

        return (
            <Modal
                ancho={window.innerWidth < window.innerHeight ? "90%" : "40%"}
                mostrar={waiting}
                titulo="Cargando..."
                nombre="modalWait"
                contenido={this.modalContent()}
                botones={[]}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        waiting: state.layout.get('waiting')
    }
}

const mapDispatchToProps = () => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalWait)