import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Modal from '@mawesi-sas/mws_react_component_modal'


import {
    showHideModalFilter,
    addModalFilterCriteria,
    removeCheckedModalFilterCriterias,
    toggleCheckedModalFilterCriterias,
    updateModalFilterCriteria,
    applyFilterCriterias,
} from '../actions'

import { operators } from '../../../utils/helpers'

class ModalFilter extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalFilter')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalFilter .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')

        setTimeout(() => this.props.showHideModalFilter(false), 300)
    }

    handleApplyFilters = (e) => {
        this.props.applyFilterCriterias(this.props.formularioId)
        this.handleCloseModal()
    }

    modalContent = (modalFilter) => {
        const resumen = this.props.secciones.valueSeq().filter(x => x.get('RESUMEN')).first() || ''
        return (
            <div>
                <div className="searchbar">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1 }}>
                            <button className="box-button" title="Nuevo filtro" onClick={e => this.props.addModalFilterCriteria()}><FontAwesome name="plus" tag="i" /></button>
                            <button className="box-button" title="Eliminar" onClick={e => this.props.removeCheckedModalFilterCriterias()} disabled={modalFilter.get('filters').filter(item => item.get('checked')).count() === 0}><FontAwesome name="trash" tag="i" /></button>
                            <button className="box-button" title="Aceptar" onClick={e => this.handleApplyFilters()}><FontAwesome name="check" tag="i" /></button>
                        </div>
                    </div>
                </div>
                <div className="modal-dialog" style={{ width: '90%' }}>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12" style={{ height: '200px', overflow: 'auto' }}>
                            <table className="table table-bordered table-condensed">
                                <thead>
                                    <tr>
                                        <th style={{ width: '20px' }}></th>
                                        <th style={{ width: '26%' }}>Columna</th>
                                        <th style={{ width: '26%' }}>Operador</th>
                                        <th style={{ width: '48%' }}>Filtro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        modalFilter.get('filters').count() > 0 ? modalFilter.get('filters').map((item, index) => {
                                            const key = `tr2[${index}]`
                                            return (
                                                <tr key={key} className={`${item.get('checked') ? 'info' : ''}`}>
                                                    <td style={{ verticalAlign: 'middle' }}><input type="checkbox" onChange={e => this.props.toggleCheckedModalFilterCriterias(index, e.target.checked)} checked={item.get('checked')} /></td>
                                                    <td>
                                                        <select className="form-control input-sm" value={item.get('column')} onChange={e => this.props.updateModalFilterCriteria(index, 'column', e.target.value)}>
                                                            <option value="" disabled>Seleccione una columna</option>
                                                            {
                                                                modalFilter.get('columns')
                                                                .filter(ele => ele.get('value').search(resumen.get('ID') + "_") === -1)
                                                                .map((x1, n1) => {
                                                                    return (
                                                                        <option key={`opt1[${x1.Id}][${n1}]`} value={x1.get('value')}>{x1.get('caption')}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className="form-control input-sm" value={item.get('operator')} onChange={e => this.props.updateModalFilterCriteria(index, 'operator', e.target.value)}>
                                                            {
                                                                operators.map((x2, n) => {
                                                                    return (
                                                                        <option key={`opt2[${x2.id}][${n}]`} value={x2.value}>{x2.caption}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control input-sm" value={item.get('value')} onChange={e => this.props.updateModalFilterCriteria(index, 'value', e.target.value)} />
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr><td colSpan="4" style={{ textAlign: 'center' }}>No hay filtros</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { modalFilter } = this.props
        if (!modalFilter.get('show')) return null

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "90%" : "70%"}
                mostrar={modalFilter.get('show')}
                contenido={this.modalContent(modalFilter)}
                titulo={modalFilter.get('title')}
                nombre="modalFilter"
                onCerrarModal={(e) => this.handleCloseModal(e)}
                botones={[]}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modalFilter: state.registros.get('modalFilter'),
        secciones: state.registros.get('secciones'),
        width: state.layout.get('width'),
        height: state.layout.get('height'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showHideModalFilter: (state) => dispatch(showHideModalFilter(state)),
        addModalFilterCriteria: () => dispatch(addModalFilterCriteria()),
        removeCheckedModalFilterCriterias: () => dispatch(removeCheckedModalFilterCriterias()),
        toggleCheckedModalFilterCriterias: (index, checked) => dispatch(toggleCheckedModalFilterCriterias(index, checked)),
        updateModalFilterCriteria: (index, attr, value) => dispatch(updateModalFilterCriteria(index, attr, value)),
        applyFilterCriterias: (formularioId) => dispatch(applyFilterCriterias(formularioId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter)