import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Input from '@mawesi-sas/mws_react_component_input';
import { Link } from "react-router-dom";

import { clearMessage } from "../Layout/actions";
import { signIn } from "./actions";

import "../../css/signin.css";
import logo from "../../img/logo_01a.png";
import background from "../../img/background_2.jpg";

const cookies = new Cookies();

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPwd: false
    };
  }

  componentWillMount() {
    this.props.clearMessage();
    const token = cookies.get("token");
    if (token) this.props.history.push("/");
  }

  componentDidUpdate() {
    this.props.clearMessage();
  }

  render() {
    const { processing, message } = this.props;
    if (message) {
      switch (message.get("type")) {
        case "danger":
          toast.error(message.get("message"));
          break;
        case "warning":
          toast.warn(message.get("message"));
          break;
        case "success":
          toast.success(message.get("message"));
          break;
        default:
          toast.info(message.get("message"));
      }
    }

    return (
      <div
        className="signin-container"
        style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPositionX: '0%', backgroundPositionY: '100%', backgroundRepeat: 'no-repeat' }}
      >
        <div className="login-box animated fadeIn">
          <div>
            <img
              src={logo}
              width="60%"
              style={{ verticalAlign: "middle" }}
              alt="logo"
            />
            <p
              className="text-center"
              style={{ fontSize: "24px", fontWeight: 300, margin: "15px 0" }}
            >
              Ingrese con su cuenta
            </p>
            <form
              onSubmit={e => {
                e.preventDefault();
                this.props.signIn();
              }}
              style={{ width: "100%" }}
            >
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <Input
                      reducer="signin"
                      path="email"
                      type="email"
                      placeholder="Usuario"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12" style={{ margin: "10px 0" }}>
                    <Input
                      reducer="signin"
                      path="password"
                      type={this.state.showPwd ? "text" : "password"}
                      placeholder="Contraseña"
                    />
                    <FontAwesome
                      name="eye"
                      onTouchStart={e => this.setState({ showPwd: true })}
                      onTouchEnd={e => this.setState({ showPwd: false })}
                      onMouseDown={e => this.setState({ showPwd: true })}
                      onMouseUp={e => this.setState({ showPwd: false })}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "20px",
                        cursor: 'pointer'
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="btn btn-default btn-sm"
                      style={{ width: "100%", padding: "5px 30px" }}
                      disabled={processing}
                    >
                      {processing ? (
                        <span>
                          <FontAwesome name="circle-o-notch" spin /> Iniciando
                          sesión ...
                        </span>
                      ) : (
                        <span>Iniciar sesión</span>
                      )}
                    </button>
                  </div>
                </div>
                <div className="login-box-links">
                  <Link to="/recovery_password">Recuperar contraseña</Link>
                  <Link to="/signup">Registrarse</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    email: state.signin.get("email"),
    password: state.signin.get("password"),
    processing: state.signin.get("processing"),
    message: state.layout.get("message")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signIn: () => dispatch(signIn()),
    clearMessage: () => dispatch(clearMessage())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
