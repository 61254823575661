import * as types from '../../types'
import { API_URL } from '../../utils/constants'
import { createAxiosInstance, isNullOrEmpty } from '../../utils/helpers'
import { displayMessages, setSavingState } from '../Layout/actions'
import objectifyArray from 'objectify-array'
import { obtenerMaestras } from '../Maestras/actions';

export function obtenerVariables() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
		axios.get(`${API_URL}/variables`)
		.then((res) => {
            const variables = objectifyArray(res.data, { by: ['ID'], recursive: true })
			const fn1 = d => d({ type: types.VARIABLES_POBLAR_VARIABLES, payload: { variables } })
            fn1(dispatch)
            if (getState().maestras.get('maestras').count() === 0) {
                dispatch(obtenerMaestras())
            }
		})
		.catch((err) => {
			displayMessages(dispatch, err)
		})
    }
}

export function displayModalCreate(show, title, mode, id = null) {
    return (dispatch) => {
        dispatch({ type: types.VARIABLES_DISPLAY_MODAL_CREACION, payload: { show, title, mode, id } })
    }
}

export function seleccionarVariable(id) {
    return (dispatch) => {
        dispatch({ type: types.VARIABLES_SELECCIONAR_VARIABLE, payload: id })
    }
}

export function resetAfterTipoCampo() {
    return (dispatch, getState) => {
        const path = 'modalCreate.data'
        dispatch({ type: types.VARIABLES_MODIFICAR_INPUTS, payload: { path: `${path}.TIPO_DATO`, value: -1 } })
        dispatch({ type: types.VARIABLES_MODIFICAR_INPUTS, payload: { path: `${path}.TAMANO`, value: null } })
        dispatch({ type: types.VARIABLES_MODIFICAR_INPUTS, payload: { path: `${path}.MAESTRA_ID`, value: -1 } })
    }
}

export function conmutarSeleccionRegistros(checked) {
    return (dispatch) => {
        dispatch({ type: types.VARIABLES_CONMUTAR_SELECCION_VARIABLES, payload: checked })
    }
}

export function almacenarVariable() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('variables',true))
        let data = getState().variables.getIn(['modalCreate', 'data'])
        const mode = getState().variables.getIn(['modalCreate', 'mode'])

        data = data.set('TIPO_DATO', String(data.get('TIPO_DATO')) === '-1' ? null : data.get('TIPO_DATO'))
        if (String(data.get('TIPO_CAMPO')) === '-1') data = data.set('TIPO_CAMPO', null)
        if (String(data.get('TIPO_DATO')) === '-1') data = data.set('TIPO_DATO', null)
        if (String(data.get('MAESTRA_ID')) === '-1') data = data.set('MAESTRA_ID', null)
        if (data.get('MAESTRA_ID') !== null) data = data.set('MAESTRA_ID', Number(data.get('MAESTRA_ID')))

        if (mode === 'create') {
            axios.post(`${API_URL}/variables`, data)
            .then(res => {
                const id = res.data.ID
                const variable = objectifyArray([res.data], { by: ['ID'], recursive: true })
                dispatch({ type: types.VARIABLES_ACTUALIZAR_VARIABLE, payload: variable[id] })
                dispatch(displayModalCreate(false))
                dispatch(setSavingState('variables',false))
                displayMessages(dispatch, { success: 'La variable ha sido guardada exitosamente' })
            })
            .catch(err => {
                console.log(err)
                dispatch(setSavingState('variables',false))
                displayMessages(dispatch, err)
            })
        } else if (mode === 'edit') {
            axios.put(`${API_URL}/variables/${data.get('ID')}`, data)
            .then(res => {
                const variable = objectifyArray([res.data], { by: ['ID'], recursive: true })
                dispatch({ type: types.VARIABLES_ACTUALIZAR_VARIABLE, payload: variable[data.get('ID')] })
                dispatch(displayModalCreate(false))
                dispatch(setSavingState('variables',false))
                displayMessages(dispatch, { success: 'La variable ha sido guardada exitosamente' })
            })
            .catch(err => {
                console.log(err)
                dispatch(setSavingState('variables',false))
                displayMessages(dispatch, err)
            })
        }
    }
}

export function eliminarVariables() {
    return (dispatch, getState) => {
        let toDelete = getState().variables.get('variables').valueSeq().filter(x => x.get('SELECTED') === true)
        const total = toDelete.count()
        toDelete = toDelete.filter(x => x.get('ID') > 0)
        if (toDelete.count() > 0) {
            const axios = createAxiosInstance()
            const fns = []
            toDelete.map(x => x.get('ID')).forEach(x => {
                const fn = () => axios.delete(`${API_URL}/variables/${x}`)
                fns.push(fn)
            })
    
            const toResultObject = (promise) => {
                return promise
                .then(result => ({ success: true, result }))
                .catch(error => ({ success: false, error }));
            }
    
            Promise.all(fns.map(x => x()).map(toResultObject))
            .then(res => {
                const succeded = []
                const failed = []
                res.forEach(x => {
                    if (!x.success) {
                        failed.push(x.error.response.data.ExceptionMessage)
                    } else {
                        succeded.push(1)
                        dispatch({ type: types.VARIABLES_ELIMINAR_VARIABLE, payload: x.result.data.ID })
                    }
                })
                if (succeded.length > 0) displayMessages(dispatch, { success: `Se han eliminado ${succeded.length + (total - toDelete.count())} de los ${total} registros` })
                if (failed.length > 0) displayMessages(dispatch, { response: { status: 500, data: failed.join('\n') } })
            })
        } else {
            displayMessages(dispatch, { warning: 'No seleccionó ningún registro para eliminar'})
        }
    }
}

export function filtrarVariables(termino) {
    return (dispatch) => {
        dispatch({ type: types.VARIABLES_MODIFICAR_FILTRO, payload: termino })
    }
}

export function ordenarPor(columna) {
    return (dispatch) => {
        dispatch({ type: types.VARIABLES_ORDENAR_COLUMNA, payload: columna })
    }
}

export function fijarNoEditable(path) {
    return (dispatch, getState) => {
        if (path === 'modalCreate.data.FORMULA') {
            const val = getState().variables.getIn(path.split('.'))
            if (!isNullOrEmpty(val)) {
                dispatch({ type: types.VARIABLES_MODIFICAR_INPUTS, payload: { path: 'modalCreate.data.EDITABLE', value: false } })
            }
        }
    }
}