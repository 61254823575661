import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import numeral from 'numeral'
import Input from '@mawesi-sas/mws_react_component_input'

import ButtonStrip from '../../Layout/components/ButtonStrip'
import BoxButton from '../../Layout/components/BoxButton'

import {
    adicionarDetalle,
    eliminarDetalles,
    almacenarDetalles,
    marcarComoActualizado,
    toggleSelectAllItems,
} from '../actions'

import { showModalConfirmAction } from '../../Layout/actions'
import BoxSection from '../../Layout/components/BoxSection'

export function findValues(c, x) {
    let value = x.get(String(c.get('ID')))
    return value
}

class DetalleParametro extends Component {
    render() {
        const { selected, parametro, saving } = this.props
        if (!selected) {
            return (
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: '1.5em', color: 'rgb(187,187,187)', fontStyle: 'italic', fontWeight: '100' }}><FontAwesome name="info-circle" /> Seleccione un parámetro para visualizar/modificar su detalle</p>
                </div>
            )
        }

        const eliminarDisabled = parametro.get('DATA').filter(x => x.get('SELECTED')).count() === 0
        const buttons = []
        if (this.props.elementos.filter(x => x === "j5TqjX8J").count()) buttons.push(<BoxButton key={`action[1][0]`} name="floppy-o" title="Guardar" onClick={() => this.props.almacenarDetalles()} disabled={saving} />)
        if (this.props.elementos.filter(x => x === "j5TqjX8J").count()) buttons.push(<BoxButton key={`action[1][1]`} name="plus" title="Nuevo registro" onClick={() => this.props.adicionarDetalle()} />)
        if (this.props.elementos.filter(x => x === "BSsdoHAk").count()) buttons.push(<BoxButton key={`action[1][2]`} name="trash" title="Eliminar registro" onClick={() => this.props.showModalConfirmAction(true, 'Está seguro que desea eliminar los registros seleccionados?', () => this.props.eliminarDetalles())} disabled={eliminarDisabled} danger />)

        // const tableColumns = parametro.getIn(['CONFIGURACIONES']).valueSeq().sortBy(x => x.get('ORDER_KEY')).map(x => {
        //     return {
        //         ID: x.get('ID'),
        //         CAMPO: x.get('CAMPO'),
        //         CAPTION: x.get('NOMBRE'),
        //         VALUE: `CAMPO${x.get('CAMPO')}`,
        //     }
        // }).toJS()

        return (
            <div className="col-md-12" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <BoxSection title={<span className="widget-caption">Detalle parámetro: <strong>{parametro.get('NOMBRE')}</strong></span>}>
                    <div style={{ padding: "0% 1%" }}>
                        <ButtonStrip
                            actions={buttons}
                        />
                    </div>
                    <div className="table-scrolled">
                        <table className="table table-condensed table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th width="25px">
                                        <input type="checkbox" style={{ verticalAlign: 'middle' }} onChange={e => this.props.toggleSelectAllItems(e.target.checked)} checked={parametro.get('DATA').valueSeq().filter(x => x.get('SELECTED')).count() > 0 && parametro.get('DATA').valueSeq().filter(x => x.get('SELECTED')).count() >= parametro.get('DATA').valueSeq().count()} />
                                    </th>
                                    {
                                        parametro.get('COLUMNAS').map(p => {
                                            return <th key={`th4[${p.get('ID')}]`}>{p.get('CAPTION')}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    parametro.get('DATA').count() > 0 ? parametro.get('DATA').keySeq().sortBy(x => Number(x)).map(n => {
                                        const p = parametro.getIn(['DATA', n])
                                        return (
                                            <tr key={`tr4[${p.get('REGISTRO')}]`} className={p.get('SELECTED') ? 'info' : ''}>
                                                <td>
                                                    <Input
                                                        type="checkbox"
                                                        reducer='parametros'
                                                        path={`parametros.${selected}.DATA.${n}.SELECTED`}
                                                        className='form-control input-sm'
                                                    />
                                                </td>
                                                {
                                                    parametro.get('COLUMNAS').map(c => {
                                                        const foundVal = findValues(c, p)
                                                        let value = foundVal.get('VALOR')
                                                        let path = `parametros.${selected}.DATA.${n}.${c.get('ID')}`
                                                        if (c.get('TYPE')) {
                                                            switch (c.get('TYPE')) {
                                                                case 'datetime':
                                                                    value = moment(value).format(c.get('FORMAT'))
                                                                    break
                                                                case 'bool':
                                                                    value = value = <div style={{ textAlign: 'center', fontSize: '1.1em' }}><i className={`fa fa-${value ? 'check-' : ''}square`} /></div>
                                                                    break
                                                                case 'number':
                                                                    value = numeral(value).format(c.get('FORMAT'))
                                                                    break
                                                                default:
                                                            }
                                                        }

                                                        return (
                                                            <td key={`td4[${foundVal.get('GUID')}]`}>
                                                                <Input
                                                                    type='text'
                                                                    reducer='parametros'
                                                                    path={`${path}.VALOR`}
                                                                    className='form-control input-sm'
                                                                    afterChange={() => this.props.marcarComoActualizado(path)}
                                                                />
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={parametro.get('COLUMNAS').count() + 1}>No existen registros</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </BoxSection>
            </div>
        )
    }
}

const mapStateToProps = (state, onwProps) => {
    return {
        selected: state.parametros.get('selected'),
        parametro: state.parametros.getIn(['parametros', String(state.parametros.get('selected'))]),
        saving: state.parametros.get('saving'),
        elementos: state.layout.get('elementos'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adicionarDetalle: () => dispatch(adicionarDetalle()),
        eliminarDetalles: () => dispatch(eliminarDetalles()),
        almacenarDetalles: () => dispatch(almacenarDetalles()),
        marcarComoActualizado: (path) => dispatch(marcarComoActualizado(path)),
        showModalConfirmAction: (show, message, action) => dispatch(showModalConfirmAction(show, message, action)),
        toggleSelectAllItems: (checked) => dispatch(toggleSelectAllItems(checked)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetalleParametro)
