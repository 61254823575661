import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import numeral from "numeral";

import ButtonStrip from "../Layout/components/ButtonStrip";
import BoxButton from "../Layout/components/BoxButton";
import ModalCreate from "./components/ModalCreate";
import Input from '@mawesi-sas/mws_react_component_input';

import {
  displayModalCreate,
  obtenerVariables,
  seleccionarVariable,
  eliminarVariables,
  conmutarSeleccionRegistros,
  filtrarVariables,
  ordenarPor
} from "./actions";
import { showModalConfirmAction } from "../Layout/actions";
import Box from "../Layout/components/Box";
import BoxSection from "../Layout/components/BoxSection";

export const tableColumns = [
  {
    ID: 1,
    CAPTION: "Nombre",
    VALUE: "NOMBRE_VARIABLE",
    TYPE: "string",
    FORMAT: "",
    LINK: true
  },
  {
    ID: 2,
    CAPTION: "Tipo de campo",
    VALUE: "TIPO_CAMPO",
    TYPE: "string",
    FORMAT: ""
  },
  {
    ID: 3,
    CAPTION: "Tipo de dato",
    VALUE: "TIPO_DATO",
    TYPE: "string",
    FORMAT: ""
  },
  { ID: 4, CAPTION: "Tamaño", VALUE: "TAMANO", TYPE: "number", FORMAT: "" },
  {
    ID: 5,
    CAPTION: "Lista maestra",
    VALUE: "ENT_MAESTRAS.NOMBRE",
    TYPE: "string",
    FORMAT: ""
  },
  { ID: 7, CAPTION: "Fórmula", VALUE: "FORMULA", TYPE: "string", FORMAT: "" },
  {
    ID: 9,
    CAPTION: "Descripción ayuda",
    VALUE: "AYUDA_CAMPO",
    TYPE: "string",
    FORMAT: ""
  },
  { ID: 10, CAPTION: "Estado", VALUE: "ESTADO", TYPE: "bool", FORMAT: "" },
  { ID: 11, CAPTION: "Ayuda", VALUE: "AYUDA", TYPE: "bool", FORMAT: "" },
  { ID: 12, CAPTION: "Editable", VALUE: "EDITABLE", TYPE: "bool", FORMAT: "" },
  {
    ID: 13,
    CAPTION: "Requerido",
    VALUE: "REQUERIDO",
    TYPE: "bool",
    FORMAT: ""
  },
  { ID: 14, CAPTION: "Bitácora", VALUE: "BITACORA", TYPE: "bool", FORMAT: "" }
];

export function findValues(c, x) {
  let value = x.getIn(c.VALUE.split("."));
  return value;
}

class Variables extends Component {
  componentWillMount() {
    this.props.obtenerVariables();
  }

  render() {
    if (!this.props.elementos) return null;
    const { variables, termino, sortBy, asc } = this.props;
    let records = variables
      .valueSeq()
      .toList()
      .filter(
        x =>
          x
            .get("NOMBRE_VARIABLE")
            .toLowerCase()
            .indexOf(termino.toLowerCase()) > -1
      )
      .sortBy(x => x.getIn(sortBy.split(".")) || "");
    if (!asc) records = records.reverse();

    const buttons = [];
    if (this.props.elementos.filter(x => x === "aWxNrhcq").count())
      buttons.push(
        <BoxButton
          key={`action[2][0]`}
          name="plus"
          onClick={e =>
            this.props.displayModalCreate(
              true,
              "Creación de nueva variable",
              "create"
            )
          }
          title="Nueva variable"
        />
      );
    if (this.props.elementos.filter(x => x === "WADcAFWi").count())
      buttons.push(
        <BoxButton
          key={`action[2][1]`}
          name="trash"
          onClick={e =>
            this.props.showModalConfirmAction(
              true,
              "Está seguro que desea eliminar los registros seleccionados?",
              () => this.props.eliminarVariables()
            )
          }
          title="Eliminar variable"
          disabled={
            variables.filter(x => x.get("SELECTED") === true).count() === 0
          }
          danger
        />
      );

    return (
      <div id="variables"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <Box title="Variables" stretch>
          <div className="row" style={{ height: "100%" }}>
            <div className="col-md-12">
              <div className="row" style={{ height: "100%" }}>
                <div
                  className="col-md-12"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <BoxSection title="Listado de variables">
                    <div style={{ padding: "0 5px", marginBottom: "10px" }}>
                      <ButtonStrip
                        actions={buttons}
                        searchBar={this.props.filtrarVariables}
                        filtro={termino}
                      />
                    </div>
                    <div className="table-scrolled">
                      <table className="table table-condensed table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "25px" }}>
                              <input
                                type="checkbox"
                                checked={
                                  variables.count() ===
                                  variables
                                    .filter(x => x.get("SELECTED"))
                                    .count()
                                }
                                onChange={e =>
                                  this.props.conmutarSeleccionRegistros(
                                    e.target.checked
                                  )
                                }
                              />
                            </th>
                            {tableColumns.map(x => {
                              const style = {
                                width: `${x.WIDTH}`,
                                cursor: "pointer"
                              };
                              return (
                                <th
                                  key={`th1[${x.ID}]`}
                                  style={style}
                                  onClick={() => this.props.ordenarPor(x.VALUE)}
                                >
                                  {x.CAPTION}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {records.count() > 0 ? (
                            records.map((a, b) => {
                              const path = `variables.${a.get("ID")}`;
                              return (
                                <tr
                                  key={`tr2[${a.get("ID")}]`}
                                  className={a.get("SELECTED") ? "info" : ""}
                                >
                                  <td>
                                    <Input
                                      type="checkbox"
                                      reducer="variables"
                                      path={`${path}.SELECTED`}
                                    />
                                  </td>
                                  {tableColumns.map((c, d) => {
                                    let alignment = "left";
                                    let value = findValues(c, a);
                                    if (c.TYPE) {
                                      switch (c.TYPE) {
                                        case "datetime":
                                          value = moment(value).format(
                                            c.FORMAT
                                          );
                                          alignment = "right";
                                          break;
                                        case "bool":
                                          if (typeof value === "string")
                                            value = parseInt(value, 10);
                                          value = (
                                            <div
                                              style={{
                                                textAlign: "center",
                                                fontSize: "1.1em"
                                              }}
                                            >
                                              <i
                                                style={{ color: "#41afbe" }}
                                                className={`fa fa-${
                                                  value ? "check-" : ""
                                                  }square`}
                                              />
                                            </div>
                                          );
                                          break;
                                        case "number":
                                          value =
                                            value !== null
                                              ? numeral(value).format(c.Format)
                                              : null;
                                          alignment = "right";
                                          break;
                                        default:
                                      }
                                    }
                                    const style = { textAlign: alignment };

                                    if (
                                      c.LINK &&
                                      this.props.elementos
                                        .filter(e => e === "Umvx8aU3")
                                        .count()
                                    ) {
                                      return (
                                        <td
                                          key={`td1[${a.get("ID")}][${c.ID}]`}
                                          className="LinkToEdit"
                                          style={style}
                                          onClick={e =>
                                            this.props.displayModalCreate(
                                              true,
                                              "Modificación de variables",
                                              "edit",
                                              a.get("ID")
                                            )
                                          }
                                        >
                                          {value}
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={`td1[${a.get("ID")}][${c.ID}]`}
                                          style={style}
                                        >
                                          {value}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                              );
                            })
                          ) : (
                              <tr>
                                <td
                                  colSpan={tableColumns.length + 1}
                                  style={{ textAlign: "center" }}
                                >
                                  No existen registros
                              </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </BoxSection>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <ModalCreate />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    variables: state.variables.get("variables"),
    selected: state.variables.get("selected"),
    termino: state.variables.get("filtro"),
    sortBy: state.variables.get("sortBy"),
    asc: state.variables.get("asc"),
    elementos: state.layout.get("elementos")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    obtenerVariables: () => dispatch(obtenerVariables()),
    displayModalCreate: (show, title, mode, id) =>
      dispatch(displayModalCreate(show, title, mode, id)),
    seleccionarVariable: id => dispatch(seleccionarVariable(id)),
    eliminarVariables: () => dispatch(eliminarVariables()),
    conmutarSeleccionRegistros: checked =>
      dispatch(conmutarSeleccionRegistros(checked)),
    filtrarVariables: termino => dispatch(filtrarVariables(termino)),
    showModalConfirmAction: (show, message, action) =>
      dispatch(showModalConfirmAction(show, message, action)),
    ordenarPor: columna => dispatch(ordenarPor(columna))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Variables);
