import React, { Component } from 'react'
import { connect } from 'react-redux'

class Titulo extends Component {
    render() {
        const { selected, seccion } = this.props
        if (!selected) return null

        return (
            <div className="titulo-seccion" style={{ background: seccion.get('COLOR') }}>
                {seccion.get('TITULO')}
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        seccion: state.crearregistro.getIn(['secciones', String(ownProps.selected)])
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Titulo)