import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { signOut } from "../SignIn/actions";
import history from "../../utils/history";


export default function(ComposedComponent, checkAuthorization = true) {
  class Authorization extends Component {
    constructor(props) {
      super(props);
      this.state = {
        show: true
      };
    }

    static contextTypes = {
      router: PropTypes.object
    };

    componentWillMount() {
      let url = `${this.props.match.path}`;
      url = url.replace(/:(\w+)/gi, "{$1}").replace('?', '')

      if (!this.props.authenticated) {
        history.push(`/signin`);
        this.props.signOut();
        this.setState({ show: false });
      } else {
        if (
          !this.props.user
            .get("MODULOS")
            .filter(
              x =>
                x
                  .get("SUBMODULOS")
                  .filter(
                    y => `${y.getIn(["CONFIG", "url"])}` === url
                  )
                  .count() > 0 ||
                `${x.getIn(["CONFIG", "url"])}` === url
            )
            .count()
        ) {
          this.setState({ show: false });
          history.push(`/unauthorized`);
        }
      }
    }

    componentWillUpdate(nextProps) {
      let url = `${this.props.match.path}`;
      url = url.replace(/:(\w+)/gi, "{$1}").replace('?', '')

      if (!nextProps.authenticated) {
        history.push(`/signin`);
        this.props.signOut();
        this.setState({ show: false });
      } else {
        if (
          !nextProps.user
            .get("MODULOS")
            .filter(
              x =>
                x
                  .get("SUBMODULOS")
                  .filter(
                    y => `${y.getIn(["CONFIG", "url"])}` === url
                  )
                  .count() > 0 ||
                `${x.getIn(["CONFIG", "url"])}` === url
            )
            .count()
        ) {
          history.push(`/unauthorized`);
          this.setState({ show: false });
        }
      }
    }

    render() {
      if (this.state.show) {
        return <ComposedComponent {...this.props} />;
      } else {
        return null;
      }
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: state.signin.get("isAuthenticated"),
      user: state.signin.get("user")
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
      signOut: () => dispatch(signOut())
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Authorization);
}
