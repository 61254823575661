import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import history from '../../../utils/history'
import ModuleOption from './ModuleOption'

import { expandCollapseModule } from '../../Layout/actions'
import { seleccionarScript } from '../../Home/actions'

class ModuleButton extends Component {
    handleOnClick = (e, hasChildren) => {
        e.preventDefault()
        const ink = ReactDOM.findDOMNode(this.ink)
        const li = ReactDOM.findDOMNode(this.li)
        ink.classList.remove("animate")

        if (!ink.offsetHeight && !ink.offsetWidth) {
            const d = Math.max(li.offsetWidth, li.offsetHeight)
            ink.style.height = `${d}px`
            ink.style.width = `${d}px`
        }

        const rect = li.getBoundingClientRect()
        const x = e.pageX - rect.left - ink.offsetWidth / 2
        const y = e.pageY - rect.top - ink.offsetHeight / 2

        ink.style.top = `${y}px`
        ink.style.left = `${x}px`
        ink.classList.add("animate")

        if (hasChildren) {
            const isExpanded = (this.props.expanded === this.props.id)
            let id
            if (isExpanded) {
                id = null
            } else {
                id = this.props.id
            }
            this.props.expandCollapseModule(id)
        } else {
            const url = this.props.url
            history.push(url)
        }
    }

    render() {
        const { caption, options, id, expandible } = this.props
        const isExpanded = (this.props.expanded === id)
        return(
            <li ref={ (li) => this.li = li } className={ `${expandible ? 'treeview' : ''}` }>
                <span className="ink" ref={ (ink) => this.ink = ink }></span>
                <a href={ `${!expandible && options.count() === 1 ? options.first().get('url') : ''}` } onClick={ e => this.handleOnClick(e, expandible) } >
                    { caption }
                    { <i style={{ marginTop: '4px' }} className={ expandible ? `fa fa-angle-${isExpanded ? 'down' : 'right'} pull-right` : '' }></i> }
                </a>
                {
                    (expandible &&
                        <ul className={ `treeview-menu ${isExpanded ? 'slideExpand' : 'slideCollapse'}` }>
                            {
                                options.map(x => {
                                    return (
                                        <ModuleOption key={ `li[${id}][${x.get('id')}]` } caption={ x.get('caption') } url={ x.get('url') } scripts={this.props.scripts} onClick={x.get('onClick')} />
                                    )
                                })
                            }
                        </ul>
                    )
                }
            </li>
        )
    }
}

function mapStateToProps(state) {
    return {
        expanded: state.layout.get('expanded')
    }
}

function mapDispatchToProps(dispatch) {
    return {
        expandCollapseModule: (moduloId) => dispatch(expandCollapseModule(moduloId)),
        seleccionarScript: (script) => dispatch(seleccionarScript(script))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleButton)