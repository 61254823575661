import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import moment from "moment";
import numeral from "numeral";
import Pagination from "react-js-pagination";

import Box from "../Layout/components/Box";
import ButtonStrip from "../Layout/components/ButtonStrip";
import BoxButton from "../Layout/components/BoxButton";
import ModalCreate from "./components/ModalCreate";

import {
  displayModalCreate,
  obtenerUsuarios,
  filtrarUsuario,
  ordenarPor,
  actualizarMostrarRegistros
} from "./actions";
import { showModalConfirmAction } from "../Layout/actions";
import BoxSection from "../Layout/components/BoxSection";

export const tableColumns = [
  {
    ID: 2,
    CAPTION: "Nombre de usuario",
    VALUE: "USERNAME",
    TYPE: "string",
    FORMAT: "",
    LINK: true
  },
  { ID: 3, CAPTION: "Nombre", VALUE: "NOMBRES", TYPE: "string", FORMAT: "" },
  {
    ID: 4,
    CAPTION: "Apellido",
    VALUE: "APELLIDOS",
    TYPE: "string",
    FORMAT: ""
  },
  {
    ID: 5,
    CAPTION: "Correo electrónico",
    VALUE: "EMAIL",
    TYPE: "string",
    FORMAT: ""
  },
  { ID: 6, CAPTION: "Empresa", VALUE: "EMPRESA", TYPE: "string", FORMAT: "" },
  { ID: 7, CAPTION: "Activo", VALUE: "ACTIVO", TYPE: "bool", FORMAT: "" },
  { ID: 8, CAPTION: "Rol", VALUE: "ROLE.NOMBRE", TYPE: "string", FORMAT: "" }
];

export function findValues(c, x) {
  let value = x.getIn(c.VALUE.split("."));
  return value;
}

class Usuarios extends Component {
  componentWillMount() {
    this.props.obtenerUsuarios(1);
  }

  render() {
    if (!this.props.elementos) return null;
    const {
      usuarios,
      sortBy,
      asc,
      page,
      size,
      totalRegistros
    } = this.props;

    let records = usuarios
      .valueSeq()
      .toList()
      .sortBy(x => x.getIn(sortBy.split(".")) || "");
    if (!asc) records = records.reverse();

    const pagination = (
      <Pagination
        activePage={page}
        itemsCountPerPage={size}
        totalItemsCount={totalRegistros}
        onChange={pg => this.props.obtenerUsuarios(pg)}
        firstPageText={<FontAwesome name="angle-double-left" />}
        prevPageText={<FontAwesome name="angle-left" />}
        nextPageText={<FontAwesome name="angle-right" />}
        lastPageText={<FontAwesome name="angle-double-right" />}
        pageRangeDisplayed={this.props.width < 1000 ? 5 : 10}
      />
    );

    const buttons = [];

    if (this.props.elementos.filter(x => x === "dkGj395G").count())
      buttons.push(
        <BoxButton
          key={`action[2][0]`}
          name="user-plus"
          onClick={e =>
            this.props.displayModalCreate(true, "Creación de usuario", "create")
          }
          title="Crear usuario"
        />
      );
    if (this.props.elementos.filter(x => x === "pfHR45El").count())
      buttons.push(
        <BoxButton
          key={`action[2][1]`}
          name="group"
          onClick={e => this.props.history.push("/roles")}
          title="Administrar Roles"
        />
      );
    return (
      <div id="usuarios"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <Box title="Usuarios" stretch>
          <div className="row" style={{ height: "100%" }}>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="row" style={{ height: "100%" }}>
                <div
                  className="col-md-12 col-sm-12 col-xs-12"
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <div
                    className="widget"
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginBottom: "0"
                    }}
                  >
                    <BoxSection title="Usuarios de la aplicación">
                      <div style={{ padding: "0 5px", marginBottom: "10px" }}>
                        <ButtonStrip
                          actions={buttons}
                          pagination={pagination}
                          totalRecords={totalRegistros}
                          showRecords={size}
                          changeRecordsToShow={records =>
                            this.props.actualizarMostrarRegistros(records)
                          }
                        />
                      </div>
                      <div className="table-scrolled">
                        <table className="table table-condensed table-striped table-bordered table-hover">
                          <thead>
                            <tr>
                              {tableColumns.map(x => {
                                const style = {
                                  width: `${x.WIDTH}`,
                                  cursor: "pointer"
                                };
                                return (
                                  <th
                                    key={`th1[${x.ID}]`}
                                    style={style}
                                    onClick={() =>
                                      this.props.ordenarPor(x.VALUE)
                                    }
                                  >
                                    {x.CAPTION}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {records.count() > 0 ? (
                              records.map((a, b) => {
                                // const path = `usuarios.${a.get("ID")}`;
                                return (
                                  <tr
                                    key={`tr2[${a.get("ID")}]`}
                                    className={a.get("SELECTED") ? "info" : ""}
                                  >
                                    {tableColumns.map((c, d) => {
                                      let alignment = "left";
                                      let value = findValues(c, a);
                                      if (c.TYPE) {
                                        switch (c.TYPE) {
                                          case "datetime":
                                            value = moment(value).format(
                                              c.FORMAT
                                            );
                                            alignment = "right";
                                            break;
                                          case "bool":
                                            if (typeof value === "string")
                                              value = parseInt(value, 10);
                                            value = (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "1.1em"
                                                }}
                                              >
                                                <i
                                                  style={{ color: "#41afbe" }}
                                                  className={`fa fa-${
                                                    value ? "check-" : ""
                                                  }square`}
                                                />
                                              </div>
                                            );
                                            break;
                                          case "color":
                                            let icon = "circle";
                                            if (value === null)
                                              icon = "circle-thin";
                                            value = (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "1.1em"
                                                }}
                                              >
                                                <i
                                                  style={{
                                                    color: value,
                                                    textShadow:
                                                      "0px 0px 1px #555"
                                                  }}
                                                  className={`fa fa-${icon}`}
                                                />
                                              </div>
                                            );
                                            break;
                                          case "number":
                                            value =
                                              value !== null
                                                ? numeral(value).format(
                                                    c.Format
                                                  )
                                                : null;
                                            alignment = "right";
                                            break;
                                          default:
                                        }
                                      }
                                      const style = { textAlign: alignment };

                                      if (
                                        c.LINK &&
                                        this.props.elementos
                                          .filter(e => e === "s0N1c4Gs")
                                          .count()
                                      ) {
                                        return (
                                          <td
                                            key={`td1[${a.get("ID")}][${c.ID}]`}
                                            className="LinkToEdit"
                                            style={style}
                                            onClick={e =>
                                              this.props.displayModalCreate(
                                                true,
                                                "Modificación de secciones",
                                                "edit",
                                                a.get("ID")
                                              )
                                            }
                                          >
                                            {value}
                                          </td>
                                        );
                                      } else {
                                        return (
                                          <td
                                            key={`td1[${a.get("ID")}][${c.ID}]`}
                                            style={style}
                                          >
                                            {value}
                                          </td>
                                        );
                                      }
                                    })}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={tableColumns.length + 1}
                                  style={{ textAlign: "center" }}
                                >
                                  No existen registros
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </BoxSection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <ModalCreate />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    usuarios: state.usuarios.get("usuarios"),
    selected: state.usuarios.get("selected"),
    termino: state.usuarios.get("filtro"),
    sortBy: state.usuarios.get("sortBy"),
    asc: state.usuarios.get("asc"),
    elementos: state.layout.get("elementos"),
    totalRegistros: state.usuarios.get("totalRegistros"),
    size: state.usuarios.get("size"),
    page: state.usuarios.get("page"),
    width: state.layout.get('width'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    obtenerUsuarios: pg => dispatch(obtenerUsuarios(pg)),
    displayModalCreate: (show, title, mode, id) =>
      dispatch(displayModalCreate(show, title, mode, id)),
    filtrarUsuario: filtro => dispatch(filtrarUsuario(filtro)),
    showModalConfirmAction: (show, message, action) =>
      dispatch(showModalConfirmAction(show, message, action)),
    ordenarPor: columna => dispatch(ordenarPor(columna)),
    actualizarMostrarRegistros: records =>
      dispatch(actualizarMostrarRegistros(records))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);
