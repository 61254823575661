import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { TreeTable } from 'cp-react-tree-table';
import moment from "moment";
import Input from '@mawesi-sas/mws_react_component_input';

import Box from "../Layout/components/Box";
import ButtonStrip from "../Layout/components/ButtonStrip";
import BoxButton from "../Layout/components/BoxButton";
import ModalCreate from "./components/ModalCreate";
import ModalCreateFolder from "./components/ModalCreateFolder";

import {
  displayModalCreate,
  displayModalEdit,
  displayModalCreateFolder,
  obtenerFuentes,
  seleccionarFuente,
  eliminarFuentes,
  conmutarSeleccionRegistros,
  filtrarFuentes,
  getFolders,
  ordenarPor,
  actualizarTreeFuentes
} from "./actions";
import { showModalConfirmAction } from "../Layout/actions";
import BoxSection from "../Layout/components/BoxSection";
import ModalEdit from "./components/ModalEdit";
import FontAwesome from "react-fontawesome";

class Fuentes extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      termino: this.props.termino
    }
  }
  componentWillMount() {
    this.props.obtenerFuentes();
    this.props.getFolders();
  }

  loadIcon = filename => {
    let nameparts = filename.split(".");
    let ext = nameparts[nameparts.length - 1];
    try {
      return require("../../img/16px/" + ext + ".png");
    } catch (err) {
      return require("../../img/16px/_blank.png");
    }
  };

  handleFilter = (termino) => {
    clearTimeout(this.timer)
    this.setState({termino})
    this.timer = setTimeout(() => this.props.filtrarFuentes(this.state.termino), 1000)
  }

  renderSelectFuente = (row) => {
    if (row.metadata.hasChildren) {
      return null
    } else {
      return (<div style={{textAlign: "center"}}>
        <Input type="checkbox" reducer="fuentes" path={`fuentes.${ row.data.ID }.SELECTED`} />
      </div>)
    }
  }
  renderIndexCell = (row) => {
    return (
      <div style={{ paddingLeft: (row.metadata.depth * 15) + 'px'}}>
        
        {(row.metadata.hasChildren)
          ? (
            <Fragment>
              <button className="box-button" onClick={row.toggleChildren}>
                <FontAwesome name={row.$state.isExpanded ? "folder-open" : "folder"} />
              </button>
              <span>{row.data.NOMBRE}</span>
            </Fragment>
          ) : this.props.elementos.filter(e => e === "eforhBkK").count() ? <div className="LinkToEdit" onClick={e =>
              this.props.displayModalEdit(
                true,
                "Modificación de la fuente",
                "edit",
                row.data.ID
              )
            }>
            <img src={this.loadIcon(row.data.NOMBRE)} alt="logo" />
            <span>{row.data.NOMBRE}</span>
          </div> : <Fragment>
            <img src={this.loadIcon(row.data.NOMBRE)} alt="logo" />
            <span>{row.data.NOMBRE}</span>
          </Fragment>
        }
      </div>
    );
  }

  renderDateTime = (row) => {
    if (!row.data.FECHA_MOD) return null

    return (
      <span className="fecha_mod">
        {moment(row.data.FECHA_MOD).format("YYYY-MM-DD HH:mm")}
      </span>
    )
  }

  render() {
    const heightTable = document.documentElement.clientHeight * 0.6
    if (!this.props.elementos) return null;
    const { treeFuentes, fuentes, termino } = this.props;

    const buttons = [];
    if (this.props.elementos.filter(x => x === "efh9HQ0F").count())
      buttons.push(
        <BoxButton
          key={`action[2][0]`}
          name="plus"
          onClick={e =>
            this.props.displayModalCreate(
              true,
              "Creación de fuentes",
              "create"
            )
          }
          title="Nueva fuente"
        />
      );
    if (this.props.elementos.filter(x => x === "ef0hu1wA").count())
      buttons.push(
        <BoxButton
          key={`action[2][1]`}
          name="trash"
          onClick={e =>
            this.props.showModalConfirmAction(
              true,
              "Está seguro que desea eliminar los registros seleccionados?",
              () => this.props.eliminarFuentes()
            )
          }
          title="Eliminar fuente"
          disabled={
            fuentes.filter(x => x.get("SELECTED") === true).count() === 0
          }
          danger
        />
      );

    if (this.props.elementos.filter(x => x === "p0f0ld3r").count())
      buttons.push(
        <BoxButton
          key={`action[2][2]`}
          name="folder"
          onClick={e =>
            this.props.displayModalCreateFolder(
              true,
              "Creación nueva carpeta",
              "create"
            )
          }
          title="Crear carpeta"
        />
      );

    return (
      <div id="fuentes"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <Box title="Fuentes" stretch>
          <div className="row" style={{ height: "100%" }}>
            <div className="col-md-12" style={{ height: "100%" }}>
              <div className="row" style={{ height: "100%" }}>
                <div
                  className="col-md-12"
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <div
                    className="widget"
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginBottom: "0"
                    }}
                  >
                    <BoxSection title="Listado de fuentes">
                      <div style={{ padding: "0 5px", marginBottom: "10px" }}>
                        <ButtonStrip
                          actions={buttons}
                          searchBar={this.handleFilter}
                          filtro={this.state.termino}
                        />
                      </div>
                      <div className="table-scrolled">
                        <TreeTable
                          height={heightTable}
                          value={treeFuentes}
                          onChange={this.props.actualizarTreeFuentes}>
                          <TreeTable.Column basis="3%" grow="0"
                            renderCell={this.renderSelectFuente}
                            renderHeaderCell={() => <input type="checkbox"
                              checked={fuentes.count() === fuentes.filter(x => x.get("SELECTED")).count()}
                              onChange={e => this.props.conmutarSeleccionRegistros(e.target.checked)} />
                             } />
                          <TreeTable.Column basis="33%" grow="0"
                            renderCell={this.renderIndexCell}
                            renderHeaderCell={() => <span>Nombre</span>} />
                          <TreeTable.Column basis="44%" grow="0"
                            renderCell={(row) => <span>{row.data.DESCRIPCION}</span>}
                            renderHeaderCell={() => <span>Descripción</span>} />
                          <TreeTable.Column basis="20%" grow="0"
                            renderCell={this.renderDateTime}
                            renderHeaderCell={() => <span>Última actualización</span>} />
                        </TreeTable>
                      </div>
                    </BoxSection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <ModalCreate />
        <ModalEdit />
        <ModalCreateFolder />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fuentes: state.fuentes.get("fuentes"),
    treeFuentes: state.fuentes.get("treeFuentes"),
    selected: state.fuentes.get("selected"),
    termino: state.fuentes.get("filtro"),
    sortBy: state.fuentes.get("sortBy"),
    asc: state.fuentes.get("asc"),
    elementos: state.layout.get("elementos")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    obtenerFuentes: () => dispatch(obtenerFuentes()),
    getFolders: () => dispatch(getFolders()),
    displayModalCreate: (show, title, mode, id) =>
      dispatch(displayModalCreate(show, title, mode, id)),
    displayModalEdit: (show, title, mode, id) =>
      dispatch(displayModalEdit(show, title, mode, id)),
    displayModalCreateFolder: (show, title, mode, id) =>
      dispatch(displayModalCreateFolder(show, title, mode, id)),
    seleccionarFuente: id => dispatch(seleccionarFuente(id)),
    eliminarFuentes: () => dispatch(eliminarFuentes()),
    conmutarSeleccionRegistros: checked =>
      dispatch(conmutarSeleccionRegistros(checked)),
    filtrarFuentes: filtro => dispatch(filtrarFuentes(filtro)),
    showModalConfirmAction: (show, message, action) =>
      dispatch(showModalConfirmAction(show, message, action)),
    ordenarPor: columna => dispatch(ordenarPor(columna)),
    actualizarTreeFuentes: newTreeFuentes => dispatch(actualizarTreeFuentes(newTreeFuentes))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Fuentes);
