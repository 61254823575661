import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Input from '@mawesi-sas/mws_react_component_input';

import { clearMessage } from "../Layout/actions";
import { recoveryPassword } from "./actions";

import "../../css/signin.css";
import logo from "../../img/logo_01a.png";
import background from "../../img/background_2.jpg";

const cookies = new Cookies();

class RecoveryPassword extends Component {
  componentWillMount() {
    this.props.clearMessage();
    const token = cookies.get("token");
    if (token) this.props.history.push("/");
  }

  componentDidUpdate() {
    this.props.clearMessage();
  }

  render() {
    const { processing, message } = this.props;
    if (message) {
      switch (message.get("type")) {
        case "danger":
          toast.error(message.get("message"));
          break;
        case "warning":
          toast.warn(message.get("message"));
          break;
        case "success":
          toast.success(message.get("message"));
          break;
        default:
          toast.info(message.get("message"));
      }
    }

    return (
      <div
        className="signin-container"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="login-box animated fadeIn">
          <div>
            <img
              src={logo}
              width="60%"
              style={{ verticalAlign: "middle" }}
              alt="logo"
            />
            <p
              className="text-center"
              style={{ fontSize: "24px", fontWeight: 300, margin: "15px 0" }}
            >
              Recupere su contraseña
            </p>
            <form style={{ width: "100%" }}>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <Input
                      reducer="recoverypassword"
                      path="email"
                      type="email"
                      placeholder="Correo electrónico"
                    />
                  </div>
                </div>
                <div className="row" style={{marginTop: "15px"}}>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <button className="btn btn-default btn-sm m" style={{ width: '50%', marginRight: "5px" }}
                      disabled={processing} onClick={e => { e.preventDefault(); this.props.recoveryPassword() }}>
                      {processing ? <span><FontAwesome name="circle-o-notch" spin /> Procesando ...</span> : <span>Recuperar contraseña</span>}
                    </button>
                    <button className="btn btn-default btn-sm" style={{ width: '48%' }}
                      disabled={processing} onClick={e => this.props.history.push('/signin')}>
                      <span>Cancelar</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    email: state.recoverypassword.get("email"),
    processing: state.recoverypassword.get("processing"),
    message: state.layout.get("message")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    recoveryPassword: () => dispatch(recoveryPassword()),
    clearMessage: () => dispatch(clearMessage())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword);
