import {
    combineReducers
} from 'redux';
import {
    inputReducer
} from "@mawesi-sas/mws_react_component_input";
import {
    tableReducer
} from '@mawesi-sas/mws_react_component_table';

const containers = [
    'SignIn',
    'SignUp',
    'RecoveryPassword',
    'Layout',
    'Maestras',
    'Variables',
    'Secciones',
    'Formularios',
    'Parametros',
    'Bitacora',
    'CargueMasivo',
    'Registros',
    'CrearRegistro',
    'Usuarios',
    'Roles',
    'Scripts',
    'Fuentes',
    'Home'
];
const reducers = {};

containers.forEach(p => {
    const m = require(`./containers/${p}/reducers`).default
    reducers[p.toLowerCase()] = m;
});

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
    if (action.type === 'SIGNIN_CERRAR_SESION' || action.type === 'SIGNIN_INICIAR_SESION') {
        state = undefined;
    }
    state = appReducer(state, action);
    state = inputReducer(state, action);
    state = tableReducer(state, action);

    return state;
}

export default rootReducer;