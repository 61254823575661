import * as types from '../../types'
import {
    API_URL
} from '../../utils/constants'
import {
    createAxiosInstance
} from '../../utils/helpers'
import {
    displayMessages,
    setSavingState
} from '../Layout/actions'
import objectifyArray from 'objectify-array'
import {
    obtenerMaestras
} from '../Maestras/actions';
import FileDownload from 'react-file-download'


export function obtenerScripts() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const fn1 = () => axios.get(`${API_URL}/scripts`)

        Promise.all([fn1()])
            .then((res) => {
                const scripts = objectifyArray(res[0].data, {
                    by: ['ID'],
                    recursive: true
                })
                const fn3 = d => d({
                    type: types.SCRIPTS_POBLAR_SCRIPTS,
                    payload: {
                        scripts
                    }
                })
                fn3(dispatch)

                if (getState().maestras.get('maestras').count() === 0) {
                    dispatch(obtenerMaestras())
                }
            })
            .catch((err) => {
                console.log(err)
                displayMessages(dispatch, err)
            })
    }
}

export function displayModalCreate(show, title, mode, id = null) {
    return (dispatch) => {
        dispatch({
            type: types.SCRIPTS_DISPLAY_MODAL_CREACION,
            payload: {
                show,
                title,
                mode,
                id
            }
        })
    }
}

export function seleccionarScript(id) {
    return (dispatch) => {
        dispatch({
            type: types.SCRIPTS_SELECCIONAR_SCRIPT,
            payload: id
        })
    }
}

export function resetAfterTipoCampo() {
    return (dispatch, getState) => {
        const path = 'modalCreate.data'
        dispatch({
            type: types.SCRIPTS_MODIFICAR_INPUTS,
            payload: {
                path: `${path}.TIPO_DATO`,
                value: -1
            }
        })
        dispatch({
            type: types.SCRIPTS_MODIFICAR_INPUTS,
            payload: {
                path: `${path}.TAMANO`,
                value: null
            }
        })
        dispatch({
            type: types.SCRIPTS_MODIFICAR_INPUTS,
            payload: {
                path: `${path}.MAESTRA_ID`,
                value: -1
            }
        })
    }
}

export function conmutarSeleccionRegistros(checked) {
    return (dispatch) => {
        dispatch({
            type: types.SCRIPTS_CONMUTAR_SELECCION_SCRIPTS,
            payload: checked
        })
    }
}

export function almacenarScript() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('scripts', true))
        let data = getState().scripts.getIn(['modalCreate', 'data'])
        const mode = getState().scripts.getIn(['modalCreate', 'mode'])

        const formdata = new FormData;
        formdata.append('NOMBRE', data.get('NOMBRE'))
        formdata.append('DESCRIPCION', data.get('DESCRIPCION'))
        formdata.append('SHINY', data.get('SHINY'))
        formdata.append('file', data.get('file'))

        let n = 0;
        data.get('ARGUMENTOS').forEach(x => {
            if (x.get('NOMBRE') && x.get('VALOR')) {
                if (x.get('ID')) formdata.append(`ARGUMENTOS.${n}.ID`, x.get('ID'))
                formdata.append(`ARGUMENTOS.${n}.NOMBRE`, x.get('NOMBRE'))
                formdata.append(`ARGUMENTOS.${n}.VALOR`, x.get('VALOR'))
                formdata.append(`ARGUMENTOS.${n}.POSICION`, x.get('POSICION'))
                n++;
            }
        })

        if (mode === 'create') {
            dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: true })
            axios.post(`${API_URL}/scripts`, formdata)
                .then(res => {
                    const id = res.data.ID
                    const script = objectifyArray([res.data], {
                        by: ['ID'],
                        recursive: true
                    })
                    dispatch({
                        type: types.SCRIPTS_ACTUALIZAR_SCRIPT,
                        payload: script[id]
                    })
                    dispatch(displayModalCreate(false))
                    dispatch(setSavingState('scripts', false))
                    displayMessages(dispatch, {
                        success: 'La script ha sido guardada exitosamente'
                    })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('scripts', false))
                    displayMessages(dispatch, err)
                })
                .finally(() => dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: false }))
        } else if (mode === 'edit') {
            dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: true })
            axios.put(`${API_URL}/scripts/${data.get('ID')}`, formdata)
                .then(res => {
                    const script = objectifyArray([res.data], {
                        by: ['ID'],
                        recursive: true
                    })
                    dispatch({
                        type: types.SCRIPTS_ACTUALIZAR_SCRIPT,
                        payload: script[data.get('ID')]
                    })
                    dispatch(displayModalCreate(false))
                    dispatch(setSavingState('scripts', false))
                    displayMessages(dispatch, {
                        success: 'La script ha sido guardada exitosamente'
                    })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('scripts', false))
                    displayMessages(dispatch, err)
                })
                .finally(() => dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: false }))
        }
    }
}

export function eliminarScripts() {
    return (dispatch, getState) => {
        let toDelete = getState().scripts.get('scripts').valueSeq().filter(x => x.get('SELECTED') === true)
        const total = toDelete.count()
        toDelete = toDelete.filter(x => x.get('ID') > 0)
        if (toDelete.count() > 0) {
            const axios = createAxiosInstance()
            const fns = []
            toDelete.map(x => x.get('ID')).forEach(x => {
                const fn = () => axios.delete(`${API_URL}/scripts/${x}`)
                fns.push(fn)
            })

            const toResultObject = (promise) => {
                return promise
                    .then(result => ({
                        success: true,
                        result
                    }))
                    .catch(error => ({
                        success: false,
                        error
                    }));
            }

            Promise.all(fns.map(x => x()).map(toResultObject))
                .then(res => {
                    const succeded = []
                    const failed = []
                    res.forEach(x => {
                        if (!x.success) {
                            failed.push(x.error.response.data.ExceptionMessage)
                        } else {
                            succeded.push(1)
                            dispatch({
                                type: types.SCRIPTS_ELIMINAR_SCRIPT,
                                payload: x.result.data.ID
                            })
                        }
                    })
                    if (succeded.length > 0) displayMessages(dispatch, {
                        success: `Se han eliminado ${succeded.length + (total - toDelete.count())} de los ${total} registros`
                    })
                    if (failed.length > 0) displayMessages(dispatch, {
                        response: {
                            status: 500,
                            data: failed.join('\n')
                        }
                    })
                })
        } else {
            displayMessages(dispatch, {
                warning: 'No seleccionó ningún registro para eliminar'
            })
        }
    }
}

export function filtrarScripts(termino) {
    return (dispatch) => {
        dispatch({
            type: types.SCRIPTS_MODIFICAR_FILTRO,
            payload: termino
        })
    }
}

export function ordenarPor(columna) {
    return (dispatch) => {
        dispatch({
            type: types.SCRIPTS_ORDENAR_COLUMNA,
            payload: columna
        })
    }
}

export function massiveUploadFiles(files) {
    return (dispatch, getState) => {
        if (files.length > 1) {
            displayMessages(dispatch, {
                message: 'No es posible cargar más de 1 script'
            })
        } else {
            const file = files[0]
            const bytesToUpload = file.size
            if (bytesToUpload > 10737418) {
                displayMessages(dispatch, {
                    message: 'No es posible cargar más de 10MB'
                })
            } else {
                const fn1 = (d) => d({
                    type: types.SCRIPTS_CARGAR_SCRIPT,
                    payload: file
                })
                fn1(dispatch)
            }
        }
    }
}

export function executeScript(script) {
    const axios = createAxiosInstance()
    let scriptType = script.substr(-3) === '.py' ? 'python' : script.substr(-2).toUpperCase() === '.R' ? 'r' : null
    if (scriptType !== null) {
        return (dispatch) => {
            dispatch({
                type: types.LAYOUT_MOSTRAR_MODAL_ESPERA,
                payload: true
            })
            const fn1 = () => axios.get(`${API_URL}/${scriptType}/run/${script.split('.').slice(0, -1).join('.')}`)
            Promise.all([fn1()])
                .then((res) => {
                    const url_response = res[0].data.URL_RESPONSE
                    const pid = res[0].data.PID
                    let html = `<iframe src="${url_response}" width="100%" height="100%" style="border: none"></iframe>`
                    dispatch(obtenerScripts())
                    dispatch(displayModalScriptResponse(true, html, pid))
                    dispatch({
                        type: types.LAYOUT_MOSTRAR_MODAL_ESPERA,
                        payload: false
                    })
                })
                .catch((err) => {
                    displayMessages(dispatch, err)
                    dispatch({
                        type: types.LAYOUT_MOSTRAR_MODAL_ESPERA,
                        payload: false
                    })
                })
        }
    }
}

function readFileDataAsBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.target.result);
        };

        reader.onerror = (err) => {
            reject(err);
        };

        reader.readAsDataURL(file);
    });
}

function readBlobAsText(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.srcElement.result);
        };

        reader.onerror = (err) => {
            reject(err);
        };

        reader.readAsText(blob);
    });
}

export function displayModalScriptResponse(show, response, pid = null) {
    const axios = createAxiosInstance()
    return (dispatch) => {
        if (!show && pid) axios.get(`${API_URL}/r/stop/${pid}`)
        dispatch({
            type: types.SCRIPTS_DISPLAY_MODAL_RESULTADO_SCRIPT,
            payload: {
                show,
                response,
                pid
            }
        })
    }
}

export function downloadScript(name) {
    const axios = createAxiosInstance()
    return (dispatch) => {
        axios.get(`${API_URL}/scripts/download?name=${name}`, { responseType: 'arraybuffer' })
            .then(res => {
                FileDownload(res.data, `${name}`)
            })
            .catch(err => {
                console.log(err)
                const decodedString = JSON.stringify(String(err))
                const obj = JSON.parse(decodedString);
                displayMessages(dispatch, obj)
            })
    }
}

export function adicionarArgumento() {
    return (dispatch) => {
        dispatch({
            type: types.SCRIPTS_ADICIONAR_ARGUMENTO
        })
    }
}

export function eliminarArgumento() {
    return (dispatch, getState) => {
        let toDelete = getState().scripts.getIn(['modalCreate', 'data', 'ARGUMENTOS']).filter(x => x.get('SELECTED') === true)
        const total = toDelete.count()
        toDelete = toDelete.filter(x => x.get('ID') > 0)
        if (toDelete.count() > 0) {
            const axios = createAxiosInstance()
            const fns = []
            toDelete.map(x => x.get('ID')).forEach(x => {
                const fn = () => axios.delete(`${API_URL}/scripts/argumentos/${x}`)
                fns.push(fn)
            })

            const toResultObject = (promise) => {
                return promise
                    .then(result => ({
                        success: true,
                        result
                    }))
                    .catch(error => ({
                        success: false,
                        error
                    }));
            }

            Promise.all(fns.map(x => x()).map(toResultObject))
                .then(res => {
                    const succeded = []
                    const failed = []
                    res.forEach(x => {
                        if (!x.success) {
                            failed.push(x.error.response.data.ExceptionMessage)
                            let url = x.error.config.url.split('/')
                            let configId = url[url.length - 1]
                            let index = getState().scripts.getIn(['modalCreate', 'data', 'ARGUMENTOS']).findIndex(x => x.get('ID') === Number(configId))
                            dispatch({
                                type: types.SCRIPTS_MODIFICAR_INPUTS,
                                payload: {
                                    path: `modalCreate.data.ARGUMENTOS.${index}.SELECTED`,
                                    value: false
                                }
                            })
                        } else {
                            succeded.push(1)
                            dispatch({
                                type: types.SCRIPTS_ELIMINAR_ARGUMENTOS,
                                payload: x.result.data.ID
                            })
                        }
                    })
                    if (succeded.length > 0) displayMessages(dispatch, {
                        success: `Se han eliminado ${succeded.length + (total - toDelete.count())} de los ${total} registros`
                    })
                    if (failed.length > 0) displayMessages(dispatch, {
                        response: {
                            status: 500,
                            data: failed.join('\n')
                        }
                    })
                    dispatch({
                        type: types.SCRIPTS_ELIMINAR_ARGUMENTOS
                    })
                })
        } else {
            dispatch({
                type: types.SCRIPTS_ELIMINAR_ARGUMENTOS
            })
        }
    }
}

export function showModalConfirmAction(open, message = null, action = null) {
    return (dispatch) => {
        dispatch({
            type: types.LAYOUT_MOSTRAR_MODAL_CONFIRMACION_ACCION,
            payload: {
                open,
                message,
                action
            }
        })
    }
}