import * as types from '../../types'
import { API_URL } from '../../utils/constants'
import { createAxiosInstance } from '../../utils/helpers'
import { displayMessages, setSavingState } from '../Layout/actions'
import objectifyArray from 'objectify-array'
import { obtenerMaestras } from '../Maestras/actions';

export function obtenerSecciones() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const fn1 = () => axios.get(`${API_URL}/secciones?resumen=false`)
        const fn2 = () => axios.get(`${API_URL}/parametros?nombre=SECCIONES_MULTIPLES`)

        Promise.all([fn1(), fn2()])
		.then((res) => {
            const secciones = objectifyArray(res[0].data, { by: ['ID'], recursive: true })
            let tablas = []
            if (res[1].data) {
                tablas = res[1].data.map(x => {
                    return { value: x.NOMBRE, caption: x.NOMBRE }
                })
            }
            const fn3 = d => d({ type: types.SECCIONES_POBLAR_SECCIONES, payload: { secciones, tablas } })
            fn3(dispatch)
            
            if (getState().maestras.get('maestras').count() === 0) {
                dispatch(obtenerMaestras())
            }
		})
		.catch((err) => {
            console.log(err)
			displayMessages(dispatch, err)
		})
    }
}

export function displayModalCreate(show, title, mode, id = null) {
    return (dispatch) => {
        dispatch({ type: types.SECCIONES_DISPLAY_MODAL_CREACION, payload: { show, title, mode, id } })
    }
}

export function seleccionarSeccion(id) {
    return (dispatch) => {
        dispatch({ type: types.SECCIONES_SELECCIONAR_SECCION, payload: id })
    }
}

export function resetAfterTipoCampo() {
    return (dispatch, getState) => {
        const path = 'modalCreate.data'
        dispatch({ type: types.SECCIONES_MODIFICAR_INPUTS, payload: { path: `${path}.TIPO_DATO`, value: -1 } })
        dispatch({ type: types.SECCIONES_MODIFICAR_INPUTS, payload: { path: `${path}.TAMANO`, value: null } })
        dispatch({ type: types.SECCIONES_MODIFICAR_INPUTS, payload: { path: `${path}.MAESTRA_ID`, value: -1 } })
    }
}

export function conmutarSeleccionRegistros(checked) {
    return (dispatch) => {
        dispatch({ type: types.SECCIONES_CONMUTAR_SELECCION_SECCIONES, payload: checked })
    }
}

export function almacenarSeccion() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('secciones',true))
        let data = getState().secciones.getIn(['modalCreate', 'data'])
        const mode = getState().secciones.getIn(['modalCreate', 'mode'])

        data = data.set('TIPO_DATO', data.get('TIPO_DATO') === -1 ? null : data.get('TIPO_DATO'))
        data = data.set('MAESTRA_ID', (data.get('MAESTRA_ID') === -1 || data.get('MAESTRA_ID') === null) ? null : Number(data.get('MAESTRA_ID')))
        data = data.set('NOMBRE_TABLA', data.get('NOMBRE_TABLA') === -1 ? null : data.get('NOMBRE_TABLA'))

        if (mode === 'create') {
            axios.post(`${API_URL}/secciones`, data)
            .then(res => {
                const id = res.data.ID
                const seccion = objectifyArray([res.data], { by: ['ID'], recursive: true })
                dispatch({ type: types.SECCIONES_ACTUALIZAR_SECCION, payload: seccion[id] })
                dispatch(displayModalCreate(false))
                dispatch(setSavingState('secciones',false))
                displayMessages(dispatch, { success: 'La seccion ha sido guardada exitosamente' })
            })
            .catch(err => {
                console.log(err)
                dispatch(setSavingState('secciones',false))
                displayMessages(dispatch, err)
            })
        } else if (mode === 'edit') {
            axios.put(`${API_URL}/secciones/${data.get('ID')}`, data)
            .then(res => {
                const seccion = objectifyArray([res.data], { by: ['ID'], recursive: true })
                dispatch({ type: types.SECCIONES_ACTUALIZAR_SECCION, payload: seccion[data.get('ID')] })
                dispatch(displayModalCreate(false))
                dispatch(setSavingState('secciones',false))
                displayMessages(dispatch, { success: 'La seccion ha sido guardada exitosamente' })
            })
            .catch(err => {
                console.log(err)
                dispatch(setSavingState('secciones',false))
                displayMessages(dispatch, err)
            })
        }
    }
}

export function eliminarSecciones() {
    return (dispatch, getState) => {
        let toDelete = getState().secciones.get('secciones').valueSeq().filter(x => x.get('SELECTED') === true)
        const total = toDelete.count()
        toDelete = toDelete.filter(x => x.get('ID') > 0)
        if (toDelete.count() > 0) {
            const axios = createAxiosInstance()
            const fns = []
            toDelete.map(x => x.get('ID')).forEach(x => {
                const fn = () => axios.delete(`${API_URL}/secciones/${x}`)
                fns.push(fn)
            })
    
            const toResultObject = (promise) => {
                return promise
                .then(result => ({ success: true, result }))
                .catch(error => ({ success: false, error }));
            }
    
            Promise.all(fns.map(x => x()).map(toResultObject))
            .then(res => {
                const succeded = []
                const failed = []
                res.forEach(x => {
                    if (!x.success) {
                        failed.push(x.error.response.data.ExceptionMessage)
                    } else {
                        succeded.push(1)
                        dispatch({ type: types.SECCIONES_ELIMINAR_SECCION, payload: x.result.data.ID })
                    }
                })
                if (succeded.length > 0) displayMessages(dispatch, { success: `Se han eliminado ${succeded.length + (total - toDelete.count())} de los ${total} registros` })
                if (failed.length > 0) displayMessages(dispatch, { response: { status: 500, data: failed.join('\n') } })
            })
        } else {
            displayMessages(dispatch, { warning: 'No seleccionó ningún registro para eliminar'})
        }
    }
}

export function filtrarSecciones(termino) {
    return (dispatch) => {
        dispatch({ type: types.SECCIONES_MODIFICAR_FILTRO, payload: termino })
    }
}

export function ordenarPor(columna) {
    return (dispatch) => {
        dispatch({ type: types.SECCIONES_ORDENAR_COLUMNA, payload: columna })
    }
}