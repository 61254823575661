import React, { Component } from 'react'
import { connect } from 'react-redux'

import ButtonStrip from '../Layout/components/ButtonStrip'
import BoxButton from '../Layout/components/BoxButton'
import Box from '../Layout/components/Box'
import Seccion from './components/Seccion'
import Titulo from './components/Titulo'
import Pie from './components/Pie';
import Resumen from './components/Resumen'

import {
    selectTab,
    almacenarRegistro,
    obtenerRegistro,
    reiniciarEstado,
    resetValorVariablesDependientes,
} from './actions'

class CrearRegistro extends Component {
    componentWillMount() {
        const formularioId = this.props.match.params.formulario_id
        const registroId = this.props.match.params.registro_id
        this.props.obtenerRegistro(formularioId, registroId)
    }

    testScroll = (e) => {
        const scroller = document.getElementById('scroller').parentElement
        scroller.scrollLeft += e.deltaY
    }

    moveToTab = (tab) => {
        this.props.selectTab(null, tab)
    }

    componentWillUnmount() {
        this.props.reiniciarEstado()
    }

	render() {
        if (!this.props.elementos) return null
        const { registro, secciones, selectedSeccion } = this.props

        const seccionesId = secciones.filter(x => !x.get('RESUMEN')).sortBy(x => x.get('ORDEN_SECCION')).valueSeq().map(x => x.get('ID')).toArray()
        const currentSelection = seccionesId.indexOf(selectedSeccion)
        const back = (currentSelection > 0) ? seccionesId[currentSelection - 1] : null
        const next = (currentSelection < seccionesId.length - 1) ? seccionesId[currentSelection + 1] : null

        const editable = this.props.elementos.filter(x => x === "5iqDMAlg").count() === 1
        const buttons = []
        if (this.props.elementos.filter(x => x === "5iqDMAlg").count()) buttons.push(<BoxButton key={ `action[0][0]` } name="floppy-o" title="Guardar" onClick={ () => this.props.almacenarRegistro(this.props.match.params.formulario_id) } disabled={ this.props.update } />)
        buttons.push(<BoxButton key={ `action[0][1]` } name="angle-left" title="Atrás" onClick={ () => this.moveToTab(back) } disabled={ back === null } />)
        buttons.push(<BoxButton key={ `action[0][2]` } name="angle-right" title="Siguiente" onClick={ () => this.moveToTab(next) } disabled={ next === null }/>)

		return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: "hidden" }}>
                <Box title={<span>{registro ? registro.get('NOMBRE') : 'NUEVO REGISTRO'} {registro ? registro.get('DESCRIPCION') : ''}</span>} stretch>
                    <div className="row" style={{ height: '100%' }}>
                        <div className="col-md-3 col-sm-4 col-xs-12">
                            <div className="row" style={{ height: '100%' }}>
                                <Resumen editable={editable} resetValorVariablesDependientes={this.props.resetValorVariablesDependientes}/>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-8 col-xs-12">
                            <div className="row" style={{ height: '100%' }}>
                                <div className="col-md-12 col-sm-12 col-xs-12" style={{ height: '100%' }}>
                                    <div className="nav-container">
                                        <div className="tabs-wrapper">
                                            <div className="tabs1-wrapper" id="scroller" onWheel={ (e) => this.testScroll(e) }>
                                                <div className="tabs1">
                                                    {
                                                        secciones.filter(x => !x.get('RESUMEN')).sortBy(x => x.get('ORDEN_SECCION')).valueSeq().map(x => {
                                                            return (
                                                                <div key={`tab1[${x.get('ID')}]`} id={`tabId${x.get('ID')}`} className={`tab1${selectedSeccion === x.get('ID') ? ' active' : ''}${x.get('ESTADO_SECCION') ? '' : ' locked'}`} onClick={ (e) => this.props.selectTab(e, x.get('ID')) }>
                                                                    <Pie seccion={x.get('ID')}/>
                                                                    <div className="tab-top-color" style={{ background: x.get('COLOR') }}></div>
                                                                    {x.get('ABREVIADO')}
                                                                </div>
                                                            )
                                                        })
                                                        
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nav-main-section">
                                            <div style={{ margin: '0 -10px' }}>
                                                <ButtonStrip actions={ buttons } />
                                            </div>
                                            <Titulo selected={ selectedSeccion }/>
                                            <div className="nav-main-section-scrollable">
                                                <Seccion selected={ selectedSeccion } editable={editable} resetValorVariablesDependientes={this.props.resetValorVariablesDependientes}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>            
		)
	}
}

function mapStateToProps(state) {
    return {
        registro: state.crearregistro.get('registro'),
        secciones: state.crearregistro.get('secciones'),
        selectedSeccion: state.crearregistro.get('selectedSeccion'),
        compania: state.layout.get('compania'),
        update: state.crearregistro.get('update'),
        elementos: state.layout.get('elementos'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        obtenerRegistro: (formularioId, registroId) => dispatch(obtenerRegistro(formularioId, registroId)),
        selectTab: (e, id) => dispatch(selectTab(e, id)),
        almacenarRegistro: (formulario_id) => dispatch(almacenarRegistro(formulario_id)),
        reiniciarEstado: () => dispatch(reiniciarEstado()),
        resetValorVariablesDependientes: (s, v) => dispatch(resetValorVariablesDependientes(s, v)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrearRegistro)