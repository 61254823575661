import axios from 'axios'
import querystring from 'querystring'
import { API_URL } from '../../utils/constants'
import * as types from '../../types'
import history from '../../utils/history'

import { displayMessages } from '../Layout/actions'

export function recoveryPassword() {
    return function (dispatch, getState) {
        const email = getState().recoverypassword.get('email');
        const fn0 = d => d({ type: types.RECOVERY_PASSWORD_PROCESANDO, payload: true  })
        fn0(dispatch)

        const data = querystring.stringify({
            email
        })

        axios
            .post(`${API_URL}/account/recovery_password`, data)
            .then((res) => {
                dispatch({type: types.LAYOUT_MOSTRAR_MENSAJE, payload: { type: 'success', message: res.data }})
                history.push('/')
            })
            .catch((err) => {
                const fn5 = d => d({ type: types.RECOVERY_PASSWORD_PROCESANDO, payload: false  })
                fn5(dispatch)
                if (err.response && err.response.status === 401) {
                    dispatch({type: types.LAYOUT_MOSTRAR_MENSAJE, payload: { type: 'danger', message: 'Nombre de usuario o contraseña incorrectas' }})
                } else {
                    displayMessages(dispatch, err)
                }
            })
    }
}