import * as types from '../../types'
import Immutable from 'immutable'
import diff from 'immutablediff'
import patch from 'immutablepatch'

import { isPrimitive } from '../../utils/helpers'
import { estructuraEntity } from '../../utils/constants';

const INITIAL_STATE = Immutable.fromJS({
    estructuras: {},
    secciones: {},
    variables: {},
    companias: {},
    selected: null,
    selectedSeccion: null,
    selectedVariable: null,
    modalCreate: {
        show: false,
        title: '',
        mode: null,
        data: null,
    },
    modalClone: {
        show: false,
        title: '',
        data: null,
    },
    saving: false,
})

export default function (state = INITIAL_STATE, action) {
    let data, selected, selectedSeccion, ops
    let maxOrdenSeccion
    switch (action.type) {
        case types.FORMULARIOS_POBLAR_FORMULARIOS:
            state = state.set('estructuras', Immutable.fromJS(action.payload.estructuras))
            state = state.set('companias', Immutable.fromJS(action.payload.companias))
            state = state.set('selected', null)
            state = state.set('selectedSeccion', null)
            state = state.set('selectedVariable', null)
            return state
        case types.FORMULARIOS_POBLAR_SECCIONES:
            state = state.set('secciones', Immutable.fromJS(action.payload.secciones))
            return state
        case types.FORMULARIOS_POBLAR_VARIABLES:
            state = state.set('variables', Immutable.fromJS(action.payload.variables))
            return state
        case types.FORMULARIOS_DISPLAY_MODAL_CREACION:
            if (action.payload.show) {
                state = state.setIn(['modalCreate', 'show'], true)
                state = state.setIn(['modalCreate', 'title'], action.payload.title)
                if (action.payload.mode === 'edit') {
                    let estructura = state.getIn(['estructuras', String(state.get('selected'))])
                    state = state.setIn(['modalCreate', 'data'], estructura)
                } else {
                    state = state.setIn(['modalCreate', 'data'], Immutable.fromJS(estructuraEntity()))
                }
                state = state.setIn(['modalCreate', 'mode'], action.payload.mode)
            } else {
                state = state.set('modalCreate', INITIAL_STATE.get('modalCreate'))
            }
            return state
        case types.FORMULARIOS_MODIFICAR_INPUTS:
            if (isPrimitive(action.payload.value)) {
                state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
            } else {
                if (action.payload.immutable === false) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                }
            }
            return state
        case types.FORMULARIOS_ACTUALIZAR_ESTRUCTURA:
            let estructuraMod = Immutable.fromJS(action.payload)
            data = state.get('secciones')
            maxOrdenSeccion = 0
            estructuraMod.get('SECCIONES_VARIABLES').valueSeq().forEach(x => {
                maxOrdenSeccion = maxOrdenSeccion < x.get('ORDEN_SECCION') ? x.get('ORDEN_SECCION') : maxOrdenSeccion
                data = data.setIn([String(x.get('SECCION_ID')), 'ESTADO'], x.get('ESTADO'))
                data = data.setIn([String(x.get('SECCION_ID')), 'ORDEN_SECCION'], x.get('ORDEN_SECCION'))
                let item = x.get('VARIABLE')
                item = item.set('ORDEN_VARIABLE', x.get('ORDEN_VARIABLE'))
                data = data.setIn([String(x.get('SECCION_ID')), 'VARIABLE', String(x.get('VARIABLE_ID'))], item)
            })
            data.valueSeq().filter(x => !x.get('ORDEN_SECCION')).sortBy(x => x.get('ID')).forEach(x => {
                maxOrdenSeccion++
                data = data.setIn([String(x.get('ID')),'ORDEN_SECCION'], maxOrdenSeccion)
                data = data.setIn([String(x.get('ID')),'ESTADO'], false)
                data = data.setIn([String(x.get('ID')),'VARIABLE'], Immutable.Map({}))
            })
            estructuraMod = estructuraMod.set('SECCIONES', data)
            ops = diff(state.getIn(['estructuras', String(action.payload.ID)]), estructuraMod)
            state = state.setIn(['estructuras', String(action.payload.ID)], patch(state.getIn(['estructuras', String(action.payload.ID)]), ops))
            return state
        case types.FORMULARIOS_SELECCIONAR_ESTRUCTURA:
            state = state.set('selected', action.payload)
            data = state.get('secciones')
            maxOrdenSeccion = 0
            state.getIn(['estructuras', String(action.payload), 'SECCIONES_VARIABLES']).valueSeq().forEach(x => {
                maxOrdenSeccion = maxOrdenSeccion < x.get('ORDEN_SECCION') ? x.get('ORDEN_SECCION') : maxOrdenSeccion
                data = data.setIn([String(x.get('SECCION_ID')), 'ESTADO'], x.get('ESTADO'))
                data = data.setIn([String(x.get('SECCION_ID')), 'ORDEN_SECCION'], x.get('ORDEN_SECCION'))
                let item = x.get('VARIABLE')
                item = item.set('ORDEN_VARIABLE', x.get('ORDEN_VARIABLE'))
                data = data.setIn([String(x.get('SECCION_ID')), 'VARIABLE', String(x.get('VARIABLE_ID'))], item)
            })
            data.valueSeq().filter(x => !x.get('ORDEN_SECCION')).sortBy(x => x.get('ID')).forEach(x => {
                maxOrdenSeccion++
                data = data.setIn([String(x.get('ID')),'ORDEN_SECCION'], maxOrdenSeccion)
                data = data.setIn([String(x.get('ID')),'ESTADO'], data.getIn([String(x.get('ID')),'RESUMEN'])?true:false)
                data = data.setIn([String(x.get('ID')),'VARIABLE'], Immutable.Map({}))
            })
            state = state.setIn(['estructuras', String(action.payload), 'SECCIONES'], data)
            state = state.set('selectedSeccion', null)
            state = state.set('selectedVariable', null)
            return state
        case types.FORMULARIOS_SELECCIONAR_SECCION_ESTRUCTURA:
            state = state.set('selectedSeccion', action.payload)
            state = state.set('selectedVariable', null)
            return state
        case types.FORMULARIOS_ADICIONAR_VARIABLE_SECCION:
            selected = state.get('selected')
            selectedSeccion = state.get('selectedSeccion')
            let path = `estructuras.${selected}.SECCIONES.${selectedSeccion}.VARIABLE`
            action.payload.value.ORDEN_VARIABLE = state.getIn(path.split('.')).keySeq().count() + 1
            state = state.setIn(`${path}.${action.payload.value.ID}`.split('.'), Immutable.fromJS(action.payload.value))
            return state
        case types.FORMULARIOS_SELECCIONAR_VARIABLE_ESTRUCTURA:
            state = state.set('selectedVariable', action.payload)
            return state
        case types.FORMULARIOS_REMOVER_VARIABLE_ESTRUCTURA:
            state = state.deleteIn(`${action.payload.path}.${action.payload.selectedVariable}`.split('.'))
            return state
        case types.FORMULARIOS_REORDERNAR_VARIABLES:
            state.getIn(`${action.payload.path}`.split('.')).valueSeq().sortBy(x => x.get('ORDEN_VARIABLE')).forEach((x, n) => {
                state = state.setIn(`${action.payload.path}.${x.get('ID')}.ORDEN_VARIABLE`.split('.'), n + 1)
            })
            return state
        case types.FORMULARIOS_REORDERNAR_SECCIONES:
            state.getIn(`${action.payload.path}`.split('.')).valueSeq().sortBy(x => x.get('ORDEN_SECCION')).forEach((x, n) => {
                state = state.setIn(`${action.payload.path}.${x.get('ID')}.ORDEN_SECCION`.split('.'), n + 1)
            })
            return state
        case types.FORMULARIOS_ELIMINAR_ESTRUCTUDA:
            state = state.set('selected', null)
            state = state.set('selectedSeccion', null)
            state = state.set('selectedVariable', null)
            state = state.deleteIn(['estructuras', String(action.payload)])
            return state
        case types.FORMULARIOS_DISPLAY_MODAL_CLONACION:
            if (action.payload.show) {
                state = state.setIn(['modalClone', 'show'], true)
                state = state.setIn(['modalClone', 'title'], action.payload.title)
                state = state.setIn(['modalClone', 'data'], Immutable.fromJS(estructuraEntity()))
            } else {
                state = state.set('modalClone', INITIAL_STATE.get('modalClone'))
            }
            return state
        case types.FORMULARIOS_GUARDANDO:
            state = state.set('saving', action.payload)
            return state
        default:
            return state
    }
}