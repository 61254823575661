import React from "react";
import { Route, Switch } from "react-router";

import Authorization from "./containers/Authorization";
import Home from "./containers/Home";
import Maestras from "./containers/Maestras";
import Variables from "./containers/Variables";
import Secciones from "./containers/Secciones";
import Formularios from "./containers/Formularios";
import Parametros from "./containers/Parametros";
import Bitacora from "./containers/Bitacora";
import CargueMasivo from "./containers/CargueMasivo";
import Registros from "./containers/Registros";
import CrearRegistro from "./containers/CrearRegistro";
import ListadoFormularios from "./containers/Registros/ListadoFormularios";
import Usuarios from "./containers/Usuarios";
import Roles from "./containers/Roles";
import SignIn from "./containers/SignIn";
import SignUp from "./containers/SignUp";
import RecoveryPassword from "./containers/RecoveryPassword";
import Layout from "./containers/Layout";
import Error401 from "./containers/Error401";
import Error404 from "./containers/Error404";
import Scripts from "./containers/Scripts";
import Fuentes from "./containers/Fuentes";

export default (
  <Switch>
    <Route exact path="/signin" component={SignIn} />
    <Route exact path="/signup" component={SignUp} />
    <Route exact path="/recovery_password" component={RecoveryPassword} />
    <Layout>
      <Switch>
        <Route exact path="/" component={Authorization(Home)} />
        <Route exact path="/maestras" component={Authorization(Maestras)} />
        <Route exact path="/variables" component={Authorization(Variables)} />
        <Route exact path="/secciones" component={Authorization(Secciones)} />
        <Route
          exact
          path="/formularios"
          component={Authorization(Formularios)}
        />
        <Route exact path="/parametros" component={Authorization(Parametros)} />
        <Route exact path="/bitacora" component={Authorization(Bitacora)} />
        <Route
          exact
          path="/formularios/cargue"
          component={Authorization(CargueMasivo)}
        />
        <Route
          exact
          path="/registros/formularios"
          component={Authorization(ListadoFormularios)}
        />
        <Route
          exact
          path="/registros/formularios/:id"
          component={Authorization(Registros)}
        />
        <Route
          exact
          path="/formularios/:formulario_id/registros/:registro_id?"
          component={Authorization(CrearRegistro)}
        />
        <Route exact path="/account" component={Authorization(Usuarios)} />
        <Route exact path="/roles" component={Authorization(Roles)} />
        <Route exact path="/scripts" component={Authorization(Scripts)} />
        <Route exact path="/fuentes" component={Authorization(Fuentes)} />
        <Route path="/unauthorized" component={Error401} />
        <Route component={Error404} />
      </Switch>
    </Layout>
  </Switch>
);
