import Immutable from 'immutable'
import * as types from '../../types'
import { isPrimitive } from '../../utils/helpers'

const INITIAL_STATE = Immutable.fromJS({
    scripts: [],
    script: null,
    iframe: null,
    loading: false
})

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.HOME_RESET_STATE:
            state = INITIAL_STATE
            return state
        case types.HOME_POBLAR_SCRIPTS:
            state = state.set('scripts', Immutable.fromJS(action.payload))
            return state
        case types.HOME_SELECCIONAR_SCRIPT:
            state = state.set('script', action.payload)
            return state
        case types.HOME_OBTENER_IFRAME_URL:
            state = state.set('iframe', action.payload)
            return state
        case types.HOME_SETEAR_LOADING:
            state = state.set('loading', action.payload)
            if (action.payload) {
                state = state.set('iframe', null)
            }
            return state
        case types.HOME_MODIFICAR_INPUTS:
            if (isPrimitive(action.payload.value)) {
                state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
            } else {
                if (action.payload.immutable === false) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                }
            }
            return state
        default:
            return state
    }
}