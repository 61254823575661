import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import ButtonStrip from '../../Layout/components/ButtonStrip'
import BoxButton from '../../Layout/components/BoxButton'
import Input from '@mawesi-sas/mws_react_component_input'
import {
    seleccionarSeccionFormulario,
    seleccionarVariableFormulario,
    removeVariableFromSeccion,
    moverVariable,
    almacenarSeccionesFormulario,
    validateSameCompany,
    moverSeccion,
    selectFromSelector,
} from '../actions'
import { showHideModalSelector, showModalConfirmAction } from '../../Layout/actions';
import BoxSection from '../../Layout/components/BoxSection'

class DetalleFormulario extends Component {
    handleOpenSelector = (list) => {
        let columns = [
            { id: 1, caption: 'Variable', value: 'NOMBRE_VARIABLE', width: '100%' },
        ]

        const payload = {}
        const fn = (a) => {
            payload.value = a
            this.props.selectFromSelector(payload)
        }

        this.props.showHideModalSelector(true, 'Listado de variables', columns, 'variables?estado=true', fn)
    }

    render() {
        const { selected, formulario, selectedSeccion, selectedVariable, saving } = this.props
        if (!selected) {
            return (
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: '1.5em', color: 'rgb(187,187,187)', fontStyle: 'italic', fontWeight: '100' }}>
                        <FontAwesome name="info-circle" /> Seleccione un formulario para visualizar/modificar su detalle
                    </p>
                </div>
            )
        }

        const buttons = []
        if (this.props.elementos.filter(x => x === "KIO6x6gw").count()) buttons.push(<BoxButton key={`action[1][0]`} name="floppy-o" title="Guardar" onClick={() => this.props.validateSameCompany()} disabled={saving} />)

        const buttons1 = [
            <BoxButton key={`action[3][0]`} name="chevron-up" title="Subir" onClick={() => this.props.moverSeccion('up')} disabled={selectedSeccion === null} />,
            <BoxButton key={`action[3][1]`} name="chevron-down" title="Bajar" onClick={() => this.props.moverSeccion('down')} disabled={selectedSeccion === null} />,
        ]

        const selectedSeccionObj = formulario.getIn(`SECCIONES.${selectedSeccion}`.split('.'))
        
        const buttons2 = [
            <BoxButton key={`action[2][0]`} name="plus" title="Adicionar" onClick={() => this.handleOpenSelector()} disabled={!(selectedSeccionObj && selectedSeccionObj.get('ESTADO'))} />,
            <BoxButton key={`action[2][1]`} name="trash" title="Remover" onClick={() => this.props.showModalConfirmAction(true, 'Esta seguro que desea remover esta variable de la sección?', () => this.props.removeVariableFromSeccion())} danger disabled={selectedVariable === null} />,
            <BoxButton key={`action[2][2]`} name="chevron-up" title="Subir" onClick={() => this.props.moverVariable('up')} disabled={selectedVariable === null} />,
            <BoxButton key={`action[2][3]`} name="chevron-down" title="Bajar" onClick={() => this.props.moverVariable('down')} disabled={selectedVariable === null} />,
        ]

        return (
            <div className="col-md-12" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <BoxSection title={<span>Detalle formulario: <strong>{formulario.get('NOMBRE')}</strong></span>} stretch>
                    <div style={{ padding: "0% 1%" }}>
                        <ButtonStrip
                            actions={buttons}
                        />
                    </div>
                    <div className="table-scrolled">
                        <div className="row" style={{width: "100%"}}>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <BoxSection title="Listado de secciones" stretch>
                                    <div style={{ padding: "0% 1%" }}>
                                        <ButtonStrip
                                            actions={buttons1}
                                        />
                                    </div>
                                    <div>
                                        {
                                            formulario.get('SECCIONES').valueSeq().sortBy(x => x.get('ORDEN_SECCION')).map(a => {
                                                return (
                                                    <div key={`sct[${a.get('ID')}]`} className={selectedSeccion === a.get('ID') ? 'info' : ''} onClick={() => this.props.seleccionarSeccionFormulario(a.get('ID'))}>
                                                        <Input
                                                            type="switch"
                                                            reducer="formularios"
                                                            path={`estructuras.${formulario.get('ID')}.SECCIONES.${a.get('ID')}.ESTADO`}
                                                            label={a.get('ABREVIADO')}
                                                            disabled={a.get('RESUMEN')}
                                                            commentario={a.get('TITULO').substring(0, 50) + '...'}
                                                            afterChange={() => this.props.seleccionarSeccionFormulario(a.get('ID'))}
                                                            title="Mostrar/Ocultar sección"
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </BoxSection>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <BoxSection title="Listado de variables" stretch>
                                    <div style={{ padding: "0% 1%" }}>
                                        <ButtonStrip
                                            actions={buttons2}
                                        />
                                    </div>
                                    <div>
                                        {
                                            (formulario.getIn(['SECCIONES', String(selectedSeccion), 'VARIABLE']) &&
                                                formulario.getIn(['SECCIONES', String(selectedSeccion), 'VARIABLE']).valueSeq().sortBy(x => x.get('ORDEN_VARIABLE')).map(e => {
                                                    return (
                                                        <div key={`var[${e.get('ID')}]`} className={selectedVariable === e.get('ID') ? ' info' : ''} onClick={() => this.props.seleccionarVariableFormulario(e.get('ID'))}>
                                                            <span>{e.get('NOMBRE_VARIABLE')}</span>
                                                        </div>
                                                    )
                                                }))
                                        }
                                    </div>
                                </BoxSection>
                            </div>
                        </div>
                    </div>
                </BoxSection>
            </div>
        )
    }
}

const mapStateToProps = (state, onwProps) => {
    return {
        selected: state.formularios.get('selected'),
        formulario: state.formularios.getIn(['estructuras', String(state.formularios.get('selected'))]),
        variables: state.variables.get('variables'),
        selectedSeccion: state.formularios.get('selectedSeccion'),
        selectedVariable: state.formularios.get('selectedVariable'),
        saving: state.formularios.get('saving'),
        elementos: state.layout.get('elementos'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        seleccionarSeccionFormulario: (id) => dispatch(seleccionarSeccionFormulario(id)),
        seleccionarVariableFormulario: (id) => dispatch(seleccionarVariableFormulario(id)),
        selectFromSelector: (payload) => dispatch(selectFromSelector(payload)),
        showHideModalSelector: (show, title, fields, url, action) => dispatch(showHideModalSelector(show, title, fields, url, action)),
        removeVariableFromSeccion: () => dispatch(removeVariableFromSeccion()),
        moverVariable: (direccion) => dispatch(moverVariable(direccion)),
        almacenarSeccionesFormulario: () => dispatch(almacenarSeccionesFormulario()),
        validateSameCompany: () => dispatch(validateSameCompany()),
        moverSeccion: (direccion) => dispatch(moverSeccion(direccion)),
        showModalConfirmAction: (show, message, action) => dispatch(showModalConfirmAction(show, message, action)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetalleFormulario)
