import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from '@mawesi-sas/mws_react_component_modal';
import Input from "@mawesi-sas/mws_react_component_input";

import {
  displayModalDownload,
  toggleSelectAllItems,
  descargarPlantilla,
  seleccionarFormulario
} from "../actions";

import { decargarMaestras } from "../../Maestras/actions";

class ModalDownload extends Component {
  handleCloseModal = e => {
    const modal = document.getElementById("modalDownload");
    modal.classList.remove("fadeIn");
    modal.classList.add("fadeOut");

    const modalDialog = document.querySelector(`#modalDownload .modal-dialog`);
    modalDialog.classList.remove("zoomIn");
    modalDialog.classList.add("zoomOut");
    setTimeout(() => this.props.displayModalDownload(false), 300);
  };

  modalContent = (modalDownload, filtro) => {
    return (
      <form id="form" noValidate>
        <div className="searchbar">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <div style={{ flex: 1 }}></div>
            <div>
              <Input
                type="text"
                reducer="carguemasivo"
                path="modalDownload.filtro"
                placeholder="Filtrar..."
              />
            </div>
          </div>
        </div>
        <div className="modal-dialog" style={{ width: "90%" }}>
          <div className="row">
            <div className="col-md-5 col-sm-5 col-xs-12">
              <div className="alert alert-info">
                <ul style={{ paddingLeft: "15px" }}>
                  <li>
                    Conserve el número del ID si quiere realizar una
                    modificación en el registro, en caso de quiera crear uno
                    nuevo, déjelo vacío.
                  </li>
                  <li>
                    Para los campos que son valores de listas de maestras, el
                    valor debe ser exactamente como lo presenta la lista. Para
                    descargar el listado de maestras haga click{" "}
                    <span
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => this.props.decargarMaestras()}
                    >
                      aquí
                    </span>
                  </li>
                  <li>
                    Los campos no editables no permitirá el ingreso o
                    modificación por medio de cargues masivos
                  </li>
                  <li>
                    Los campos inactivos, no editables y que contienen fórmulas
                    o imágenes no harán parte del descargue de plantillas ni del
                    cargue de archivos
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7 col-sm-7 col-xs-12">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label className="control-label">
                        Seleccione el formulario
                      </label>
                      <Input
                        type="select"
                        options={this.props.formularios}
                        reducer="carguemasivo"
                        path="modalDownload.selectedFormulario"
                        afterChange={this.props.seleccionarFormulario}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="checkbox">
                      <label style={{ color: "#000" }}>
                        <input
                          type="checkbox"
                          style={{ height: "13px", marginTop: "2px" }}
                          onChange={e =>
                            this.props.toggleSelectAllItems(e.target.checked)
                          }
                          checked={
                            modalDownload
                              .getIn(["estructura", "SECCIONES_VARIABLES"])
                              .filter(x => x.get("SELECTED"))
                              .count() > 0 &&
                            modalDownload
                              .getIn(["estructura", "SECCIONES_VARIABLES"])
                              .filter(x => x.get("SELECTED"))
                              .count() >=
                              modalDownload
                                .getIn(["estructura", "SECCIONES_VARIABLES"])
                                .count()
                          }
                        />
                        Seleccionar todos
                      </label>
                    </div>
                  </div>
                  <div className="form-group" style={{ marginBottom: "2px" }}>
                    <label className="control-label">
                      Seleccione los campos que desea en la plantilla
                    </label>
                    <div className="lista-variables-wrapper">
                      {modalDownload
                        .getIn(["estructura", "SECCIONES_VARIABLES"])
                        .map((x, n) => {
                          if (
                            x
                              .getIn(["VARIABLE", "NOMBRE_VARIABLE"])
                              .toUpperCase()
                              .indexOf(filtro.toUpperCase()) === -1
                          )
                            return null;
                          return (
                            <div
                              key={`dv6[${x.get("ID")}]`}
                              className={`${x.get("SELECTED") ? "info" : ""}`}
                            >
                              <label>
                                <Input
                                  type="checkbox"
                                  reducer="carguemasivo"
                                  path={`modalDownload.estructura.SECCIONES_VARIABLES.${n}.SELECTED`}
                                />
                                <span>&nbsp;</span>
                                {x.getIn(["VARIABLE", "NOMBRE_VARIABLE"])}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <div
                      style={{
                        background: "#f2f2f2",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        padding: "5px 10px"
                      }}
                    >
                      <label
                        className="control-label"
                        style={{ margin: "5px 0" }}
                      >
                        Opciones
                      </label>
                      <div>
                        <div
                          style={{
                            borderBottom: "1px solid rgb(230, 230, 230)",
                            padding: "4px 0"
                          }}
                        >
                          <Input
                            type="switch"
                            reducer="carguemasivo"
                            path="modalDownload.registros"
                            label="Información de registros"
                            commentario="Incluir los registros actuales en la plantilla"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  render() {
    const { modalDownload } = this.props;
    if (!modalDownload.get("show")) return null;
    const filtro = modalDownload.get("filtro");
    const seleccion =
      modalDownload
        .getIn(["estructura", "SECCIONES_VARIABLES"])
        .filter(x => x.get("SELECTED"))
        .count() === 0;

    return (
      <Modal
        ancho={this.props.width < this.props.height ? "90%" : "60%"}
        mostrar={modalDownload.get("show")}
        contenido={this.modalContent(modalDownload, filtro)}
        titulo={modalDownload.get("title")}
        nombre="modalDownload"
        botones={[
          <button
            key="download"
            className={`btn btn-default ${
              modalDownload.get("saving") || seleccion ? "disabled" : ""
            }`}
            title="Descargar"
            disabled={modalDownload.get("saving") || seleccion}
            onClick={() => this.props.descargarPlantilla()}
          >
            Descargar plantilla
          </button>
        ]}
        onCerrarModal={e => this.handleCloseModal(e)}
      />
    );
  }
}

const mapStateToProps = (state, onwProps) => {
  return {
    modalDownload: state.carguemasivo.get("modalDownload"),
    formularios: state.carguemasivo.get("formularios"),
    width: state.layout.get("width"),
    height: state.layout.get("height")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    displayModalDownload: show => dispatch(displayModalDownload(show)),
    toggleSelectAllItems: checked => dispatch(toggleSelectAllItems(checked)),
    descargarPlantilla: () => dispatch(descargarPlantilla()),
    decargarMaestras: () => dispatch(decargarMaestras()),
    seleccionarFormulario: () => dispatch(seleccionarFormulario())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDownload);
