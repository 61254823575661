import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Input from '@mawesi-sas/mws_react_component_input'

import { clearMessage } from '../Layout/actions';
import { signUp, assingUsernameFromEmail } from './actions';

import '../../css/signup.css';
import logo from "../../img/logo_01a.png";
import background from '../../img/background_2.jpg'

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPwd: false
        };
    }

    componentDidUpdate() {
        this.props.clearMessage();
    }

    render() {
        const { processing, message } = this.props;
        if (message) {
            switch (message.get('type')) {
                case 'danger':
                    toast.error(message.get('message'));
                    break;
                case 'warning':
                    toast.warn(message.get('message'));
                    break;
                case 'success':
                    toast.success(message.get('message'));
                    break;
                default:
                    toast.info(message.get('message'));
            }
        }

        return (
            <div className="signup-container" style={{ backgroundImage: `url(${background})` }}>
                <div className="register-box animated fadeIn">
                    <div>
                        <img src={logo} width="27%" style={{ verticalAlign: "middle" }} alt="logo"/>
                        <p className="text-center" style={{ fontSize: "24px", fontWeight: 300, margin: "15px 0" }}>
                            Registre su nueva cuenta
                        </p>
                        <form className="signup-form">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Nombre de usuario</label>
                                            <Input reducer='signup' path='username' type="text" placeholder="Nombre de usuario" maxLength="20" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Nombre</label>
                                            <Input reducer='signup' path='name' type="text" placeholder="Nombre" maxLength="20" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                            <label className="control-label">Apellido</label>
                                            <Input reducer='signup' path='lastname' type="text" placeholder="Apellido" maxLength="50" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Correo electrónico</label>
                                            <Input reducer='signup' path='email' type="email" placeholder="Correo electrónico" maxLength="20"
                                                afterChange={() => this.props.assingUsernameFromEmail()} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Empresa</label>
                                            <Input reducer='signup' path='empresa' type="text" placeholder="Empresa" maxLength="50" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Contraseña</label>
                                            <Input reducer='signup' path='password' type={this.state.showPwd ? "text" : "password"} placeholder="Contraseña" />
                                            <FontAwesome name="eye"onMouseDown={e => this.setState({ showPwd: true })}
                                                onMouseUp={e => this.setState({ showPwd: false })} onTouchStart={e => this.setState({ showPwd: true })}
                                                onTouchEnd={e => this.setState({ showPwd: false })} style={{ position: 'absolute', top: '60%', right: '6%' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Input type="switch" reducer="signup" path="termsConditions" label={
                                                <span>Acepto los <a href="/static/terminos-y-condiciones.pdf" target="_blank">t&eacute;rminos y condiciones</a></span>
                                            } />
                                            <Input type="switch" reducer="signup" path="policyPrivate" label={
                                                <span>Acepto la <a href="https://www.lonjacali.org/wp-content/uploads/2019/09/AVISO-DE-PRIVACIDAD-LONJACALI.pdf" target="_blank">pol&iacute;tica de privacidad</a></span>
                                            } />
                                        </div>
                                    </div>
                                </div>
                                <div className="buttons-section">
                                    <button className="btn btn-default btn-sm" style={{ width: '30%' }}
                                        disabled={processing} onClick={e => { e.preventDefault(); this.props.signUp() }}>
                                        {processing ? <span><FontAwesome name="circle-o-notch" spin /> Registrando usuario ...</span> : <span>Crear</span>}
                                    </button>
                                    <button className="btn btn-default btn-sm" style={{ width: '30%' }}
                                        disabled={processing} onClick={e => this.props.history.push('/signin')}>
                                        {processing ? <span><FontAwesome name="circle-o-notch" spin /> Volviendo ...</span> : <span>Cancelar</span>}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        email: state.signup.get('email'),
        password: state.signup.get('password'),
        processing: state.signup.get('processing'),
        message: state.layout.get('message')
    };
}

function mapDispatchToProps(dispatch) {
    return {
        signUp: () => dispatch(signUp()),
        assingUsernameFromEmail: () => dispatch(assingUsernameFromEmail()),
        clearMessage: () => dispatch(clearMessage())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);