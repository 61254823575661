import React, { Component } from "react";

export default class Box extends Component {
  render() {
    const { onModal, stretch } = this.props;

    const styles = {
      box: {
        position: "relative"
      },
      title: {},
      body: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      },
      header: {
        position: "relative"
      },
      bfContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: 0,
        padding: "10px 0"
      }
    };
    if (stretch) {
      styles.box = {
        marginBottom: "0",
        height: "100%"
      };
      styles.bfContent.height = "0";
    }
    if (onModal) {
      styles.box = {
        marginBottom: "10px"
      };
      styles.title = {
        fontSize: "0.95em",
        fontWeight: "bold",
        paddingTop: "3px",
        paddingBottom: "2px"
      };
      styles.body = {
        padding: "3px 10px",
        background: "#efefef"
      };
      styles.header = {
        ...{
          borderBottom: "1px solid #aaa"
        }
      };
      styles.bfContent = {
        ...{
          marginTop: "0"
        }
      };
    }

    return (
      <div
        className="tab-content box box-default animated fadeIn"
        style={styles.box}
      >
        <div className="box-body" style={styles.body}>
          <div className="box-header with-border" style={styles.header}>
            <h3 className="box-title" style={styles.title}>
              {this.props.title}
            </h3>
            <div className="box-actions">{this.props.actions}</div>
          </div>
          <div style={styles.bfContent}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
