import moment from 'moment'
import objectifyArray from 'objectify-array'
import FileDownload from 'react-file-download'
import * as types from '../../types'
import { API_URL } from '../../utils/constants'
import { createAxiosInstance, procesarResumen, evalListadoRegistros } from '../../utils/helpers'
import { displayMessages, showHideModalMsgBox, setSavingState } from '../Layout/actions'


export function obtenerListadoFormularios() {
    const axios = createAxiosInstance()
    return (dispatch) => {
        axios.get(`${API_URL}/formularios`).then(res => {
            dispatch({
                type: types.REGISTROS_LLENADO_FORMULARIOS,
                payload: { formularios: res.data }
            })
        }).catch(err => {
            console.log(err)
            displayMessages(dispatch, err)
        })
    }
}

export function obtenerRegistros(page = 1, formularioId) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: true })
        const size = getState().registros.get('size')
        
        const params = {
            formularioId,
            page,
            size,
            filters: "[]"
        }
        const fn0 = () => axios.get(`${API_URL}/registros`, { params })
        const fn1 = () => axios.get(`${API_URL}/formularios?id=${formularioId}`)

        Promise.all([fn0(), fn1()])
            .then((res) => {
                if (res[0].data.registros.length === 0) {
                    // Data formulario
                    let data = res[1].data
                    data.SECCIONES_VARIABLES = data.SECCIONES_VARIABLES.filter(x => x.ESTADO)
                    // const proc = procesarEstructuras(data, res[2].data.map(x => x.VARIABLE))
                    const proc = procesarResumen(data, data.SECCIONES_VARIABLES.map(x => x.VARIABLE.NOMBRE_VARIABLE))
                    let secciones = proc.secciones
                    let tableColumns = proc.tableColumns
                    const fn3 = d => d({
                        type: types.REGISTROS_POBLAR_REGISTROS,
                        payload: {
                            registros: [],
                            secciones,
                            tableColumns,
                            total: 0,
                            selectedFormulario: res[0].data.formulario,
                            variablesList: data.SECCIONES_VARIABLES.filter(x => x.ESTADO && x.VARIABLE.TIPO_CAMPO !== "Imagen").map(x => {
                                return {
                                    value: `${x.SECCION_ID}_${x.VARIABLE_ID}`,
                                    caption: x.VARIABLE.NOMBRE_VARIABLE
                                }
                            }),
                            scripts: res[0].data.scripts || []
                        } })
                    fn3(dispatch)
                } else {
                    let registros = objectifyArray(res[0].data.registros, { by: ['ID'], recursive: true })
                    //Data Formulario
                    let data = res[1].data
                    data.SECCIONES_VARIABLES = data.SECCIONES_VARIABLES.filter(x => x.ESTADO)
                    // Se evalua las formulas que tenga cada registro
                    registros = evalListadoRegistros(registros, data.SECCIONES_VARIABLES)
                    // const proc = procesarEstructuras(data, res[2].data.map(x => x.VARIABLE))
                    const proc = procesarResumen(data, data.SECCIONES_VARIABLES.map(x => x.VARIABLE.NOMBRE_VARIABLE))
                    let secciones = proc.secciones
                    let tableColumns = proc.tableColumns

                    const fn4 = d => d({
                        type: types.REGISTROS_POBLAR_REGISTROS,
                        payload: {
                            registros,
                            secciones,
                            tableColumns,
                            total: res[0].data.total,
                            selectedFormulario: res[0].data.formulario,
                            variablesList: data.SECCIONES_VARIABLES.filter(x => x.ESTADO && x.VARIABLE.TIPO_CAMPO !== "Imagen").map(x => {
                                return {
                                    value: `${x.SECCION_ID}_${x.VARIABLE_ID}`,
                                    caption: x.VARIABLE.NOMBRE_VARIABLE
                                }
                            }),
                            scripts: res[0].data.scripts || []
                        } })
                    fn4(dispatch)
                }
                dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: false })
            })
            .catch((err) => {
                dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: false })
                displayMessages(dispatch, err)
                if (err.response && err.response.status && err.response.status === 500) {
                    const fn4 = d => d({ type: types.REGISTROS_POBLAR_REGISTROS, payload: { registros: [], secciones: [], tableColumns: [], total: 0 } })
                    fn4(dispatch)
                }
            })
    }
}

export function seleccionarRegistro(id) {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_SELECCIONAR_ARTICULO, payload: id })
    }
}

export function resetAfterTipoCampo() {
    return (dispatch, getState) => {
        const path = 'modalCreate.data'
        dispatch({ type: types.REGISTROS_MODIFICAR_INPUTS, payload: { path: `${path}.TIPO_DATO`, value: -1 } })
        dispatch({ type: types.REGISTROS_MODIFICAR_INPUTS, payload: { path: `${path}.TAMANO`, value: null } })
        dispatch({ type: types.REGISTROS_MODIFICAR_INPUTS, payload: { path: `${path}.MAESTRA_ID`, value: -1 } })
    }
}

export function conmutarSeleccionRegistros(checked) {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_CONMUTAR_SELECCION_REGISTROS, payload: checked })
    }
}

export function almacenarRegistro() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        let data = getState().registros.getIn(['modalCreate', 'data'])
        const mode = getState().registros.getIn(['modalCreate', 'mode'])

        data = data.set('TIPO_DATO', data.get('TIPO_DATO') === -1 ? null : data.get('TIPO_DATO'))
        data = data.set('MAESTRA_ID', (data.get('MAESTRA_ID') === -1 || data.get('MAESTRA_ID') === null) ? null : Number(data.get('MAESTRA_ID')))

        if (mode === 'create') {
            axios.post(`${API_URL}/registros`, data)
                .then(res => {
                    const id = res.data.ID
                    const articulo = objectifyArray([res.data], { by: ['ID'], recursive: true })
                    dispatch({ type: types.REGISTROS_ACTUALIZAR_ARTICULO, payload: articulo[id] })
                    displayMessages(dispatch, { success: 'El artículo ha sido guardado exitosamente' })
                })
                .catch(err => {
                    displayMessages(dispatch, err)
                })
        } else if (mode === 'edit') {
            axios.put(`${API_URL}/registros/${data.get('ID')}`, data)
                .then(res => {
                    const articulo = objectifyArray([res.data], { by: ['ID'], recursive: true })
                    dispatch({ type: types.REGISTROS_ACTUALIZAR_ARTICULO, payload: articulo[data.get('ID')] })
                    displayMessages(dispatch, { success: 'El artículo ha sido guardado exitosamente' })
                })
                .catch(err => {
                    displayMessages(dispatch, err)
                })
        }
    }
}

export function eliminarRegistros(formularioId) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        let toDelete = getState().registros.get('registros').valueSeq().filter(x => x.get('SELECTED') === true).map(x => x.get('ID')).toJS()

        axios({
            method: "delete",
            url: `${API_URL}/registros?formularioId=${formularioId}`,
            data: {ids: toDelete}
        })
        .then(res => {
                displayMessages(dispatch, { success: 'Los registros han sido eliminados exitosamente.' })
                dispatch(obtenerRegistros(1, formularioId))
            })
            .catch(err => {
                
                displayMessages(dispatch, err)
            })
    }
}

export function reiniciarEstado() {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_REINICIAR_ESTADO })
    }
}

export function mostrarCondicionesFiltrado() {
    const axios = createAxiosInstance()
    return (dispatch) => {
        axios.get(`${API_URL}/parametros?nombre=CONDICIONES_REGISTROS_DELTA`)
            .then(res => {
                let message = "Las condiciones de filtrado que fueron aplicadas para listar los artículos del sistema Delta son:\n\n"
                res.data.forEach((x, n) => {
                    message += `${n + 1}. ${x.CAMPO} ${x.CONDICION}\n`
                })
                dispatch(showHideModalMsgBox(true, 'info', 'Condiciones de filtrado', message))
            })
            .catch(err => {
                displayMessages(dispatch, err)
            })
    }
}

export function displayModalDownload(show, title) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        if (!show) {
            dispatch({ type: types.REGISTROS_MOSTRAR_MODAL_DESCARGA, payload: { show } })
            return
        }
        const formularioId = getState().registros.getIn(['selectedFormulario', 'ID'])
        axios.get(`${API_URL}/formularios?id=${formularioId}`)
            .then((res) => {
                let {data} = res
                data.SECCIONES_VARIABLES = data.SECCIONES_VARIABLES.filter(x => x.ESTADO)
                data.SECCIONES_VARIABLES = data.SECCIONES_VARIABLES
                    .filter(x => {
                        return (
                            x.VARIABLE.ESTADO && x.SECCION.ESTADO && x.VARIABLE.TIPO_CAMPO !== 'Imagen'
                        )
                    })
                    .sort((a, b) => {
                        if (a.ORDEN_SECCION > b.ORDEN_SECCION) return 1
                        if (a.ORDEN_SECCION < b.ORDEN_SECCION) return -1
                        if (a.ORDEN_VARIABLE > b.ORDEN_VARIABLE) return 1
                        if (a.ORDEN_VARIABLE < b.ORDEN_VARIABLE) return -1
                        return 0
                    })
                dispatch({ type: types.REGISTROS_MOSTRAR_MODAL_DESCARGA, payload: { show, title, estructura: data } })
            })
            .catch((err) => {
                
                displayMessages(dispatch, err)
            })
    }
}

export function toggleSelectAllItems(value) {
    return (dispatch, getState) => {
        getState().registros.getIn(['modalDownload', 'estructura', 'SECCIONES_VARIABLES']).forEach((x, n) => {
            const path = `modalDownload.estructura.SECCIONES_VARIABLES.${n}.SELECTED`
            dispatch({ type: types.REGISTROS_MODIFICAR_INPUTS, payload: { path, value } })
        })
    }
}

export function descargarTabla() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('registros_descarga', true))
        const formularioId = getState().registros.getIn(['selectedFormulario', 'ID'])
        const variables = getState().registros.getIn(['modalDownload', 'estructura', 'SECCIONES_VARIABLES'])
                                    .filter(x => x.get('SELECTED')).map(x => `${x.get('SECCION_ID')}_${x.get('VARIABLE_ID')}`)
        let filters = [];
        if (getState().registros.get('records').count() !== getState().registros.get('registros').count()) {
            filters = [{
                Column: "ID",
                Operator: "IN",
                Value: getState().registros.get('records').toJS().join(','),
                Comparison: 'string'
            }]
        }
        
                            
        const data = {
            formularioId,
            variables,
            filters: JSON.stringify(filters)
        }

        axios.post(`${API_URL}/registros/descargar`, data, { responseType: 'arraybuffer' })
            .then((res) => {
                const formulario = getState().registros.getIn(['selectedFormulario', 'NOMBRE']).replace(" ", "_");
                FileDownload(res.data, `Registros_${formulario}_${moment().format('YYYYMMDDHHmmSS')}.xlsx`)
                dispatch(setSavingState('registros_descarga', false))
                dispatch(displayModalDownload(false))
            })
            .catch((err) => {
                const encodedString = String.fromCharCode.apply(null, new Uint8Array(err.response.data))
                const decodedString = JSON.parse(decodeURIComponent(escape(encodedString)))
                const obj = {
                    response: {
                        data: decodedString,
                        status: 500
                    }
                }
                displayMessages(dispatch, obj)
                dispatch(setSavingState('registros_descarga', false))
                dispatch(displayModalDownload(false))
            })
    }
}

export function cambiarPagina(page) {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_CAMBIAR_PAGINA, payload: page })
    }
}

export function showHideModalFilter(state) {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_MOSTRAR_MODAL_CRITERIOS_FILTRADO, payload: state })
    }
}

export function addModalFilterCriteria() {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_ADICIONAR_CRITERIO_FILTRADO })
    }
}

export function removeCheckedModalFilterCriterias() {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_ELIMINAR_CRITERIO_FILTRADO })
    }
}

export function toggleCheckedModalFilterCriterias(index, checked) {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_CONMUTAR_SELECCION_CRITERIO_FILTRADO, payload: { index, checked } })
    }
}

export function updateModalFilterCriteria(index, attr, value) {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_MODIFICAR_CRITERIO_FILTRADO, payload: { index, attr, value } })
    }
}

export function applyFilterCriterias(formularioId) {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_APLICAR_CRITERIOS_FILTRADO })
        dispatch(filtrarRegistros())
    }
}

export function ordenarPor(columna) {
    return (dispatch) => {
        dispatch({ type: types.REGISTROS_ORDENAR_REGISTROS, payload: columna })
    }
}

export function changeRecordsToShow(size) {
    return {
        type: types.REGISTROS_ACTUALIZAR_MOSTRAR_REGISTROS,
        payload: { size }
    }
}

export function executeScript() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const script = getState().registros.get('script')
        let scriptType = script.substr(-3) === '.py' ? 'python' : script.substr(-2).toUpperCase() === '.R' ? 'r' : 'r'
        if (script) {
            dispatch({
                type: types.LAYOUT_MOSTRAR_MODAL_ESPERA,
                payload: true
            })
            let filters = [{
                Column: "ID",
                Operator: "IN",
                Value: getState().registros.get('records').toJS().join(','),
                Comparison: 'string'
            }]
    
            const params = {
                filters: JSON.stringify(filters)
            }

            const fn1 = () => axios.get(`${API_URL}/${scriptType}/run/${script.split('.').slice(0, -1).join('.')}`, { params })
            Promise.all([fn1()])
                .then((res) => {
                    const url_response = res[0].data.URL_RESPONSE
                    const pid = res[0].data.PID
                    let html = `<iframe src="${url_response}" width="100%" height="100%" style="border: none"></iframe>`
                    dispatch(displayModalScriptResponse(true, html, pid))
                    dispatch({
                        type: types.LAYOUT_MOSTRAR_MODAL_ESPERA,
                        payload: false
                    })
                })
                .catch((err) => {
                    displayMessages(dispatch, err)
                    dispatch({
                        type: types.LAYOUT_MOSTRAR_MODAL_ESPERA,
                        payload: false
                    })
                })
        }
    }
}

export function displayModalScriptResponse(show, response, pid = null) {
    const axios = createAxiosInstance()
    return (dispatch) => {
        if (!show && pid) axios.get(`${API_URL}/r/stop/${pid}`)
        dispatch({
            type: types.REGISTROS_DISPLAY_MODAL_RESULTADO_SCRIPT,
            payload: {
                show,
                response,
                pid
            }
        })
    }
}

export function updateFilters(key, value) {
    return {
        type: types.REGISTROS_MODIFICAR_FILTRADO,
        payload: {key, value}
    }
}

export function loadOptions(value) {
    return {
        type: types.REGISTROS_LOADING_OPTIONS,
        payload: value
    }
}

export function borrarFiltros() {
    return {
        type: types.REGISTROS_BORRAR_FILTROS
    }
}

// export function queryOptions(query, callback) {
//     const axios = createAxiosInstance()
//     return (dispatch, getState) => {
//         const formularioId = getState().registros.getIn(['selectedFormulario', 'ID'])
//         const filter_state = getState().registros.getIn(['filter', 'values']).toJS()
//         delete filter_state[query]

//         let filtros = []
//         for(var e in filter_state){
//             filtros.push({
//                 Column: e,
//                 Operator: 'IN',
//                 Value: filter_state[e] ? filter_state[e].split('|').map(x => `'${x}'`).join(',') : "",
//                 Comparison: 'string',
//             })
//         }
//         filtros = JSON.stringify(filtros)
//         dispatch(loadOptions(query))
//         axios.post(`${API_URL}/registros/queryOptions`, {
//             formularioId, variable: query, filtros
//         })
//             .then(res => {
//                 const options = res.data.map(e => ({
//                     value: e,
//                     label: e
//                 }))
//                 dispatch({
//                     type: types.REGISTROS_MODIFICAR_OPTIONS,
//                     payload: {
//                         key: query,
//                         value: res.data
//                     }
//                 })

//                 callback(null, {
//                     options,
//                     cache: false,
//                     complete: true
//                 })
//             })
//             .catch(err => displayMessages(dispatch, err))
//             .finally(() => dispatch(loadOptions(false)))
//     }
// }

export function queryOptions(query, callback) {
    return (dispatch, getState) => {
        const filter_state = getState().registros.getIn(['filter', 'values'])
        dispatch(loadOptions(query))
        let registros = getState().registros.get('registros')
        filter_state.delete(query).forEach((value, key) => {
            if (value !== null) {
                registros = registros.filter(x => value.split('|').indexOf(x.get(key)) >= 0)
            }
        })
        const values = registros.map(x => x.get(query) ? x.get(query).toString().substring(0,30) : '').toSet().toJS()
        
        dispatch({
            type: types.REGISTROS_MODIFICAR_OPTIONS,
            payload: {
                key: query,
                value: values
            }
        })

        callback(null, {
            options: values.map(x => ({ value: x, label: x })),
            cache: false,
            complete: true
        })
        dispatch(loadOptions(false))

    }
}

export function filtrarRegistros() {
    return {
        type: types.REGISTROS_FILTRAR_REGISTROS
    }
}

export function displayModalImagen(show, imageSrc) {
    return {
        type: types.REGISTROS_MOSTRAR_MODAL_IMAGEN,
        payload: {show, imageSrc}
    }
}