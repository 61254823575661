import * as types from '../../types'
import { API_URL } from '../../utils/constants'
import { createAxiosInstance, getSelectedCompany } from '../../utils/helpers'
import { displayMessages } from '../Layout/actions'

export function obtenerBitacora(page = 1) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const compania = getSelectedCompany()
        const companiaId = compania.get('CODIGO_COMPANIA')

        const size = getState().bitacora.get('size')
        const orderBy = getState().bitacora.get('orderBy')
        const filters = getState().bitacora.getIn(['modalFilter', 'filters']).filter(x => x.get('apply')).toJS().map(x => JSON.stringify(x))

        axios.post(`${API_URL}/bitacora?compania=${companiaId}`, { page, size, orderBy, filters })
            .then(res => {
                const registros = res.data.registros

                const fn1 = d => d({ type: types.BITACORA_POBLAR_BITACORA, payload: { registros, total: res.data.total, page } })
                fn1(dispatch)
            })
            .catch(err => {
                console.log(err)
                displayMessages(dispatch, err)
            })
    }
}

export function actualizarMostrarRegistros(size) {
    return dispatch => {
        const fn0 = (d) => d({ type: types.BITACORA_ACTUALIZAR_MOSTRAR_REGISTROS, payload: size })
        const fn1 = (d) => d(obtenerBitacora(1))
        fn0(dispatch)
        fn1(dispatch)
    }
}

export function showHideModalFilter(state) {
    return (dispatch) => {
        dispatch({ type: types.BITACORA_MOSTRAR_MODAL_CRITERIOS_FILTRADO, payload: state })
    }
}

export function addModalFilterCriteria() {
    return (dispatch) => {
        dispatch({ type: types.BITACORA_ADICIONAR_CRITERIO_FILTRADO })
    }
}

export function removeCheckedModalFilterCriterias() {
    return (dispatch) => {
        dispatch({ type: types.BITACORA_ELIMINAR_CRITERIO_FILTRADO })
    }
}

export function toggleCheckedModalFilterCriterias(index, checked) {
    return (dispatch) => {
        dispatch({ type: types.BITACORA_CONMUTAR_SELECCION_CRITERIO_FILTRADO, payload: { index, checked } })
    }
}

export function updateModalFilterCriteria(index, attr, value) {
    return (dispatch) => {
        dispatch({ type: types.BITACORA_MODIFICAR_CRITERIO_FILTRADO, payload: { index, attr, value } })
    }
}

export function applyFilterCriterias() {
    return (dispatch) => {
        const fn0 = (d) => d({ type: types.BITACORA_APLICAR_CRITERIOS_FILTRADO })
        const fn1 = (d) => d(obtenerBitacora(1))
        fn0(dispatch)
        fn1(dispatch)
    }
}

export function updateInputs(path, value) {
    return (dispatch) => {
        const fn0 = (d) => d({ type: types.BITACORA_MODIFICAR_INPUTS, payload: { path, value } })
        fn0(dispatch)
    }
}

export function setOrderBy(field) {
    return (dispatch, getState) => {
        let current = getState().bitacora.get('orderBy')
        current = current.set('field', field.VALUE)
        if (field === null) {
            current = current.set('asc', false)
        } else {
            current = current.set('asc', !current.get('asc'))
        }

        const fn0 = (d) => d({ type: types.BITACORA_ORDENAR_TABLA, payload: { orderBy: current } })
        const fn1 = (d) => d(obtenerBitacora(1))
        fn0(dispatch)
        fn1(dispatch)
    }
}