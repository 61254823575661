import Immutable from 'immutable'
import * as types from '../../types'
import { tableColumns } from './index'
import { isPrimitive } from '../../utils/helpers'


const columnas = Immutable.fromJS(tableColumns)

const INITIAL_STATE = Immutable.fromJS({
    records: [],
    page: 1,
    size: 100,
    totalRegistros: 0,
    formularios: [],
    orderBy: {
        field: null,
        asc: false
    },
    modalFilter: {
        show: false,
        filters: []
    },
    variables: {},
    modalDownload: {
        selectedFormulario: null,
        show: false,
        title: '',
        estructura: { SECCIONES_VARIABLES: [] },
        registros: true,
        filtro: '',
        saving: false,
    },
    modalUpload: {
        selectedFormulario: null,
        show: false,
        title: '',
        data: {
            file: null,
            icono: null,
            nombre: ''
        }
    }
})

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.CARGUEMASIVO_MOSTRAR_MODAL_DESCARGA:
            if (action.payload.show) {
                state = state.setIn(['modalDownload', 'show'], true)
                state = state.setIn(['modalDownload', 'title'], action.payload.title)
                if (action.payload.estructura) state = state.setIn(['modalDownload', 'estructura'], Immutable.fromJS(action.payload.estructura))
            } else {
                state = state.set('modalDownload', INITIAL_STATE.get('modalDownload'))
            }
            return state
        case types.CARGUEMASIVO_REINICIAR_ESTADO:
            return INITIAL_STATE
        case types.CARGUEMASIVO_MODIFICAR_INPUTS:
            if (isPrimitive(action.payload.value)) {
                state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
            } else {
                if (action.payload.immutable === false) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                }
            }
            return state
        case types.CARGUEMASIVO_MOSTRAR_MODAL_CARGA:
            if (action.payload.show) {
                state = state.setIn(['modalUpload', 'show'], true)
                state = state.setIn(['modalUpload', 'title'], action.payload.title)
            } else {
                state = state.set('modalUpload', INITIAL_STATE.get('modalUpload'))
            }
            return state
        case types.CARGUEMASIVO_POBLAR_CARGUEMASIVO:
            state = state.set('records', Immutable.fromJS(action.payload.registros))
            state = state.set('page', action.payload.page)
            state = state.set('totalRegistros', action.payload.total)
            return state
        case types.CARGUEMASIVO_ACTUALIZAR_MOSTRAR_REGISTROS:
            state = state.set('size', action.payload)
            return state
        case types.CARGUEMASIVO_MOSTRAR_MODAL_CRITERIOS_FILTRADO:
            if (action.payload) {
                state = state.setIn(['modalFilter', 'show'], action.payload)
            } else {
                state = state.set('modalFilter', INITIAL_STATE.get('modalFilter'))
            }
            return state
        case types.CARGUEMASIVO_ADICIONAR_CRITERIO_FILTRADO:
            const firstCol = columnas.first()
            state = state.updateIn(['modalFilter', 'filters'], filter => filter
                .push(Immutable.fromJS({
                    column: firstCol.get('VALUE'),
                    operator: '===',
                    value: '',
                    checked: false,
                    comparison: firstCol.get('TYPE'),
                    apply: false
                }))
            )
            return state
        case types.CARGUEMASIVO_ELIMINAR_CRITERIO_FILTRADO:
            state = state.setIn(['modalFilter', 'filters'], state.getIn(['modalFilter', 'filters']).filter(item => !item.get('checked')))
            return state
        case types.CARGUEMASIVO_CONMUTAR_SELECCION_CRITERIO_FILTRADO:
            state = state.setIn(['modalFilter', 'filters', action.payload.index, 'checked'], action.payload.checked)
            return state
        case types.CARGUEMASIVO_MODIFICAR_CRITERIO_FILTRADO:
            if (action.payload.attr === 'column') {
                state = state.setIn(['modalFilter', 'filters', action.payload.index, 'comparison'], columnas.find(x => x.get('VALUE') === action.payload.value).get('TYPE'))
            }
            state = state.setIn(['modalFilter', 'filters', action.payload.index, action.payload.attr], action.payload.value)
            return state
        case types.CARGUEMASIVO_APLICAR_CRITERIOS_FILTRADO:
            state = state.updateIn(['modalFilter', 'filters'], filter => filter
                .map(x => {
                    x = x.set('apply', true)
                    return x
                })
            )
            return state
        case types.CARGUEMASIVO_DESCARGA_GUARDANDO:
            state = state.setIn(['modalDownload', 'saving'], action.payload)
            return state
        case types.CARGUEMASIVO_ORDENAR_TABLA:
            state = state.set('orderBy', action.payload.orderBy)
            return state
        case types.CARGUEMASIVO_POBLAR_OPCION_FORMULARIOS:
            return state.set('formularios', action.payload.formularios)
        default:
            return state
    }
}