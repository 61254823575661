import objectifyArray from 'objectify-array'
import * as types from '../../types'
import { API_URL } from '../../utils/constants'
import { createAxiosInstance, uniqueArray } from '../../utils/helpers'
import { displayMessages, setSavingState } from '../Layout/actions'

export function obtenerParametros() {
    const axios = createAxiosInstance()
    return (dispatch) => {
        axios.get(`${API_URL}/parametros`)
            .then((res) => {
                const parametros = objectifyArray(res.data, { by: ['ID'], recursive: true })
                const fn1 = d => d({ type: types.PARAMETROS_POBLAR_PARAMETROS, payload: { parametros } })
                fn1(dispatch)
            })
            .catch((err) => {
                displayMessages(dispatch, err)
            })
    }
}

export function displayModalCreate(show, title, mode) {
    return (dispatch) => {
        dispatch({ type: types.PARAMETROS_DISPLAY_MODAL_CREACION, payload: { show, title, mode } })
    }
}

export function adicionarConfiguracion() {
    return (dispatch) => {
        dispatch({ type: types.PARAMETROS_ADICIONAR_CONFIGURACION })
    }
}

export function adicionarDetalle() {
    return (dispatch) => {
        dispatch({ type: types.PARAMETROS_ADICIONAR_DETALLE })
    }
}

export function eliminarConfiguracion() {
    return (dispatch, getState) => {
        let toDelete = getState().parametros.getIn(['modalCreate', 'data', 'CONFIGURACIONES']).filter(x => x.get('SELECTED') === true)
        const total = toDelete.count()
        toDelete = toDelete.filter(x => x.get('ID') > 0)
        if (toDelete.count() > 0) {
            const axios = createAxiosInstance()
            const fns = []
            toDelete.map(x => x.get('ID')).forEach(x => {
                const fn = () => axios.delete(`${API_URL}/parametros/configuracion/${x}`)
                fns.push(fn)
            })

            const toResultObject = (promise) => {
                return promise
                    .then(result => ({ success: true, result }))
                    .catch(error => ({ success: false, error }));
            }

            Promise.all(fns.map(x => x()).map(toResultObject))
                .then(res => {
                    const succeded = []
                    const failed = []
                    res.forEach(x => {
                        if (!x.success) {
                            failed.push(x.error.response.data.ExceptionMessage)
                            let url = x.error.config.url.split('/')
                            let configId = url[url.length - 1]
                            let index = getState().parametros.getIn(['modalCreate', 'data', 'CONFIGURACIONES']).findIndex(x => x.get('ID') === Number(configId))
                            dispatch({ type: types.PARAMETROS_MODIFICAR_INPUTS, payload: { path: `modalCreate.data.CONFIGURACIONES.${index}.SELECTED`, value: false } })
                        } else {
                            succeded.push(1)
                            dispatch({ type: types.PARAMETROS_ELIMINAR_CONFIGURACION, payload: x.result.data.ID })
                        }
                    })
                    if (succeded.length > 0) displayMessages(dispatch, { success: `Se han eliminado ${succeded.length + (total - toDelete.count())} de los ${total} registros` })
                    if (failed.length > 0) displayMessages(dispatch, { response: { status: 500, data: failed.join('\n') } })
                    dispatch({ type: types.PARAMETROS_ELIMINAR_CONFIGURACIONES })
                })
        } else {
            dispatch({ type: types.PARAMETROS_ELIMINAR_CONFIGURACIONES })
        }
    }
}

export function seleccionarParametro(id) {
    return (dispatch) => {
        dispatch({ type: types.PARAMETROS_SELECCIONAR_PARAMETRO, payload: id })
    }
}

export function almacenarParametro() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('parametros', true))
        let data = getState().parametros.getIn(['modalCreate', 'data'])
        const mode = getState().parametros.getIn(['modalCreate', 'mode'])

        const selects = ['TIPO_DATO', 'TIPO_CAMPO', 'LISTA_PARAMETRO_ID', 'CAMPO_LISTA_PARAMETRO']
        const trueFalse = ['UNICO']
        data.get('CONFIGURACIONES').forEach((x, n) => {
            let v
            selects.forEach(s => {
                v = data.getIn(['CONFIGURACIONES', String(n), s])
                if (String(v) === '-1') data = data.setIn(['CONFIGURACIONES', String(n), s], null)
            })
            trueFalse.forEach(t => {
                v = String(data.getIn(['CONFIGURACIONES', String(n), t])) === "true"
                data = data.setIn(['CONFIGURACIONES', String(n), t], v ? true : false)
            })
        })

        if (mode === 'create') {
            axios.post(`${API_URL}/parametros`, data)
                .then(res => {
                    const id = res.data.ID
                    const parametro = objectifyArray([res.data], { by: ['ID'], recursive: true })
                    dispatch({ type: types.PARAMETROS_ACTUALIZAR_PARAMETRO, payload: parametro[id] })
                    dispatch(displayModalCreate(false))
                    dispatch(setSavingState('parametros', false))
                    displayMessages(dispatch, { success: 'La parametro ha sido guardada exitosamente' })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('parametros', false))
                    displayMessages(dispatch, err)
                })
        } else if (mode === 'edit') {
            axios.put(`${API_URL}/parametros/${data.get('ID')}`, data)
                .then(res => {
                    const parametro = objectifyArray([res.data], { by: ['ID'], recursive: true })
                    dispatch({ type: types.PARAMETROS_ACTUALIZAR_PARAMETRO, payload: parametro[data.get('ID')] })
                    dispatch(displayModalCreate(false))
                    dispatch(setSavingState('parametros', false))
                    displayMessages(dispatch, { success: 'La parametro ha sido guardada exitosamente' })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('parametros', false))
                    displayMessages(dispatch, err)
                })
        }
    }
}

export function almacenarDetalles() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('parametros', true))
        const selected = getState().parametros.get('selected')
        let details = getState().parametros.getIn(['parametros', String(selected), 'DATA']).valueSeq()
        const configuraciones = getState().parametros.getIn(['parametros', String(selected), 'COLUMNAS'])
        let toStore = []
        details.forEach(a => {
            configuraciones.forEach(b => {
                let dt = a.get(String(b.get('ID')))
                dt = dt.set('REGISTRO', a.get('REGISTRO'))
                if (a.getIn([String(b.get('ID')), 'UPDATED'])) toStore.push(dt)
            })
        })

        if (toStore.length > 0) {
            axios.put(`${API_URL}/parametros/detalles`, toStore)
                .then(res => {
                    const parametro = objectifyArray([res.data], { by: ['ID'], recursive: true })
                    dispatch({ type: types.PARAMETROS_ACTUALIZAR_PARAMETRO, payload: parametro[selected] })
                    dispatch(setSavingState('parametros', false))
                    displayMessages(dispatch, { success: 'La parametro ha sido guardada exitosamente' })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('parametros', false))
                    displayMessages(dispatch, err)
                })
        } else {
            dispatch(setSavingState('parametros', false))
        }
    }
}

export function resetAfterTipoCampo(path) {
    return (dispatch) => {
        dispatch({ type: types.PARAMETROS_MODIFICAR_INPUTS, payload: { path: `${path}.TIPO_DATO`, value: -1 } })
        dispatch({ type: types.PARAMETROS_MODIFICAR_INPUTS, payload: { path: `${path}.LISTA_PARAMETRO_ID`, value: -1 } })
        dispatch({ type: types.PARAMETROS_MODIFICAR_INPUTS, payload: { path: `${path}.CAMPO_LISTA_PARAMETRO`, value: -1 } })
    }
}

export function marcarComoActualizado(path) {
    return (dispatch) => {
        dispatch({ type: types.PARAMETROS_MODIFICAR_INPUTS, payload: { path: `${path}.UPDATED`, value: true } })
    }
}

export function eliminarDetalles() {
    return (dispatch, getState) => {
        const selected = getState().parametros.get('selected')
        let selectedDetails = getState().parametros.getIn(['parametros', String(selected), 'DATA']).valueSeq().filter(x => x.get('SELECTED') === true)
        const configuraciones = getState().parametros.getIn(['parametros', String(selected), 'COLUMNAS'])
        let toDelete = []

        selectedDetails.forEach(a => {
            configuraciones.forEach(b => {
                let dt = a.get(String(b.get('ID')))
                if (dt.get('ID') > 0) toDelete.push(a.get('REGISTRO'))
            })
        })
        toDelete = uniqueArray(toDelete)

        const total = selectedDetails.count()
        if (toDelete.length > 0) {
            const axios = createAxiosInstance()
            const fns = []
            toDelete.forEach(x => {
                const fn = () => axios.delete(`${API_URL}/parametros/${selected}/detalles/${x}`)
                fns.push(fn)
            })

            const toResultObject = (promise) => {
                return promise
                    .then(result => ({ success: true, result }))
                    .catch(error => ({ success: false, error }));
            }

            Promise.all(fns.map(x => x()).map(toResultObject))
                .then(res => {
                    const succeded = []
                    const failed = []
                    res.forEach(x => {
                        if (!x.success) {
                            failed.push(x.error.response.data.ExceptionMessage)
                            let url = x.error.config.url.split('/')
                            let registro = url[url.length - 1]
                            dispatch({ type: types.PARAMETROS_MODIFICAR_INPUTS, payload: { path: `parametros.${selected}.DATA.${registro}.SELECTED`, value: false } })
                        } else {
                            succeded.push(1)
                            let url = x.result.config.url.split('/')
                            let registro = url[url.length - 1]
                            dispatch({ type: types.PARAMETROS_ELIMINAR_DETALLE, payload: registro })
                        }
                    })
                    if (succeded.length > 0) displayMessages(dispatch, { success: `Se han eliminado ${succeded.length + (total - toDelete.length)} de los ${total} registros` })
                    if (failed.length > 0) displayMessages(dispatch, { response: { status: 500, data: failed.join('\n') } })
                    dispatch({ type: types.PARAMETROS_ELIMINAR_DETALLES })
                })
        } else {
            dispatch({ type: types.PARAMETROS_ELIMINAR_DETALLES })
        }
    }
}

export function eliminarParametros() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const selected = getState().parametros.get('selected')
        axios.delete(`${API_URL}/parametros/${selected}`)
            .then(res => {
                dispatch({ type: types.PARAMETROS_ELIMINAR_PARAMETROS, payload: selected })
            })
            .catch(err => {
                displayMessages(dispatch, err)
            })
    }
}

export function toggleSelectAllItems(value) {
    return (dispatch, getState) => {
        const selected = getState().parametros.get('selected')
        getState().parametros.getIn(['parametros', String(selected), 'DATA']).keySeq().forEach(n => {
            const path = `parametros.${selected}.DATA.${n}.SELECTED`
            dispatch({ type: types.PARAMETROS_MODIFICAR_INPUTS, payload: { path, value } })
        })
    }
}

export function reiniciarEstado() {
    return (dispatch) => {
        dispatch({ type: types.PARAMETROS_REINICIAR_ESTADO })
    }
}

export function filtrarParametros(termino) {
    return (dispatch) => {
        dispatch({ type: types.PARAMETROS_MODIFICAR_FILTRO, payload: termino })
    }
}

export function ordenarPor(columna) {
    return (dispatch) => {
        dispatch({ type: types.PARAMETROS_ORDENAR_COLUMNA, payload: columna })
    }
}