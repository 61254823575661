import React, { Component } from 'react'
import { connect } from 'react-redux'

class Error404 extends Component {
	render() {
		return (
            <div>
                Error 401
            </div>
		)
	}
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Error404)