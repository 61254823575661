import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import numeral from "numeral";

import ButtonStrip from "../Layout/components/ButtonStrip";
import Box from "../Layout/components/Box";
import BoxSection from "../Layout/components/BoxSection";
import BoxButton from "../Layout/components/BoxButton";
import ModalCreate from "./components/ModalCreate";
import DetalleMaestra from "./components/DetalleMaestra";

import {
  displayModalCreate,
  obtenerMaestras,
  seleccionarMaestra,
  eliminarMaestras,
  filtrarMaestras,
  ordenarPor
} from "./actions";

import { showModalConfirmAction } from "../Layout/actions";

export const tableColumns = [
  {
    ID: 1,
    CAPTION: "Nombre",
    VALUE: "NOMBRE",
    TYPE: "string",
    FORMAT: "",
    WIDTH: "30%"
  },
  {
    ID: 2,
    CAPTION: "Descripción",
    VALUE: "DESCRIPCION",
    TYPE: "string",
    FORMAT: "",
    WIDTH: "70%"
  }
];

export function findValues(c, x) {
  let value = x.getIn(c.VALUE.split("."));
  return value;
}

class Maestras extends Component {
  componentWillMount() {
    this.props.obtenerMaestras();
  }

  render() {
    if (!this.props.elementos) return null;
    const { maestras, selected, termino, sortBy, asc } = this.props;
    const buttons = [];
    if (this.props.elementos.filter(x => x === "30R8KRNb").count())
      buttons.push(
        <BoxButton
          key={`action[0][0]`}
          name="plus"
          onClick={e =>
            this.props.displayModalCreate(
              true,
              "Creación de nueva maestra",
              "create"
            )
          }
          title="Nueva maestra"
        />
      );
    if (this.props.elementos.filter(x => x === "U5Yt8urj").count())
      buttons.push(
        <BoxButton
          key={`action[0][1]`}
          name="pencil"
          onClick={e =>
            this.props.displayModalCreate(
              true,
              "Modificación de maestra",
              "edit"
            )
          }
          title="Modificar maestra"
          disabled={!!!selected}
        />
      );
    if (this.props.elementos.filter(x => x === "52T9MTOc").count())
      buttons.push(
        <BoxButton
          key={`action[0][2]`}
          name="trash"
          onClick={e =>
            this.props.showModalConfirmAction(
              true,
              "Está seguro que desea eliminar este registro?",
              () => this.props.eliminarMaestras()
            )
          }
          title="Eliminar maestra"
          disabled={!!!selected}
          danger
        />
      );

    let records = maestras
      .valueSeq()
      .toList()
      .filter(
        x =>
          x
            .get("NOMBRE")
            .toLowerCase()
            .indexOf(termino.toLowerCase()) > -1
      )
      .sortBy(x => x.getIn(sortBy.split(".")) || "");
    if (!asc) records = records.reverse();

    return (
      <div id="maestras"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <Box title="Maestras" stretch>
          <div className="row" style={{ height: "100%" }}>
            <div className="col-md-5 col-xs-12">
              <div className="row" style={{ height: "100%" }}>
                <div
                  className="col-md-12 col-xs-12"
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <BoxSection title="Listado de Maestras">
                    <div style={{ padding: "0 5px", marginBottom: "10px" }}>
                      <ButtonStrip
                        actions={buttons}
                        searchBar={this.props.filtrarMaestras}
                        filtro={termino}
                      />
                    </div>
                    <div className="table-scrolled">
                      <table className="table table-condensed table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            {tableColumns.map(x => {
                              const style = { width: `${x.WIDTH}` };
                              return (
                                <th
                                  key={`th1[${x.ID}]`}
                                  style={style}
                                  onClick={() => this.props.ordenarPor(x.VALUE)}
                                >
                                  {x.CAPTION}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {records.count() > 0 ? (
                            records.map((a, b) => {
                              return (
                                <tr
                                  key={`tr2[${a.get("ID")}]`}
                                  className={`${
                                    selected === a.get("ID") ? "info" : ""
                                  }`}
                                  onClick={() =>
                                    this.props.seleccionarMaestra(a.get("ID"))
                                  }
                                >
                                  {tableColumns.map((c, d) => {
                                    let alignment = "left";
                                    let value = findValues(c, a);
                                    if (c.TYPE) {
                                      switch (c.TYPE) {
                                        case "datetime":
                                          value = moment(value).format(
                                            c.FORMAT
                                          );
                                          alignment = "right";
                                          break;
                                        case "bool":
                                          if (typeof value === "string")
                                            value = parseInt(value, 10);
                                          value = (
                                            <div
                                              style={{
                                                textAlign: "center",
                                                fontSize: "1.1em"
                                              }}
                                            >
                                              <i
                                                className={`fa fa-${
                                                  value ? "check-" : ""
                                                }square`}
                                              />
                                            </div>
                                          );
                                          break;
                                        case "number":
                                          value = numeral(value).format(
                                            c.Format
                                          );
                                          alignment = "right";
                                          break;
                                        default:
                                      }
                                    }
                                    const style = { textAlign: alignment };

                                    if (c.LINK) {
                                      return (
                                        <td
                                          key={`td1[${a.get("ID")}][${c.ID}]`}
                                          className="LinkToEdit"
                                          style={style}
                                          onClick={e =>
                                            this.props.displayModalCreate(
                                              true,
                                              "Modificación de areas",
                                              "edit",
                                              a.get("ID")
                                            )
                                          }
                                        >
                                          {value}
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={`td1[${a.get("ID")}][${c.ID}]`}
                                          style={style}
                                        >
                                          {value}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={tableColumns.length + 1}
                                style={{ textAlign: "center" }}
                              >
                                No existen registros
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </BoxSection>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-xs-12">
              <div className="row" style={{ height: "100%" }}>
                <DetalleMaestra />
              </div>
            </div>
          </div>
        </Box>
        <ModalCreate />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    maestras: state.maestras.get("maestras"),
    selected: state.maestras.get("selected"),
    termino: state.maestras.get("filtro"),
    sortBy: state.maestras.get("sortBy"),
    asc: state.maestras.get("asc"),
    elementos: state.layout.get("elementos")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    obtenerMaestras: () => dispatch(obtenerMaestras()),
    displayModalCreate: (show, title, mode) =>
      dispatch(displayModalCreate(show, title, mode)),
    seleccionarMaestra: id => dispatch(seleccionarMaestra(id)),
    eliminarMaestras: () => dispatch(eliminarMaestras()),
    showModalConfirmAction: (show, message, action) =>
      dispatch(showModalConfirmAction(show, message, action)),
    filtrarMaestras: termino => dispatch(filtrarMaestras(termino)),
    ordenarPor: columna => dispatch(ordenarPor(columna))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Maestras);
