import axios from 'axios'
import { API_URL } from '../../utils/constants'
import * as types from '../../types'
import history from '../../utils/history'
import { displayMessages } from '../Layout/actions'

export function assingUsernameFromEmail() {
    return {
        type: types.SIGNUP_CHANGE_USERNAME_FROM_EMAIL
    }
}

export function signUp() {
    return (dispatch, getState) => {
        const termConditions = getState().signup.get('termsConditions')
        const policyPrivate = getState().signup.get('policyPrivate')
        if (!(termConditions && policyPrivate)) {
            return dispatch({ type: types.LAYOUT_MOSTRAR_MENSAJE, payload: { type: 'danger', message: 'Debe aceptar los términos y condiciones y la política de privacidad' } })
        }
        axios.post(`${API_URL}/signup`, {
            Username: getState().signup.get('username'),
            Nombres: getState().signup.get('name'),
            Apellidos: getState().signup.get('lastname'),
            Email: getState().signup.get('email'),
            Password: getState().signup.get('password'),
            Empresa: getState().signup.get('empresa'),
            AcceptPolicy: termConditions && policyPrivate
        }).then(res => {
            dispatch({
                type: types.LAYOUT_MOSTRAR_MENSAJE,
                payload: {
                    type: 'success',
                    message: `El usuario ${res.data.USERNAME} fue creado exitosamente`
                }
            })

            history.push('/signin');
        }).catch(err => displayMessages(dispatch, err))
    }
}