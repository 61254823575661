import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import Modal from '@mawesi-sas/mws_react_component_modal'
import Input from '@mawesi-sas/mws_react_component_input'

import {
    displayModalCreate,
    almacenarUsuario,
    assingUsernameFromEmail
} from '../actions';

class ModalCreate extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalCreate')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalCreate .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')
        setTimeout(() => this.props.displayModalCreate(false), 300)
    }

    modalContent = (roles, saving, isAdmin) => {
        return (
            <form id="form" onSubmit={e => { e.preventDefault(); this.props.almacenarUsuario() }} noValidate>
                <div className="buttonstrip">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1 }}>
                            <button className="box-button" title="Guardar" disabled={saving} ><FontAwesome name="floppy-o" tag="i" /></button>
                        </div>
                    </div>
                </div>
                <div className="modal-dialog" style={{ width: '90%' }}>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Nombre de usuario</label>
                                        <Input type="text" reducer="usuarios" path="modalCreate.data.USERNAME" maxLength={20} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Nombre</label>
                                        <Input type="text" reducer="usuarios" path="modalCreate.data.NOMBRES" maxLength={20} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Apellido</label>
                                        <Input type="text" reducer="usuarios" path="modalCreate.data.APELLIDOS" maxLength={50} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Correo</label>
                                        <Input type="email" reducer="usuarios" path="modalCreate.data.EMAIL" maxLength={20}
                                               afterChange={() => this.props.assingUsernameFromEmail()} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Empresa</label>
                                        <Input type="text" reducer="usuarios" path="modalCreate.data.EMPRESA" maxLength={20} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <Input type="switch" reducer="usuarios" path="modalCreate.data.ACTIVO" label='Activo' commentario='Activar la usuario' />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <label className="control-label">Seleccione un rol</label>
                                    <Input type="select" options={roles} reducer="usuarios" path="modalCreate.data.ROL_ID" disabled={!isAdmin} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        const { modalCreate, saving, roles, user } = this.props
        if (!modalCreate.get('show')) return null
        const roleOptions = roles.valueSeq().map(x => ({value: x.get('ID'), caption: x.get('NOMBRE')})).toArray()
        const isAdmin = user.getIn(['ROLE', 'NOMBRE']) === 'Administrador'
        return (
            <Modal
                ancho={this.props.width < this.props.height ? "83%" : "63%"}
                mostrar={modalCreate.get('show')}
                contenido={this.modalContent(roleOptions, saving, isAdmin)}
                titulo={modalCreate.get('title')}
                nombre="modalCreate"
                onCerrarModal={(e) => this.handleCloseModal(e)}
            />
        )
    }
}

const mapStateToProps = (state, onwProps) => {
    return {
        user: state.signin.get('user'),
        modalCreate: state.usuarios.get('modalCreate'),
        roles: state.usuarios.get('roles'),
        saving: state.usuarios.get('saving'),
        width: state.layout.get('width'),
        height: state.layout.get('height')
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayModalCreate: (show) => dispatch(displayModalCreate(show)),
        almacenarUsuario: () => dispatch(almacenarUsuario()),
        assingUsernameFromEmail: () => dispatch(assingUsernameFromEmail()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreate)
