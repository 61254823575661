import React, {Component} from 'react';
import {connect} from 'react-redux';
import Input from '@mawesi-sas/mws_react_component_input'
import { css } from "@emotion/core";
import RingLoader from "react-spinners/RingLoader";
import { obtenerScripts, seleccionarScript } from './actions';
import FontAwesome from 'react-fontawesome';

const override = css`
  display: block;
  margin: 0 auto;
`;

class App extends Component {
    componentWillMount() {
        this.props.seleccionarScript()
    }

    createIFrame = (url_response) => {
        if (url_response) {
            return <iframe src={url_response} width="100%" height="100%" style={{border: 'none'}}></iframe>
        }
        return (
            <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{fontSize: '2.5em', textAlign: 'center'}}>Su usuario no tiene ningún dashboard asignado o no pudo ser cargado<br /><FontAwesome name='frown-o' size='2x'/></span>
            </div>
        )
    }

    render() {
        let { scripts } = this.props
        return (
            <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div style={{ flex: 1, minHeight: 0, border: '1px solid #ddd', marginTop: '5px' }}>
                    {(this.props.loading && 
                        <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{textAlign: 'center'}}>
                                <RingLoader
                                    css={override}
                                    size={100}
                                    color={"rgb(0,68,99)"}
                                    loading={true}
                                />
                                <span style={{fontSize: '2.5em'}}>Cargando</span>
                            </div>
                        </div>
                    )}
                    {this.createIFrame(this.props.iframe)}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        scripts: state.home.get('scripts'),
        iframe: state.home.get('iframe'),
        loading: state.home.get('loading'),
    };
}

const mapDispatchToProps = {
    obtenerScripts,
    seleccionarScript
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);