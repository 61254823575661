import Immutable from 'immutable'
import diff from 'immutablediff'
import patch from 'immutablepatch'
import * as types from '../../types'

import { isPrimitive, proximoCampoDisponible, transformParameterData } from '../../utils/helpers'
import { parametroEntity, parametroConfigEntity, parametroDetalleEntity } from '../../utils/constants';

const INITIAL_STATE = Immutable.fromJS({
    parametros: {},
    selected: null,
    filtro: '',
    sortBy: 'ID',
    asc: true,
    modalCreate: {
        show: false,
        title: '',
        mode: null,
        data: null,
    },
    saving: false,
})

export default function (state = INITIAL_STATE, action) {
    let data, selected, ops, transformed
    switch (action.type) {
        case types.PARAMETROS_POBLAR_PARAMETROS:
            state = state.set('parametros', Immutable.fromJS(action.payload.parametros))
            return state
        case types.PARAMETROS_DISPLAY_MODAL_CREACION:
            if (action.payload.show) {
                state = state.setIn(['modalCreate', 'show'], true)
                state = state.setIn(['modalCreate', 'title'], action.payload.title)
                if (action.payload.mode === 'edit') {
                    let parametro = state.getIn(['parametros', String(state.get('selected'))])
                    parametro = parametro.set('CONFIGURACIONES', Immutable.fromJS(parametro.get('CONFIGURACIONES').sortBy(x => x.get('POSICION')).valueSeq().toJS()))
                    state = state.setIn(['modalCreate', 'data'], parametro)
                } else {
                    state = state.setIn(['modalCreate', 'data'], Immutable.fromJS(parametroEntity()))
                }
                state = state.setIn(['modalCreate', 'mode'], action.payload.mode)
            } else {
                state = state.set('modalCreate', INITIAL_STATE.get('modalCreate'))
            }
            return state
        case types.PARAMETROS_MODIFICAR_INPUTS:
            if (isPrimitive(action.payload.value)) {
                state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
            } else {
                if (action.payload.immutable === false) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                }
            }
            return state
        case types.PARAMETROS_ADICIONAR_CONFIGURACION:
            for (let i = 0, j = state.getIn(['modalCreate', 'data', 'CONFIGURACIONES']).count(); i < j; i++) {
                state = state.setIn(['modalCreate', 'data', 'CONFIGURACIONES', String(i), 'POSICION'], i + 1)
            }
            data = state.getIn(['modalCreate', 'data', 'CONFIGURACIONES']).maxBy(x => x.get('POSICION')).get('POSICION') + 1
            let campo = proximoCampoDisponible(state.getIn(['modalCreate', 'data', 'CONFIGURACIONES']))
            state = state.updateIn(['modalCreate', 'data', 'CONFIGURACIONES'], l => l.push(Immutable.fromJS(parametroConfigEntity(data, campo))))
            return state
        case types.PARAMETROS_ELIMINAR_PARAMETROS:
            state = state.set('selected', null)
            state = state.deleteIn(['parametros', String(action.payload)])
            return state
        case types.PARAMETROS_ELIMINAR_CONFIGURACIONES:
            state = state.setIn(['modalCreate', 'data', 'CONFIGURACIONES'], state.getIn(['modalCreate', 'data', 'CONFIGURACIONES']).filter(x => x.get('SELECTED') !== true))
            for (let i = 0, j = state.getIn(['modalCreate', 'data', 'CONFIGURACIONES']).count(); i < j; i++) {
                state = state.setIn(['modalCreate', 'data', 'CONFIGURACIONES', String(i), 'POSICION'], i + 1)
            }
            return state
        case types.PARAMETROS_ELIMINAR_CONFIGURACION:
            selected = state.get('selected')
            state = state.deleteIn(['parametros', String(selected), 'CONFIGURACIONES', String(action.payload)])
            data = state.getIn(['parametros', String(selected)])
            transformed = transformParameterData(data)
            state = state.setIn(['parametros', String(selected), 'COLUMNAS'], Immutable.fromJS(transformed.COLUMNAS))
            state = state.setIn(['parametros', String(selected), 'DATA'], Immutable.fromJS(transformed.DATA))
            return state
        case types.PARAMETROS_ACTUALIZAR_PARAMETRO:
            data = Immutable.fromJS(action.payload)
            transformed = transformParameterData(data)
            data = data.set('COLUMNAS', Immutable.fromJS(transformed.COLUMNAS))
            data = data.set('DATA', Immutable.fromJS(transformed.DATA))
            ops = diff(state.getIn(['parametros', String(action.payload.ID)]), data)
            state = state.setIn(['parametros', String(action.payload.ID)], patch(state.getIn(['parametros', String(action.payload.ID)]), ops))
            return state
        case types.PARAMETROS_ACTUALIZAR_PARAMETROS:
            ops = diff(state.get('parametros'), Immutable.fromJS(action.payload))
            state = state.set('parametros', patch(state.get('parametros'), ops))
            return state
        case types.PARAMETROS_SELECCIONAR_PARAMETRO:
            state = state.set('selected', action.payload)
            data = state.getIn(['parametros', String(action.payload)])
            transformed = transformParameterData(data)
            state = state.setIn(['parametros', String(action.payload), 'COLUMNAS'], Immutable.fromJS(transformed.COLUMNAS))
            state = state.setIn(['parametros', String(action.payload), 'DATA'], Immutable.fromJS(transformed.DATA))
            return state
        case types.PARAMETROS_ADICIONAR_DETALLE:
            selected = state.get('selected')
            let registro = state.getIn(['parametros', String(selected), 'DATA']).maxBy(x => x.get('REGISTRO'))
            registro = registro ? registro.get('REGISTRO') + 1 : 1
            const newItem = parametroDetalleEntity(state.getIn(['parametros', String(selected), 'COLUMNAS']), registro)
            state = state.setIn(['parametros', String(selected), 'DATA', String(registro)], Immutable.fromJS(newItem))
            return state
        case types.PARAMETROS_ELIMINAR_DETALLE:
            selected = state.get('selected')
            state = state.deleteIn(['parametros', String(selected), 'DATA', String(action.payload)])
            return state
        case types.PARAMETROS_ELIMINAR_DETALLES:
            selected = state.get('selected')
            state.getIn(['parametros', String(selected), 'DATA']).valueSeq().filter(x => x.get('SELECTED') === true).forEach(x => {
                state = state.deleteIn(['parametros', String(selected), 'DATA', String(x.get('REGISTRO'))])
            })
            return state
        case types.PARAMETROS_ACTUALIZAR_DETALLES_PARAMETRO:
            selected = state.get('selected')
            ops = diff(state.getIn(['parametros', String(selected), 'DETALLES']), Immutable.fromJS(action.payload))
            state = state.setIn(['parametros', String(selected), 'DETALLES'], patch(state.getIn(['parametros', String(selected), 'DETALLES']), ops))
            return state
        case types.PARAMETROS_REINICIAR_ESTADO:
            return INITIAL_STATE
        case types.PARAMETROS_MODIFICAR_FILTRO:
            state = state.set('filtro', action.payload)
            return state
        case types.PARAMETROS_ORDENAR_COLUMNA:
            state = state.set('sortBy', action.payload)
            if (action.payload === state.get('sortBy')) {
                state = state.set('asc', !state.get('asc'))
            } else {
                state = state.set('asc', true)
            }
            return state
        case types.PARAMETROS_GUARDANDO:
            state = state.set('saving', action.payload)
            return state
        default:
            return state
    }
}