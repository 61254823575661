import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from '@mawesi-sas/mws_react_component_modal';

import { showModalConfirmAction } from "../../Layout/actions";

class ModalConfirmAction extends Component {
  renderMessage = message => {
    return (
      <div>
        <div className="row">
          <div
            className="col-md-12"
            style={{ color: "#000", fontWeight: "300" }}
          >
            {message || "Esta seguro que desea eliminar este registro?"}
          </div>
        </div>
      </div>
    );
  };

  handleAcceptAccion = () => {
    const { modalConfirmAction } = this.props;
    modalConfirmAction.get("action")();
    this.props.showModalConfirmAction(false);
  };

  render() {
    const { modalConfirmAction } = this.props;

    const message = modalConfirmAction.get("message");
    return (
      <Modal
        ancho="400px"
        mostrar={modalConfirmAction.get("show")}
        contenido={this.renderMessage(message)}
        titulo="Confirmación"
        nombre="msgbox"
        onCerrarModal={() => this.props.showModalConfirmAction(false)}
        botones={
          <React.Fragment>
            <button
              className="btn btn-default"
              type="button"
              onClick={e => this.props.showModalConfirmAction(false)}
            >
              Cancelar
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => this.handleAcceptAccion()}
            >
              Aceptar
            </button>
          </React.Fragment>
        }
      />
    );
  }
}

const mapStateToProps = (state, onwProps) => {
  return {
    modalConfirmAction: state.layout.get("modalConfirmAction")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showModalConfirmAction: open => dispatch(showModalConfirmAction(open))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmAction);
