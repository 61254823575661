import React, { Component } from "react";
import DropZone from "react-dropzone";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { modificarValores } from '../actions'

class ImageField extends Component {
  handleOnDrop = (files) => {
    if (files.length > 1) {
      return toast.warn(`Solo es permitido procesar un archivo a la vez`, {
        bodyClassName: "toastify-content toastify-warning",
      });
    }
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    this.props.upload(this.props.path, files[0]);
  };

  handleDropRejected = (files) => {
    files.forEach((x) => {
      toast.warn(`El archivo ${x.name} no es del tipo esperado. ${x.type}`, {
        bodyClassName: "toastify-content toastify-warning",
      });
    });
  };

  handleText = (e) => {
    e.preventDefault()
    this.props.modificarValores(this.props.path, e.target.value)
    this.props.afterChange()
  }
  render() {
    let imageSrc = false;
    if (this.props.valor) {
      if (typeof this.props.valor === "object") {
        imageSrc = this.props.valor.preview;
      } else {
        imageSrc = this.props.valor;
      }
    }

    return (
      <React.Fragment>
        <div className="image-field dropzone1">
          <DropZone
            accept="image/*"
            onDropAccepted={this.handleOnDrop}
            onDropRejected={this.handleDropRejected}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Arrastre aquí la imagen a cargar o digite en el campo de texto de abajo la URL de la imagen</p>
                </div>
              </section>
            )}
          </DropZone>
        </div>
        <input className="form-control input-sm" style={{marginTop: "5px"}} type="text" value={imageSrc || ""} onChange={this.handleText} />
        {imageSrc && (
          <div
            style={{
              display: "flex",
              padding: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={imageSrc} alt="Preview" width="50%" />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    valor: state[ownProps.reducer].getIn(ownProps.path.split(".")),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    modificarValores: (p, v, i) => dispatch(modificarValores(p, v, i))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageField);
