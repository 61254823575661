import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Box from '../../Layout/components/Box'


import {
    applyFilterCriterias,
    queryOptions,
    borrarFiltros,
    updateFilters
} from '../actions'
import { Input } from '@mawesi-sas/mws_react_component_input'

class Filtros extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id_tab: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const resumen = this.props.secciones.valueSeq().filter(x => x.get('RESUMEN')).first()
        if (resumen && prevState.id_tab === null) {
            this.setState({id_tab: resumen.get('ID')})
        }
    }
    handleApplyFilters = (e) => {
        e.preventDefault()
        this.props.applyFilterCriterias(this.props.formularioId)
    }

    handleCleanFilters = (e) => {
        e.preventDefault()
        this.props.borrarFiltros()
        this.props.applyFilterCriterias(this.props.formularioId)

        const resumen = this.props.secciones.valueSeq().filter(x => x.get('RESUMEN')).first()
        if (resumen) {
            this.setState({id_tab: resumen.get('ID')})
        }
    }
    testScroll = (e) => {
        const scroller = document.getElementById('scroller').parentElement
        scroller.scrollLeft += e.deltaY
    }

    render() {
        const { filter, secciones } = this.props
        const resumen = this.props.secciones.valueSeq().filter(x => x.get('RESUMEN')).first()
        if (!resumen) return null

        return (
            <div id="registros_filtros" style={{ "position": "relative", "zIndex": 2, marginBottom: "10px" }}>
                <Box title="Realice aquí su búsqueda" stretch>
                    <div className="row">
                        <div className="col-md-11 col-sm-10 col-xs-12">
                            <div className="nav-container" style={{ "height": "100% !important" }}>
                                <div className="tabs-wrapper">
                                    <div className="tabs1-wrapper" id="scroller" onWheel={(e) => this.testScroll(e)}>
                                        <div className="tabs1">
                                            {
                                                secciones.sortBy(x => x.get('ORDEN_SECCION')).valueSeq().map(x => {
                                                    return (
                                                        <div key={`tab1[${ x.get('ID') }]`} id={`tabId${ x.get('ID') }`} className={`tab1${ this.state.id_tab === x.get('ID') ? ' active' : '' }`}
                                                            onClick={(e) => this.setState({ id_tab: x.get('ID') })}>
                                                            <div className="tab-top-color" style={{ background: x.get('COLOR') }}></div>
                                                            {x.get('ABREVIADO') === 'Resumen' ? 'Búsqueda básica' : x.get('ABREVIADO')}
                                                        </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="nav-main-section" style={{ display: 'block !important' }}>
                                    {
                                        filter.get('columns')
                                            .filter(ele => ele.get('value').search(this.state.id_tab + "_") >= 0)
                                            .map(ele => <div className="col-md-2 col-sm-3 col-xs-12" key={`options[${ ele.get('value') }]`}>
                                                <div className="form-group" style={{ "marginBottom": "8px" }}>
                                                    <label className="control-label label-filtro">{ele.get('caption')}</label>
                                                    <Input type="autocomplete" reducer="registros" path={`filter.values.${ ele.get('value') }`} multi={true} delimiter="|" autoload={true}
                                                        loadOptions={(input, callback) => this.props.queryOptions(ele.get('value'), callback)} joinValues={true} simpleValue={true}
                                                        placeholder="Seleccione una opción" />
                                                </div>
                                            </div>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-2 col-xs-12 buttons-filter">
                            <button className="btn btn-primary btn-sm" onClick={this.handleApplyFilters} style={{ marginBottom: "5px" }}>Buscar</button>
                            <button className="btn btn-default btn-sm" onClick={this.handleCleanFilters}>Limpiar</button>
                        </div>
                    </div>
                </Box>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filter: state.registros.get('filter'),
        secciones: state.registros.get('secciones'),
        width: state.layout.get('width'),
        height: state.layout.get('height'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        queryOptions: (query, callback) => dispatch(queryOptions(query, callback)),
        updateFilters: (key, value) => dispatch(updateFilters(key, value)),
        borrarFiltros: () => dispatch(borrarFiltros()),
        applyFilterCriterias: (formularioId) => dispatch(applyFilterCriterias(formularioId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filtros)