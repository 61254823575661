import * as types from '../../types'
import Immutable from 'immutable'
import { rolEntity } from '../../utils/constants'

const INITIAL_STATE = Immutable.fromJS({
    roles: {},
    selected: null,
    saving: false,
    modulos: {},
    objetos: {},
    formularios:{},
})

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.ROLES_POBLAR_ROLES:
            state = state.set('formularios', Immutable.fromJS(action.payload.formularios))
            state = state.set('objetos', Immutable.fromJS(action.payload.objetos))
            return state.set('roles', Immutable.fromJS(action.payload.roles))
        case types.ROLES_PERMISOS_FORMULARIOS_OBTENIDOS:
            state = state.setIn(`roles.${action.payload.roleId}.PERMISOS`.split('.'), Immutable.fromJS(action.payload.permisos))
            return state.setIn(`roles.${action.payload.roleId}.FORMULARIOS`.split('.'), Immutable.fromJS(action.payload.formularios))
        case types.ROLES_SELECCIONAR_ROL:
            return state.set('selected', action.payload)
        case types.ROLES_ADICIONAR_ROL:
            const newRole = Immutable.fromJS(rolEntity())
            state = state.set('selected', newRole.get('GUID'))
            return state.setIn(`roles.${newRole.get('GUID')}`.split('.'), newRole)
        case types.ROLES_ADICIONAR_ELIMINAR_PERMISOS_ROL:
            const permiso = action.payload.permiso
            if (action.payload.checked) {
                return state.setIn(`roles.${state.get('selected')}.PERMISOS.${permiso.get('COD_OBJETO')}`.split('.'), Immutable.fromJS({
                    'COD_OBJETO': permiso.get('COD_OBJETO'),
                    'COD_MODULO': permiso.get('COD_MODULO'),
                    'COD_ROL': state.get('selected')
                }))
            } else {
                return state.deleteIn(`roles.${state.get('selected')}.PERMISOS.${permiso.get('COD_OBJETO')}`.split('.'))
            }
        case types.ROLES_ADICIONAR_ELIMINAR_FORMULARIOS_ROL:
            const formulario = action.payload.formulario;
            if(action.payload.checked){
                return state.setIn(`roles.${state.get('selected')}.FORMULARIOS.${formulario.get('ID_ESTRUCTURA')}`.split('.'), Immutable.fromJS({
                    'ID_ESTRUCTURA': formulario.get('ID_ESTRUCTURA'),
                    'COD_ROL': state.get('selected')
                }))
            }else{
                return state.deleteIn(`roles.${state.get('selected')}.FORMULARIOS.${formulario.get('ID_ESTRUCTURA')}`.split('.'))
            }
        case types.ROLES_ACTUALIZAR_ROLES:
            const rol = action.payload.rol
            rol.PERMISOS = action.payload.permisos
            rol.FORMULARIOS = action.payload.formularios

            state = state.deleteIn(`roles.${state.get('selected')}`.split('.'))
            state = state.set('selected', rol.ID)
            return state.setIn(`roles.${rol.ID}`.split('.'), Immutable.fromJS(rol))
        default:
            return state
    }
}