import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import Modal from '@mawesi-sas/mws_react_component_modal'
import Input from '@mawesi-sas/mws_react_component_input'

import { tiposCampo, tiposDato } from '../../../utils/constants'
import {
    displayModalCreate,
    resetAfterTipoCampo,
    almacenarVariable,
    fijarNoEditable
} from '../actions';

class ModalCreate extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalCreate')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalCreate .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')
        setTimeout(() => this.props.displayModalCreate(false), 300)
    }

    modalContent = (tipoCampo, tipoDato, maestrasList, saving, variablesPadre) => {
        const { modalCreate } = this.props

        return (
            <form id="form" onSubmit={e => { e.preventDefault(); this.props.almacenarVariable() }} noValidate>
                <div className="buttonstrip">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1 }}>
                            <button className="box-button" title="Guardar" disabled={saving} ><FontAwesome name="floppy-o" tag="i" /></button>
                        </div>
                    </div>
                </div>
                <div className="modal-dialog" style={{ width: '90%' }}>
                    <div className="row">
                        <div className="col-md-7 col-sm-7 col-xs-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Nombre</label>
                                        <Input type="text" reducer="variables" path="modalCreate.data.NOMBRE_VARIABLE" maxLength={60} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Tipo de campo</label>
                                        <Input type="select" reducer="variables" path="modalCreate.data.TIPO_CAMPO" options={tiposCampo} afterChange={() => this.props.resetAfterTipoCampo()} disabled={modalCreate.get('mode') === 'edit'} />
                                    </div>
                                </div>
                                {
                                    (tipoCampo === 'Caja texto' &&
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="form-group">
                                                <label className="control-label">Tipo de dato</label>
                                                <Input type="select" reducer="variables" path="modalCreate.data.TIPO_DATO" options={tiposDato} disabled={modalCreate.get('mode') === 'edit'} />
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    ((tipoCampo === 'Caja texto' || tipoCampo === 'Area texto') && (tipoDato === 'Alfanumerico' || tipoDato === 'Numerico') &&
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="form-group">
                                                <label className="control-label">Tamaño</label>
                                                <Input type="number" reducer="variables" path="modalCreate.data.TAMANO" disabled={modalCreate.get('mode') === 'edit'} />
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (tipoCampo === 'Lista maestra' &&
                                        <Fragment>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Lista maestra</label>
                                                    <Input type="select" reducer="variables" path="modalCreate.data.MAESTRA_ID" options={maestrasList} disabled={modalCreate.get('mode') === 'edit'} />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Depende de</label>
                                                    <Input type="select" reducer="variables" path="modalCreate.data.VARIABLE_PADRE_ID" options={variablesPadre} disabled={modalCreate.get('mode') === 'edit'} />
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Fórmula</label>
                                        <Input type="text" reducer="variables" path="modalCreate.data.FORMULA" maxLength={500} afterChange={() => this.props.fijarNoEditable('modalCreate.data.FORMULA')} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Descripción ayuda</label>
                                        <Input type="textarea" reducer="variables" path="modalCreate.data.AYUDA_CAMPO" maxLength={2000} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-5 col-xs-12">
                            <div className="form-group">
                                <div style={{ background: '#f2f2f2', border: '1px solid #ddd', borderRadius: '4px', padding: '5px 10px' }}>
                                    <label className="control-label" style={{ margin: '5px 0' }}>Opciones</label>
                                    <div>
                                        <div style={{ borderBottom: '1px solid rgb(230, 230, 230)', padding: '4px 0' }}>
                                            <Input
                                                type="switch"
                                                reducer="variables"
                                                path="modalCreate.data.ESTADO"
                                                label='Estado'
                                                commentario='Activar o desactivar la variable'
                                            />
                                        </div>
                                        <div style={{ borderBottom: '1px solid rgb(230, 230, 230)', padding: '4px 0' }}>
                                            <Input
                                                type="switch"
                                                reducer="variables"
                                                path="modalCreate.data.AYUDA"
                                                label='Ayuda'
                                                commentario='Mostrar icono de ayuda para la variable'
                                            />
                                        </div>
                                        <div style={{ borderTop: '1px solid rgb(230, 230, 230)', borderBottom: '1px solid rgb(230, 230, 230)', padding: '4px 0' }}>
                                            <Input
                                                type="switch"
                                                reducer="variables"
                                                path="modalCreate.data.EDITABLE"
                                                label='Editable'
                                                commentario='Permitir editar la variable'
                                            />
                                        </div>
                                        <div style={{ borderBottom: '1px solid rgb(230, 230, 230)', padding: '4px 0' }}>
                                            <Input
                                                type="switch"
                                                reducer="variables"
                                                path="modalCreate.data.REQUERIDO"
                                                label='Requerido'
                                                commentario='Validar que la variable se diligencie'
                                            />
                                        </div>
                                        <div style={{ borderBottom: '1px solid rgb(230, 230, 230)', padding: '4px 0' }}>
                                            <Input
                                                type="switch"
                                                reducer="variables"
                                                path="modalCreate.data.BITACORA"
                                                label='Bitácora'
                                                commentario='Incluir log en reporte de seguimiento'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    render() {
        const { modalCreate, maestras, saving, variables } = this.props
        if (!modalCreate.get('show')) return null

        const tipoCampo = modalCreate.getIn(['data', 'TIPO_CAMPO'])
        const tipoDato = modalCreate.getIn(['data', 'TIPO_DATO'])
        const maestrasList = maestras.valueSeq().toJS().map(x => {
            return {
                value: x.ID,
                caption: x.NOMBRE
            }
        })
        const variablesPadre = variables.valueSeq()
                                        .filter(x => x.get('TIPO_CAMPO') === 'Lista maestra')
                                        .toJS().map(x => ({value: x.ID, caption: x.NOMBRE_VARIABLE}))

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "73%" : "53%"}
                mostrar={modalCreate.get('show')}
                contenido={this.modalContent(tipoCampo, tipoDato, maestrasList, saving, variablesPadre)}
                titulo={modalCreate.get('title')}
                nombre="modalCreate"
                onCerrarModal={(e) => this.handleCloseModal(e)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modalCreate: state.variables.get('modalCreate'),
        variables: state.variables.get('variables'),
        maestras: state.maestras.get('maestras'),
        saving: state.variables.get('saving'),
        width: state.layout.get('width'),
        height: state.layout.get('height'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayModalCreate: (show) => dispatch(displayModalCreate(show)),
        resetAfterTipoCampo: () => dispatch(resetAfterTipoCampo()),
        almacenarVariable: () => dispatch(almacenarVariable()),
        fijarNoEditable: (path) => dispatch(fijarNoEditable(path)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreate)
