import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import Modal from '@mawesi-sas/mws_react_component_modal';
import Input from '@mawesi-sas/mws_react_component_input';

import {
  displayModalCreateFolder,
  createFolder
} from "../actions";

class ModalCreateFolder extends Component {
  handleCloseModal = e => {
    const modal = document.getElementById("modalCreateFolder");
    modal.classList.remove("fadeIn");
    modal.classList.add("fadeOut");

    const modalDialog = document.querySelector(
      `#modalCreateFolder .mws-modal-dialog`
    );
    modalDialog.classList.remove("zoomIn");
    modalDialog.classList.add("zoomOut");
    setTimeout(() => this.props.displayModalCreateFolder(false), 300);
  };

  handleSave = e => {
    e.preventDefault();
    this.props.createFolder();
  }

  modalContent = (modalCreateFolder, boxActions, saving) => {
    return (
      <form id="form" onSubmit={this.handleSave} noValidate>
        <div className="buttonstrip">
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <div style={{ flex: 1 }}>
              <button className="box-button" title="Guardar" disabled={saving}>
                <FontAwesome name="floppy-o" tag="i" />
              </button>
            </div>
          </div>
        </div>
        <div className="modal-dialog" style={{ width: "90%" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">Nombre</label>
                <Input
                  type="text"
                  reducer="fuentes"
                  path="modalCreateFolder.data.NOMBRE"
                  maxLength={100}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  render() {
    const { modalCreateFolder, saving } = this.props;
    if (!modalCreateFolder.get("show")) return null;

    const boxActions = [];

    return (
      <Modal
        ancho={this.props.width < this.props.height ? "75%" : "45%"}
        mostrar={modalCreateFolder.get("show")}
        contenido={this.modalContent(modalCreateFolder, boxActions, saving)}
        titulo={modalCreateFolder.get("title")}
        nombre="modalCreateFolder"
        onCerrarModal={e => this.handleCloseModal(e)}
      />
    );
  }
}

const mapStateToProps = (state, onwProps) => {
  return {
    modalCreateFolder: state.fuentes.get("modalCreateFolder"),
    saving: state.fuentes.get("saving"),
    width: state.layout.get('width'),
    height: state.layout.get('height')
  };
};

const mapDispatchToProps = {
  displayModalCreateFolder,
  createFolder
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateFolder);
