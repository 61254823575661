import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import Modal from '@mawesi-sas/mws_react_component_modal'
import Input from '@mawesi-sas/mws_react_component_input'
import BoxButton from '../../Layout/components/BoxButton'
import { tiposCampoMaestras, tiposDato, siNoOptions } from '../../../utils/constants'
import {
    displayModalCreate,
    adicionarConfiguracion,
    eliminarConfiguracion,
    almacenarMaestra,
    resetAfterTipoCampo,
} from '../actions';
import { modifyInputs } from '../../Layout/actions';

class ModalCreate extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalCreate')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalCreate .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')
        setTimeout(() => this.props.displayModalCreate(false), 300)
    }

    obtenerCamposMaestra = (id) => {
        if (id === -1 || id === null) return []
        const output = this.props.maestras.getIn([String(id), 'ENT_CONFIG_MAESTRAS']).valueSeq().map(x => {
            return {
                value: x.get('ID'),
                caption: x.get('NOMBRE')
            }
        })

        return output
    }

    modalContent = (boxActions, maestrasList, saving) => {
        const { modalCreate } = this.props

        return (
            <form id="form" onSubmit={e => { e.preventDefault(); this.props.almacenarMaestra() }} noValidate>
                <div className="buttonstrip">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1 }}>
                            <button className="box-button" title="Guardar" disabled={saving} ><FontAwesome name="floppy-o" tag="i" /></button>
                        </div>
                    </div>
                </div>
                <div className="modal-dialog" style={{ width: '70vw' }}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="control-label">Nombre</label>
                                <Input type="text" reducer="maestras" path="modalCreate.data.NOMBRE" disabled={modalCreate.get('mode') === 'edit'} />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label className="control-label">Descripción</label>
                                <Input type="text" reducer="maestras" path="modalCreate.data.DESCRIPCION" />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <div className="buttonstrip">
                                    <div><span className="modal-box-title">Configuración</span></div>
                                    <div>{boxActions}</div>
                                </div>
                                <div className="box-content" style={{ height: '188px', overflow: 'auto', background: '#f5f5f5' }}>
                                    <table className="table table-condensed table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '25px' }}></th>
                                                <th>Nombre</th>
                                                <th>Descripción</th>
                                                <th>Tipo campo</th>
                                                <th>Tipo dato</th>
                                                <th>Maestra</th>
                                                <th>Unico</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                modalCreate.getIn(['data', 'ENT_CONFIG_MAESTRAS']).map((x, n) => {
                                                    const path = `modalCreate.data.ENT_CONFIG_MAESTRAS.${n}`
                                                    const disabledMaestra = x.get('TIPO_CAMPO') !== 'Lista maestra'
                                                    const disabledTipoDat0 = x.get('TIPO_CAMPO') === 'Panel color' || x.get('TIPO_CAMPO') === 'Area texto'
                                                    if (disabledTipoDat0) {
                                                        this.props.modifyInputs('maestras', `${path}.TIPO_DATO`, 'Alfanumerico')
                                                    }
                                                    return (
                                                        <tr key={`tr6[${x.get('ID') || x.get('GUID')}]`} className={x.get('SELECTED') ? 'info' : ''}>
                                                            <td><Input type="checkbox" reducer="maestras" path={`${path}.SELECTED`} /></td>
                                                            <td><Input type="text" reducer="maestras" path={`${path}.NOMBRE`} /></td>
                                                            <td><Input type="text" reducer="maestras" path={`${path}.DESCRIPCION`} /></td>
                                                            <td><Input type="select" reducer="maestras" path={`${path}.TIPO_CAMPO`} options={tiposCampoMaestras} afterChange={() => this.props.resetAfterTipoCampo(path)} /></td>
                                                            <td><Input type="select" reducer="maestras" path={`${path}.TIPO_DATO`} options={tiposDato} disabled={!disabledMaestra || disabledTipoDat0} /></td>
                                                            <td><Input type="select" reducer="maestras" path={`${path}.LISTA_MAESTRA_ID`} options={maestrasList} disabled={disabledMaestra} /></td>
                                                            <td><Input type="select" reducer="maestras" path={`${path}.UNICO`} options={siNoOptions} /></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        const { modalCreate, maestras, saving } = this.props
        if (!modalCreate.get('show')) return null;

        const boxActions = [
            <BoxButton key="ba[0][0]" name="plus-circle" onClick={e => { e.preventDefault(); this.props.adicionarConfiguracion() }} title="Nueva" />,
            <BoxButton key="ba[0][1]" name="trash" onClick={e => { e.preventDefault(); this.props.eliminarConfiguracion() }} title="Eliminar" disabled={modalCreate.getIn(['data', 'ENT_CONFIG_MAESTRAS']).filter(x => x.get('SELECTED')).count() === 0} danger />,
        ]

        const maestrasList = maestras.valueSeq().toJS().map(x => {
            if (x.NOMBRE === modalCreate.getIn(['data', 'NOMBRE'])) return null
            return {
                value: x.ID,
                caption: x.NOMBRE
            }
        }).filter(x => x !== null)

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "95%" : "73%"}
                mostrar={modalCreate.get('show')}
                contenido={this.modalContent(boxActions, maestrasList, saving)}
                titulo={modalCreate.get('title')}
                nombre="modalCreate"
                onCerrarModal={(e) => this.handleCloseModal(e)}
            />
        )
    }
}

const mapStateToProps = (state, onwProps) => {
    return {
        modalCreate: state.maestras.get('modalCreate'),
        maestras: state.maestras.get('maestras'),
        saving: state.maestras.get('saving'),
        width: state.layout.get('width'),
        height: state.layout.get('height')
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayModalCreate: (show) => dispatch(displayModalCreate(show)),
        adicionarConfiguracion: () => dispatch(adicionarConfiguracion()),
        eliminarConfiguracion: () => dispatch(eliminarConfiguracion()),
        almacenarMaestra: () => dispatch(almacenarMaestra()),
        resetAfterTipoCampo: (path) => dispatch(resetAfterTipoCampo(path)),
        modifyInputs: (reducer, path, value) => dispatch(modifyInputs(reducer, path, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreate)
