import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify'
import numeral from 'numeral'
import { Input } from '@mawesi-sas/mws_react_component_input'
import Modal from '@mawesi-sas/mws_react_component_modal'

import {
    displayModalUpload,
    seleccionArchivo,
    cargarArchivo
} from '../actions'

import { decargarMaestras } from '../../Maestras/actions';
import { acceptedFiles } from '../../../utils/constants'

const filesTypes = acceptedFiles.map(x => x.type).join()

class ModalUpload extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalUpload')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalUpload .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')
        setTimeout(() => this.props.displayModalUpload(false), 300)
    }

    handleOnDrop = (files) => {
        if (files.length > 1) {
            toast.warn(`Solo es permitido procesar un archivo a la vez`, { bodyClassName: 'toastify-content toastify-warning' })
            return
        }
        this.props.seleccionArchivo(files[0])
    }

    handleOnRejected = (files) => {
        files.forEach(x => {
            toast.warn(`El archivo ${x.name} no es del tipo esperado. ${x.type}`, { bodyClassName: 'toastify-content toastify-warning' })
        })
    }

    setIcon = (icon) => {
        if (icon) {
            const imageSrc = require(`../../../img/16px/${icon}`)
            return <img style={{ verticalAlign: 'top' }} src={imageSrc} alt="icon"/>
        }
        return null
    }

    modalContent = (modalUpload) => {
        return (
            <form id="form" noValidate>
                <div className="modal-dialog" style={{ width: '90%' }}>
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="alert alert-info">
                                <ul style={{ paddingLeft: '15px' }}>
                                    <li>Conserve el número del ID si quiere realizar una modificación en el registro, en caso de quiera crear uno nuevo, déjelo vacío.</li>
                                    <li>Para los campos que son valores de listas de maestras, el valor debe ser exactamente como lo presenta la lista. Para descargar el listado de maestras haga click <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.props.decargarMaestras()}>aquí</span></li>
                                    <li>Los campos no editables no permitirá el ingreso o modificación por medio de cargues masivos</li>
                                    <li>Los campos inactivos, no editables y que contienen fórmulas o imágenes no harán parte del descargue de plantillas ni del cargue de archivos</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                                <label className="control-label">Seleccione el formulario</label>
                                <Input type="select" options={this.props.formularios} reducer="carguemasivo" path="modalUpload.selectedFormulario"
                                    afterChange={this.props.seleccionarFormulario} />
                            </div>
                            <div className="dropzone1" style={{ marginBottom: "15px" }}>
                                <Dropzone accept={filesTypes} onDropAccepted={this.handleOnDrop} onDropRejected={this.handleOnRejected}>
                                    {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>
                                            Arrastre los archivos a esta área o haga click para
                                            seleccionarlos
                                        </p>
                                        </div>
                                    </section>
                                    )}
                                </Dropzone>
                            </div>
                            <div className="form-group">
                                <label className="control-label">Nombre</label>
                                <div className="form-control input-sm">
                                    {
                                        (modalUpload.getIn(['data', 'file']) &&
                                            <div style={{ paddingTop: '1px' }}>
                                                <span>{this.setIcon(modalUpload.getIn(['data', 'icono']))}</span>
                                                <span style={{ marginLeft: '4px', verticalAlign: 'bottom' }}>{modalUpload.getIn(['data', 'nombre'])}</span>
                                                <span style={{ marginLeft: '3px', verticalAlign: 'bottom', color: '#ccc' }}>({numeral(modalUpload.getIn(['data', 'file']).size).format('0.0 b')})</span>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        const { modalUpload } = this.props
        if (!modalUpload.get('show')) return null

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "90%" : "60%"}
                mostrar={modalUpload.get('show')}
                contenido={this.modalContent(modalUpload)}
                titulo={modalUpload.get('title')}
                nombre="modalUpload"
                botones={[<button key="upload"
                    className="btn btn-default"
                    disabled={modalUpload.get('btnDisabled')}
                    onClick={() => this.props.cargarArchivo()}>Cargar archivo</button>]}
                onCerrarModal={(e) => this.handleCloseModal(e)}
            />
        )
    }
}

const mapStateToProps = (state, onwProps) => {
    return {
        modalUpload: state.carguemasivo.get('modalUpload'),
        formularios: state.carguemasivo.get('formularios'),
        width: state.layout.get('width'),
        height: state.layout.get('height')
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayModalUpload: (show) => dispatch(displayModalUpload(show)),
        seleccionArchivo: (file) => dispatch(seleccionArchivo(file)),
        cargarArchivo: () => dispatch(cargarArchivo()),
        decargarMaestras: () => dispatch(decargarMaestras()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpload)
