import * as types from '../../types'
import Immutable from 'immutable'
import diff from 'immutablediff'
import patch from 'immutablepatch'

import {
    isPrimitive,
    proximoCampoDisponible
} from '../../utils/helpers'
import {
    scriptEntity,
    argsScriptEntity
} from '../../utils/constants';

const INITIAL_STATE = Immutable.fromJS({
    scripts: {},
    selected: null,
    filtro: '',
    sortBy: 'ID',
    asc: true,
    modalCreate: {
        show: false,
        title: '',
        mode: null,
        data: null,
    },
    modalScriptResponse: {
        show: false,
        data: null,
        pid: null,
    },
    saving: false,
})

export default function (state = INITIAL_STATE, action) {
    let data, selected, ops
    switch (action.type) {
        case types.SCRIPTS_POBLAR_SCRIPTS:
            state = state.set('scripts', Immutable.fromJS(action.payload.scripts))
            return state
        case types.SCRIPTS_DISPLAY_MODAL_CREACION:
            if (action.payload.show) {
                state = state.setIn(['modalCreate', 'show'], true)
                state = state.setIn(['modalCreate', 'title'], action.payload.title)
                if (action.payload.mode === 'edit' && action.payload.id) {
                    let script = state.getIn(['scripts', String(action.payload.id)])
                    script = script.set('ARGUMENTOS', Immutable.fromJS(script.get('ARGUMENTOS').sortBy(x => x.get('POSICION')).valueSeq().toJS()))
                    state = state.setIn(['modalCreate', 'data'], script)
                } else {
                    let data = state.get('scripts').valueSeq().map(x => x.get('ORDER_KEY')).toJS()
                    if (data.length > 0) {
                        data = (Math.max(...data)) + 1
                    } else {
                        data = 1
                    }
                    state = state.setIn(['modalCreate', 'data'], Immutable.fromJS(scriptEntity(data)))
                }
                state = state.setIn(['modalCreate', 'mode'], action.payload.mode)
            } else {
                state = state.set('modalCreate', INITIAL_STATE.get('modalCreate'))
            }
            return state
        case types.SCRIPTS_MODIFICAR_INPUTS:
            if (isPrimitive(action.payload.value)) {
                state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
            } else {
                if (action.payload.immutable === false) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                }
            }
            return state
        case types.SCRIPTS_ACTUALIZAR_SCRIPT:
            ops = diff(state.getIn(['scripts', String(action.payload.ID)]), Immutable.fromJS(action.payload))
            state = state.setIn(['scripts', String(action.payload.ID)], patch(state.getIn(['scripts', String(action.payload.ID)]), ops))
            return state
        case types.SCRIPTS_ELIMINAR_SCRIPT:
            state = state.deleteIn(['scripts', String(action.payload)])
            return state
        case types.SCRIPTS_CONMUTAR_SELECCION_SCRIPTS:
            state.get('scripts').keySeq().forEach(x => {
                state = state.setIn(['scripts', String(x), 'SELECTED'], action.payload)
            })
            return state
        case types.SCRIPTS_MODIFICAR_FILTRO:
            state = state.set('filtro', action.payload)
            return state
        case types.SCRIPTS_ORDENAR_COLUMNA:
            state = state.set('sortBy', action.payload)
            if (action.payload === state.get('sortBy')) {
                state = state.set('asc', !state.get('asc'))
            } else {
                state = state.set('asc', true)
            }
            return state
        case types.SCRIPTS_GUARDANDO:
            state = state.set('saving', action.payload)
            return state
        case types.SCRIPTS_CARGAR_SCRIPT:
            state = state.setIn('modalCreate.data.file'.split('.'), action.payload)
            let file = action.payload
            state = state.setIn('modalCreate.data.NOMBRE'.split('.'), file.name)
            return state
        case types.SCRIPTS_DISPLAY_MODAL_RESULTADO_SCRIPT:
            if (action.payload.show) {
                state = state.setIn(['modalScriptResponse', 'show'], true)
                state = state.setIn(['modalScriptResponse', 'data'], action.payload.response)
                state = state.setIn(['modalScriptResponse', 'pid'], action.payload.pid)
            } else {
                state = state.set('modalScriptResponse', INITIAL_STATE.get('modalScriptResponse'))
            }
            return state
        case types.SCRIPTS_ADICIONAR_ARGUMENTO:
            for (let i = 0, j = state.getIn(['modalCreate', 'data', 'ARGUMENTOS']).count(); i < j; i++) {
                state = state.setIn(['modalCreate', 'data', 'ARGUMENTOS', String(i), 'POSICION'], i + 1)
            }
            if (state.getIn(['modalCreate', 'data', 'ARGUMENTOS']).count() === 0) {
                data = 1
            } else {
                data = state.getIn(['modalCreate', 'data', 'ARGUMENTOS']).maxBy(x => x.get('POSICION')).get('POSICION') + 1
            }
            state = state.updateIn(['modalCreate', 'data', 'ARGUMENTOS'], l => l.push(Immutable.fromJS(argsScriptEntity(data))))
            return state
        case types.SCRIPTS_ELIMINAR_ARGUMENTOS:
            state = state.setIn(['modalCreate', 'data', 'ARGUMENTOS'], state.getIn(['modalCreate', 'data', 'ARGUMENTOS']).filter(x => x.get('SELECTED') !== true))
            for (let i = 0, j = state.getIn(['modalCreate', 'data', 'ARGUMENTOS']).count(); i < j; i++) {
                state = state.setIn(['modalCreate', 'data', 'ARGUMENTOS', String(i), 'POSICION'], i + 1)
            }
            return state
        default:
            return state
    }
}