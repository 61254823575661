import React, { Component } from 'react'

export default class BoxButton extends Component {
	render() {
        const disabled = (this.props.disabled === true)
        const onClick = (disabled) ? null : this.props.onClick
        const { size, contrast } = this.props
        let styleButton = {...this.props.style || {}}
        let styleI = {}
        if (size) {
            styleButton.lineHeight = `${size}px`
            styleI.fontSize = `${size}px`
        }
		return (
            <button className={ `box-button ${disabled ? 'disabled' : ''} ${this.props.danger ? 'danger' : ''} ${this.props.active ? 'active' : ''} ${this.props.dropdown ? 'dropped' : ''} ${contrast ? 'contrast' : ''}` } onClick={ onClick } title={ this.props.title } style={ styleButton }>
                <i className={ `fa fa-${this.props.name}` } style={ styleI } />
            </button>
		)
	}
}