import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";

import ButtonStrip from "../../Layout/components/ButtonStrip";
import BoxButton from "../../Layout/components/BoxButton";
import BoxSection from "../../Layout/components/BoxSection";
import Input from '@mawesi-sas/mws_react_component_input';

import {
  adicionarDetalle,
  eliminarDetalles,
  almacenarDetalles,
  marcarComoActualizado
} from "../actions";
import { obtenerOpciones } from "../../../utils/helpers";
import { showModalConfirmAction } from "../../Layout/actions";

class DetalleMaestra extends Component {
  render() {
    const { selected, maestra, saving } = this.props;
    if (!selected) {
      return (
        <div
          style={{
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "5%",
            paddingRight: "5%"
          }}
        >
          <p
            style={{
              fontSize: "1.5em",
              color: "rgb(187,187,187)",
              fontStyle: "italic",
              fontWeight: "100"
            }}
          >
            <FontAwesome name="info-circle" /> Seleccione una maestra para
            visualizar / modificar su detalle
          </p>
        </div>
      );
    }

    const eliminarDisabled =
      maestra
        .getIn(["ENT_DETALLE_MAESTRAS"])
        .valueSeq()
        .filter(x => x.get("SELECTED"))
        .count() === 0;
    const buttons = [];
    if (this.props.elementos.filter(x => x === "43oY4Vk1").count())
      buttons.push(
        <BoxButton
          key={`action[1][0]`}
          name="floppy-o"
          title="Guardar"
          onClick={() => this.props.almacenarDetalles()}
          disabled={saving}
        />
      );
    if (this.props.elementos.filter(x => x === "43oY4Vk1").count())
      buttons.push(
        <BoxButton
          key={`action[1][1]`}
          name="plus"
          title="Nuevo registro"
          onClick={() => this.props.adicionarDetalle()}
        />
      );
    if (this.props.elementos.filter(x => x === "f0joJ41u").count())
      buttons.push(
        <BoxButton
          key={`action[1][2]`}
          name="trash"
          title="Eliminar registro"
          onClick={() =>
            this.props.showModalConfirmAction(
              true,
              "Está seguro que desea eliminar los registros seleccionados?",
              () => this.props.eliminarDetalles()
            )
          }
          disabled={eliminarDisabled}
          danger
        />
      );

    const tableColumns = maestra
      .getIn(["ENT_CONFIG_MAESTRAS"])
      .valueSeq()
      .sortBy(x => x.get("ORDER_KEY"))
      .map(x => {
        return {
          ID: x.get("ID"),
          CAMPO: x.get("CAMPO"),
          CAPTION: x.get("NOMBRE"),
          VALUE: `CAMPO${x.get("CAMPO")}`
        };
      })
      .toJS();

    return (
      <div
        className="col-md-12"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div
          className="widget"
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "0"
          }}
        >
          <BoxSection
            title={`Detalle maestra: ${maestra.get("NOMBRE")}`}
            stretch
          >
            <div style={{ padding: "0px 5px", marginBottom: "10px" }}>
              <ButtonStrip actions={buttons} />
            </div>
            <div className="table-scrolled">
              <table className="table table-condensed table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th style={{ width: "25px" }}></th>
                    {tableColumns.map(x => {
                      return <th key={`th1[${x.ID}]`}>{x.CAPTION}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {maestra
                    .get("ENT_DETALLE_MAESTRAS")
                    .valueSeq()
                    .sortBy(x => x.get("ORDER_KEY"))
                    .map(o => {
                      const x = String(o.get("ID") || o.get("GUID"));
                      const b = maestra.getIn(["ENT_DETALLE_MAESTRAS", x]);
                      const path = `maestras.${selected}.ENT_DETALLE_MAESTRAS.${x}`;
                      return (
                        <tr
                          key={`tr4[${x}]`}
                          className={b.get("SELECTED") ? "info" : ""}
                        >
                          <td>
                            <Input
                              type="checkbox"
                              reducer="maestras"
                              path={`${path}.SELECTED`}
                            />
                          </td>
                          {maestra
                            .get("ENT_CONFIG_MAESTRAS")
                            .valueSeq()
                            .sortBy(d => d.get("ORDER_KEY"))
                            .map(d => {
                              const attrs = {
                                reducer: "maestras",
                                className: "form-control input-sm",
                                afterChange: () =>
                                  this.props.marcarComoActualizado(path)
                              };
                              switch (d.get("TIPO_CAMPO")) {
                                case "Caja texto":
                                  if (d.get("TIPO_DATO") === "Alfanumerico") {
                                    attrs.type = "text";
                                  } else if (d.get("TIPO_DATO") === "Fecha") {
                                    attrs.type = "date";
                                  } else {
                                    attrs.type = "number";
                                  }
                                  break;
                                case "Area texto":
                                  attrs.type = "textarea";
                                  break;
                                case "Lista maestra":
                                  attrs.type = "select";
                                  attrs.options = obtenerOpciones(
                                    d.get("LISTA_MAESTRA_ID")
                                  );
                                  break;
                                case "Panel color":
                                  attrs.type = "color";
                                  break;
                              }
                              attrs.path = `${path}.CAMPO${d.get("CAMPO")}`;
                              return (
                                <td key={`td5[${x}][${d.get("ID")}]`}>
                                  <Input {...attrs} />
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </BoxSection>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, onwProps) => {
  return {
    selected: state.maestras.get("selected"),
    maestra: state.maestras.getIn([
      "maestras",
      String(state.maestras.get("selected"))
    ]),
    saving: state.maestras.get("saving"),
    elementos: state.layout.get("elementos")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    adicionarDetalle: () => dispatch(adicionarDetalle()),
    eliminarDetalles: () => dispatch(eliminarDetalles()),
    almacenarDetalles: () => dispatch(almacenarDetalles()),
    marcarComoActualizado: path => dispatch(marcarComoActualizado(path)),
    showModalConfirmAction: (show, message, action) =>
      dispatch(showModalConfirmAction(show, message, action))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetalleMaestra);
