import Immutable from 'immutable'
import * as types from '../../types'
import { tableColumns } from './index'
import { isPrimitive } from '../../utils/helpers'

const columnas = Immutable.fromJS(tableColumns)

const INITIAL_STATE = Immutable.fromJS({
    records: [],
    page: 1,
    size: 100,
    totalRegistros: 0,
    orderBy: {
        field: null,
        asc: false
    },
    modalFilter: {
        show: false,
        filters: []
    },
})

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.BITACORA_REINICIAR_ESTADO:
            return INITIAL_STATE
        case types.BITACORA_POBLAR_BITACORA:
            state = state.set('records', Immutable.fromJS(action.payload.registros))
            state = state.set('page', action.payload.page)
            state = state.set('totalRegistros', action.payload.total)
            return state
        case types.BITACORA_ACTUALIZAR_MOSTRAR_REGISTROS:
            state = state.set('size', action.payload)
            return state
        case types.BITACORA_MOSTRAR_MODAL_CRITERIOS_FILTRADO:
            state = state.setIn(['modalFilter', 'show'], action.payload)
            return state
        case types.BITACORA_ADICIONAR_CRITERIO_FILTRADO:
            const firstCol = columnas.first()
            state = state.updateIn(['modalFilter', 'filters'], filter => filter
                .push(Immutable.fromJS({
                    column: firstCol.get('VALUE'),
                    operator: '===',
                    value: '',
                    checked: false,
                    comparison: firstCol.get('TYPE'),
                    apply: false
                }))
            )
            return state
        case types.BITACORA_ELIMINAR_CRITERIO_FILTRADO:
            state = state.setIn(['modalFilter', 'filters'], state.getIn(['modalFilter', 'filters']).filter(item => !item.get('checked')))
            return state
        case types.BITACORA_CONMUTAR_SELECCION_CRITERIO_FILTRADO:
            state = state.setIn(['modalFilter', 'filters', action.payload.index, 'checked'], action.payload.checked)
            return state
        case types.BITACORA_MODIFICAR_CRITERIO_FILTRADO:
            if (action.payload.attr === 'column') {
                state = state.setIn(['modalFilter', 'filters', action.payload.index, 'comparison'], columnas.find(x => x.get('VALUE') === action.payload.value).get('TYPE'))
            }
            state = state.setIn(['modalFilter', 'filters', action.payload.index, action.payload.attr], action.payload.value)
            return state
        case types.BITACORA_APLICAR_CRITERIOS_FILTRADO:
            state = state.updateIn(['modalFilter', 'filters'], filter => filter
                .map(x => {
                    x = x.set('apply', true)
                    return x
                })
            )
            return state
        case types.BITACORA_MODIFICAR_INPUTS:
            if (isPrimitive(action.payload.value)) {
                state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
            } else {
                if (action.payload.immutable === false) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                }
            }
            return state
        case types.BITACORA_ORDENAR_TABLA:
            state = state.set('orderBy', action.payload.orderBy)
            return state
        default:
            return state
    }
}