import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import Input from '@mawesi-sas/mws_react_component_input'
import Modal from '@mawesi-sas/mws_react_component_modal'

import { displayModalDownload, toggleSelectAllItems, descargarTabla } from "../actions";

class ModalDownload extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalDownload')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalDownload .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')
        setTimeout(() => this.props.displayModalDownload(false), 300)
    }

    modalContent = (modalDownload) => {
        const filtro = modalDownload.get('filtro')
        const seleccion = modalDownload.getIn(['estructura', 'SECCIONES_VARIABLES']).filter(x => x.get('SELECTED')).count() === 0
        return (
            <form onSubmit={(e) => { e.preventDefault(); this.props.descargarTabla() }} noValidate>
                <div className="searchbar">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1 }}>
                            <button className={`box-button ${(modalDownload.get('saving') || seleccion) ? 'disabled' : ''}`} title="Descargar" disabled={modalDownload.get('saving') || seleccion}>
                                <FontAwesome name="cloud-download" tag="i" />
                            </button>
                        </div>
                        <div>
                            <Input type="text" reducer="registros" path="modalDownload.filtro" placeholder="Filtrar..." />
                        </div>
                    </div>
                </div>
                <div className="modal-dialog" style={{"width": "100%"}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group" style={{ marginBottom: '2px' }}>
                                <label className="control-label">Seleccione los campos que desea en el reporte</label>
                                <div className="lista-variables-wrapper">
                                    {
                                        modalDownload.getIn(['estructura', 'SECCIONES_VARIABLES'])
                                            .sort((a, b) => {
                                                if (a.get('ORDEN_SECCION') > b.get('ORDEN_SECCION')) return 1
                                                if (a.get('ORDEN_SECCION') < b.get('ORDEN_SECCION')) return -1
                                                if (a.get('ORDEN_VARIABLE') > b.get('ORDEN_VARIABLE')) return 1
                                                if (a.get('ORDEN_VARIABLE') < b.get('ORDEN_VARIABLE')) return -1
                                                return 0
                                            })
                                            .map((x, n) => {
                                                if (x.getIn(['VARIABLE', 'NOMBRE_VARIABLE']).toUpperCase().indexOf(filtro.toUpperCase()) === -1) return null
                                                if (!x.getIn(['VARIABLE', 'ESTADO'])) return null
                                                if (!x.getIn(['SECCION', 'ESTADO'])) return null
                                                return (
                                                    <div key={`dv6[${x.get('ID')}]`} className={`${x.get('SELECTED') ? 'info' : ''}`}>
                                                        <label>
                                                            <Input
                                                                type='checkbox'
                                                                reducer='registros'
                                                                path={`modalDownload.estructura.SECCIONES_VARIABLES.${n}.SELECTED`}
                                                            />
                                                            <span>&nbsp;</span>
                                                            {x.getIn(['VARIABLE', 'NOMBRE_VARIABLE'])}
                                                        </label>
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="checkbox">
                                <label style={{ color: '#000' }}>
                                    <input type="checkbox" style={{ height: '13px', marginTop: '2px' }} onChange={e => this.props.toggleSelectAllItems(e.target.checked)} checked={modalDownload.getIn(['estructura', 'SECCIONES_VARIABLES']).filter(x => x.get('SELECTED')).count() > 0 && modalDownload.getIn(['estructura', 'SECCIONES_VARIABLES']).filter(x => x.get('SELECTED')).count() >= modalDownload.getIn(['estructura', 'SECCIONES_VARIABLES']).count()} />
                                    Seleccionar todos
                                        </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        const { modalDownload } = this.props
        if (!modalDownload.get('show')) return null

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "60%" : "40%"}
                mostrar={modalDownload.get('show')}
                contenido={this.modalContent(modalDownload)}
                titulo={modalDownload.get('title')}
                nombre="modalDownload"
                onCerrarModal={(e) => this.handleCloseModal(e)}
                botones={[]}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modalDownload: state.registros.get('modalDownload'),
        width: state.layout.get('width'),
        height: state.layout.get('height')
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayModalDownload: (show) => dispatch(displayModalDownload(show)),
        toggleSelectAllItems: (checked) => dispatch(toggleSelectAllItems(checked)),
        descargarTabla: () => dispatch(descargarTabla())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDownload)