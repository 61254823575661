import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import numeral from 'numeral'

import BoxButton from '../Layout/components/BoxButton'
import Box from '../Layout/components/Box'
import BoxSection from '../Layout/components/BoxSection'
import ButtonStrip from '../Layout/components/ButtonStrip'
import DetalleRoles from './components/DetalleRoles'
import RolesFormularios from './components/RolesFormularios'

import {
    obtenerRoles,
    seleccionarRol,
    adicionarRol
} from './actions'

import { lecturaRegistros } from '../../utils/constants'
import { Input } from '@mawesi-sas/mws_react_component_input'

export const tableColumns = [
    { ID: 1, CAPTION: 'Nombre', VALUE: 'NOMBRE', TYPE: 'string', FORMAT: '', WIDTH: '30%' },
    { ID: 2, CAPTION: 'Descripción', VALUE: 'DESCRIPCION', TYPE: 'string', FORMAT: '', WIDTH: '40%' },
    { ID: 3, CAPTION: 'Nivel acceso registros', VALUE: 'LECTURA_REGISTROS', TYPE: 'options', FORMAT: '', WIDTH: '30%' },
]

export function findValues(c, x) {
    let value = x.getIn(c.VALUE.split('.'))
    return value
}

class Roles extends Component {
    componentWillMount() {
        this.props.obtenerRoles()
    }

    render() {
        const { roles, selected } = this.props
        const buttons = [
            <BoxButton key="action[0][0]" name="plus" onClick={e => this.props.adicionarRol()} title="Agregar rol" />
        ]
        const records = roles.valueSeq().toList();

        return (
            <div id="roles" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box title="Roles" stretch>
                    <div className="row" style={{ height: '100%' }}>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="row" style={{ height: '100%' }}>
                                <div className="col-md-12 col-sm-12 col-xs-12" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <BoxSection title="Listado de Roles">
                                        <div style={{ padding: "0% 1%" }}>
                                            <ButtonStrip actions={buttons} />
                                        </div>
                                        <div className="table-scrolled">
                                            <table className="table table-condensed table-striped table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        {
                                                            tableColumns.map(x => {
                                                                const style = { width: x.WIDTH }
                                                                return (
                                                                    <th key={`th1[${x.ID}]`} style={style}>{x.CAPTION}</th>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        records.count() > 0 ? records.map((a, b) => {
                                                            const roleId = a.get('ID') || a.get('GUID')
                                                            return (
                                                                <tr key={`tr2[${roleId}]`} className={`${selected === roleId ? 'info' : ''}`} onClick={() => this.props.seleccionarRol(roleId)}>
                                                                    {
                                                                        tableColumns.map((c, d) => {
                                                                            let alignment = 'left'
                                                                            let value = findValues(c, a)
                                                                            if (c.TYPE) {
                                                                                switch (c.TYPE) {
                                                                                    case 'datetime':
                                                                                        value = moment(value).format(c.FORMAT)
                                                                                        alignment = 'right'
                                                                                        break
                                                                                    case 'bool':
                                                                                        if (typeof value === 'string') value = parseInt(value, 10)
                                                                                        value = <div style={{ textAlign: 'center', fontSize: '1.1em' }}><i className={`fa fa-${value ? 'check-' : ''}square`} /></div>
                                                                                        break
                                                                                    case 'number':
                                                                                        value = numeral(value).format(c.Format)
                                                                                        alignment = 'right'
                                                                                        break
                                                                                    default:
                                                                                }
                                                                            }
                                                                            const style = { textAlign: alignment }
                                                                            if (a.get('GUID')) {
                                                                                if (c.TYPE === 'options') {
                                                                                    return (
                                                                                        <td key={`td1[${roleId}][${c.ID}]`} style={style}>
                                                                                            <Input type="select" reducer="roles" options={lecturaRegistros} path={`roles.${roleId}.${c.VALUE}`} maxLength={50} />
                                                                                        </td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td key={`td1[${roleId}][${c.ID}]`} style={style}>
                                                                                            <Input type="text" reducer="roles" path={`roles.${roleId}.${c.VALUE}`} maxLength={50} />
                                                                                        </td>
                                                                                    ) 
                                                                                }
                                                                            } else {
                                                                                if (c.TYPE === 'options') {
                                                                                    return (
                                                                                        <td key={`td1[${roleId}][${c.ID}]`} style={style}>
                                                                                            <Input type="select" reducer="roles" options={lecturaRegistros} path={`roles.${roleId}.${c.VALUE}`} maxLength={50} />
                                                                                        </td>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <td key={`td1[${roleId}][${c.ID}]`} style={style}>{value}</td>
                                                                                    )
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        }) : <tr><td colSpan={tableColumns.length + 1} style={{ textAlign: 'center' }}>No existen registros</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </BoxSection>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="row" style={{ height: '100%' }}>
                                <DetalleRoles />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="row" style={{ height: '100%' }}>
                                <RolesFormularios/>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        roles: state.roles.get('roles'),
        selected: state.roles.get('selected')
    }
}

function mapDispatchToProps(dispatch) {
    return {
        obtenerRoles: () => dispatch(obtenerRoles()),
        seleccionarRol: (id) => dispatch(seleccionarRol(id)),
        adicionarRol: () => dispatch(adicionarRol())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Roles)