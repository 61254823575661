import querystring from 'querystring'
import { signOut } from '../SignIn/actions'
import * as types from '../../types'
import { API_URL } from '../../utils/constants'
import { getUserFromToken } from '../SignIn/actions'
import { createAxiosInstance, guid } from '../../utils/helpers'
import { seleccionarScript } from '../Home/actions'
import ModalChangePassword from './components/ModalChangePassword'


export function displayMessages(dispatch, msg) {
    let obj = {
        type: 'warning',
        message: ''
    }
    let message = ''
    if (msg.response) {
        switch (msg.response.status) {
            case 500:
                message = (msg.response.data.ExceptionMessage) ? msg.response.data.ExceptionMessage : msg.response.data.Message
                message = message ? message : msg.response.data
                dispatch({
                    type: types.LAYOUT_MOSTRAR_MODAL_ERROR,
                    payload: {
                        show: true,
                        message: message
                    }
                })
                break;
            case 401:
                dispatch({
                    type: types.LAYOUT_MOSTRAR_MENSAJE,
                    payload: {
                        type: 'danger',
                        message: 'No está autorizado para proceder con esta solicitud. Por favor, inicie sesión y vuelva a intentarlo'
                    }
                })
                dispatch(signOut())
                break;
            case 400:
                if (msg.response.data.ModelState) {
                    for (let property in msg.response.data.ModelState) {
                        if (msg.response.data.ModelState.hasOwnProperty(property)) {
                            for (let n = 0; n < msg.response.data.ModelState[property].length; n++) {
                                dispatch({
                                    type: types.LAYOUT_MOSTRAR_MENSAJE,
                                    payload: {
                                        type: 'danger',
                                        message: msg.response.data.ModelState[property][n]
                                    }
                                })
                            }
                        }
                    }
                } else if (msg.response.data.error) {
                    dispatch({
                        type: types.LAYOUT_MOSTRAR_MENSAJE,
                        payload: {
                            type: 'danger',
                            message: msg.response.data.error_description || msg.response.data.error
                        }
                    })
                }
                break;
            case 422:
                if (msg.response.data) {
                    for (let property in msg.response.data) {
                        if (msg.response.data.hasOwnProperty(property)) {
                            for (let n = 0; n < msg.response.data[property].length; n++) {
                                dispatch({
                                    type: types.LAYOUT_MOSTRAR_MENSAJE,
                                    payload: {
                                        type: 'warning',
                                        message: msg.response.data[property][n]
                                    }
                                })
                            }
                        }
                    }
                }
                break;
            case 405:
                if (msg.response.data.Message) {
                    dispatch({
                        type: types.LAYOUT_MOSTRAR_MODAL_ERROR,
                        payload: {
                            show: true,
                            message: msg.response.data.Message
                        }
                    })
                    //dispatch({type: types.LAYOUT_MOSTRAR_MENSAJE, payload: { type: 'danger', message: msg.response.data.Message }})
                }
                break;

            case 403:
                dispatch({
                    type: types.LAYOUT_MOSTRAR_MENSAJE,
                    payload: {
                        type: 'danger',
                        message: 'Acceso Denegado'
                    }
                })
                break;

            default:

        }
    } else {
        if (msg.data) {
            obj.message = msg.data.Message
            dispatch({
                type: types.LAYOUT_MOSTRAR_MENSAJE,
                payload: obj
            })
        } else if (msg.message) {
            obj.type = 'danger'
            obj.message = msg.message
            dispatch({
                type: types.LAYOUT_MOSTRAR_MENSAJE,
                payload: obj
            })
        } else if (msg.info) {
            obj.type = 'info'
            obj.message = msg.info
            dispatch({
                type: types.LAYOUT_MOSTRAR_MENSAJE,
                payload: obj
            })
        } else if (msg.success) {
            obj.type = 'success'
            obj.message = msg.success
            dispatch({
                type: types.LAYOUT_MOSTRAR_MENSAJE,
                payload: obj
            })
        } else if (msg.warning) {
            if (msg.warning instanceof Array) {
                for (let n = 0; n < msg.warning.length; n++) {
                    const payload = {
                        type: 'warning',
                        message: msg.warning[n]
                    }
                    const fn = (d) => d({
                        type: types.LAYOUT_MOSTRAR_MENSAJE,
                        payload
                    })
                    fn(dispatch)
                }
            } else {
                obj.type = 'warning'
                obj.message = msg.warning
                dispatch({
                    type: types.LAYOUT_MOSTRAR_MENSAJE,
                    payload: obj
                })
            }
        } else {
            obj.type = 'danger'
            obj.message = msg
            dispatch({
                type: types.LAYOUT_MOSTRAR_MODAL_ERROR,
                payload: obj
            })
        }
    }
}

export function clearMessage() {
    return (dispatch) => {
        dispatch({
            type: types.LAYOUT_LIMPIAR_MENSAJES
        })
    }
}

export function expandCollapseModule(moduloId) {
    return (dispatch) => {
        dispatch({
            type: types.LAYOUT_EXPANDIR_CONTRAER_MODULO,
            payload: moduloId
        })
    }
}

export function showHideModalMsgBox(show = false, message = null) {
    return (dispatch) => {
        dispatch({
            type: types.LAYOUT_MOSTRAR_MODAL_ERROR,
            payload: {
                show,
                message
            }
        })
    }
}

export function displayModalChangePassword(show=false) {
    return {
        type: types.LAYOUT_MOSTRAR_MODAL_CAMBIO_CONTRASENA,
        payload: {
            show
        }
    }
}

export function setDimension(width, height) {
    return {
        type: types.LAYOUT_SET_DIMENSION,
        payload: { width, height }
    }
}

export function getUserConfig() {
    return (dispatch) => {
        getUserFromToken()
            .then(data => {
                const fn2 = d => d({
                    type: types.SIGNIN_ASIGNAR_USUARIO_SESION,
                    payload: data
                })
                fn2(dispatch)
                data.MODULOS = data.MODULOS.map(x => {
                    return {
                        id: x.COD_NODO,
                        caption: x.NOM_MODULO,
                        is_active: false,
                        is_open: false,
                        url: x.CONFIG.url,
                        subitems: x.SUBMODULOS.map(m => {
                            return {
                                id: m.COD_NODO,
                                caption: m.NOM_MODULO,
                                is_active: false,
                                url: m.CONFIG.url,
                                roles: [],
                                visible: m.VISIBLE
                            }
                        }),
                        roles: [],
                        visible: x.NOM_MODULO == 'Scripts' && data.ROL_ID != 1 ? false : x.VISIBLE,
                    }
                })

                if (data.SCRIPTS.length) dispatch(seleccionarScript(data.SCRIPTS[0]))
                const dashboard = {
                    id: 0,
                    caption: "DASHBOARDS",
                    is_active: false,
                    is_open: false,
                    url: null,
                    scripts: true,
                    subitems: data.SCRIPTS.map(s => {
                        return {
                            id: guid(),
                            caption: s,
                            is_active: false,
                            url: false,
                            roles: [],
                            visible: true,
                            onClick: () => dispatch(seleccionarScript(s))
                        }
                    }),
                    roles: [],
                    visible: (data.SCRIPTS.length > 0),
                }
                let MODULOS = [dashboard, ...data.MODULOS]
                const fn3 = d => d({
                    type: types.LAYOUT_POBLAR_MODULOS,
                    payload: {
                        modulos: MODULOS,
                        elementos: data.ELEMENTOS
                    }
                })
                fn3(dispatch)
            })
            .catch(err => {
                if (err.response && err.response.data.status === 401) {
                    dispatch(signOut())
                }
            })
    }
}

export function showModalConfirmAction(open, message = null, action = null) {
    return (dispatch) => {
        dispatch({
            type: types.LAYOUT_MOSTRAR_MODAL_CONFIRMACION_ACCION,
            payload: {
                open,
                message,
                action
            }
        })
    }
}

export function setSavingState(reducer, value) {
    return (dispatch) => {
        dispatch({
            type: `${reducer.toUpperCase()}_GUARDANDO`,
            payload: value
        })
    }
}

export function showHideModalSelector(show, title, fields, url, action) {
    return (dispatch) => {
        dispatch({
            type: types.LAYOUT_MOSTRAR_MODAL_SELECTOR,
            payload: {
                show,
                title,
                fields,
                url,
                action
            }
        })
        if (show) dispatch(getDataFromServer())
    }
}

export function showHideModalPoliticas(show, title="") {
    return (dispatch) => {
        dispatch({
            type: types.LAYOUT_MOSTRAR_MODAL_POLITICAS,
            payload: {
                show,
                title
            }
        })
    }
}

export function getDataFromServer(page = 1) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch({
            type: types.LAYOUT_MOSTRAR_LOADING_SELECTOR,
            payload: true
        })

        const pages = getState().layout.getIn(['modalSelector', 'pages'])
        const take = getState().layout.getIn(['modalSelector', 'take'])
        const url = getState().layout.getIn(['modalSelector', 'url'])

        const skip = (page - 1) * take

        if (pages.filter(x => x === page).count() < 1) {
            dispatch({
                type: types.LAYOUT_MODIFICAR_PAGINA_MODAL_SELECTOR,
                payload: {
                    page
                }
            })
            axios.get(`${API_URL}/${url}`, { params: { skip, take } })
                .then((res) => {
                    let list = res.data
                    const filter = getState().layout.getIn(['modalSelector', 'filter'])
                    const fields = getState().layout.getIn(['modalSelector', 'fields'])
                    list = list.filter(x => {
                        if (filter) {
                            let compare = false
                            fields.forEach(y => {
                                compare = compare || (x[`${y.get('value')}`].toLowerCase().indexOf(filter.toLowerCase()) > -1)
                            })
                            return compare
                        } else {
                            return true
                        }
                    })

                    dispatch({
                        type: types.LAYOUT_ACTUALIZAR_LISTADO_SELECTOR,
                        payload: {
                            list
                        }
                    })
                    dispatch({
                        type: types.LAYOUT_MOSTRAR_LOADING_SELECTOR,
                        payload: false
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: types.LAYOUT_MOSTRAR_LOADING_SELECTOR,
                        payload: false
                    })
                    displayMessages(dispatch, err)
                })
        } else {
            dispatch({
                type: types.LAYOUT_MOSTRAR_LOADING_SELECTOR,
                payload: false
            })
        }
    }
}

export function clearPagesModalSelector() {
    return (dispatch) => {
        dispatch({
            type: types.LAYOUT_REINICIAR_LISTA_MODAL_SELECTOR
        })
    }
}

export function addTitleIfOverflow(element) {
    if (element.offsetWidth <= element.scrollWidth && !element.hasAttribute('title')) {
        element.setAttribute('title', element.innerHTML)
    }
}

export function selectFromSelector(payload) {
    return (dispatch) => {
        dispatch({
            type: 'HYQ3_MODIFY_INPUT_VALUE',
            payload
        })
    }
}

export function modifyInputs(reducer, path, value) {
    return (dispatch) => {
        const type = `${reducer.toUpperCase()}_MODIFICAR_INPUTS`
        dispatch({ type, payload: { path, value } })
        if (type === 'COMMON_MODIFICAR_INPUTS' && path === 'compania') {
            dispatch(getUserConfig())
        }
    }
}

export function cambiarContrasena() {
    const axios = createAxiosInstance()

    return (dispatch, getState) => {
        const modalChangePassword = getState().layout.get('modalChangePassword')

        if (modalChangePassword.getIn(['data','password']) !== modalChangePassword.getIn(['data','repeatPassword'])) {
            dispatch(displayModalChangePassword(false))
            return dispatch({type: types.LAYOUT_MOSTRAR_MENSAJE, payload: { type: 'danger', message: 'Las contraseñas no son iguales.' }})
        }
        const data = querystring.stringify({
            password: modalChangePassword.getIn(['data','password'])
        })
        axios.post(`${API_URL}/account/change_password`, data)
            .then(res => {
                return dispatch({type: types.LAYOUT_MOSTRAR_MENSAJE, payload: { type: 'success', message: res.data }})
            }).catch(err => displayMessages(dispatch, err))
    }
}

export function acceptPolicies () {
    const axios = createAxiosInstance()

    return (dispatch, getState) => {
        const acepta_terminos_condiciones = getState().layout.getIn(['modalPoliticas', 'acepta_terminos_condiciones'])
        const acepta_politica_privacidad = getState().layout.getIn(['modalPoliticas', 'acepta_politica_privacidad'])

        const data = querystring.stringify({
            AcceptPolicy: acepta_terminos_condiciones && acepta_politica_privacidad
        })
        axios.post(`${API_URL}/account/accept_policies`, data)
            .then(res => {
                dispatch(getUserConfig())
                return dispatch(showHideModalPoliticas(false))
            }).catch(err => displayMessages(dispatch, err))
    }
}