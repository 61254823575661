import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import Dropzone from "react-dropzone";
import Modal from '@mawesi-sas/mws_react_component_modal';
import Input from '@mawesi-sas/mws_react_component_input';
import { toast } from "react-toastify";
import BoxButton from "../../Layout/components/BoxButton";

import {
  displayModalCreate,
  almacenarFuente,
  massiveUploadFiles,
  showModalConfirmAction,
  addFileCreate,
  deleteFileCreate
} from "../actions";

class ModalCreate extends Component {
  handleCloseModal = e => {
    const modal = document.getElementById("modalCreate");
    modal.classList.remove("fadeIn");
    modal.classList.add("fadeOut");

    const modalDialog = document.querySelector(
      `#modalCreate .mws-modal-dialog`
    );
    modalDialog.classList.remove("zoomIn");
    modalDialog.classList.add("zoomOut");
    setTimeout(() => this.props.displayModalCreate(false), 300);
  };

  handleOnRejected = files => {
    files.forEach(x => {
      toast.warn(`El archivo ${ x.name } no es del tipo esperado`, {
        bodyClassName: "toastify-content toastify-warning"
      });
    });
  };

  handleAddFile = e => {
    e.preventDefault()
    this.props.addFileCreate()
  }

  handleDeleteFile = e => {
    e.preventDefault()
    this.props.deleteFileCreate()
  }
  modalContent = (modalCreate, boxActions, saving) => {
    return (
      <form id="form" onSubmit={e => { e.preventDefault(); this.props.almacenarFuente('create'); }} noValidate>
        <div className="buttonstrip">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <div style={{ flex: 1 }}>
              <button className="box-button" title="Guardar" disabled={saving}>
                <FontAwesome name="floppy-o" tag="i" />
              </button>
              <BoxButton key="ba[0][0]" name="plus-circle" onClick={this.handleAddFile} title="Nuevo archivo" />
              <BoxButton key="ba[0][1]" name="trash" onClick={this.handleDeleteFile} title="Eliminar Archivo" disabled={modalCreate.getIn(["data","FUENTES"]).filter(x => x.get('SELECTED')).count() === 0} danger />
            </div>
          </div>
        </div>
        <div className="modal-dialog" style={{ width: "100%" }}>
          <div className="box-content" style={{ height: '188px', overflow: 'auto', background: '#f5f5f5' }}>
            <table className="table table-condensed table-bordered">
              <thead>
                <tr>
                  <th style={{ width: '25px' }}></th>
                  <th>Archivo</th>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Carpeta</th>
                </tr>
              </thead>
              <tbody>
                {
                  modalCreate.getIn(['data', 'FUENTES']).map((x, n) => {
                    const path = `modalCreate.data.FUENTES.${ n }`
                    return (
                      <tr key={`tr6[${ x.get('ID') || x.get('GUID') }]`} className={x.get('SELECTED') ? 'info' : ''}>
                        <td style={{verticalAlign: "middle"}}><Input type="checkbox" reducer="fuentes" path={`${ path }.SELECTED`} /></td>
                        <td style={{verticalAlign: "middle"}}><div className="dropzone1" style={{ marginBottom: "15px", minHeight: "40px", height: "40px", minWidth: "130px" }}>
                          <Dropzone onDropAccepted={(files) => this.props.massiveUploadFiles(files, n)}>
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <p style={{lineHeight: 1.5}}>
                                    Arrastre o haga clic para subir archivos
                                  </p>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div></td>
                        <td style={{verticalAlign: "middle"}}><Input type="text" reducer="fuentes" path={`${ path }.NOMBRE`} maxLength={100} disabled /></td>
                        <td style={{verticalAlign: "middle"}}><Input type="textarea" reducer="fuentes" path={`${ path }.DESCRIPCION`} rows="3" maxLength={1000} /></td>
                        <td style={{verticalAlign: "middle"}}><Input type="select" reducer="fuentes" path={`${ path }.CARPETA`} options={this.props.folders} /></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </form>
    );
  };

  render() {
    const { modalCreate, saving } = this.props;
    if (!modalCreate.get("show")) return null;

    const boxActions = [];

    return (
      <Modal
        ancho={this.props.width < this.props.height ? "95%" : "75%"}
        mostrar={modalCreate.get("show")}
        contenido={this.modalContent(modalCreate, boxActions, saving)}
        titulo={modalCreate.get("title")}
        nombre="modalCreate"
        onCerrarModal={e => this.handleCloseModal(e)}
      />
    );
  }
}

const mapStateToProps = (state, onwProps) => {
  return {
    modalCreate: state.fuentes.get("modalCreate"),
    folders: state.fuentes.get("folders"),
    saving: state.fuentes.get("saving"),
    width: state.layout.get('width'),
    height: state.layout.get('height')
  };
};

const mapDispatchToProps = {
  displayModalCreate,
  almacenarFuente,
  massiveUploadFiles,
  showModalConfirmAction,
  addFileCreate,
  deleteFileCreate
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreate);
