import axios from 'axios';
import Cookies from 'universal-cookie';
import objectifyArray from "objectify-array";
import { Map } from 'immutable';

import store from "./store";
import history from "./history";
import { evalExcelFormula } from "./eval-formula";
import { API_URL } from './constants';

const cookies = new Cookies();

export function createAxiosInstance(config) {
    const token = cookies.get('token');
    if (!token) return null;
    let minConfig = {
        baseURL: API_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Compania': getSelectedCompany().get('CODIGO_COMPANIA') || '00'
        }
    };
    if (config) {
        if (config.headers) {
            minConfig.headers = {
                ...minConfig.headers,
                ...config.headers
            };
        } else {
            minConfig = {
                ...minConfig,
                ...config
            };
        }
    }

    return axios.create(minConfig);
}

export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export function isPrimitive(test) {
    return (test !== Object(test))
}

export function isFunction(fn) {
    return fn && {}.toString.call(fn) === '[object Function]';
}

export const uniqueArray = (arr) => [...new Set(arr)]


export const operators = [
    { id: 1, value: '===', caption: 'Igual a', comparisons: ['string', 'number', 'datetime'] },
    { id: 2, value: '!==', caption: 'Diferente de', comparisons: ['string', 'number', 'datetime'] },
    { id: 3, value: '>', caption: 'Mayor a', comparisons: ['number', 'datetime'] },
    { id: 4, value: '>=', caption: 'Mayor o igual a', comparisons: ['number', 'datetime'] },
    { id: 5, value: '<', caption: 'Menor a', comparisons: ['number', 'datetime'] },
    { id: 6, value: '<=', caption: 'Menor o igual a', comparisons: ['number', 'datetime'] },
    { id: 7, value: 'like', caption: 'Contiene', comparisons: ['string', 'datetime'] },
]

export function obtenerOpciones(maestraId, referencia = true, parentValue) {
    let options = []
    const maestra = store.getState().maestras.getIn(['maestras', String(maestraId)])
    if (maestra) {
        maestra.get('ENT_DETALLE_MAESTRAS').valueSeq().sortBy(x => x.get('ORDER_KEY')).forEach(x => {
            if (referencia) {
                options.push({ value: x.get(`ID`), caption: x.get(`CONCATENADO`) })
            } else {
                options.push({ value: x.get(`CONCATENADO`), caption: x.get(`CONCATENADO`) })
            }
        })
    }

    if (parentValue !== undefined) {
        options = options.filter(x => String(x.caption).toUpperCase().indexOf(String(parentValue).toUpperCase()) >= 0)
    }
    return options.sort((a, b) => {
        if (a.caption < b.caption) return -1
        if (a.caption > b.caption) return 1
        return 0
    })
}

export function proximoCampoDisponible(configuraciones) {
    let campo = 0
    configuraciones.some((x, n) => {
        if (x.get('CAMPO') !== (n + 1)) campo = (n + 1)
        return campo !== 0
    })
    if (campo === 0) campo = configuraciones.count() + 1

    return campo
}

export function addTitleIfOverflow(element) {
    if (element.offsetWidth <= element.scrollWidth && !element.hasAttribute('title')) {
        element.setAttribute('title', element.innerHTML)
    }
}

export function getSelectedCompany() {
    const compania = JSON.parse(localStorage.getItem('compania'))
    if (isPrimitive(compania)) {
        return Map({})
    } else {
        return Map(JSON.parse(localStorage.getItem('compania')))
    }
}

export function construirArregloColumnas(secciones) {

}

export function calcularCompletadoSeccion(seccion) {
    let completado = 0
    let m = 1

    if (seccion.get('TABLA_VARIABLES').count() > 0) {
        m = seccion.get('TABLA_VARIABLES').count()
        seccion.get('TABLA_VARIABLES').forEach(tv => {
            tv.forEach(v => {
                completado += isNullOrEmpty(v.get('VALOR')) ? 0 : 1
            })
        })
    } else {
        seccion.get('VARIABLES').valueSeq().forEach(v => {
            completado += isNullOrEmpty(v.get('VALOR')) ? 0 : 1
        })
    }
    completado = completado / (seccion.get('VARIABLES').keySeq().count() * m)

    return completado
}


export function calcularCompletado(secciones) {
    const regex = /\[(.*?)\]/g
    const variables = secciones.reduce((a, b) => a.concat(b.VARIABLES), [])

    secciones = secciones.map(s => {
        s.COMPLETADO = 0
        let m = 1
        if (s.TABLA_VARIABLES.length > 0) {
            m = s.TABLA_VARIABLES.length
            s.TABLA_VARIABLES.forEach(tv => {
                tv.forEach(v => {
                    s.COMPLETADO += isNullOrEmpty(v.VALOR) ? 0 : 1
                })
            })
        } else {
            s.VARIABLES.forEach(v => {
                if (!isNullOrEmpty(v.FORMULA)) {
                    let formula = v.FORMULA
                    let matches = v.FORMULA.match(regex)
                    if (matches) {
                        matches.forEach((m, i) => {
                            const rgx = new RegExp(`${escapeRegExp(m)}`, 'g')
                            const nombreVar = m.replace('[', '').replace(']', '')
                            const vrbl = variables.filter(x => x.NOMBRE_VARIABLE === nombreVar)
                            if (vrbl.length === 1) {
                                formula = formula.replace(rgx, vrbl[0].VALOR)
                            }
                        })
                    }
                    try {
                        v.VALOR = evalExcelFormula(formula)({})
                    } catch (error) {
                        v.VALOR = '#ERROR'
                    }
                }
                s.COMPLETADO += isNullOrEmpty(v.VALOR) ? 0 : 1
            })
        }
        s.COMPLETADO = s.COMPLETADO / (s.VARIABLES.length * m)
        s.VARIABLES = objectifyArray(s.VARIABLES, { by: ['ID'], recursive: false })
        return s
    })

    secciones = objectifyArray(secciones, { by: ['ID'], recursive: false })
    return secciones
}

export function evalListadoRegistros(registros, secciones) {
    const regex = /\[(.*?)\]/g
    const variables = secciones.reduce((a, b) => a.concat({...b.VARIABLE, ...{ID: `${b.SECCION_ID}_${b.VARIABLE_ID}`}}), [])
    
    variables.filter(x => x.FORMULA !== "").forEach(value => {
        for (const key in registros) {
            const reg = registros[key]
            let formula = value.FORMULA
            const matches = formula.match(regex)
            if (matches) {
                matches.forEach((m, i) => {
                    const rgx = new RegExp(`${escapeRegExp(m)}`, 'g')
                    const nombreVar = m.replace('[', '').replace(']', '')
                    const vrbl = variables.filter(x => x.NOMBRE_VARIABLE === nombreVar)
                    if (vrbl.length === 1) {
                        formula = formula.replace(rgx, reg[vrbl[0].ID])
                    }
                })
            }
            try {
                reg[value.ID] = evalExcelFormula(formula)({})
            } catch (error) {
                reg[value.ID] = '#ERROR'
            }
        }
    })
    return registros
}

export function evalVariablesFormulas(secciones) {
    const regex = /\[(.*?)\]/g
    const variables = secciones.valueSeq().reduce((accum, s) => {
        return s.get('VARIABLES').valueSeq().toJS()
    }, [])

    secciones.entrySeq().forEach(s => {
        s[1].get('VARIABLES').entrySeq().forEach(v => {
            if (!isNullOrEmpty(v[1].get('FORMULA'))) {
                let formula = v[1].get('FORMULA');
                let matches = v[1].get('FORMULA').match(regex);

                if (matches) {
                    matches.forEach((m, i) => {
                        const rgx = new RegExp(`${escapeRegExp(m)}`, 'g')
                        const nombreVar = m.replace('[', '').replace(']', '')
                        const vrbl = variables.filter(x => x.NOMBRE_VARIABLE === nombreVar)
                        if (vrbl.length === 1) {
                            formula = formula.replace(rgx, vrbl[0].VALOR)
                        }
                    })
                    try {
                        secciones = secciones.setIn(`${s[0]}.VARIABLES.${v[0]}.VALOR`.split('.'), evalExcelFormula(formula)({}))
                    } catch (error) {
                        secciones = secciones.setIn(`${s[0]}.VARIABLES.${v[0]}.VALOR`.split('.'), '#ERROR')
                    }
                    
                }
            }
        })
    })

    return secciones
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function procesarResumen(data, columnas) {
    let secciones = {}
    let tableColumns = []

    data.SECCIONES_VARIABLES.filter(a => a.ESTADO)
        .sort((a, b) => {
            return a.ORDEN_SECCION - b.ORDEN_SECCION || a.ORDEN_VARIABLE - b.ORDEN_VARIABLE
        }).forEach(x => {
            if (!secciones[x.SECCION.ID]) {
                secciones[x.SECCION.ID] = x.SECCION
                secciones[x.SECCION.ID].ORDEN_SECCION = x.ORDEN_SECCION
                secciones[x.SECCION.ID].COMPLETADO = 0
                secciones[x.SECCION.ID].VARIABLES = {}
            }
            if (!secciones[x.SECCION.ID].VARIABLES[x.VARIABLE.ID]) {
                secciones[x.SECCION.ID].VARIABLES[x.VARIABLE.ID] = x.VARIABLE
                let tipo_dato = 'string';
                let format = '';

                if (x.VARIABLE.TIPO_DATO === 'Fecha') {
                    tipo_dato = 'datetime'
                    format = 'YYYY-MM-DD'
                } else if (x.VARIABLE.TIPO_DATO == 'Numerico') {
                    tipo_dato = 'number'
                    format = '0[.]00'
                }

                if (columnas && columnas.length > 0) {
                    if (columnas.indexOf(x.VARIABLE.NOMBRE_VARIABLE) !== -1) {
                        tableColumns.push({
                            ID: tableColumns.length + 1,
                            CAPTION: x.VARIABLE.NOMBRE_VARIABLE,
                            VALUE: `${x.SECCION.ID}_${x.VARIABLE.ID}`,
                            COLOR: x.SECCION.COLOR,
                            TYPE: tipo_dato,
                            LINK: tableColumns.length === 0,
                            IMAGEN: x.VARIABLE.TIPO_CAMPO === 'Imagen',
                            FORMAT: format
                        })
                    }
                } else {
                    tableColumns.push({
                        ID: tableColumns.length + 1,
                        CAPTION: x.VARIABLE.NOMBRE_VARIABLE,
                        VALUE: `${x.SECCION.ID}_${x.VARIABLE.ID}`,
                        COLOR: x.SECCION.COLOR,
                        TYPE: tipo_dato,
                        LINK: tableColumns.length === 0,
                        IMAGEN: x.VARIABLE.TIPO_CAMPO === 'Imagen',
                        FORMAT: format
                    })
                }
            }
        })
    return { secciones, tableColumns }
}

export function procesarEstructuras(data, columnas, values) {
    let secciones = {}
    let tableColumns = []

    const variables = data.SECCIONES_VARIABLES.filter(a => a.ESTADO && a.SECCION.TABLA).reduce((a, b) => {
        a[b.ENT_VARIABLES.ID] = { ...b.ENT_VARIABLES }
        return a
    }, {})

    data.SECCIONES_VARIABLES
        .filter(a => a.ESTADO)
        .sort((a, b) => {
            if (a.ORDEN_SECCION > b.ORDEN_SECCION) return 1
            if (a.ORDEN_SECCION < b.ORDEN_SECCION) return -1
            if (a.ORDEN_VARIABLE > b.ORDEN_VARIABLE) return 1
            if (a.ORDEN_VARIABLE < b.ORDEN_VARIABLE) return -1
            return 0
        })
        .forEach(x => {
            if (!secciones[x.SECCION.ID]) {
                secciones[x.SECCION.ID] = x.SECCION
                secciones[x.SECCION.ID].ORDEN_SECCION = x.ORDEN_SECCION
                secciones[x.SECCION.ID].COMPLETADO = 0
                secciones[x.SECCION.ID].VARIABLES = {}
            }
            if (!x.SECCION.TABLA) {
                if (!secciones[x.SECCION.ID].VARIABLES[x.VARIABLE.ID]) {
                    secciones[x.SECCION.ID].VARIABLES[x.VARIABLE.ID] = x.VARIABLE
                    if (values) {
                        secciones[x.SECCION.ID].VARIABLES[x.VARIABLE.ID].VALOR = values[x.VARIABLE.ID]
                        secciones[x.SECCION.ID].COMPLETADO += isNullOrEmpty(values[x.VARIABLE.ID]) ? 0 : 1
                    }
                    if (columnas && columnas.length > 0) {
                        if (columnas.indexOf(x.VARIABLE.NOMBRE_VARIABLE) !== -1) {
                            tableColumns.push({
                                ID: tableColumns.length + 1,
                                CAPTION: x.VARIABLE.NOMBRE_VARIABLE,
                                VALUE: String(x.VARIABLE.ID),
                                COLOR: x.SECCION.COLOR,
                                TYPE: 'string',
                                LINK: tableColumns.length === 0
                            })
                        }
                    } else {
                        tableColumns.push({
                            ID: tableColumns.length + 1,
                            CAPTION: x.VARIABLE.NOMBRE_VARIABLE,
                            VALUE: String(x.VARIABLE.ID),
                            COLOR: x.SECCION.COLOR,
                            TYPE: 'string',
                            LINK: tableColumns.length === 0
                        })
                    }
                }
            }

        })

    if (values) {
        Object.keys(secciones).forEach(id => {
            if (secciones[id].TABLA) {
                let n = 0
                let t = 0
                secciones[id].TABLA = values.tablas[id].reduce((a, b) => {
                    const row = {}
                    t = Object.keys(b).length
                    Object.keys(b).forEach(k => {
                        secciones[id].COMPLETADO += isNullOrEmpty(b[k]) ? 0 : 1
                        row[k] = { ...variables[k], VALOR: b[k] }
                    })
                    a[n] = row
                    n++
                    return a
                }, [])
                const div = n * t
                secciones[id].COMPLETADO = secciones[id].COMPLETADO / div
            } else {
                secciones[id].COMPLETADO = secciones[id].COMPLETADO / Object.keys(secciones[id].VARIABLES).length
            }
        })
    }

    return { secciones, tableColumns }
}

export function dispatchCrearArticulo(d, secciones, articuloDelta, redirect = false) {
    secciones.keySeq().forEach(x => {
        secciones.getIn([String(x), 'VARIABLES']).keySeq().forEach(y => {
            let value = ''
            if (!secciones.getIn([String(x), 'VARIABLES', String(y), 'EDITABLE']) && secciones.getIn([String(x), 'VARIABLES', String(y), 'CAMPO_DELTA'])) {
                value = articuloDelta.get(secciones.getIn([String(x), 'VARIABLES', String(y), 'CAMPO_DELTA']))
            }
            if (secciones.getIn([String(x), 'VARIABLES', String(y), 'TIPO_CAMPO']) === 'Lista maestra') {
                value = -1
            }

            secciones = secciones.setIn([String(x), 'VARIABLES', String(y), 'VALOR'], value)
        })
    })
    const payload = { articuloDelta, secciones }
    d({ type: 'CREARARTICULO_POBLAR_ARTICULO', payload })
    if (redirect) history.push(`/articulos/${payload.articuloDelta.get('ART.ARTICULO')}`)
}

const percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } }
]

export function getColorForPercentage(pct) {
    for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break;
        }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
}

export function isNullOrEmpty(value) {
    if (value === 0) return false
    return !value
}

export function transformParameterData(parametro) {
    const columnas = []
    const table = parametro.get('CONFIGURACIONES').valueSeq().reduce((a, b) => {
        if (columnas.filter(c => c.NOMBRE === b.get('NOMBRE')).length === 0) {
            columnas.push({
                ID: b.get('ID'),
                POSICION: b.get('POSICION'),
                CAPTION: b.get('NOMBRE').replace(/_/g, ' '),
                VALUE: b.get('NOMBRE'),
                TYPE: 'string',
                FORMAT: '',
                PARAMETRO_ID: parametro.get('ID'),
                CONFIG_PARAMETRO_ID: b.get('ID')
            })
        }
        b.get('DETALLES').valueSeq().forEach(x => {
            if (!a[x.get('REGISTRO')]) a[x.get('REGISTRO')] = {}
            a[x.get('REGISTRO')].REGISTRO = x.get('REGISTRO')
            a[x.get('REGISTRO')][b.get('ID')] = { ID: x.get('ID'), VALOR: x.get('VALOR'), UPDATED: false, GUID: guid(), PARAMETRO_ID: parametro.get('ID'), CONFIG_PARAMETRO_ID: b.get('ID'), CREATED_AT: x.get('CREATED_AT') }
        })
        return a
    }, {})

    const data = {}
    for (let key in table) {
        if (table.hasOwnProperty(key)) {
            const obj = columnas.reduce((a, b) => {
                a[b.ID] = { ID: null, VALOR: null, UPDATED: false, GUID: guid(), PARAMETRO_ID: parametro.get('ID'), CONFIG_PARAMETRO_ID: b.CONFIG_PARAMETRO_ID }
                a.SELECTED = false
                a.DELETED = false
                return a
            }, {})
            data[key] = { ...obj, ...table[key] }
        }
    }

    return { COLUMNAS: columnas, DATA: data }
}

export function createTreeFuentes(data, termino) {
    const records = data.valueSeq().toList().filter(x =>
        x.get("NOMBRE")
         .toLowerCase()
         .indexOf(termino.toLowerCase()) > -1
    )

    const folders = records.map(x => x.get('CARPETA')).toSet().toList();

    const result = folders.map(x => ({
        data: {
            NOMBRE: !x ? "/" : x
        },
        children: records.filter(y => y.get('CARPETA') === x).map(y => (
            {
                data: y.toJS(),
                height: 32
            }
        )),
        height: 32
    }))

    return result.toJS()
}