import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import history from '../../../utils/history'

export default class ModuleOption extends Component {
    handleOnClick = (e) => {
        
        e.preventDefault()
        const ink = ReactDOM.findDOMNode(this.ink)
        const li = ReactDOM.findDOMNode(this.li)
        ink.classList.remove("animate")

        if (!ink.offsetHeight && !ink.offsetWidth) {
            const d = Math.max(li.offsetWidth, li.offsetHeight)
            ink.style.height = `${d}px`
            ink.style.width = `${d}px`
        }

        const rect = li.getBoundingClientRect()
        const x = e.pageX - rect.left - ink.offsetWidth / 2
        const y = e.pageY - rect.top - ink.offsetHeight / 2

        ink.style.top = `${y}px`
        ink.style.left = `${x}px`
        ink.classList.add("animate")       

        if (this.props.scripts) {
            this.props.onClick()
        } else {
            history.push(this.props.url)
        }
    }
    render() {

        const { caption } = this.props

        return(
            <li ref={ (li) => this.li = li } >
                <span className="ink" ref={ (ink) => this.ink = ink }></span>
                <a style={{width:'100%', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}} title={caption} onClick={ e => this.handleOnClick(e) }>{ caption }</a>
            </li>
        )
    }
}