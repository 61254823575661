import * as types from '../../types'
import Immutable from 'immutable'

const INITIAL_STATE = Immutable.fromJS({
    email: '',
    processing: false,
})

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.RECOVERY_PASSWORD_PROCESANDO:
            state = state.set('processing', action.payload)
            if (!action.payload) state = state.set('email', '')
            return state
        default:
            return state
    }
}