import * as types from '../../types'
import { createAxiosInstance } from '../../utils/helpers'
import { seccionVariableEntity, API_URL } from '../../utils/constants';
import { displayMessages, setSavingState } from '../Layout/actions'
import objectifyArray from 'objectify-array'

export function obtenerFormularios() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        // const compania = getSelectedCompany().get('CODIGO_COMPANIA')
        const fn0 = () => axios.get(`${API_URL}/formularios`)
        const fn1 = () => axios.get(`${API_URL}/secciones?resumen=true`)
        const fn2 = () => axios.get(`${API_URL}/variables`)
        const fn3 = () => axios.get(`${API_URL}/companias`)

        Promise.all([fn0(), fn1(), fn2(), fn3()])
		.then((res) => {
            const secciones = {}
            res[1].data.forEach(x => {
                secciones[x.ID] = {
                    ...x,
                    ESTADO: x.RESUMEN ? true : false,
                    VARIABLE: {}
                }
                delete secciones[x.ID].SECCIONES_VARIABLES
            })
            const estructuras = objectifyArray(res[0].data, { by: ['ID'], recursive: true })
            const variables = objectifyArray(res[2].data, { by: ['ID'], recursive: true })
            const companias = objectifyArray(res[3].data, { by: ['CODIGO_COMPANIA'], recursive: false })

            const fn3 = d => d({ type: types.FORMULARIOS_POBLAR_FORMULARIOS, payload: { estructuras, companias } })
            fn3(dispatch)
            const fn4 = d => d({ type: types.FORMULARIOS_POBLAR_SECCIONES, payload: { secciones } })
            fn4(dispatch)
            const fn5 = d => d({ type: types.FORMULARIOS_POBLAR_VARIABLES, payload: { variables } })
            fn5(dispatch)
		})
		.catch((err) => {
            console.log(err)
			displayMessages(dispatch, err)
		})
    }
}

export function displayModalCreate(show, title, mode) {
    return (dispatch) => {
        dispatch({ type: types.FORMULARIOS_DISPLAY_MODAL_CREACION, payload: { show, title, mode } })
    }
}

export function displayModalClone(show, title) {
    return (dispatch) => {
        dispatch({ type: types.FORMULARIOS_DISPLAY_MODAL_CLONACION, payload: { show, title } })
    }
}

export function almacenarFormulario(mode = null) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('estructuras',true))
        const modal = (!mode) ? 'modalCreate' : 'modalClone'
        if (!mode) mode = getState().formularios.getIn(['modalCreate', 'mode'])
        let data = getState().formularios.getIn([modal, 'data'])
        const selected = getState().formularios.get('selected')

        if (mode === 'create') {
            axios.post(`${API_URL}/formularios`, data)
            .then(res => {
                const id = res.data.ID
                const estructura = objectifyArray([res.data], { by: ['ID'], recursive: true })
                dispatch({ type: types.FORMULARIOS_ACTUALIZAR_ESTRUCTURA, payload: estructura[id] })
                displayMessages(dispatch, { success: 'El formulario ha sido guardado exitosamente' })
                dispatch(setSavingState('estructuras',false))
                dispatch(displayModalCreate(false))
            })
            .catch(err => {
                console.log(err)
                dispatch(setSavingState('estructuras',false))
                displayMessages(dispatch, err)
            })
        } else if (mode === 'edit') {
            axios.put(`${API_URL}/formularios/${data.get('ID')}`, data)
            .then(res => {
                const estructura = objectifyArray([res.data], { by: ['ID'], recursive: true })
                dispatch({ type: types.FORMULARIOS_ACTUALIZAR_ESTRUCTURA, payload: estructura[data.get('ID')] })
                displayMessages(dispatch, { success: 'El formulario ha sido guardado exitosamente' })
                dispatch(setSavingState('estructuras',false))
                dispatch(displayModalCreate(false))
            })
            .catch(err => {
                console.log(err)
                dispatch(setSavingState('estructuras',false))
                displayMessages(dispatch, err)
            })
        } else if (mode === 'clone') {
            axios.post(`${API_URL}/formularios/clonar/${selected}`, data)
            .then(res => {
                const id = res.data.ID
                const estructura = objectifyArray([res.data], { by: ['ID'], recursive: true })
                dispatch({ type: types.FORMULARIOS_ACTUALIZAR_ESTRUCTURA, payload: estructura[id] })
                displayMessages(dispatch, { success: 'El formulario ha sido clonado exitosamente' })
                dispatch(setSavingState('estructuras',false))
                dispatch(displayModalClone(false))
            })
            .catch(err => {
                console.log(err)
                dispatch(setSavingState('estructuras',false))
                displayMessages(dispatch, err)
            })
        }
    }
}

export function seleccionarFormulario(id) {
    return (dispatch) => {
        dispatch({ type: types.FORMULARIOS_SELECCIONAR_ESTRUCTURA, payload: id })
    }
}

export function seleccionarSeccionFormulario(id) {
    return (dispatch) => {
        dispatch({ type: types.FORMULARIOS_SELECCIONAR_SECCION_ESTRUCTURA, payload: id })
    }
}
export function seleccionarVariableFormulario(id) {
    return (dispatch) => {
        dispatch({ type: types.FORMULARIOS_SELECCIONAR_VARIABLE_ESTRUCTURA, payload: id })
    }
}

export function removeVariableFromSeccion() {
    return (dispatch, getState) => {
        const selected = getState().formularios.get('selected')
        const selectedSeccion = getState().formularios.get('selectedSeccion')
        const selectedVariable = getState().formularios.get('selectedVariable')
        const path = `estructuras.${selected}.SECCIONES.${selectedSeccion}.VARIABLE`
        dispatch({ type: types.FORMULARIOS_REMOVER_VARIABLE_ESTRUCTURA, payload: { path, selectedVariable } })
        dispatch({ type: types.FORMULARIOS_REORDERNAR_VARIABLES, payload: { path } })
    }
}

export function validateSameCompany() {
    return (dispatch, getState) => {
        const selectedcompania = getState().signin.get('compania')
        const compania = getState().signin.getIn(['user', 'COMPANIAS', selectedcompania])
        const selected = getState().formularios.get('selected')
        const estructura = getState().formularios.getIn(`estructuras.${selected}`.split('.'))
        
        dispatch(almacenarSeccionesFormulario())
    }
}

export function almacenarSeccionesFormulario() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('estructuras',true))
        const selected = getState().formularios.get('selected')
        const secciones = getState().formularios.getIn(`estructuras.${selected}.SECCIONES`.split('.')).valueSeq()
        const data = []
        let resumenSinVariables = false
        let secionesSinVariables = []
        secciones.forEach(x => {
            const itm = seccionVariableEntity(selected)
            itm.SECCION_ID = x.get('ID')
            if (x.get('VARIABLE').valueSeq().count() > 0) {
                x.get('VARIABLE').valueSeq().sortBy(y => y.get('ORDEN_VARIABLE')).forEach(y => {
                    itm.VARIABLE_ID = y.get('ID')
                    itm.ORDEN_SECCION = x.get('ORDEN_SECCION')
                    itm.ORDEN_VARIABLE = y.get('ORDEN_VARIABLE')
                    itm.ESTADO = x.get('ESTADO')
                    data.push({...itm})
                })
            } else {
                if (x.get('RESUMEN')) { resumenSinVariables = true }
                if (x.get('ESTADO')) secionesSinVariables.push(x.get('ABREVIADO'))
            }
        })

        if (resumenSinVariables) { return displayMessages(dispatch, { warning: `La sección de resumen debe tener al menos una variable` }) }

        axios.put(`${API_URL}/formularios/${selected}/secciones`, data)
        .then(res => {
            const id = res.data.ID
            const estructura = objectifyArray([res.data], { by: ['ID'], recursive: true })
            dispatch({ type: types.FORMULARIOS_ACTUALIZAR_ESTRUCTURA, payload: estructura[id] })
            displayMessages(dispatch, { success: 'El formulario ha sido guardada exitosamente' })
            dispatch(setSavingState('estructuras',false))
            secionesSinVariables.forEach(ssv => {
                displayMessages(dispatch, { warning: `La sección ${ssv} fue removida porque no contenía variables` })
            })
        })
        .catch(err => {
            console.log(err)
            dispatch(setSavingState('estructuras',false))
            displayMessages(dispatch, err)
        })
    }
}

export function moverSeccion(direccion) {
    return (dispatch, getState) => {

        const selected = getState().formularios.get('selected')
        const selectedSeccion = getState().formularios.get('selectedSeccion')
        const path = `estructuras.${selected}.SECCIONES`
        const secciones = getState().formularios.getIn(`${path}`.split('.')).valueSeq().sortBy(x => x.get('ORDEN_SECCION')).toList()
        const total = secciones.count()
        const currentPos = getState().formularios.getIn(`${path}.${selectedSeccion}.ORDEN_SECCION`.split('.'))

        if (total > 0 && (direccion === 'up' && currentPos > 1) || (direccion === 'down' && currentPos < total)) {
            const newPos = (direccion === 'up') ? currentPos - 1 : currentPos + 1
            let vecino
            if (direccion === 'up') {
                vecino = secciones.filter(x => x.get('ORDEN_SECCION') < currentPos).last()
            } else {
                vecino = secciones.filter(x => x.get('ORDEN_SECCION') > currentPos).first()
            }
            if (vecino) {
                dispatch({ type: types.FORMULARIOS_MODIFICAR_INPUTS, payload: { path: `${path}.${selectedSeccion}.ORDEN_SECCION`, value: vecino.get('ORDEN_SECCION') } })
                dispatch({ type: types.FORMULARIOS_MODIFICAR_INPUTS, payload: { path: `${path}.${vecino.get('ID')}.ORDEN_SECCION`, value: currentPos } })
            }
            dispatch({ type: types.FORMULARIOS_REORDERNAR_SECCIONES, payload: { path } })
        }
    }
}

export function moverVariable(direccion) {
    return (dispatch, getState) => {

        const selected = getState().formularios.get('selected')
        const selectedSeccion = getState().formularios.get('selectedSeccion')
        const selectedVariable = getState().formularios.get('selectedVariable')
        const path = `estructuras.${selected}.SECCIONES.${selectedSeccion}.VARIABLE`
        const variables = getState().formularios.getIn(`${path}`.split('.')).valueSeq().sortBy(x => x.get('ORDEN_VARIABLE')).toList()
        const total = variables.count()
        const currentPos = getState().formularios.getIn(`${path}.${selectedVariable}.ORDEN_VARIABLE`.split('.'))

        if (total > 0 && (direccion === 'up' && currentPos > 1) || (direccion === 'down' && currentPos < total)) {
            const newPos = (direccion === 'up') ? currentPos - 1 : currentPos + 1
            let vecino
            if (direccion === 'up') {
                vecino = variables.filter(x => x.get('ORDEN_VARIABLE') < currentPos).last()
            } else {
                vecino = variables.filter(x => x.get('ORDEN_VARIABLE') > currentPos).first()
            }
            if (vecino) {
                dispatch({ type: types.FORMULARIOS_MODIFICAR_INPUTS, payload: { path: `${path}.${selectedVariable}.ORDEN_VARIABLE`, value: vecino.get('ORDEN_VARIABLE') } })
                dispatch({ type: types.FORMULARIOS_MODIFICAR_INPUTS, payload: { path: `${path}.${vecino.get('ID')}.ORDEN_VARIABLE`, value: currentPos } })
            }
            dispatch({ type: types.FORMULARIOS_REORDERNAR_VARIABLES, payload: { path } })
        }
    }
}

export function eliminarFormulario() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        let toDelete = getState().formularios.get('selected')
        if (toDelete) {
            axios.delete(`${API_URL}/formularios/${toDelete}`)
            .then(res => {
                dispatch({ type: types.FORMULARIOS_ELIMINAR_ESTRUCTUDA, payload: toDelete })
                displayMessages(dispatch, { success: `Se han eliminado el formulario exitosamente` })
            })
            .catch(err => {
                console.log(err)
                displayMessages(dispatch, err)
            })
        }
    }
}

export function selectFromSelector(payload) {
    return (dispatch, getState) => {
        const selected = getState().formularios.get('selected')
        const selectedSeccion = getState().formularios.get('selectedSeccion')
        const secciones = getState().formularios.getIn(['estructuras', String(selected), 'SECCIONES']).filter(x => !x.get('RESUMEN')).valueSeq().toList()
        const isResumen = getState().formularios.getIn(['estructuras', String(selected), 'SECCIONES', String(selectedSeccion), 'RESUMEN'])
        let uses = 0

        secciones.forEach(x => {
            let path = `estructuras.${selected}.SECCIONES.${x.get('ID')}.VARIABLE`
            const variablesSeccion = getState().formularios.getIn(path.split('.'))
            if (variablesSeccion) {
                uses +=  variablesSeccion.valueSeq().filter(v => v.get('ID') === payload.value.ID).count()
            }
        })

        if (uses > 0 && !isResumen) {
            displayMessages(dispatch, { warning: `La variable ${payload.value.NOMBRE_VARIABLE} ya ha sido adicionada a otra sección` })
        } else {
            dispatch({ type: types.FORMULARIOS_ADICIONAR_VARIABLE_SECCION, payload})
        }
    }
}