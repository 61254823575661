import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import moment from "moment";
import numeral from "numeral";
import Input from '@mawesi-sas/mws_react_component_input';

import Box from "../Layout/components/Box";
import ButtonStrip from "../Layout/components/ButtonStrip";
import BoxButton from "../Layout/components/BoxButton";
import ModalCreate from "./components/ModalCreate";
import ModalScriptResponse from "./components/ModalScriptResponse";

import {
  displayModalCreate,
  obtenerScripts,
  seleccionarScript,
  eliminarScripts,
  conmutarSeleccionRegistros,
  filtrarScripts,
  ordenarPor,
  executeScript,
    displayModalScriptResponse,
    downloadScript
} from "./actions";
import { showModalConfirmAction } from "../Layout/actions";
import BoxSection from "../Layout/components/BoxSection";

import py from "../../img/py.png";
import r from "../../img/R.png";
import { API_URL } from "../../utils/constants";

export const tableColumns = [
  {
    ID: 0,
    CAPTION: "",
    VALUE: "NOMBRE",
    TYPE: "command",
    FORMAT: "",
    WIDTH: "64"
  },
  {
    ID: 1,
    CAPTION: "Nombre",
    VALUE: "NOMBRE",
    TYPE: "string",
    FORMAT: "",
    LINK: true
  },
  {
    ID: 2,
    CAPTION: "Descripción",
    VALUE: "DESCRIPCION",
    TYPE: "string",
    FORMAT: ""
  },
    //{ ID: 3, CAPTION: "Shiny", VALUE: "SHINY", TYPE: "bool", FORMAT: "" },
  { ID: 4, CAPTION: "Autor", VALUE: "AUTOR", TYPE: "string", FORMAT: "" },
  {
    ID: 5,
    CAPTION: "Última actualización",
    VALUE: "FECHA_MOD",
    TYPE: "datetime",
    FORMAT: "YYYY-MM-DD HH:mm"
  },
  {
    ID: 6,
    CAPTION: "Última ejecución",
    VALUE: "ULTIMA_EJECUCION",
    TYPE: "datetime",
    FORMAT: "YYYY-MM-DD HH:mm"
    }
];

export function findValues(c, x) {
  let value = x.getIn(c.VALUE.split("."));
  return value;
}

class Scripts extends Component {
  componentWillMount() {
    this.props.obtenerScripts();
  }

  render() {
    if (!this.props.elementos) return null;
    const { scripts, selected, termino, sortBy, asc } = this.props;

    let records = scripts
      .valueSeq()
      .toList()
      .filter(
        x =>
          x
            .get("NOMBRE")
            .toLowerCase()
            .indexOf(termino.toLowerCase()) > -1
      )
      .sortBy(x => x.getIn(sortBy.split(".")) || "");
    if (!asc) records = records.reverse();

    const buttons = [];
    if (this.props.elementos.filter(x => x === "cdh9HQ0F").count())
      buttons.push(
        <BoxButton
          key={`action[2][0]`}
          name="plus"
          onClick={e =>
            this.props.displayModalCreate(
              true,
              "Creación de nuevo script",
              "create"
            )
          }
          title="Nueva script"
        />
      );
    if (this.props.elementos.filter(x => x === "cd0hu1wA").count())
      buttons.push(
        <BoxButton
          key={`action[2][1]`}
          name="trash"
          onClick={e =>
            this.props.showModalConfirmAction(
              true,
              "Está seguro que desea eliminar los registros seleccionados?",
              () => this.props.eliminarScripts()
            )
          }
          title="Eliminar script"
          disabled={
            scripts.filter(x => x.get("SELECTED") === true).count() === 0
          }
          danger
        />
      );

    return (
      <div id="scripts"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <Box title="Scripts" stretch>
          <div className="row" style={{ height: "100%" }}>
            <div className="col-md-12" style={{ height: "100%" }}>
              <div className="row" style={{ height: "100%" }}>
                <div
                  className="col-md-12"
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <div
                    className="widget"
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginBottom: "0"
                    }}
                  >
                    <BoxSection title="Listado de scripts">
                      <div style={{ padding: "0 5px", marginBottom: "10px" }}>
                        <ButtonStrip
                          actions={buttons}
                          searchBar={this.props.filtrarScripts}
                          filtro={termino}
                        />
                      </div>
                      <div className="table-scrolled">
                        <table className="table table-condensed table-striped table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "25px" }}>
                                <input
                                  type="checkbox"
                                  checked={
                                    scripts.count() ===
                                    scripts
                                      .filter(x => x.get("SELECTED"))
                                      .count()
                                  }
                                  onChange={e =>
                                    this.props.conmutarSeleccionRegistros(
                                      e.target.checked
                                    )
                                  }
                                />
                              </th>
                              {tableColumns.map(x => {
                                const style = {
                                  width: `${x.WIDTH}`,
                                  cursor: "pointer"
                                };
                                return (
                                  <th
                                    key={`th1[${x.ID}]`}
                                    style={style}
                                    onClick={() =>
                                      this.props.ordenarPor(x.VALUE)
                                    }
                                  >
                                    {x.CAPTION}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {records.count() > 0 ? (
                              records.map((a, b) => {
                                const path = `scripts.${a.get("ID")}`;
                                return (
                                  <tr
                                    key={`tr2[${a.get("ID")}]`}
                                    className={a.get("SELECTED") ? "info" : ""}
                                  >
                                    <td>
                                      <Input
                                        type="checkbox"
                                        reducer="scripts"
                                        path={`${path}.SELECTED`}
                                      />
                                    </td>
                                    {tableColumns.map((c, d) => {
                                      let alignment = "left";
                                      let value = findValues(c, a);
                                      let scriptType = [".py"].includes(
                                        a.get("NOMBRE").substr(-3)
                                      )
                                        ? "py"
                                        : [".R"].includes(
                                            a.get("NOMBRE").substr(-2)
                                          ) ||
                                          [".Rmd"].includes(
                                            a.get("NOMBRE").substr(-4)
                                          )
                                        ? "r"
                                        : null;

                                      if (c.TYPE) {
                                        switch (c.TYPE) {
                                          case "datetime":
                                            if (value) {
                                              value = moment(value).format(
                                                c.FORMAT
                                              );
                                              alignment = "right";
                                            }
                                            break;
                                          case "bool":
                                            if (typeof value === "string")
                                              value = parseInt(value, 10);
                                            value = (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "1.1em"
                                                }}
                                              >
                                                <i
                                                  style={{ color: "#41afbe" }}
                                                  className={`fa fa-${
                                                    value ? "check-" : ""
                                                  }square`}
                                                />
                                              </div>
                                            );
                                            break;
                                          case "color":
                                            let icon = "circle";
                                            if (value === null)
                                              icon = "circle-thin";
                                            value = (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "1.1em"
                                                }}
                                              >
                                                <i
                                                  style={{
                                                    color: value,
                                                    textShadow:
                                                      "0px 0px 1px #555"
                                                  }}
                                                  className={`fa fa-${icon}`}
                                                />
                                              </div>
                                            );
                                            break;
                                          case "number":
                                            value =
                                              value !== null
                                                ? numeral(value).format(
                                                    c.Format
                                                  )
                                                : null;
                                            alignment = "right";
                                            break;
                                          case "command":
                                                let scriptname = `${value}`;
                                                const dwnLink = (<a href="/" title="Descargar" style={{ cursor: "pointer" }} onClick={e => {
                                                    e.preventDefault();
                                                    this.props.downloadScript(
                                                        a.get("NOMBRE")
                                                    );
                                                }}>
                                                    <FontAwesome name="cloud-download" />
                                                </a>)
                                                let runLink = null;
                                                let execLink = null;
                                            if (this.props.elementos.filter(el => el === "9C8TVReS").count() && ![".Rmd"].includes(scriptname.substr(-4))) {
                                                runLink = (
                                                <a
                                                  href="/"
                                                  title="Ejecutar"
                                                        style={{ cursor: "pointer", marginLeft: "5px", }}
                                                  onClick={e => {
                                                    e.preventDefault();
                                                    this.props.executeScript(
                                                      scriptname
                                                    );
                                                  }}
                                                >
                                                  <FontAwesome name="play" />
                                                </a>
                                              );
                                            }
                                            if (this.props.elementos.filter(el => el === "9C8TVReS").count() && !a.get('SHINY') && a.get("ULTIMA_EJECUCION")) {
                                                execLink = (
                                                  <a
                                                    href="/"
                                                    title="Visualizar"
                                                    style={{
                                                      cursor: "pointer",
                                                      marginLeft: "5px",
                                                      color: "#777"
                                                    }}
                                                    onClick={e => {
                                                      e.preventDefault();
                                                      this.props.displayModalScriptResponse(
                                                        true,
                                                        `<iframe src="${API_URL.replace(
                                                          "/api",
                                                          `/run/${scriptType}/${a.get(
                                                            "ID"
                                                          )}`
                                                        )}" width="100%" height="100%" style="border: none"></iframe>`
                                                      );
                                                    }}
                                                  >
                                                    <FontAwesome name="window-maximize" />
                                                  </a>
                                              );
                                            }
                                            value = <span>{dwnLink}{runLink}{execLink}</span>
                                            break;
                                          default:
                                        }
                                      }
                                      const style = { textAlign: alignment };
                                      if (c.WIDTH) {
                                        style.width = `${c.WIDTH}px`;
                                      }
                                      let imgsrc = null;

                                      if (
                                        c.LINK &&
                                        this.props.elementos
                                          .filter(e => e === "cdorhBkK")
                                          .count()
                                      ) {
                                        if (scriptType === "py") imgsrc = py;
                                        if (scriptType === "r") imgsrc = r;

                                        return (
                                          <td
                                            key={`td1[${a.get("ID")}][${c.ID}]`}
                                            className="LinkToEdit"
                                            style={style}
                                            onClick={e =>
                                              this.props.displayModalCreate(
                                                true,
                                                "Modificación de scripts",
                                                "edit",
                                                a.get("ID")
                                              )
                                            }
                                          >
                                            <div>
                                              <img
                                                alt="icon"
                                                src={imgsrc}
                                                height="16px"
                                                style={{ marginRight: "3px" }}
                                              />
                                              <span>{value}</span>
                                            </div>
                                          </td>
                                        );
                                      } else {
                                        return (
                                          <td
                                            key={`td1[${a.get("ID")}][${c.ID}]`}
                                            style={style}
                                          >
                                            {value}
                                          </td>
                                        );
                                      }
                                    })}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={tableColumns.length + 1}
                                  style={{ textAlign: "center" }}
                                >
                                  No existen registros
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </BoxSection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <ModalCreate />
        <ModalScriptResponse />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    scripts: state.scripts.get("scripts"),
    selected: state.scripts.get("selected"),
    termino: state.scripts.get("filtro"),
    sortBy: state.scripts.get("sortBy"),
    asc: state.scripts.get("asc"),
    elementos: state.layout.get("elementos")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    obtenerScripts: () => dispatch(obtenerScripts()),
    displayModalCreate: (show, title, mode, id) => dispatch(displayModalCreate(show, title, mode, id)),
    seleccionarScript: id => dispatch(seleccionarScript(id)),
    eliminarScripts: () => dispatch(eliminarScripts()),
    conmutarSeleccionRegistros: checked => dispatch(conmutarSeleccionRegistros(checked)),
    filtrarScripts: filtro => dispatch(filtrarScripts(filtro)),
    showModalConfirmAction: (show, message, action) => dispatch(showModalConfirmAction(show, message, action)),
    ordenarPor: columna => dispatch(ordenarPor(columna)),
    executeScript: script => dispatch(executeScript(script)),
    displayModalScriptResponse: (show, response) => dispatch(displayModalScriptResponse(show, response)),
    downloadScript: (id) => dispatch(downloadScript(id))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Scripts);
