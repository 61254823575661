import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import Modal from '@mawesi-sas/mws_react_component_modal'
import Input from '@mawesi-sas/mws_react_component_input'

import {
    displayModalCreate,
    almacenarSeccion
} from '../actions';

class ModalCreate extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalCreate')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalCreate .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')
        setTimeout(() => this.props.displayModalCreate(false), 300)
    }

    modalContent = (modalCreate, saving) => {
        return (
            <form id="form" onSubmit={e => { e.preventDefault(); this.props.almacenarSeccion() }} noValidate>
                <div className="buttonstrip">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1 }}>
                            <button className="box-button" title="Guardar" disabled={saving} ><FontAwesome name="floppy-o" tag="i" /></button>
                        </div>
                    </div>
                </div>
                <div className="modal-dialog" style={{ width: '90%' }}>
                    <div className="row">
                        <div className="col-md-7 col-sm-7 col-xs-12">
                            <div className="row">
                                <div className="col-md-9 col-sm-9 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Nombre abreviado</label>
                                        <Input type="text" reducer="secciones" path="modalCreate.data.ABREVIADO" maxLength={100} />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3 xol-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Color</label>
                                        <Input type="color" reducer="secciones" path="modalCreate.data.COLOR" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label className="control-label">Nombre completo</label>
                                        <Input type="textarea" reducer="secciones" path="modalCreate.data.TITULO" maxLength={1000} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-5 col-xs-12">
                            <div className="form-group">
                                <div style={{ background: '#f2f2f2', border: '1px solid #ddd', borderRadius: '4px', padding: '5px 10px' }}>
                                    <label className="control-label" style={{ margin: '5px 0' }}>Opciones</label>
                                    <div>
                                        <div style={{ borderBottom: '1px solid rgb(230, 230, 230)', padding: '4px 0' }}>
                                            <Input
                                                type="switch"
                                                reducer="secciones"
                                                path="modalCreate.data.ESTADO"
                                                label='Activo'
                                                commentario='Activar la sección'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        const { modalCreate, saving } = this.props
        if (!modalCreate.get('show')) return null

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "73%" : "53%"}
                mostrar={modalCreate.get('show')}
                contenido={this.modalContent(modalCreate, saving)}
                titulo={modalCreate.get('title')}
                nombre="modalCreate"
                onCerrarModal={(e) => this.handleCloseModal(e)}
            />
        )
    }
}

const mapStateToProps = (state, onwProps) => {
    return {
        modalCreate: state.secciones.get('modalCreate'),
        tablas: state.secciones.get('tablas'),
        saving: state.secciones.get('saving'),
        width: state.layout.get('width'),
        height: state.layout.get('height'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayModalCreate: (show) => dispatch(displayModalCreate(show)),
        almacenarSeccion: () => dispatch(almacenarSeccion()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreate)
