import React, {
    Fragment
} from 'react';
import ReactDOM from 'react-dom';
import {
    Provider
} from 'react-redux';
import {
    Router
} from 'react-router';
import {
    ToastContainer
} from 'react-toastify';
import Cookies from 'universal-cookie';

import Routes from './routes.jsx'
import {
    SIGNIN_INICIAR_SESION,
    SIGNIN_CERRAR_SESION,
    SIGNIN_ASIGNAR_USUARIO_SESION
} from './types'
import {
    getUserFromToken
} from './containers/SignIn/actions'
import * as serviceWorker from './serviceWorker';

import history from './utils/history'
import store from './utils/store'
import './utils/polyfills.js'

import './css/animate.css'
import './css/bootstrap.css'
import './css/bootstrap.min.css'
import './css/custom-toastify.css'
import './css/font-awesome.min.css'
import './css/fonts.css'
import './css/layout.css'
import './css/react-select.css'
import './css/responsive.css'
import '@mawesi-sas/mws_react_component_modal/dist/css/styles.css'
//import 'react-toas'tify/dist/ReactToastify.min.css
import MsgBox from './containers/Layout/components/MsgBox';
import ModalWait from './containers/Layout/components/ModalWait';
import ModalConfirmAction from './containers/Layout/components/ModalConfirmAction';
import ModalSelector from './containers/Layout/components/ModalSelector';

const cookies = new Cookies()
const token = cookies.get('token')

if (token) {
    const d = store.dispatch
    d({
        type: SIGNIN_INICIAR_SESION
    })
    getUserFromToken().then(data => {
        d({
            type: SIGNIN_ASIGNAR_USUARIO_SESION,
            payload: data
        })
    }).catch(err => {
        console.log(err)
        store.dispatch({
            type: SIGNIN_CERRAR_SESION
        })
        history.push('/signin')
    })
}

window.root = window.document.getElementById('root')

ReactDOM.render( <
    Provider store = {
        store
    } >
    <
    Fragment >
    <
    Router history = {
        history
    } > {
        Routes
    } <
    /Router> <
    ToastContainer toastClassName = "toastify" / >
    <
    MsgBox / >
    <
    ModalWait / >
    <
    ModalConfirmAction / >
    <
    ModalSelector / >
    <
    /Fragment> <
    /Provider>,
    window.root
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();