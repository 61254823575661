import {
    guid
} from "./helpers";

export const API_URL = window.API_URL;
export const CLIENT_ROOT_URL = window.CLIENT_ROOT_URL;

export const modulos = [{
        ID: 1,
        ICON: 'home',
        CAPTION: 'Inicio',
        IS_ACTIVE: false,
        IS_OPEN: false,
        URL: '/',
        SUBITEMS: [],
        ROLES: [],
        VISIBLE: true
    },
    {
        ID: 2,
        ICON: 'cogs',
        CAPTION: 'Configuración',
        IS_ACTIVE: false,
        IS_OPEN: false,
        URL: false,
        TITLE: '',
        SUBITEMS: [{
                ID: 3,
                CAPTION: 'Maestras',
                IS_ACTIVE: false,
                URL: '/maestras',
                ROLES: [],
                VISIBLE: true
            },
            {
                ID: 4,
                CAPTION: 'Variables',
                IS_ACTIVE: false,
                URL: '/variables',
                ROLES: [],
                VISIBLE: true
            },
            {
                ID: 5,
                CAPTION: 'Parámetros',
                IS_ACTIVE: false,
                URL: '/parametros',
                ROLES: [],
                VISIBLE: true
            },
            {
                ID: 6,
                CAPTION: 'Secciones',
                IS_ACTIVE: false,
                URL: '/secciones',
                ROLES: [],
                VISIBLE: true
            },
            {
                ID: 7,
                CAPTION: 'Formularios',
                IS_ACTIVE: false,
                URL: '/formularios',
                ROLES: [],
                VISIBLE: true
            }
        ],
        ROLES: [],
        VISIBLE: true
    },
    {
        ID: 8,
        ICON: 'cubes',
        CAPTION: 'Formularios',
        IS_ACTIVE: false,
        IS_OPEN: false,
        URL: false,
        TITLE: '',
        SUBITEMS: [{
                ID: 9,
                CAPTION: 'Cargue masivo',
                IS_ACTIVE: false,
                URL: '/formularios/cargue',
                ROLES: [],
                VISIBLE: true
            },
            {
                ID: 10,
                CAPTION: 'Bitácora',
                IS_ACTIVE: false,
                URL: '/bitacora',
                ROLES: [],
                VISIBLE: true
            },
            {
                ID: 9,
                CAPTION: 'Llenado de formularios',
                IS_ACTIVE: false,
                URL: '/registros/formularios',
                ROLES: [],
                VISIBLE: true
            }
        ],
        ROLES: [],
        VISIBLE: true
    },
    {
        ID: 13,
        ICON: 'user',
        CAPTION: 'Gestión de usuarios',
        IS_ACTIVE: false,
        IS_OPEN: false,
        URL: '/',
        SUBITEMS: [],
        ROLES: [],
        VISIBLE: true
    },
]

export const paginationSize = 20

export const tiposCampoMaestras = [{
        value: 'Caja texto',
        caption: 'Caja de texto'
    },
    {
        value: 'Area texto',
        caption: 'Area de texto'
    },
    {
        value: 'Lista maestra',
        caption: 'Lista maestra'
    },
    {
        value: 'Panel color',
        caption: 'Panel de color'
    },
]

export const tiposCampo = [{
        value: 'Caja texto',
        caption: 'Caja de texto'
    },
    {
        value: 'Area texto',
        caption: 'Area de texto'
    },
    {
        value: 'Lista maestra',
        caption: 'Lista maestra'
    },
    {
        value: 'Panel color',
        caption: 'Panel de color'
    },
    {
        value: 'Imagen',
        caption: 'Imagen'
    },
    {
        value: 'Ubicacion',
        caption: 'Ubicación'
    },
]

export const tiposDato = [{
        value: 'Alfanumerico',
        caption: 'Alfanumérico'
    },
    {
        value: 'Numerico',
        caption: 'Numérico'
    },
    {
        value: 'Fecha',
        caption: 'Fecha'
    },
]

export const siNoOptions = [{
        value: false,
        caption: 'NO'
    },
    {
        value: true,
        caption: 'SI'
    },
]

export const acceptedFiles = [{
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    icon: 'xlsx.png'
}, ]

export const lecturaRegistros = [{
        value: 'todas',
        caption: 'Todas'
    },
    {
        value: 'solo_mi_empresa',
        caption: 'Sólo de mi Empresa'
    },
    {
        value: 'solo_yo',
        caption: 'Sólo las mías'
    },
]

export const maestraEntity = () => {
    return {
        GUID: guid(),
        NOMBRE: '',
        DESCRIPCION: '',
        ENT_CONFIG_MAESTRAS: [maestraConfigEntity(1, 1)]
    }
}

export const maestraConfigEntity = (order, campo) => {
    return {
        GUID: guid(),
        NOMBRE: '',
        DESCRIPCION: '',
        LISTA_MAESTRA_ID: -1,
        TIPO_CAMPO: -1,
        TIPO_DATO: -1,
        UNICO: 'NO',
        ORDER_KEY: order,
        SELECTED: false,
        CAMPO: campo,
    }
}

export const maestraDetalleEntity = (maestraId, order) => {
    return {
        GUID: guid(),
        MAESTRA_ID: maestraId,
        CAMPO1: '',
        CAMPO2: '',
        CAMPO3: '',
        CAMPO4: '',
        CAMPO5: '',
        CAMPO6: '',
        ESTADO: 1,
        ORDER_KEY: order,
        UPDATED: true,
        SELECTED: false,
    }
}

export const variableEntity = () => {
    return {
        GUID: guid(),
        NOMBRE_VARIABLE: '',
        ESTADO: true,
        VALORES_PERMITIDOS: '',
        AYUDA: true,
        TIPO_DATO: -1,
        TAMANO: null,
        EDITABLE: true,
        REQUERIDO: false,
        BITACORA: false,
        CAMPO_DELTA: '',
        TIPO_CAMPO: -1,
        MULTIPLE: false,
        FORMULA: '',
        AYUDA_CAMPO: ''
    }
}

export const seccionEntity = (order) => {
    return {
        GUID: guid(),
        TITULO: '',
        ABREVIADO: '',
        COLOR: '',
        ESTADO: true,
        TABLA: false,
        ORDER_KEY: order
    }
}

export const estructuraEntity = () => {
    return {
        GUID: guid(),
        CIA: '',
        NOMBRE: '',
        DESCRIPCION: '',
        ESTADO: true
    }
}

export const seccionVariableEntity = (estructuraId) => {
    return {
        SECCION_ID: null,
        VARIABLE_ID: null,
        ESTRUCTURA_ID: estructuraId,
        ORDEN_VARIABLE: null,
    }
}

export const articuloEntity = () => {
    return {
        GUID: guid(),
    }
}

export const parametroEntity = () => {
    return {
        GUID: guid(),
        NOMBRE: '',
        DESCRIPCION: '',
        CONFIGURACIONES: [parametroConfigEntity(1, 1)]
    }
}

export const parametroConfigEntity = (posicion) => {
    return {
        GUID: guid(),
        NOMBRE: '',
        DESCRIPCION: '',
        POSICION: posicion,
        SELECTED: false,
    }
}

export const parametroDetalleEntity = (columnas, registro) => {
    const output = {
        REGISTRO: registro,
        SELECTED: false
    }
    columnas.forEach(x => {
        output[x.get('ID')] = {
            GUID: guid(),
            ID: null,
            UPDATED: true,
            VALOR: '',
            PARAMETRO_ID: x.get('PARAMETRO_ID'),
            CONFIG_PARAMETRO_ID: x.get('CONFIG_PARAMETRO_ID')
        }
    })
    return output
}

export const usuarioEntity = () => ({
    USERNAME: '',
    NOMBRES: '',
    APELLIDOS: '',
    EMAIL: '',
    EMPRESA: '',
    ACTIVO: false,
    ROL_ID: 0
})

export const rolEntity = () => ({
    ID: null,
    GUID: guid(),
    NOMBRE: '',
    DESCRIPCION: '',
    PERMISOS: {},
    USERS: null
})

export const scriptEntity = () => {
    return {
        GUID: guid(),
        NOMBRE: '',
        DESCRIPCION: '',
        AUTOR: '',
        ARGUMENTOS: [argsScriptEntity(1)]
    }
}

export const argsScriptEntity = (posicion) => {
    return {
        GUID: guid(),
        NOMBRE: '',
        VALOR: '',
        POSICION: posicion,
        SELECTED: false,
    }
}

export const fuenteEntity = () => {
    return {
        GUID: guid(),
        NOMBRE: '',
        DESCRIPCION: '',
        CARPETA: '',
        file: null
    }
}

export const folderEntity = () => {
    return {
        GUID: guid(),
        NOMBRE: ''
    }
}