import * as types from '../../types'
import {
    API_URL
} from '../../utils/constants'
import {
    createAxiosInstance, uniqueArray
} from '../../utils/helpers'
import {
    displayMessages,
    setSavingState,
    modifyInputs
} from '../Layout/actions'
import objectifyArray from 'objectify-array'
import history from '../../utils/history'

export function obtenerScripts() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const userScriptsArr = getState().layout.get('scripts').toJS()

        // const fn1 = () => axios.get(`${API_URL}/parametros?nombre=VISUALIZACION_RESULTADO_SCRIPTS`)
        // Promise.all([fn1()])
        //     .then((res) => {
        //         const userRole = getState().signin.getIn('user.ROLE.NOMBRE'.split('.'))
        //         const scriptsRole = objectifyArray(res[0].data, { by: ['ROL'], recursive: true })
        //         let userScripts = ''
        //         if (userRole === "Administrador") {
        //             let first = true
        //             Object.keys(scriptsRole).forEach(x => {
        //                 userScripts += (first ? '' : ',') + scriptsRole[x].SCRIPT
        //                 first = false
        //             })
        //         } else {
        //             userScripts = scriptsRole[userRole] ? scriptsRole[userRole].SCRIPT : ''
        //         }
                // const userScriptsArr = uniqueArray(userScripts.split(',').map(x => x.trim()))
                dispatch({ type: types.HOME_POBLAR_SCRIPTS, payload: userScriptsArr })
                if (userScriptsArr.length > 0) {
                    dispatch(modifyInputs('home', 'script', userScriptsArr[0]))
                    // dispatch(seleccionarScript(userScriptsArr[0]))
                } else {
                    dispatch(setLoading(false))
                }
            // })
            // .catch((err) => {
            //     console.log(err)
            //     displayMessages(dispatch, err)
            // })
    }
}

export function seleccionarScript(script = null) {
    history.push('/')
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setLoading(true))
        if (!script) {
            script = getState().home.get('script')
        } else {
            dispatch(modifyInputs('home', 'script', script))
        }
        if (script) {
            let scriptType = script.substr(-3) === '.py' ? 'python' : script.substr(-2).toUpperCase() === '.R' ? 'r' : 'r'
            const fn1 = () => axios.get(`${API_URL}/${scriptType}/run/${script.split('.').slice(0, -1).join('.')}`)
            Promise.all([fn1()])
            .then(res => {
                let url = res[0].data.URL_RESPONSE
                dispatch({ type: types.HOME_OBTENER_IFRAME_URL, payload: url })
                dispatch(setLoading(false))
            })
            .catch(err => {
                console.log(err)
                displayMessages(dispatch, err)
                dispatch(setLoading(false))
            })
        } else {
            dispatch(setLoading(false))
        }
    }
}

export function setLoading(loading) {
    return (dispatch) => {
        dispatch({ type: types.HOME_SETEAR_LOADING, payload: loading })
    }
}