import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import numeral from 'numeral'
import Pagination from 'react-js-pagination'
import Input from '@mawesi-sas/mws_react_component_input'

import history from '../../utils/history'
import Box from '../Layout/components/Box'
import BoxSection from '../Layout/components/BoxSection'
import ButtonStrip from '../Layout/components/ButtonStrip'
import BoxButton from '../Layout/components/BoxButton'
// import ShowRecords from '../Layout/components/ShowRecords'
import ModalDownload from './components/ModalDownload'
import { addTitleIfOverflow } from '../../utils/helpers';

import {
    obtenerRegistros,
    seleccionarRegistro,
    eliminarRegistros,
    conmutarSeleccionRegistros,
    reiniciarEstado,
    displayModalDownload,
    cambiarPagina,
    ordenarPor,
    changeRecordsToShow,
    showHideModalFilter,
    executeScript,
    displayModalImagen
} from './actions'

import { showModalConfirmAction } from '../Layout/actions'
import ModalFilter from './components/ModalFilter'
import ModalScriptResponse from "./components/ModalScriptResponse";
import Filtros from './components/Filtros'
import ModalImagen from './components/ModalImagen'

export function findValues(c, x) {
    let value = x.getIn(c.VALUE.split('.'))
    return value
}

class Registros extends Component {
    componentWillMount() {
        this.props.obtenerRegistros(1, this.props.match.params.id)
    }

    render() {
        if (!this.props.elementos) return null
        const { registros, records, tableColumns, page, size, selectedFormulario } = this.props

        let verDetalleRegistro = false
        const moduloRegistros = this.props.user.get('MODULOS').filter(m => m.get('NOM_MODULO') === 'Formularios')

        if (moduloRegistros.count()) {
            verDetalleRegistro = moduloRegistros.first().get('SUBMODULOS').filter(s => s.get('NOM_MODULO') === 'Ver detalle registro').count() === 1
        }
        const pagination = <Pagination
            activePage={page}
            itemsCountPerPage={size}
            totalItemsCount={records.count()}
            onChange={(pg) => this.props.cambiarPagina(pg)}
            firstPageText={<FontAwesome name="angle-double-left" />}
            prevPageText={<FontAwesome name="angle-left" />}
            nextPageText={<FontAwesome name="angle-right" />}
            lastPageText={<FontAwesome name="angle-double-right" />}
            pageRangeDisplayed={this.props.width < 1000 ? 5 : 10}
        />

        const buttons = []
        // buttons.push(<BoxButton key={`action[2][0]`} name="refresh" onClick={e => this.props.obtenerRegistros(1, this.props.match.params.id)} title="Recargar registros formulario" />)
        if (this.props.elementos.filter(x => x === "7N8rOpnL").count()) buttons.push(<BoxButton key={`action[2][1]`} name="plus" onClick={e => this.props.history.push(`/formularios/${this.props.match.params.id}/registros/`)} title="Nuevo registro formulario" />)
        if (this.props.elementos.filter(x => x === "3L1m1N4R").count()) buttons.push(
            <BoxButton key={`action[2][2]`} name="trash" title="Eliminar formulario"
                onClick={e => this.props.showModalConfirmAction(true, "¿Está seguro que desea eliminar los registros seleccionados?", () => this.props.eliminarRegistros(this.props.match.params.id))}
                disabled={registros.filter(x => x.get('SELECTED') === true).count() === 0} danger />
        )
        if (this.props.elementos.filter(x => x === "pUXvTZpl").count()) buttons.push(<BoxButton key={`action[2][3]`} name="file-excel-o" onClick={e => this.props.displayModalDownload(true, 'Selección de campos - Reporte formulario')} title="Descargar reporte" />)
        buttons.push(<BoxButton key={`action[2][4]`} name="filter" onClick={() => this.props.showHideModalFilter(true)} title="Filtrar" />)

        if (this.props.elementos.filter(x => x === "9C8TVReS").count()) {
            buttons.push(<Input
                key={`action[2][5]`}
                type='select'
                reducer='registros'
                path='script'
                options={this.props.scripts.map(x => { return { caption: x, value: x } }).toJS()}
                style={{width: '195px', marginTop: '2px' }}
            />)
            buttons.push(<BoxButton key={`action[2][6]`} name="play" onClick={e => this.props.executeScript()} title="Ejecutar script" disabled={!!!this.props.script}/>)
        }

        return (
            <div id="registros" style={{ height: '100%', overflowY: 'auto', overflowX: "hidden" }}>
                <Filtros formularioId={this.props.match.params.id} />
                <Box title={selectedFormulario && selectedFormulario.get('NOMBRE')} stretch>
                    <div className="row" style={{ height: '100%', position: "relative", zIndex: 1 }}>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="row" style={{ height: '100%' }}>
                                <div className="col-md-12 col-sm-12 col-xs-12" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <BoxSection title={<span>Listado registros</span>}>
                                        <div style={{ padding: "0px 5px", marginBottom: "10px" }}>
                                            <ButtonStrip
                                                actions={buttons}
                                                showRecords={size}
                                                totalRecords={records.count()}
                                                changeRecordsToShow={this.props.changeRecordsToShow}
                                                pagination={pagination}
                                            />
                                        </div>
                                        <div className="table-scrolled">
                                            <table className="table table-condensed table-striped table-bordered table-hover" id="tabla-registros">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '25px' }}><input type="checkbox" checked={registros.count() === registros.filter(x => x.get('SELECTED')).count()} onChange={e => this.props.conmutarSeleccionRegistros(e.target.checked)} /></th>
                                                        {
                                                            tableColumns.map(x => {
                                                                return (
                                                                    <th key={`th1[${x.ID}]`} style={{ background: x.COLOR, cursor: 'pointer' }} onClick={() => this.props.ordenarPor(x.VALUE)}>{x.CAPTION}</th>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        records.count() > 0 ? records.toJS().slice(((page - 1) * size), (page * size)).map(b => {
                                                            const a = registros.get(b)
                                                            const path = `registros.${a.get('ID')}`
                                                            const activo = (a.get('ESTADO')) ? '' : 'inactivo'
                                                            return (
                                                                <tr key={`tr2[${a.get('ID')}]`} className={`${a.get('SELECTED') ? 'info' : ''} ${activo}`}>
                                                                    <td><Input type="checkbox" reducer="registros" path={`${path}.SELECTED`} /></td>
                                                                    {
                                                                        tableColumns.map((c, d) => {
                                                                            let alignment = 'left'
                                                                            let value = findValues(c, a)
                                                                            if (c.TYPE) {
                                                                                switch (c.TYPE) {
                                                                                    case 'datetime':
                                                                                        value = moment(value).format(c.FORMAT)
                                                                                        alignment = 'right'
                                                                                        break
                                                                                    case 'bool':
                                                                                        if (typeof value === 'string') value = parseInt(value, 10)
                                                                                        value = <div style={{ textAlign: 'center', fontSize: '1.1em' }}><i style={{ color: '#41afbe' }} className={`fa fa-${value ? 'check-' : ''}square`} /></div>
                                                                                        break
                                                                                    case 'color':
                                                                                        let icon = 'circle'
                                                                                        if (value === null) icon = 'circle-thin'
                                                                                        value = <div style={{ textAlign: 'center', fontSize: '1.1em' }}><i style={{ color: value, textShadow: '0px 0px 1px #555' }} className={`fa fa-${icon}`} /></div>
                                                                                        break
                                                                                    case 'number':
                                                                                        value = value !== null ? numeral(value).format(c.FORMAT) : null
                                                                                        alignment = 'right'
                                                                                        break
                                                                                    default:
                                                                                }
                                                                            }
                                                                            const style = { textAlign: alignment }

                                                                            if (d === 0 && verDetalleRegistro) {
                                                                                return (
                                                                                    <td key={`td1[${a.get('ARTICULO')}][${c.ID}]`} className='LinkToEdit' style={style} onClick={() => history.push(`/formularios/${this.props.match.params.id}/registros/${a.get('ID')}`)}>{value}</td>
                                                                                )
                                                                            } else if (c.IMAGEN && value) {
                                                                                style.textAlign = "center";
                                                                                return (
                                                                                    <td key={`td1[${a.get('ARTICULO')}][${c.ID}]`} style={style} onMouseEnter={e => addTitleIfOverflow(e.target)}>
                                                                                        <button className="btn btn-primary btn-sm" onClick={() => this.props.displayModalImagen(true, value)} style={{ marginBottom: "5px" }}>Ver</button>
                                                                                    </td>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <td key={`td1[${a.get('ARTICULO')}][${c.ID}]`} style={style} onMouseEnter={e => addTitleIfOverflow(e.target)}>{value}</td>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        }) : <tr><td colSpan={(isNaN(tableColumns.length)) ? 1 : tableColumns.length + 1} style={{ textAlign: 'center' }}>No existen registros</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </BoxSection>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
                <ModalFilter formularioId={this.props.match.params.id} />
                <ModalDownload />
                <ModalScriptResponse />
                <ModalImagen />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        formularios: state.registros.get('formularios'),
        selectedFormulario: state.registros.get('selectedFormulario'),
        registros: state.registros.get('registros'),
        records: state.registros.get('records'),
        page: state.registros.get('page'),
        size: state.registros.get('size'),
        total: state.registros.get('total'),
        selected: state.registros.get('selected'),
        filtrarPorRegistro: state.registros.get('filtrarPorRegistro'),
        user: state.signin.get('user'),
        compania: state.signin.get('compania'),
        tableColumns: state.registros.get('tableColumns'),
        elementos: state.layout.get('elementos'),
        width: state.layout.get('width'),
        scripts: state.registros.get('scripts'),
        script: state.registros.get('script'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        obtenerRegistros: (page, formId) => dispatch(obtenerRegistros(page, formId)),
        seleccionarRegistro: (id) => dispatch(seleccionarRegistro(id)),
        eliminarRegistros: (formularioId) => dispatch(eliminarRegistros(formularioId)),
        conmutarSeleccionRegistros: (checked) => dispatch(conmutarSeleccionRegistros(checked)),
        reiniciarEstado: () => dispatch(reiniciarEstado()),
        displayModalDownload: (show, title) => dispatch(displayModalDownload(show, title)),
        showModalConfirmAction: (show, message, action) => dispatch(showModalConfirmAction(show, message, action)),
        cambiarPagina: (page) => dispatch(cambiarPagina(page)),
        ordenarPor: (campo) => dispatch(ordenarPor(campo)),
        changeRecordsToShow: (size) => dispatch(changeRecordsToShow(size)),
        showHideModalFilter: (show) => dispatch(showHideModalFilter(show)),
        executeScript: () => dispatch(executeScript()),
        displayModalImagen: (show, imageSrc) => dispatch(displayModalImagen(show, imageSrc)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registros)