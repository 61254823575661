import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import Input from '@mawesi-sas/mws_react_component_input';

import { obtenerOpciones, isNullOrEmpty } from "../../../utils/helpers";
import { modificarValores, recalcularCompletado } from "../actions";
import ImageField from "./ImageField";
import LocationField from "./LocationField";

class Seccion extends Component {
  getValue = (variableId) => {
    return this.props.secciones
      .filter((x) => x.getIn(`VARIABLES.${variableId}`.split(".")))
      .map((x) => x.getIn(`VARIABLES.${variableId}.VALOR`.split(".")))
      .first();
  };
  render() {
    const { selected, seccion, editable, maestras } = this.props;
    if (!selected) return null;

    return (
      <div className="row">
        <div className="variables-seccion">
          {seccion.get("TABLA") === false &&
            seccion
              .get("VARIABLES")
              .valueSeq()
              .sortBy((x) => x.get("ORDEN_VARIABLE"))
              .map((x) => {
                const path = `secciones.${selected}.VARIABLES.${x.get(
                  "ID"
                )}.VALOR`;
                const attrs = {
                  reducer: "crearregistro",
                  path,
                  afterChange: () => this.props.recalcularCompletado(),
                };
                let estado = x.get("ESTADO");
                if (!seccion.get("ESTADO_SECCION")) estado = false;
                if (!estado) attrs.disabled = true;
                if (!x.get("EDITABLE")) attrs.disabled = true;
                if (!editable) attrs.disabled = true;

                switch (x.get("TIPO_CAMPO")) {
                  case "Caja texto":
                    if (x.get("TIPO_DATO") === "Alfanumerico") {
                      attrs.type = "text";
                      if (x.get("TAMANO")) attrs.maxLength = x.get("TAMANO");
                    } else if (x.get("TIPO_DATO") === "Fecha") {
                      attrs.type = "date";
                    } else {
                      if (x.get("TAMANO")) attrs.maxLength = x.get("TAMANO");
                      attrs.type = "number";
                    }
                    break;
                  case "Area texto":
                    attrs.type = "textarea";
                    if (x.get("TAMANO")) attrs.maxLength = x.get("TAMANO");
                    break;
                  case "Lista maestra":
                    attrs.type = "select";
                    if (
                      seccion.getIn(
                        `VARIABLES.${x.get("ID")}.VARIABLE_PADRE_ID`.split(".")
                      )
                    ) {
                      attrs.options = maestras.count()
                        ? obtenerOpciones(
                            x.get("MAESTRA_ID"),
                            false,
                            this.getValue(x.get("VARIABLE_PADRE_ID"))
                          )
                        : [];
                    } else {
                      attrs.options = maestras.count()
                        ? obtenerOpciones(x.get("MAESTRA_ID"), false)
                        : [];
                    }
                    attrs.afterChange = () =>
                      this.props.resetValorVariablesDependientes(
                        null,
                        x.get("ID")
                      );
                    break;
                  case "Panel color":
                    attrs.type = "color";
                    break;
                  case "Ubicacion":
                    attrs.title = x.get("NOMBRE_VARIABLE");
                    attrs.setLocation = this.props.modificarValores;
                    break;
                  default:
                }

                const labelStyle = { fontSize: "inherit", color: "#444" };
                if (!estado) {
                  labelStyle.color = "#999";
                  labelStyle.fontStyle = "italic";
                  labelStyle.textDecoration = "line-through";
                }
                return (
                  <div
                    key={`var[${x.get("ID")}]`}
                    className="col-md-12 col-sm-12 col-xs-12"
                  >
                    <div className="form-group" style={{ marginBottom: "8px" }}>
                      {!isNullOrEmpty(x.get("FORMULA")) && (
                        <span
                          style={{ marginRight: "4px", cursor: "pointer" }}
                          title={x.get("FORMULA")}
                        >
                          <FontAwesome
                            name="calculator"
                            style={{ color: "#444" }}
                          />
                        </span>
                      )}
                      <label className="control-label" style={labelStyle}>
                        {x.get("NOMBRE_VARIABLE")}
                      </label>
                      {x.get("AYUDA") && (
                        <span
                          style={{ marginLeft: "4px", cursor: "pointer" }}
                          title={`${x.get("AYUDA_CAMPO")}`}
                        >
                          <FontAwesome
                            name="info-circle"
                            style={{ color: "#2196f3" }}
                          />
                        </span>
                      )}
                      {x.get("TIPO_CAMPO") === "Imagen" ? (
                        <ImageField
                          upload={this.props.modificarValores}
                          {...attrs}
                        />
                      ) : x.get("TIPO_CAMPO") === "Ubicacion" ? (
                        <LocationField {...attrs} />
                      ) : (
                        <Input {...attrs} />
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    seccion: state.crearregistro.getIn([
      "secciones",
      String(ownProps.selected),
    ]),
    secciones: state.crearregistro
      .get("secciones")
      .filter((x) => !x.get("RESUMEN")),
    maestras: state.maestras.get("maestras"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modificarValores: (path, file) => dispatch(modificarValores(path, file)),
    recalcularCompletado: () => dispatch(recalcularCompletado()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Seccion);
