import moment from 'moment'
import objectifyArray from 'objectify-array'
import FileDownload from 'react-file-download'
import * as types from '../../types'
import { API_URL } from '../../utils/constants'
import { createAxiosInstance, uniqueArray } from '../../utils/helpers'
import { displayMessages, setSavingState } from '../Layout/actions'

export function obtenerMaestras() {
    const axios = createAxiosInstance()
    return (dispatch) => {
        axios.get(`${API_URL}/maestras`)
            .then((res) => {
                const maestras = objectifyArray(res.data, { by: ['ID'], recursive: true })
                const fn1 = d => d({ type: types.MAESTRAS_POBLAR_MAESTRAS, payload: { maestras } })
                fn1(dispatch)
            })
            .catch((err) => {
                displayMessages(dispatch, err)
            })
    }
}

export function displayModalCreate(show, title, mode) {
    return (dispatch) => {
        dispatch({ type: types.MAESTRAS_DISPLAY_MODAL_CREACION, payload: { show, title, mode } })
    }
}

export function adicionarConfiguracion() {
    return (dispatch) => {
        dispatch({ type: types.MAESTRAS_ADICIONAR_CONFIGURACION })
    }
}

export function adicionarDetalle() {
    return (dispatch) => {
        dispatch({ type: types.MAESTRAS_ADICIONAR_DETALLE })
    }
}

export function eliminarConfiguracion() {
    return (dispatch, getState) => {
        let toDelete = getState().maestras.getIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS']).filter(x => x.get('SELECTED') === true)
        const total = toDelete.count()
        toDelete = toDelete.filter(x => x.get('ID') > 0)
        if (toDelete.count() > 0) {
            const axios = createAxiosInstance()
            const fns = []
            toDelete.map(x => x.get('ID')).forEach(x => {
                const fn = () => axios.delete(`${API_URL}/maestras/configuracion/${x}`)
                fns.push(fn)
            })

            const toResultObject = (promise) => {
                return promise
                    .then(result => ({ success: true, result }))
                    .catch(error => ({ success: false, error }));
            }

            Promise.all(fns.map(x => x()).map(toResultObject))
                .then(res => {
                    const succeded = []
                    const failed = []
                    res.forEach(x => {
                        if (!x.success) {
                            failed.push(x.error.response.data.ExceptionMessage)
                            let url = x.error.config.url.split('/')
                            let configId = url[url.length - 1]
                            let index = getState().maestras.getIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS']).findIndex(x => x.get('ID') === Number(configId))
                            dispatch({ type: types.MAESTRAS_MODIFICAR_INPUTS, payload: { path: `modalCreate.data.ENT_CONFIG_MAESTRAS.${index}.SELECTED`, value: false } })
                        } else {
                            succeded.push(1)
                            dispatch({ type: types.MAESTRAS_ELIMINAR_CONFIGURACION, payload: x.result.data.ID })
                        }
                    })
                    if (succeded.length > 0) displayMessages(dispatch, { success: `Se han eliminado ${succeded.length + (total - toDelete.count())} de los ${total} registros` })
                    if (failed.length > 0) displayMessages(dispatch, { response: { status: 500, data: failed.join('\n') } })
                    dispatch({ type: types.MAESTRAS_ELIMINAR_CONFIGURACIONES })
                })
        } else {
            dispatch({ type: types.MAESTRAS_ELIMINAR_CONFIGURACIONES })
        }
    }
}

export function seleccionarMaestra(id) {
    return (dispatch) => {
        dispatch({ type: types.MAESTRAS_SELECCIONAR_MAESTRA, payload: id })
    }
}

export function almacenarMaestra() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('maestras', true))
        let data = getState().maestras.getIn(['modalCreate', 'data'])
        const mode = getState().maestras.getIn(['modalCreate', 'mode'])

        const selects = ['TIPO_DATO', 'TIPO_CAMPO', 'LISTA_MAESTRA_ID', 'CAMPO_LISTA_MAESTRA']
        const trueFalse = ['UNICO']
        data.get('ENT_CONFIG_MAESTRAS').forEach((x, n) => {
            let v
            selects.forEach(s => {
                v = data.getIn(['ENT_CONFIG_MAESTRAS', String(n), s])
                if (String(v) === '-1') data = data.setIn(['ENT_CONFIG_MAESTRAS', String(n), s], null)
            })
            trueFalse.forEach(t => {
                v = String(data.getIn(['ENT_CONFIG_MAESTRAS', String(n), t])) === "true"
                data = data.setIn(['ENT_CONFIG_MAESTRAS', String(n), t], v ? true : false)
            })
        })

        if (mode === 'create') {
            axios.post(`${API_URL}/maestras`, data)
                .then(res => {
                    const id = res.data.ID
                    const maestra = objectifyArray([res.data], { by: ['ID'], recursive: true })
                    dispatch({ type: types.MAESTRAS_ACTUALIZAR_MAESTRA, payload: maestra[id] })
                    dispatch(displayModalCreate(false))
                    dispatch(setSavingState('maestras', false))
                    displayMessages(dispatch, { success: 'La maestra ha sido guardada exitosamente' })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('maestras', false))
                    displayMessages(dispatch, err)
                })
        } else if (mode === 'edit') {
            axios.put(`${API_URL}/maestras/${data.get('ID')}`, data)
                .then(res => {
                    const maestra = objectifyArray([res.data], { by: ['ID'], recursive: true })
                    dispatch({ type: types.MAESTRAS_ACTUALIZAR_MAESTRA, payload: maestra[data.get('ID')] })
                    dispatch(displayModalCreate(false))
                    dispatch(setSavingState('maestras', false))
                    displayMessages(dispatch, { success: 'La maestra ha sido guardada exitosamente' })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('maestras', false))
                    displayMessages(dispatch, err)
                })
        }
    }
}

export function almacenarDetalles() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const selected = getState().maestras.get('selected')
        let detalles = getState().maestras.getIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS']).valueSeq()
        let data = detalles.filter(x => x.get('UPDATED'))
        const configuracion = getState().maestras.getIn(['maestras', String(selected), 'ENT_CONFIG_MAESTRAS']).valueSeq()
        
        if (data.count() > 0) {
            dispatch(setSavingState('maestras', true))
            const errores = []
            configuracion.filter(x => x.get('UNICO') === 'SI').forEach(x => {
                const c = x.get('CAMPO')
                const values = detalles.map(d => d.get(`CAMPO${c}`)).toArray()
                const unique = uniqueArray(values)
                if (values.length !== unique.length) {
                    errores.push(`El campo ${x.get('NOMBRE')} fue marcado como único y se encontraron valores duplicados`)
                }
            })
            if (errores.length === 0) {
                axios.put(`${API_URL}/maestras/detalles`, data)
                    .then(res => {
                        const maestra = objectifyArray([res.data], { by: ['ID'], recursive: true })
                        dispatch({ type: types.MAESTRAS_ACTUALIZAR_MAESTRA, payload: maestra[selected] })
                        dispatch(setSavingState('maestras', false))
                        displayMessages(dispatch, { success: 'La maestra ha sido guardada exitosamente' })
                    })
                    .catch(err => {
                        dispatch(setSavingState('maestras', false))
                        displayMessages(dispatch, err)
                    })
            } else {
                dispatch(setSavingState('maestras', false))
                errores.forEach(x => {
                    displayMessages(dispatch, { warning: x })
                })
            }
        }
    }
}

export function resetAfterTipoCampo(path) {
    return (dispatch) => {
        dispatch({ type: types.MAESTRAS_MODIFICAR_INPUTS, payload: { path: `${path}.TIPO_DATO`, value: -1 } })
        dispatch({ type: types.MAESTRAS_MODIFICAR_INPUTS, payload: { path: `${path}.LISTA_MAESTRA_ID`, value: -1 } })
        dispatch({ type: types.MAESTRAS_MODIFICAR_INPUTS, payload: { path: `${path}.CAMPO_LISTA_MAESTRA`, value: -1 } })
    }
}

export function marcarComoActualizado(path) {
    return (dispatch) => {
        dispatch({ type: types.MAESTRAS_MODIFICAR_INPUTS, payload: { path: `${path}.UPDATED`, value: true } })
    }
}

export function eliminarDetalles() {
    return (dispatch, getState) => {
        const selected = getState().maestras.get('selected')
        let toDelete = getState().maestras.getIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS']).valueSeq().filter(x => x.get('SELECTED') === true)
        const total = toDelete.count()
        toDelete = toDelete.filter(x => x.get('ID') > 0)
        if (toDelete.count() > 0) {
            const axios = createAxiosInstance()
            const fns = []
            toDelete.map(x => x.get('ID')).forEach(x => {
                const fn = () => axios.delete(`${API_URL}/maestras/detalles/${x}`)
                fns.push(fn)
            })

            const toResultObject = (promise) => {
                return promise
                    .then(result => ({ success: true, result }))
                    .catch(error => ({ success: false, error }));
            }

            Promise.all(fns.map(x => x()).map(toResultObject))
                .then(res => {
                    const succeded = []
                    const failed = []
                    res.forEach(x => {
                        if (!x.success) {
                            failed.push(x.error.response.data.ExceptionMessage)
                            let url = x.error.config.url.split('/')
                            let detalleId = url[url.length - 1]
                            dispatch({ type: types.MAESTRAS_MODIFICAR_INPUTS, payload: { path: `maestras.${selected}.ENT_DETALLE_MAESTRAS.${detalleId}.SELECTED`, value: false } })
                        } else {
                            succeded.push(1)
                            dispatch({ type: types.MAESTRAS_ELIMINAR_DETALLE, payload: x.result.data.ID })
                        }
                    })
                    if (succeded.length > 0) displayMessages(dispatch, { success: `Se han eliminado ${succeded.length + (total - toDelete.count())} de los ${total} registros` })
                    if (failed.length > 0) displayMessages(dispatch, { response: { status: 500, data: failed.join('\n') } })
                    dispatch({ type: types.MAESTRAS_ELIMINAR_DETALLES })
                })
        } else {
            dispatch({ type: types.MAESTRAS_ELIMINAR_DETALLES })
        }
    }
}

export function eliminarMaestras() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const selected = getState().maestras.get('selected')
        axios.delete(`${API_URL}/maestras/${selected}`)
            .then(res => {
                dispatch({ type: types.MAESTRAS_ELIMINAR_MAESTRAS, payload: selected })
            })
            .catch(err => {
                displayMessages(dispatch, err)
            })
    }
}

export function filtrarMaestras(termino) {
    return (dispatch) => {
        dispatch({ type: types.MAESTRAS_MODIFICAR_FILTRO, payload: termino })
    }
}

export function ordenarPor(columna) {
    return (dispatch) => {
        dispatch({ type: types.MAESTRAS_ORDENAR_COLUMNA, payload: columna })
    }
}

export function decargarMaestras() {
    const axios = createAxiosInstance()
    return (dispatch) => {
        axios.get(`${API_URL}/maestras/descargar`, { responseType: 'arraybuffer' })
            .then((res) => {
                FileDownload(res.data, `Maestras_${moment().format('YYYYMMDDHHmmSS')}.xlsx`)
            })
            .catch((err) => {
                const encodedString = String.fromCharCode.apply(null, new Uint8Array(err.response.data))
                const decodedString = JSON.parse(decodeURIComponent(escape(encodedString)))
                const obj = {
                    response: {
                        data: decodedString,
                        status: 500
                    }
                }
                displayMessages(dispatch, obj)
            })
    }
}