import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import moment from "moment";
import numeral from "numeral";
import Pagination from "react-js-pagination";

import ButtonStrip from "../Layout/components/ButtonStrip";
import BoxButton from "../Layout/components/BoxButton";
import ModalFilter from "./components/ModalFilter";

import {
    obtenerBitacora,
    actualizarMostrarRegistros,
    showHideModalFilter,
    setOrderBy
} from "./actions";
import Box from "../Layout/components/Box";
import BoxSection from "../Layout/components/BoxSection";

export const tableColumns = [
    { ID: 3, CAPTION: "Variable", VALUE: "VARIABLE", TYPE: "string", FORMAT: "" },
    { ID: 5, CAPTION: "Fecha", VALUE: "FECHA", TYPE: "datetime", FORMAT: "YYYY-MM-DD HH:mm:ss" },
    { ID: 1, CAPTION: "Valor anterior", VALUE: "ANTIGUO_VALOR", TYPE: "string", FORMAT: "" },
    { ID: 6, CAPTION: "Nuevo valor", VALUE: "NUEVO_VALOR", TYPE: "string", FORMAT: ""},
    { ID: 4, CAPTION: "Usuario", VALUE: "USUARIO", TYPE: "string", FORMAT: "" }
];

export function findValues(c, x) {
    let value = x.getIn(c.VALUE.split("."));
    return value;
}

class Bitacora extends Component {
    componentWillMount() {
        this.props.obtenerBitacora(1);
    }

    render() {
        const { records, orderBy, page, totalRegistros, size } = this.props;

        const pagination = (
            <Pagination
                activePage={page}
                itemsCountPerPage={size}
                totalItemsCount={totalRegistros}
                onChange={pg => this.props.obtenerBitacora(pg)}
                firstPageText={<FontAwesome name="angle-double-left" />}
                prevPageText={<FontAwesome name="angle-left" />}
                nextPageText={<FontAwesome name="angle-right" />}
                lastPageText={<FontAwesome name="angle-double-right" />}
                pageRangeDisplayed={this.props.width < 1000 ? 5 : 10}
            />
        );

        const buttons = [
            <BoxButton
                key={`action[7][0]`}
                name="refresh"
                onClick={() => this.props.obtenerBitacora(1)}
                title="Refrescar"
            />,
            <BoxButton
                key={`action[7][1]`}
                name="filter"
                onClick={() => this.props.showHideModalFilter(true)}
                title="Filtrar"
            />
        ];

        return (
            <div id="bitacora"
                style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}
            >
                <Box title="Bitácora" stretch>
                    <div className="row" style={{ height: "100%" }}>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="row" style={{ height: "100%" }}>
                                <div
                                    className="col-md-12 col-sm-12 col-xs-12"
                                    style={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <BoxSection title="Historial de modificaciones">
                                        <div style={{ padding: "0 5px", marginBottom: "10px" }}>
                                            <ButtonStrip
                                                actions={buttons}
                                                pagination={pagination}
                                                totalRecords={totalRegistros}
                                                showRecords={size}
                                                changeRecordsToShow={records =>
                                                    this.props.actualizarMostrarRegistros(records)
                                                }
                                            />
                                        </div>
                                        <div className="table-scrolled">
                                            <table className="table table-condensed table-striped table-bordered table-hover table-sorted">
                                                <thead>
                                                    <tr>
                                                        {tableColumns.map(x => {
                                                            const style = {
                                                                width: `${x.WIDTH}`,
                                                                cursor: "pointer"
                                                            };
                                                            return (
                                                                <th
                                                                    key={`th1[${x.ID}]`}
                                                                    style={style}
                                                                    onClick={() => this.props.setOrderBy(x)}
                                                                    className={
                                                                        orderBy.get("field") === x.VALUE
                                                                            ? orderBy.get("asc")
                                                                                ? "asc"
                                                                                : "desc"
                                                                            : ""
                                                                    }
                                                                >
                                                                    {x.CAPTION}
                                                                </th>
                                                            );
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {records.count() > 0 ? (
                                                        records.map((a, b) => {
                                                            // const path = `secciones.${a.get("ID")}`;
                                                            return (
                                                                <tr
                                                                    key={`tr2[${a.get("ID")}]`}
                                                                    className={a.get("SELECTED") ? "info" : ""}
                                                                >
                                                                    {tableColumns.map((c, d) => {
                                                                        let alignment = "left";
                                                                        let value = findValues(c, a);
                                                                        if (c.TYPE) {
                                                                            switch (c.TYPE) {
                                                                                case "datetime":
                                                                                    value = moment(value).format(
                                                                                        c.FORMAT
                                                                                    );
                                                                                    alignment = "right";
                                                                                    break;
                                                                                case "bool":
                                                                                    if (typeof value === "string")
                                                                                        value = parseInt(value, 10);
                                                                                    value = (
                                                                                        <div
                                                                                            style={{
                                                                                                textAlign: "center",
                                                                                                fontSize: "1.1em"
                                                                                            }}
                                                                                        >
                                                                                            <i
                                                                                                style={{ color: "#41afbe" }}
                                                                                                className={`fa fa-${
                                                                                                    value ? "check-" : ""
                                                                                                    }square`}
                                                                                            />
                                                                                        </div>
                                                                                    );
                                                                                    break;
                                                                                case "color":
                                                                                    let icon = "circle";
                                                                                    if (value === null)
                                                                                        icon = "circle-thin";
                                                                                    value = (
                                                                                        <div
                                                                                            style={{
                                                                                                textAlign: "center",
                                                                                                fontSize: "1.1em"
                                                                                            }}
                                                                                        >
                                                                                            <i
                                                                                                style={{
                                                                                                    color: value,
                                                                                                    textShadow: "0px 0px 1px #555"
                                                                                                }}
                                                                                                className={`fa fa-${icon}`}
                                                                                            />
                                                                                        </div>
                                                                                    );
                                                                                    break;
                                                                                case "number":
                                                                                    value =
                                                                                        value !== null
                                                                                            ? numeral(value).format(c.Format)
                                                                                            : null;
                                                                                    alignment = "right";
                                                                                    break;
                                                                                default:
                                                                            }
                                                                        }
                                                                        const style = { textAlign: alignment };

                                                                        if (c.LINK) {
                                                                            return (
                                                                                <td
                                                                                    key={`td1[${a.get("ID")}][${c.ID}]`}
                                                                                    className="LinkToEdit"
                                                                                    style={style}
                                                                                    onClick={e =>
                                                                                        this.props.displayModalCreate(
                                                                                            true,
                                                                                            "Modificación de secciones",
                                                                                            "edit",
                                                                                            a.get("ID")
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {value}
                                                                                </td>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <td
                                                                                    key={`td1[${a.get("ID")}][${c.ID}]`}
                                                                                    style={style}
                                                                                >
                                                                                    {value}
                                                                                </td>
                                                                            );
                                                                        }
                                                                    })}
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                            <tr>
                                                                <td
                                                                    colSpan={tableColumns.length}
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    No existen registros
                              </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </BoxSection>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
                <ModalFilter />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        records: state.bitacora.get("records"),
        orderBy: state.bitacora.get("orderBy"),
        totalRegistros: state.bitacora.get("totalRegistros"),
        size: state.bitacora.get("size"),
        page: state.bitacora.get("page"),
        width: state.layout.get('width'),
        height: state.layout.get('height')
    };
}

function mapDispatchToProps(dispatch) {
    return {
        obtenerBitacora: page => dispatch(obtenerBitacora(page)),
        actualizarMostrarRegistros: n => dispatch(actualizarMostrarRegistros(n)),
        showHideModalFilter: show => dispatch(showHideModalFilter(show)),
        setOrderBy: campo => dispatch(setOrderBy(campo))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Bitacora);
