import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import Modal from '@mawesi-sas/mws_react_component_modal'
import Input from '@mawesi-sas/mws_react_component_input'
import { displayModalClone, almacenarFormulario } from '../actions';

class ModalClone extends Component {
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalClone')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalClone .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')
        setTimeout(() => this.props.displayModalClone(false), 300)
    }

    modalContent = (modalClone, formulario) => {
        return (
            <form id="form" onSubmit={e => { e.preventDefault(); this.props.almacenarFormulario('clone') }} noValidate>
                <div className="buttonstrip">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1 }}>
                            <button className="box-button" title="Guardar" disabled={modalClone.get('btnDisabled')}><FontAwesome name="floppy-o" tag="i" /></button>
                        </div>
                    </div>
                </div>
                <div className="modal-dialog" style={{ width: '30vw' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="well">
                                <dl className="dl-horizontal" style={{ marginBottom: '0' }}>
                                    <dt>Formulario base:</dt>
                                    <dd>{formulario.get('NOMBRE')}</dd>
                                    <dt>Secciones:</dt>
                                    <dd>{formulario.get('SECCIONES').valueSeq().filter(x => x.get('ESTADO')).count()}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="control-label">Nombre</label>
                                <Input
                                    type="text"
                                    reducer="formularios"
                                    path="modalClone.data.NOMBRE"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="control-label">Descripción</label>
                                <Input
                                    type="text"
                                    reducer="formularios"
                                    path="modalClone.data.DESCRIPCION"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        const { modalClone, formulario } = this.props
        if (!modalClone.get('show')) return null

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "60%" : "40%"}
                mostrar={modalClone.get('show')}
                contenido={this.modalContent(modalClone, formulario)}
                titulo={modalClone.get('title')}
                nombre="modalClone"
                onCerrarModal={(e) => this.handleCloseModal(e)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modalClone: state.formularios.get('modalClone'),
        formulario: state.formularios.getIn(['estructuras', String(state.formularios.get('selected'))]),
        width: state.layout.get('width'),
        height: state.layout.get('height'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayModalClone: (show) => dispatch(displayModalClone(show)),
        almacenarFormulario: (mode) => dispatch(almacenarFormulario(mode)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalClone)
