import * as _ from 'lodash'
import Immutable from 'immutable'
import diff from 'immutablediff'
import patch from 'immutablepatch'
import moment from 'moment'
import * as types from '../../types'
import { isPrimitive, getSelectedCompany } from '../../utils/helpers'

const INITIAL_STATE = Immutable.fromJS({
    compania: null,
    registros: {},
    records: [],
    page: 1,
    size: 20,
    total: 0,
    secciones: {},
    tableColumns: [],
    scripts: [],
    selected: null,
    selectedFormulario: null,
    filtrarPor: -1,
    filtro: '',
    sortBy: '1',
    asc: false,
    modalCreate: {
        show: false,
        title: '',
        page: 1,
        size: 20,
        total: 0,
        data: {},
        orderBy: {
            field: null,
            asc: false
        },
        filtrarPor: -1,
        filtro: ''
    },
    modalDownload: {
        show: false,
        title: '',
        estructura: {},
        filtro: '',
        saving: false,
    },
    formularios: [],
    modalFilter: {
        show: false,
        filters: [],
        columns: []
    },
    filter: {
        loading: false,
        columns: [],
        values: {},
        options: {}
    },
    modalScriptResponse: {
        show: false,
        data: null,
        pid: null,
    },
    modalImagen: {
        show: false,
        imageSrc: '',
        title: 'Visualizador de imagen'
    }
})

export default function (state = INITIAL_STATE, action) {
    let data, ops;
    let filters = []
    switch (action.type) {
        case types.REGISTROS_POBLAR_REGISTROS:
            state = state.set('compania', getSelectedCompany())
            state = state.set('selectedFormulario', Immutable.fromJS(action.payload.selectedFormulario))
            state = state.set('registros', Immutable.fromJS(action.payload.registros))
            state = state.set('records', state.get('registros').keySeq().toList())
            state = state.set('total', action.payload.total)
            state = state.set('secciones', Immutable.fromJS(action.payload.secciones))
            state = state.set('tableColumns', action.payload.tableColumns)
            state = state.setIn(['filter', 'columns'], Immutable.fromJS(_.uniqBy(action.payload.variablesList, 'value')))
            state = state.setIn(['modalFilter', 'columns'], Immutable.fromJS(_.uniqBy(action.payload.variablesList, 'value')))
            state = state.set('scripts', Immutable.fromJS(action.payload.scripts))
            return state
        case types.REGISTROS_CAMBIAR_PAGINA:
            state = state.set('page', action.payload)
            return state
        case types.REGISTROS_DISPLAY_MODAL_CREACION:
            if (action.payload.show) {
                state = state.setIn(['modalCreate', 'show'], true)
                state = state.setIn(['modalCreate', 'title'], action.payload.title)
            } else {
                state = state.set('modalCreate', INITIAL_STATE.get('modalCreate'))
            }
            return state
        case types.REGISTROS_MODIFICAR_INPUTS:
            if (isPrimitive(action.payload.value)) {
                state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
            } else {
                if (action.payload.immutable === false) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                }
            }
            return state
        case types.REGISTROS_ACTUALIZAR_ARTICULO:
            ops = diff(state.getIn(['registros', String(action.payload.ARTICULO)]), Immutable.fromJS(action.payload))
            ops = ops.filter(x => x.get('op') !== 'remove')
            state = state.setIn(['registros', String(action.payload.ARTICULO)], patch(state.getIn(['registros', String(action.payload.ARTICULO)]), ops))
            return state
        case types.REGISTROS_ELIMINAR_ARTICULO:
            state = state.deleteIn(['registros', String(action.payload)])
            return state
        case types.REGISTROS_CONMUTAR_SELECCION_REGISTROS:
            state.get('registros').keySeq().forEach(x => {
                state = state.setIn(['registros', String(x), 'SELECTED'], action.payload)
            })
            return state
        case types.REGISTROS_POBLAR_REGISTROS_DELTA:
            state = state.setIn(['modalCreate', 'data'], Immutable.fromJS(action.payload.registros))
            state = state.setIn(['modalCreate', 'total'], Immutable.fromJS(action.payload.total))
            state = state.setIn(['modalCreate', 'page'], Immutable.fromJS(action.payload.page))
            return state
        case types.REGISTROS_MOSTRAR_MODAL_DESCARGA:
            if (action.payload.show) {
                state = state.setIn(['modalDownload', 'show'], true)
                state = state.setIn(['modalDownload', 'title'], action.payload.title)
                state = state.setIn(['modalDownload', 'estructura'], Immutable.fromJS(action.payload.estructura))
            } else {
                state = state.set('modalDownload', INITIAL_STATE.get('modalDownload'))
            }
            return state
        case types.REGISTROS_REINICIAR_ESTADO:
            return INITIAL_STATE
        case types.REGISTROS_FILTRAR_REGISTROS:
            state = state.set('page', 1)
            let registros = state.get('registros')
            
            state.getIn(['filter', 'values']).forEach((value, key) => {
                filters.push({
                    column: key,
                    operator: 'IN',
                    value: value ? value.split('|') : null,
                    comparison: 'string',
                })
            })
            filters = filters.concat(state.getIn(['modalFilter', 'filters']).filter(x => x.get('apply')).toJS())

            filters.forEach(f => {
                if (f.value !== null) {
                    const definition = state.get('tableColumns').find(x => x.VALUE === f.column)

                    if (f.operator === '===') {
                        registros = registros.filter(x => String(x.get(f.column)).toLowerCase() === String(f.value).toLowerCase());
                    } else if (f.operator === '!==') {
                        registros = registros.filter(x => String(x.get(f.column)).toLowerCase() !== String(f.value).toLowerCase());
                    } else if (f.operator === '>') {
                        if (definition && definition.TYPE === 'datetime') {
                            registros = registros.filter(x => moment(f.value).isBefore(moment(x.get(f.column))));
                        } else if (definition && definition.TYPE === 'number') {
                            registros = registros.filter(x => Number(x.get(f.column)) > Number(f.value));
                        } else {
                            registros = registros.filter(x => x.get(f.column) > f.value);
                        }

                    } else if (f.operator === '>=') {
                        if (definition && definition.TYPE === 'datetime') {
                            registros = registros.filter(x => moment(f.value).isSameOrBefore(moment(x.get(f.column))));
                        } else if (definition && definition.TYPE === 'number') {
                            registros = registros.filter(x => Number(x.get(f.column)) >= Number(f.value));
                        } else {
                            registros = registros.filter(x => x.get(f.column) >= f.value);
                        }
                    } else if (f.operator === '<') {
                        if (definition && definition.TYPE === 'datetime') {
                            registros = registros.filter(x => moment(f.value).isAfter(moment(x.get(f.column))));
                        } else if (definition && definition.TYPE === 'number') {
                            registros = registros.filter(x => Number(x.get(f.column)) < Number(f.value));
                        } else {
                            registros = registros.filter(x => x.get(f.column) < f.value);
                        }
                    } else if (f.operator === '<=') {
                        if (definition && definition.TYPE === 'datetime') {
                            registros = registros.filter(x => moment(f.value).isSameOrAfter(moment(x.get(f.column))));
                        } else if (definition && definition.TYPE === 'number') {
                            registros = registros.filter(x => Number(x.get(f.column)) <= Number(f.value));
                        } else {
                            registros = registros.filter(x => x.get(f.column) <= f.value);
                        }
                    } else if (f.operator === 'like') {
                        registros = registros.filter(x => String(x.get(f.column)).toLowerCase().includes(String(f.value).toLowerCase()));
                    } else if (f.operator === 'IN') {
                        registros = registros.filter(x => f.value.indexOf(String(x.get(f.column))) >= 0)
                    }
                }
            })

            return state.set('records', registros.valueSeq().toList().map(x => x.get('ID').toString()))
        case types.REGISTROS_ORDENAR_REGISTROS:
            state = state.set('sortBy', action.payload)
            if (action.payload === state.get('sortBy')) {
                state = state.set('asc', !state.get('asc'))
            } else {
                state = state.set('asc', true)
            }
            data = state.get('registros').valueSeq().toList().sortBy(x => x.get(action.payload))

            state.getIn(['filter', 'values']).forEach((value, key) => {
                filters.push({
                    column: key,
                    operator: 'IN',
                    value: value ? value.split('|') : null,
                    comparison: 'string',
                })
            })
            filters = filters.concat(state.getIn(['modalFilter', 'filters']).filter(x => x.get('apply')).toJS())

            filters.forEach(f => {
                if (f.value !== null) {
                    const definition = state.get('tableColumns').find(x => x.VALUE === f.column)

                    if (f.operator === '===') {
                        data = data.filter(x => String(x.get(f.column)).toLowerCase() === String(f.value).toLowerCase());
                    } else if (f.operator === '!==') {
                        data = data.filter(x => String(x.get(f.column)).toLowerCase() !== String(f.value).toLowerCase());
                    } else if (f.operator === '>') {
                        if (definition && definition.TYPE === 'datetime') {
                            data = data.filter(x => moment(f.value).isBefore(moment(x.get(f.column))));
                        } else if (definition && definition.TYPE === 'number') {
                            data = data.filter(x => Number(x.get(f.column)) > Number(f.value));
                        } else {
                            data = data.filter(x => x.get(f.column) > f.value);
                        }

                    } else if (f.operator === '>=') {
                        if (definition && definition.TYPE === 'datetime') {
                            data = data.filter(x => moment(f.value).isSameOrBefore(moment(x.get(f.column))));
                        } else if (definition && definition.TYPE === 'number') {
                            data = data.filter(x => Number(x.get(f.column)) >= Number(f.value));
                        } else {
                            data = data.filter(x => x.get(f.column) >= f.value);
                        }
                    } else if (f.operator === '<') {
                        if (definition && definition.TYPE === 'datetime') {
                            data = data.filter(x => moment(f.value).isAfter(moment(x.get(f.column))));
                        } else if (definition && definition.TYPE === 'number') {
                            data = data.filter(x => Number(x.get(f.column)) < Number(f.value));
                        } else {
                            data = data.filter(x => x.get(f.column) < f.value);
                        }
                    } else if (f.operator === '<=') {
                        if (definition && definition.TYPE === 'datetime') {
                            data = data.filter(x => moment(f.value).isSameOrAfter(moment(x.get(f.column))));
                        } else if (definition && definition.TYPE === 'number') {
                            data = data.filter(x => Number(x.get(f.column)) <= Number(f.value));
                        } else {
                            data = data.filter(x => x.get(f.column) <= f.value);
                        }
                    } else if (f.operator === 'like') {
                        data = data.filter(x => String(x.get(f.column)).toLowerCase().includes(String(f.value).toLowerCase()));
                    } else if (f.operator === 'IN') {
                        data = data.filter(x => f.value.indexOf(String(x.get(f.column))) >= 0)
                    }
                }
            })

            if (!state.get('asc')) data = data.reverse()
            state = state.set('records', data.map(x => x.get('ID').toString()))
            return state
        case types.REGISTROS_DESCARGA_GUARDANDO:
            state = state.setIn(['modalDownload', 'saving'], action.payload)
            return state
        case types.REGISTROS_ACTUALIZAR_ESTADO_ARTICULO:
            action.payload.codigos.forEach(x => {
                state = state.setIn(['registros', x, 'ESTADO'], action.payload.estado)
            })
            return state
        case types.REGISTROS_LLENADO_FORMULARIOS:
            return state.set('formularios', Immutable.fromJS(action.payload.formularios))
        case types.REGISTROS_ACTUALIZAR_MOSTRAR_REGISTROS:
            return state.set('size', action.payload.size)
        case types.REGISTROS_MOSTRAR_MODAL_CRITERIOS_FILTRADO:
            return state.setIn(['modalFilter', 'show'], action.payload)
        case types.REGISTROS_ADICIONAR_CRITERIO_FILTRADO:
            state = state.updateIn(['modalFilter', 'filters'], filter => filter
                .push(Immutable.fromJS({
                    column: "",
                    operator: '===',
                    value: '',
                    checked: false,
                    comparison: 'string',
                    apply: false
                }))
            )
            return state
        case types.REGISTROS_ELIMINAR_CRITERIO_FILTRADO:
            return state.updateIn(['modalFilter', 'filters'], l => l.filter(item => !item.get('checked')))
        case types.REGISTROS_CONMUTAR_SELECCION_CRITERIO_FILTRADO:
            return state.setIn(['modalFilter', 'filters', action.payload.index, 'checked'], action.payload.checked)
        case types.REGISTROS_MODIFICAR_CRITERIO_FILTRADO:
            return state.setIn(['modalFilter', 'filters', action.payload.index, action.payload.attr], action.payload.value)
        case types.REGISTROS_APLICAR_CRITERIOS_FILTRADO:
            state = state.updateIn(['modalFilter', 'filters'], filter => filter
                .map(x => {
                    x = x.set('apply', true)
                    return x
                })
            )
            return state
        case types.REGISTROS_LOADING_OPTIONS:
            return state.setIn(['filter', 'loading'], action.payload)
        case types.REGISTROS_MODIFICAR_OPTIONS:
            return state.setIn(['filter', 'options', action.payload.key], action.payload.value)
        case types.REGISTROS_MODIFICAR_FILTRADO:
            return state.setIn(['filter', 'values', action.payload.key], action.payload.value)
        case types.REGISTROS_BORRAR_FILTROS:
            return state.setIn(['filter', 'values'], INITIAL_STATE.getIn(['filter', 'values']))
        case types.REGISTROS_DISPLAY_MODAL_RESULTADO_SCRIPT:
            if (action.payload.show) {
                state = state.setIn(['modalScriptResponse', 'show'], true)
                state = state.setIn(['modalScriptResponse', 'data'], action.payload.response)
                state = state.setIn(['modalScriptResponse', 'pid'], action.payload.pid)
            } else {
                state = state.set('modalScriptResponse', INITIAL_STATE.get('modalScriptResponse'))
            }
            return state
        case types.REGISTROS_MOSTRAR_MODAL_IMAGEN:
            if (action.payload.show) {
                state = state.setIn(['modalImagen', 'show'], true)
                state = state.setIn(['modalImagen', 'imageSrc'], action.payload.imageSrc)
            } else {
                state = state.set('modalImagen', INITIAL_STATE.get('modalImagen'))
            }
            return state
        default:
            return state
   }
}