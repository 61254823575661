import objectifyArray from 'objectify-array'
import * as types from '../../types'
import { API_URL } from '../../utils/constants'
import { createAxiosInstance } from '../../utils/helpers'
import { setSavingState, displayMessages } from '../Layout/actions'

export function obtenerRoles() {
    const axios = createAxiosInstance()
    return (dispatch) => {
        const fn1 = () => axios.get(`${API_URL}/roles`)
        const fn2 = () => axios.get(`${API_URL}/roles/permisos`)
        const fn3 = () => axios.get(`${API_URL}/roles/formularios`)
        Promise.all([fn1(), fn2(), fn3()]).then(res => {
            const roles = objectifyArray(res[0].data, { by: ['ID'], recursive: true })
            dispatch({
                type: types.ROLES_POBLAR_ROLES,
                payload: { 
                    roles, 
                    objetos: res[1].data, 
                    formularios:res[2].data.map(item => {
                        return {ID_ESTRUCTURA: item.ID, NOMBRE: item.NOMBRE}
                    })
                }
            })
        }).catch(err => displayMessages(dispatch, err))
    }
}

export function seleccionarRol(id) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch({ type: types.ROLES_SELECCIONAR_ROL, payload: id })
        const isNew = !!getState().roles.getIn(`roles.${id}.GUID`.split('.'))
        if (!isNew) {
            const fn0 = () => axios.get(`${API_URL}/roles/${id}/permisos`)
            const fn1 = () => axios.get(`${API_URL}/roles/${id}/formularios`)
            Promise.all([fn0(), fn1()]).then(res => {
                dispatch({
                    type: types.ROLES_PERMISOS_FORMULARIOS_OBTENIDOS,
                    payload: {
                        roleId: id,
                        permisos: objectifyArray(res[0].data, { by: ['COD_OBJETO'], recursive: true }),
                        formularios: objectifyArray(res[1].data, { by: ['ID_ESTRUCTURA'], recursive: true })
                    }
                })
            }).catch(err => displayMessages(dispatch, err))
        }
    }
}

export function adicionarRol() {
    return {
        type: types.ROLES_ADICIONAR_ROL
    }
}

export function agregarEliminarPermiso(permiso, checked) {
    return {
        type: types.ROLES_ADICIONAR_ELIMINAR_PERMISOS_ROL,
        payload: { permiso, checked }
    }
}

export function agregarEliminarFormularios(formulario, checked) {
    return {
        type: types.ROLES_ADICIONAR_ELIMINAR_FORMULARIOS_ROL,
        payload: { formulario, checked }
    }
}

export function almacenarRolPermisosFormularios() {
    const axios = createAxiosInstance()

    return (dispatch, getState) => {
        let role = getState().roles.getIn(`roles.${getState().roles.get('selected')}`.split('.'))
        role = role.set('PERMISOS', role.get('PERMISOS').valueSeq().toList());
        role = role.set('FORMULARIOS', role.get('FORMULARIOS').valueSeq().toList())

        dispatch(setSavingState('roles', true))

        if (role.get('PERMISOS').count() === 0) {
            return displayMessages(dispatch, { warning: 'Seleccione al menos un permiso.' })
        }
        
        if (role.get('GUID')) {
            axios.post(`${API_URL}/roles`, role).then(res => {
                const { rol, permisos, formularios } = res.data
                
                dispatch({
                    type: types.ROLES_ACTUALIZAR_ROLES,
                    payload: { rol, permisos: objectifyArray(permisos, { by: ['COD_OBJETO'], recursive: true }), formularios: objectifyArray(formularios, { by: ['ID_ESTRUCTURA'], recursive: true }) }
                })
                
                displayMessages(dispatch, { success: `El rol ${rol.NOMBRE} ha sido creado exitosamente.` })
            }).catch(err => displayMessages(dispatch, err)).finally(() => dispatch(setSavingState('roles', false)))
        } else {
            axios.put(`${API_URL}/roles/${role.get('ID')}`, role).then(res => {
                const { rol, permisos, formularios} = res.data
                
                dispatch({
                    type: types.ROLES_ACTUALIZAR_ROLES,
                    payload: { rol, permisos: objectifyArray(permisos, { by: ['COD_OBJETO'], recursive: true }), formularios: objectifyArray(formularios, { by: ['ID_ESTRUCTURA'], recursive: true }) }
                })

                displayMessages(dispatch, { success: `Los permisos han sido asignados al rol ${rol.NOMBRE} exitosamente.` })
            }).catch(err => displayMessages(dispatch, err)).finally(() => dispatch(setSavingState('roles', false)))
        }
    }
}