import React, { Component } from 'react'
import { connect } from 'react-redux'
import Box from '../Layout/components/Box'
import {Link} from 'react-router-dom'

import { obtenerListadoFormularios, reiniciarEstado } from './actions'

class ListadoFormularios extends Component {
    componentWillMount() {
        this.props.reiniciarEstado()
        this.props.obtenerListadoFormularios()
    }
    render() {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box title="Listado de Formularios" stretch>
                    <div className="list-forms">
                        {
                            this.props.formularios.filter(f => f.get('SECCIONES_VARIABLES').count() > 0).map((f, idx) => (
                                <Link to={`/registros/formularios/${f.get('ID')}`} className="forms" key={`forms[${idx}]`}>
                                    <h2>{f.get('NOMBRE')}</h2>
                                    <p>{f.get('DESCRIPCION')}</p>
                                </Link>
                            )
                            )
                        }
                    </div>
                </Box>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        formularios: state.registros.get('formularios')
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        obtenerListadoFormularios: () => dispatch(obtenerListadoFormularios()),
        reiniciarEstado: () => dispatch(reiniciarEstado())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListadoFormularios)