import diff from 'immutablediff'
import * as _ from 'lodash'
import * as types from '../../types'
import {
    API_URL
} from '../../utils/constants'
import {
    createAxiosInstance,
    getSelectedCompany,
    calcularCompletado,
    calcularCompletadoSeccion,
    evalVariablesFormulas
} from '../../utils/helpers'
import {
    showHideModalMsgBox,
    displayMessages
} from '../Layout/actions'
import {
    obtenerMaestras
} from '../Maestras/actions'
import history from '../../utils/history'

function custom(objValue, srcValue) {
    if (srcValue === null || srcValue === undefined || _.isNaN(srcValue)) {
        return objValue;
    }
}
export function obtenerRegistro(formulario_id, registro_id) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        let sin_imagenes = [];
        let con_imagenes = [];
        if (getState().maestras.get('maestras').keySeq().count() === 0) dispatch(obtenerMaestras())

        axios.get(`${API_URL}/registros/${registro_id || '0'}?formularioId=${formulario_id}&imagen=false`).then(res => {
            dispatch({
                type: types.CREAR_REGISTRO_POBLAR_REGISTRO,
                payload: {
                    secciones: calcularCompletado(res.data.secciones),
                    registro: res.data.registro
                }
            })

            axios.get(`${API_URL}/registros/${registro_id || '0'}?formularioId=${formulario_id}&imagen=true`).then(res2 => {
                sin_imagenes = getState().crearregistro.get('secciones').toJS();
                con_imagenes = calcularCompletado(res2.data.secciones)
                dispatch({
                    type: types.CREAR_REGISTRO_POBLAR_REGISTRO,
                    payload: {
                        secciones: _.mergeWith(sin_imagenes, con_imagenes, custom),
                        registro: res2.data.registro
                    }
                })

                getState().crearregistro.get('secciones').valueSeq().forEach((s, idx) => {
                    const completado = calcularCompletadoSeccion(s)
                    dispatch({
                        type: types.CREAR_REGISTRO_MODIFICAR_INPUTS,
                        payload: {
                            path: `secciones.${s.get('ID')}.COMPLETADO`,
                            value: completado
                        }
                    })
                });
            })
        }).catch(err => {
            showHideModalMsgBox(dispatch, err)
        })
    }
}

export function reiniciarEstado() {
    return (dispatch) => {
        dispatch({
            type: types.CREAR_REGISTRO_REINICIAR_ESTADO
        })
    }
}

export function selectTab(e, id) {
    if (e) e.preventDefault()
    const scroller = document.getElementById('scroller').parentElement
    // const scrollLeft = scroller.scrollLeft
    const scrollerWidth = scroller.offsetWidth
    // const tab = document.getElementById(`tabId${id}`)
    const tabLeft = document.getElementById(`tabId${id}`).offsetLeft - 16
    const tabWidth = document.getElementById(`tabId${id}`).offsetWidth
    if ((tabLeft + tabWidth) > scrollerWidth) {
        scroller.scrollLeft = ((tabLeft + tabWidth) - scrollerWidth) + 1
    } else {
        scroller.scrollLeft = 0
    }
    return (dispatch) => {
        dispatch({
            type: types.CREAR_REGISTRO_SELECCION_TAB,
            payload: id
        })
    }
}

export function almacenarRegistro(formulario_id) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const fn0 = (d, e) => d({
            type: types.CREAR_REGISTRO_DESHABILITAR_BOTON_GUARDAR,
            payload: e
        })
        fn0(dispatch, true)
        const registro = getState().crearregistro.get('registro')
        const modificaciones = diff(
            getState().crearregistro.get('oldSecciones'),
            evalVariablesFormulas(getState().crearregistro.get('secciones'))
        )
        let data = new FormData()

        modificaciones.forEach(m => {
            const regex = /^\/(\d+)\/VARIABLES\/(\d+)\/VALOR$/g
            const match = regex.exec(m.get('path'))
            if (match && m.get('value')) {
                data.append(`${match[1]}.${match[2]}`, m.get('value'))
            }
        })

        if ([...data.keys()].length > 0) {
            if (registro == null) {
                axios.post(`${API_URL}/registros?formularioId=${formulario_id}`, data).then(res => {
                    displayMessages(dispatch, {
                        success: 'El registro ha sido creado exitosamente'
                    })
                    fn0(dispatch, false)
                    setTimeout(() => history.push(`/registros/formularios/${formulario_id}`), 1500)
                }).catch(err => {
                    displayMessages(dispatch, err)
                    fn0(dispatch, false)
                })
            } else {
                axios.put(`${API_URL}/registros/${registro.get('ID')}?formularioId=${formulario_id}`, data).then(res => {
                    displayMessages(dispatch, {
                        success: 'El registro ha sido actualizado correctamente exitosamente'
                    })
                    dispatch(obtenerRegistro(formulario_id, registro.get('ID')))
                    fn0(dispatch, false)
                }).catch(err => {
                    console.log(err)
                    showHideModalMsgBox(dispatch, err)
                    fn0(dispatch, false)
                })
            }
        } else {
            showHideModalMsgBox(dispatch, {
                warning: 'No hay modificaciones pendientes por actualizar'
            })
            fn0(dispatch, false)
        }
    }
}

export function modificarValores(path, value, immutable = false) {
    return (dispatch) => {
        dispatch({
            type: types.CREAR_REGISTRO_MODIFICAR_INPUTS,
            payload: {
                path,
                value,
                immutable
            }
        })
        dispatch(recalcularCompletado())
    }
}

export function recalcularCompletado() {
    return (dispatch, getState) => {
        const selected = getState().crearregistro.get('selectedSeccion')
        const seccion = getState().crearregistro.getIn(['secciones', String(selected)])
        const completado = calcularCompletadoSeccion(seccion)
        dispatch({
            type: types.CREAR_REGISTRO_MODIFICAR_INPUTS,
            payload: {
                path: `secciones.${selected}.COMPLETADO`,
                value: completado
            }
        })
    }
}

export function displayModalCreate(show, title, articulo) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const compania = getSelectedCompany().get('CODIGO_COMPANIA')
        if (!show) {
            dispatch({
                type: types.CREAR_REGISTRO_DISPLAY_MODAL_CREACION,
                payload: {
                    show
                }
            })
        } else {
            axios.get(`${API_URL}/articulos/${articulo.get('ART.ARTICULO')}?compania=${compania}`)
                .then((res) => {
                    history.push(`/articulos/${articulo.get('ART.ARTICULO')}`)
                })
                .catch(err => {
                    if (err.response && err.response.status && err.response.status === 400) {
                        let data = {
                            VARIABLES: {},
                            articuloDelta: articulo
                        }
                        getState().articulos.get('secciones').valueSeq().toList().sortBy(b => b.get('ORDEN_SECCION')).forEach(b => {
                            b.get('VARIABLES').valueSeq().filter(x => x.get('REQUERIDO') && x.get('EDITABLE')).sortBy(b => b.get('ORDEN_VARIABLE')).forEach(x => {
                                data.VARIABLES[x.get('ID')] = x
                            })
                        })
                        dispatch({
                            type: types.CREAR_REGISTRO_DISPLAY_MODAL_CREACION,
                            payload: {
                                show,
                                title,
                                data
                            }
                        })
                    } else {
                        showHideModalMsgBox(dispatch, err)
                    }
                })
        }
    }
}

export function crearArticulo() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const compania = getSelectedCompany().get('CODIGO_COMPANIA')
        const data = getState().crearregistro.getIn(['modalCreate', 'data'])
        const articulo = {
            articulo: data.getIn(['articuloDelta', 'ART.ARTICULO'])
        }
        data.get('VARIABLES').forEach(x => {
            articulo[x.get('NOMBRE_VARIABLE')] = x.get('VALOR') || null
        })

        axios.post(`${API_URL}/articulos?compania=${compania}`, articulo)
            .then((res) => {
                history.push(`/articulos/${articulo.articulo}`)
                dispatch(displayModalCreate(false))
            })
            .catch(err => {
                console.log(err)
                history.push('/articulos')
                showHideModalMsgBox(dispatch, err)
            })
    }

}

export function resetValorVariablesDependientes(seccionId, variableId) {
    return {
        type: types.CREAR_REGISTRO_REINICIAR_VALOR_VARIABLE_DEPENDIENTE,
        payload: {
            seccionId,
            variableId
        }
    }
}