import Immutable from 'immutable'
import diff from 'immutablediff'
import patch from 'immutablepatch'
import * as types from '../../types'

import {
    isPrimitive
} from '../../utils/helpers'

const INITIAL_STATE = Immutable.fromJS({
    registro: {},
    oldSecciones: {},
    secciones: {},
    modificaciones: {},
    selectedSeccion: null,
    update: false,
    modalCreate: {
        show: false,
        title: '',
        data: null,
    },
    variablesDependientes: []
})

export default function (state = INITIAL_STATE, action) {
    let ops
    switch (action.type) {
        case types.CREAR_REGISTRO_POBLAR_REGISTRO:
            state = state.set('secciones', Immutable.fromJS(action.payload.secciones))
            state = state.set('oldSecciones', Immutable.fromJS(action.payload.secciones))
            state = state.set('registro', Immutable.fromJS(action.payload.registro))

            const firstSeccion = state.get('secciones').filter(x => !x.get('RESUMEN')).valueSeq().toList().sortBy(x => x.get('ORDEN_SECCION')).first()
            state = state.set('selectedSeccion', firstSeccion ? firstSeccion.get('ID') : 0)

            state.get('secciones').valueSeq().forEach(s => {
                s.get('VARIABLES').valueSeq().forEach(v => {
                    if (v.get('REQUERIDO') && v.get('EDITABLE')) {
                        state = state.setIn(['modificaciones', `SEC${s.get('ID')}`, `VAR${v.get('ID')}`, '0'], v.get('VALOR'))
                    }
                    if (v.get('VARIABLE_PADRE_ID') && !s.get('RESUMEN')) {
                        state = state.update('variablesDependientes', l => l.push(Immutable.fromJS({
                            seccion: s.get('ID'),
                            variable: v.get('ID'),
                            padre: v.get('VARIABLE_PADRE_ID')
                        })))
                    }
                })
            })
            return state
        case types.CREAR_REGISTRO_SELECCION_TAB:
            state = state.set('selectedSeccion', action.payload)
            return state
        case types.CREAR_REGISTRO_MODIFICAR_INPUTS:
            const regex = /^secciones\.(\d+)\.VARIABLES\.(\d+)\.VALOR$/g
            const match = regex.exec(action.payload.path)
            if (match) {
                const seccion = match[1]
                const variable = match[2]
                state = state.setIn(['modificaciones', `SEC${seccion}`, `VAR${variable}`, '0'], action.payload.value)
            }
            if (isPrimitive(action.payload.value)) {
                state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
            } else {
                if (action.payload.immutable === false) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                }
            }
            return state
        case types.CREAR_REGISTRO_REINICIAR_ESTADO:
            return INITIAL_STATE
        case types.CREAR_REGISTRO_ACTUALIZAR_ARTICULO:
            ops = diff(state.get('secciones'), Immutable.fromJS(action.payload))
            state = state.set('secciones', patch(state.get('secciones'), ops))
            return state
        case types.CREAR_REGISTRO_DISPLAY_MODAL_CREACION:
            if (action.payload.show) {
                state = state.setIn(['modalCreate', 'show'], true)
                state = state.setIn(['modalCreate', 'title'], action.payload.title)
                state = state.setIn(['modalCreate', 'data'], Immutable.fromJS(action.payload.data))
            } else {
                state = state.set('modalCreate', INITIAL_STATE.get('modalCreate'))
            }
            return state
        case types.CREAR_REGISTRO_DESHABILITAR_BOTON_GUARDAR:
            state = state.set('update', action.payload)
            return state
        case types.CREAR_REGISTRO_REINICIAR_VALOR_VARIABLE_DEPENDIENTE:
            let dependientes = []
            if (action.payload.seccionId) {
                dependientes = state.getIn(`secciones.${action.payload.seccionId}.VARIABLES`.split('.'))
                    .filter(x => x.get('VARIABLE_PADRE_ID') === action.payload.variableId)
                    .valueSeq().map(x => x.get('ID')).toJS()

                for (let i = 0; i < dependientes.length; i++) {
                    state = state.setIn(`secciones.${action.payload.seccionId}.VARIABLES.${dependientes[i]}.VALOR`.split('.'), null)
                }
            } else {
                state.get('variablesDependientes').filter(x => x.get('padre') === action.payload.variableId).valueSeq().forEach(x => {
                    state = state.setIn(`secciones.${x.get('seccion')}.VARIABLES.${x.get('variable')}.VALOR`.split('.'), null)
                })
            }

            return state
        default:
            return state
    }
}