import * as types from '../../types'
import Immutable from 'immutable'
import diff from 'immutablediff'
import patch from 'immutablepatch'
import { usuarioEntity } from '../../utils/constants';

const INITIAL_STATE = Immutable.fromJS({
    usuarios: {},
    roles: {},
    selected: null,
    filtro: '',
    sortBy: 'ID',
    asc: true,
    modalCreate: {
        show: false,
        title: '',
        mode: null,
        data: null,
    },
    saving: false,
    page: 1,
    size: 20,
    totalRegistros: 0
})

export default function (state = INITIAL_STATE, action) {
    let ops;
    switch (action.type) {
        case types.USUARIOS_POBLAR_USUARIOS:
            state = state.set('usuarios', Immutable.fromJS(action.payload.usuarios))
            state = state.set('totalRegistros', Immutable.fromJS(action.payload.total))
            state = state.set('page', Immutable.fromJS(action.payload.page))
            return state.set('roles', Immutable.fromJS(action.payload.roles))
        case types.USUARIOS_DISPLAY_MODAL_CREACION:
            if (action.payload.show) {
                state = state.setIn(['modalCreate', 'show'], true)
                state = state.setIn(['modalCreate', 'title'], action.payload.title)
                if (action.payload.mode === 'edit' && action.payload.id) {
                    let usuario = state.getIn(['usuarios', String(action.payload.id)])
                    state = state.setIn(['modalCreate', 'data'], usuario)
                } else {
                    state = state.setIn(['modalCreate', 'data'], Immutable.fromJS(usuarioEntity()))
                }
                state = state.setIn(['modalCreate', 'mode'], action.payload.mode)
            } else {
                state = state.set('modalCreate', INITIAL_STATE.get('modalCreate'))
            }
            return state
        case types.USUARIOS_CHANGE_USERNAME_FROM_EMAIL:
            let email = state.getIn(['modalCreate', 'data', 'EMAIL'])
            email = email.split("@")
            if (email.length > 1 && !state.getIn(['modalCreate', 'data', 'USERNAME'])) {
                state = state.setIn(['modalCreate', 'data', 'USERNAME'], email[0])
            }
            return state
        case types.USUARIOS_ACTUALIZAR_USUARIO:
            ops = diff(state.getIn(['usuarios', String(action.payload.ID)]), Immutable.fromJS(action.payload))
            state = state.setIn(['usuarios', String(action.payload.ID)], patch(state.getIn(['usuarios', String(action.payload.ID)]), ops))
            return state
        case types.USUARIOS_ACTUALIZAR_MOSTRAR_REGISTROS:
            return state.set('size', action.payload)
        default:
            return state;
    }
}
