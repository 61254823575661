import * as types from '../../types'
import Immutable from 'immutable'
import { getSelectedCompany } from '../../utils/helpers'

const INITIAL_STATE = Immutable.fromJS({
    user: null,
    email: '',
    password: '',
    processing: false,
    isAuthenticated: false,
})

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.SIGNIN_INICIAR_SESION:
            state = state.set('isAuthenticated', true)
            return state
        case types.SIGNIN_CERRAR_SESION:
            state = state.set('isAuthenticated', false)
            return state
        case types.SIGNIN_PROCESANDO:
            state = state.set('processing', action.payload)
            if (!action.payload) state = state.set('password', '')
            return state
        case types.SIGNIN_ASIGNAR_USUARIO_SESION:
            state = state.set('user', Immutable.fromJS(action.payload))
            let compania = getSelectedCompany()
            if (!compania.get('ID')) {
                compania = state.getIn(['user', 'COMPANIAS']).valueSeq().toList().first()
                localStorage.setItem('compania', JSON.stringify(compania.toJS()))
            }
            state = state.set('compania', compania.get('ID'))
            return state
        default:
            return state
    }
}