import * as types from '../../types'
import Immutable from 'immutable'
import diff from 'immutablediff'
import patch from 'immutablepatch'

import { isPrimitive, proximoCampoDisponible } from '../../utils/helpers'
import { maestraEntity, maestraConfigEntity, maestraDetalleEntity } from '../../utils/constants';

const INITIAL_STATE = Immutable.fromJS({
    maestras: {},
    selected: null,
    filtro: '',
    sortBy: 'ID',
    asc: true,
    modalCreate: {
        show: false,
        title: '',
        mode: null,
        data: null,
    },
    saving: false
})

export default function (state = INITIAL_STATE, action) {
    let data, selected, ops
    switch (action.type) {
        case types.MAESTRAS_POBLAR_MAESTRAS:
            state = state.set('maestras', Immutable.fromJS(action.payload.maestras))
            return state
        case types.MAESTRAS_DISPLAY_MODAL_CREACION:
            if (action.payload.show) {
                state = state.setIn(['modalCreate', 'show'], true)
                state = state.setIn(['modalCreate', 'title'], action.payload.title)
                if (action.payload.mode === 'edit') {
                    let maestra = state.getIn(['maestras', String(state.get('selected'))])
                    maestra = maestra.set('ENT_CONFIG_MAESTRAS', Immutable.fromJS(maestra.get('ENT_CONFIG_MAESTRAS').sortBy(x => x.get('ORDER_KEY')).valueSeq().toJS()))
                    state = state.setIn(['modalCreate', 'data'], maestra)
                } else {
                    state = state.setIn(['modalCreate', 'data'], Immutable.fromJS(maestraEntity()))
                }
                state = state.setIn(['modalCreate', 'mode'], action.payload.mode)
            } else {
                state = state.set('modalCreate', INITIAL_STATE.get('modalCreate'))
            }
            return state
        case types.MAESTRAS_MODIFICAR_INPUTS:
            if (isPrimitive(action.payload.value)) {
                state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
            } else {
                if (action.payload.immutable === false) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                }
            }
            return state
        case types.MAESTRAS_ADICIONAR_CONFIGURACION:
            for (let i = 0, j = state.getIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS']).count(); i < j; i++) {
                state = state.setIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS', String(i), 'ORDER_KEY'], i + 1)
            }
            data = state.getIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS']).maxBy(x => x.get('ORDER_KEY')).get('ORDER_KEY') + 1
            let campo = proximoCampoDisponible(state.getIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS']))
            state = state.updateIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS'], l => l.push(Immutable.fromJS(maestraConfigEntity(data, campo))))
            return state
        case types.MAESTRAS_ELIMINAR_MAESTRAS:
            state = state.set('selected', null)
            state = state.deleteIn(['maestras', String(action.payload)])
            return state
        case types.MAESTRAS_ELIMINAR_CONFIGURACIONES:
            state = state.setIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS'], state.getIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS']).filter(x => x.get('SELECTED') !== true))
            for (let i = 0, j = state.getIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS']).count(); i < j; i++) {
                state = state.setIn(['modalCreate', 'data', 'ENT_CONFIG_MAESTRAS', String(i), 'ORDER_KEY'], i + 1)
            }
            return state
        case types.MAESTRAS_ELIMINAR_CONFIGURACION:
            selected = state.get('selected')
            state = state.deleteIn(['maestras', String(selected), 'ENT_CONFIG_MAESTRAS', String(action.payload)])
            return state
        case types.MAESTRAS_ACTUALIZAR_MAESTRA:
            ops = diff(state.getIn(['maestras', String(action.payload.ID)]), Immutable.fromJS(action.payload))
            state = state.setIn(['maestras', String(action.payload.ID)], patch(state.getIn(['maestras', String(action.payload.ID)]), ops))
            return state
        case types.MAESTRAS_ACTUALIZAR_MAESTRAS:
            ops = diff(state.get('maestras'), Immutable.fromJS(action.payload))
            state = state.set('maestras', patch(state.get('maestras'), ops))
            return state
        case types.MAESTRAS_SELECCIONAR_MAESTRA:
            state = state.set('selected', action.payload)
            return state
        case types.MAESTRAS_ADICIONAR_DETALLE:
            selected = state.get('selected')
            let num = state.getIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS']).valueSeq().map(x => x.get('ORDER_KEY')).toJS()
            if (num.length > 0) {
                num = (Math.max(...num)) + 1
            } else {
                num = 1
            }
            
            data = maestraDetalleEntity(selected, num)
            state = state.setIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS', data.GUID], Immutable.fromJS(data))
            return state
        case types.MAESTRAS_ELIMINAR_DETALLE:
            selected = state.get('selected')
            state = state.deleteIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS', String(action.payload)])
            return state
        case types.MAESTRAS_ELIMINAR_DETALLES:
            selected = state.get('selected')
            state = state.setIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS'], state.getIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS']).filter(x => x.get('SELECTED') !== true))
            state.getIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS']).valueSeq().sortBy(x => x.get('ORDER_KEY')).forEach((a, b) => {
                state = state.setIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS', String(a.get('ID') || a.get('GUID')), 'ORDER_KEY'], b + 1)
            })
            return state
        case types.MAESTRAS_ACTUALIZAR_DETALLES_MAESTRA:
            selected = state.get('selected')
            ops = diff(state.getIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS']), Immutable.fromJS(action.payload))
            state = state.setIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS'], patch(state.getIn(['maestras', String(selected), 'ENT_DETALLE_MAESTRAS']), ops))
            return state
        case types.MAESTRAS_MODIFICAR_FILTRO:
            state = state.set('filtro', action.payload)
            return state
        case types.MAESTRAS_ORDENAR_COLUMNA:
            state = state.set('sortBy', action.payload)
            if (action.payload === state.get('sortBy')) {
                state = state.set('asc', !state.get('asc'))
            } else {
                state = state.set('asc', true)
            }
            return state
        case types.MAESTRAS_GUARDANDO:
            state = state.set('saving', action.payload)
            return state
        default:
            return state
    }
}