import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import numeral from "numeral";
import Input from '@mawesi-sas/mws_react_component_input';

import Box from "../Layout/components/Box";
import ButtonStrip from "../Layout/components/ButtonStrip";
import BoxButton from "../Layout/components/BoxButton";
import ModalCreate from "./components/ModalCreate";

import {
  displayModalCreate,
  obtenerSecciones,
  seleccionarSeccion,
  eliminarSecciones,
  conmutarSeleccionRegistros,
  filtrarSecciones,
  ordenarPor
} from "./actions";
import { showModalConfirmAction } from "../Layout/actions";
import BoxSection from "../Layout/components/BoxSection";

export const tableColumns = [
  {
    ID: 2,
    CAPTION: "Nombre abreviado",
    VALUE: "ABREVIADO",
    TYPE: "string",
    FORMAT: "",
    LINK: true
  },
  {
    ID: 3,
    CAPTION: "Nombre completo",
    VALUE: "TITULO",
    TYPE: "string",
    FORMAT: ""
  },
  { ID: 4, CAPTION: "Color", VALUE: "COLOR", TYPE: "color", FORMAT: "" },
  { ID: 5, CAPTION: "Estado", VALUE: "ESTADO", TYPE: "bool", FORMAT: "" }
];

export function findValues(c, x) {
  let value = x.getIn(c.VALUE.split("."));
  return value;
}

class Secciones extends Component {
  componentWillMount() {
    this.props.obtenerSecciones();
  }

  render() {
    if (!this.props.elementos) return null;
    const { secciones, termino, sortBy, asc } = this.props;

    let records = secciones
      .valueSeq()
      .toList()
      .filter(
        x =>
          x
            .get("ABREVIADO")
            .toLowerCase()
            .indexOf(termino.toLowerCase()) > -1
      )
      .sortBy(x => x.getIn(sortBy.split(".")) || "");
    if (!asc) records = records.reverse();

    const buttons = [];
    if (this.props.elementos.filter(x => x === "dbh9HQ0F").count())
      buttons.push(
        <BoxButton
          key={`action[2][0]`}
          name="plus"
          onClick={e =>
            this.props.displayModalCreate(
              true,
              "Creación de nueva seccion",
              "create"
            )
          }
          title="Nueva seccion"
        />
      );
    if (this.props.elementos.filter(x => x === "tZ0hu1wA").count())
      buttons.push(
        <BoxButton
          key={`action[2][1]`}
          name="trash"
          onClick={e =>
            this.props.showModalConfirmAction(
              true,
              "Está seguro que desea eliminar los registros seleccionados?",
              () => this.props.eliminarSecciones()
            )
          }
          title="Eliminar seccion"
          disabled={
            secciones.filter(x => x.get("SELECTED") === true).count() === 0
          }
          danger
        />
      );

    return (
      <div id="secciones"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <Box title="Secciones" stretch>
          <div className="row" style={{ height: "100%" }}>
            <div className="col-md-12" style={{ height: "100%" }}>
              <div className="row" style={{ height: "100%" }}>
                <div
                  className="col-md-12"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <div
                    className="widget"
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginBottom: "0"
                    }}
                  >
                    <BoxSection title="Listado de secciones">
                      <div style={{ padding: "0 5px", marginBottom: "10px" }}>
                        <ButtonStrip
                          actions={buttons}
                          searchBar={this.props.filtrarSecciones}
                          filtro={termino}
                        />
                      </div>
                      <div className="table-scrolled">
                        <table className="table table-condensed table-striped table-bordered table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "25px" }}>
                                <input
                                  type="checkbox"
                                  checked={
                                    secciones.count() ===
                                    secciones
                                      .filter(x => x.get("SELECTED"))
                                      .count()
                                  }
                                  onChange={e =>
                                    this.props.conmutarSeleccionRegistros(
                                      e.target.checked
                                    )
                                  }
                                />
                              </th>
                              {tableColumns.map(x => {
                                const style = {
                                  width: `${x.WIDTH}`,
                                  cursor: "pointer"
                                };
                                return (
                                  <th
                                    key={`th1[${x.ID}]`}
                                    style={style}
                                    onClick={() =>
                                      this.props.ordenarPor(x.VALUE)
                                    }
                                  >
                                    {x.CAPTION}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {records.count() > 0 ? (
                              records.map((a, b) => {
                                const path = `secciones.${a.get("ID")}`;
                                return (
                                  <tr
                                    key={`tr2[${a.get("ID")}]`}
                                    className={a.get("SELECTED") ? "info" : ""}
                                  >
                                    <td>
                                      <Input
                                        type="checkbox"
                                        reducer="secciones"
                                        path={`${path}.SELECTED`}
                                      />
                                    </td>
                                    {tableColumns.map((c, d) => {
                                      let alignment = "left";
                                      let value = findValues(c, a);
                                      if (c.TYPE) {
                                        switch (c.TYPE) {
                                          case "datetime":
                                            value = moment(value).format(
                                              c.FORMAT
                                            );
                                            alignment = "right";
                                            break;
                                          case "bool":
                                            if (typeof value === "string")
                                              value = parseInt(value, 10);
                                            value = (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "1.1em"
                                                }}
                                              >
                                                <i
                                                  style={{ color: "#41afbe" }}
                                                  className={`fa fa-${
                                                    value ? "check-" : ""
                                                  }square`}
                                                />
                                              </div>
                                            );
                                            break;
                                          case "color":
                                            let icon = "circle";
                                            if (value === null)
                                              icon = "circle-thin";
                                            value = (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "1.1em"
                                                }}
                                              >
                                                <i
                                                  style={{
                                                    color: value,
                                                    textShadow:
                                                      "0px 0px 1px #555"
                                                  }}
                                                  className={`fa fa-${icon}`}
                                                />
                                              </div>
                                            );
                                            break;
                                          case "number":
                                            value =
                                              value !== null
                                                ? numeral(value).format(
                                                    c.Format
                                                  )
                                                : null;
                                            alignment = "right";
                                            break;
                                          default:
                                        }
                                      }
                                      const style = { textAlign: alignment };

                                      if (
                                        c.LINK &&
                                        this.props.elementos
                                          .filter(e => e === "h0orhBkK")
                                          .count()
                                      ) {
                                        return (
                                          <td
                                            key={`td1[${a.get("ID")}][${c.ID}]`}
                                            className="LinkToEdit"
                                            style={style}
                                            onClick={e =>
                                              this.props.displayModalCreate(
                                                true,
                                                "Modificación de secciones",
                                                "edit",
                                                a.get("ID")
                                              )
                                            }
                                          >
                                            {value}
                                          </td>
                                        );
                                      } else {
                                        return (
                                          <td
                                            key={`td1[${a.get("ID")}][${c.ID}]`}
                                            style={style}
                                          >
                                            {value}
                                          </td>
                                        );
                                      }
                                    })}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={tableColumns.length + 1}
                                  style={{ textAlign: "center" }}
                                >
                                  No existen registros
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </BoxSection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <ModalCreate />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    secciones: state.secciones.get("secciones"),
    selected: state.secciones.get("selected"),
    termino: state.secciones.get("filtro"),
    sortBy: state.secciones.get("sortBy"),
    asc: state.secciones.get("asc"),
    elementos: state.layout.get("elementos")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    obtenerSecciones: () => dispatch(obtenerSecciones()),
    displayModalCreate: (show, title, mode, id) =>
      dispatch(displayModalCreate(show, title, mode, id)),
    seleccionarSeccion: id => dispatch(seleccionarSeccion(id)),
    eliminarSecciones: () => dispatch(eliminarSecciones()),
    conmutarSeleccionRegistros: checked =>
      dispatch(conmutarSeleccionRegistros(checked)),
    filtrarSecciones: filtro => dispatch(filtrarSecciones(filtro)),
    showModalConfirmAction: (show, message, action) =>
      dispatch(showModalConfirmAction(show, message, action)),
    ordenarPor: columna => dispatch(ordenarPor(columna))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Secciones);
