import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import Modal from '@mawesi-sas/mws_react_component_modal'
import Input from '@mawesi-sas/mws_react_component_input'

import {
    displayModalChangePassword,
    cambiarContrasena
} from '../actions';

class ModalChangePassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPwd1: false,
            showPwd2: false
        }
    }
    handleCloseModal = (e) => {
        const modal = document.getElementById('modalChangePassword')
        modal.classList.remove('fadeIn')
        modal.classList.add('fadeOut')

        const modalDialog = document.querySelector(`#modalChangePassword .modal-dialog`)
        modalDialog.classList.remove('zoomIn')
        modalDialog.classList.add('zoomOut')
        setTimeout(() => this.props.displayModalChangePassword(false), 300)
    }

    modalContent = (modalChangePassword, saving) => {
        return (
            <form id="form" onSubmit={e => { e.preventDefault(); this.props.cambiarContrasena() }} noValidate>
                <div className="buttonstrip">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1 }}>
                            <button className="box-button" title="Guardar" disabled={modalChangePassword.get(saving)} ><FontAwesome name="floppy-o" tag="i" /></button>
                        </div>
                    </div>
                </div>
                <div className="modal-dialog" style={{ width: '90%' }}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label className="control-label">Contraseña</label>
                                <Input reducer='layout' path='modalChangePassword.data.password' type={this.state.showPwd1 ? "text" : "password"} placeholder="Digite su contraseña" />
                                <FontAwesome name="eye"onMouseDown={e => this.setState({ showPwd1: true })}
                                    onMouseUp={e => this.setState({ showPwd1: false })} onTouchStart={e => this.setState({ showPwd1: true })}
                                    onTouchEnd={e => this.setState({ showPwd1: false })} style={{ position: 'absolute', top: '50%', right: '6%' }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label className="control-label">Repetir Contraseña</label>
                                <Input reducer='layout' path='modalChangePassword.data.repeatPassword' type={this.state.showPwd2 ? "text" : "password"} placeholder="Vuelva a escribir su contraseña para verificar" />
                                <FontAwesome name="eye"onMouseDown={e => this.setState({ showPwd2: true })}
                                    onMouseUp={e => this.setState({ showPwd2: false })} onTouchStart={e => this.setState({ showPwd2: true })}
                                    onTouchEnd={e => this.setState({ showPwd2: false })} style={{ position: 'absolute', top: '50%', right: '6%' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        const { modalChangePassword, saving } = this.props
        if (!modalChangePassword.get('show')) return null

        return (
            <Modal
                ancho={this.props.width < this.props.height ? "53%" : "33%"}
                mostrar={modalChangePassword.get('show')}
                contenido={this.modalContent(modalChangePassword, saving)}
                titulo={modalChangePassword.get('title')}
                nombre="modalChangePassword"
                onCerrarModal={(e) => this.handleCloseModal(e)}
            />
        )
    }
}

const mapStateToProps = (state, onwProps) => {
    return {
        modalChangePassword: state.layout.get('modalChangePassword'),
        width: state.layout.get('width'),
        height: state.layout.get('height'),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayModalChangePassword: (show) => dispatch(displayModalChangePassword(show)),
        cambiarContrasena: () => dispatch(cambiarContrasena()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangePassword)
