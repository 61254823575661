import React, { Component } from 'react'

export default class BoxSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visibility: true
        }
    }

	render() {
        const { title, actions, children } = this.props
		return (
            <div className="section-area" style={{ marginTop: 0 }}>
                <div className="section-area-header">
                    <h4 className="section-area-title">
                        { title }
                        <i className={ `box-minimize fa fa-angle-${this.state.visibility ? 'up' : 'down'}`}
                            onClick={e => this.setState({visibility: !this.state.visibility})}></i>
                    </h4>
                    <div>
                        { actions }
                    </div>
                </div>
                <div className={`section-area-body ${this.state.visibility ? 'is_open': ''}`}>{ children }</div>
            </div>
		)
	}
}