import { createAxiosInstance } from '../../utils/helpers'
import axios from 'axios'
import Cookies from 'universal-cookie'
import querystring from 'querystring'
import { API_URL } from '../../utils/constants'
import * as types from '../../types'
import history from '../../utils/history'

import { displayMessages } from '../Layout/actions'

const cookies = new Cookies()

export function signIn() {
    return function (dispatch, getState) {
        const email = getState().signin.get('email');
        const password = getState().signin.get('password');
        const fn0 = d => d({ type: types.SIGNIN_PROCESANDO, payload: true  })
        fn0(dispatch)

        const data = querystring.stringify({
            grant_type: 'password',
            username: email,
            password: password
        })

        axios
            .post(`${API_URL}/account/signin`, data)
            .then((res) => {
                cookies.set('token', res.data.access_token, { path: '/' })
                const fn1 = d => d({ type: types.SIGNIN_INICIAR_SESION })
                fn1(dispatch)
                history.push('/')
            })
            .catch((err) => {
                const fn5 = d => d({ type: types.SIGNIN_PROCESANDO, payload: false  })
                fn5(dispatch)
                if (err.response && err.response.status === 401) {
                    dispatch({type: types.LAYOUT_MOSTRAR_MENSAJE, payload: { type: 'danger', message: 'Nombre de usuario o contraseña incorrectas' }})
                } else {
                    displayMessages(dispatch, err)
                }
            })
    }
}

export function signOut() {
    const axiosWithToken = createAxiosInstance()
    return function (dispatch) {
        if (axiosWithToken) {
            axiosWithToken.get(`${API_URL}/account/signout`)
            .then((res) => {
                cookies.remove('token', { path: '/' })
                dispatch({ type: types.SIGNIN_CERRAR_SESION })
                history.push('/signin')
            })
            .catch((err) => {
                cookies.remove('token', { path: '/' })
                dispatch({ type: types.SIGNIN_CERRAR_SESION })
                history.push('/signin')
            })

        } else {
            cookies.remove('token', { path: '/' })
            dispatch({ type: types.SIGNIN_CERRAR_SESION })
            history.push('/signin')
        }
    }
}

export function getUserFromToken(){
    const axiosWithToken = createAxiosInstance()
    return axiosWithToken.get(`${API_URL}/account/user`)
    .then((res) => {
        const data = res.data
        data.Usuario.Fullname = `${data.Usuario.NOMBRES} ${data.Usuario.APELLIDOS}`

        data.Modulos.push({
            CADENA_EJECUCION: "root",
            CODIGO_COMPANIA: null,
            COD_MODULO_HIJO: null,
            COD_MODULO_PADRE: null,
            COD_NODO: 9999,
            COD_ROL: null,
            NOM_MODULO: "Root",
            CONFIG: { menu: 1 }
        })

        const objDict = data.Modulos.reduce(function(p,c) {
            if (c.NOM_MODULO !== "Root" && c.COD_MODULO_PADRE === 0) c.COD_MODULO_PADRE = 9999
            p[c.COD_NODO] = c
            c.SUBMODULOS = []
            return p
            
        }, {})

        const modulos = data.Modulos.reduce(function(p,c) {
            if (!c.COD_MODULO_PADRE) {
                p = c
            }
            else {
                if (objDict[c.COD_MODULO_PADRE]) {
                    objDict[c.COD_MODULO_PADRE].SUBMODULOS.push(c)
                }
            }
            return p
        }, {})
        data.Usuario.MODULOS = modulos.SUBMODULOS
        data.Usuario.ELEMENTOS = data.Elementos
        data.Usuario.COMPANIAS = data.Companias
        data.Usuario.SCRIPTS = data.Scripts

        return data.Usuario
    })
    .catch((err) => {
        throw err
    })
}