import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { Input } from '@mawesi-sas/mws_react_component_input';
import Modal from '@mawesi-sas/mws_react_component_modal'

class LocationField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            draggable: true,
            open: false,
            zoom: 15,
            position: []
        }
    }

    refmarker = createRef()

    componentWillMount() {
        navigator.geolocation.getCurrentPosition(
            (position) => this.setState({ position: [position.coords.latitude, position.coords.longitude] })
        )
    }

    toggleDraggable = () => {
        this.setState({ draggable: !this.state.draggable })
    }

    handleOpenModal = (e) => {
        e.preventDefault();
        this.setState({
            open: true
        });
    }

    handleCloseModal = () => {
        if (!this.props.valor && this.state.position.length) {
            this.props.setLocation(this.props.path, `${this.state.position[0]},${this.state.position[1]}`)
        }
        this.setState({ open: false })
    }

    handlePositionClick = (e) => {
        const { lat, lng } = e.latlng
        this.props.setLocation(this.props.path, `${lat},${lng}`)
    }

    handlePosition = () => {
        const marker = this.refmarker.current
        if (marker != null) {
            const { lat, lng } = marker.leafletElement.getLatLng()
            this.props.setLocation(this.props.path, `${lat},${lng}`)
        }
    }

    modalContent = (position) => {
        if (position.length < 2) return <span>Por favor, habilite compatir ubicación en el navegador o verifique las coordenadas</span>

        return (
            <Map center={position} zoom={this.state.zoom} style={{ "height": "60vh" }} onClick={this.handlePositionClick}>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position} ref={this.refmarker} onDragend={this.handlePosition} draggable={this.state.draggable}>
                    <Popup onClick={this.toggleDraggable}>
                        Usted se encuentra aquí
                </Popup>
                </Marker>
            </Map>
        )
    }

    render() {
        let { position, open } = this.state;

        if (this.props.valor) {
            position = this.props.valor.split(',')
        }
        return (
            <div className="form-group SelectorInput">
                <Input type="text" reducer="crearregistro" path={this.props.path} value="position" />
                <button className="btn btn-default btn-xs" onClick={this.handleOpenModal}><b>...</b></button>
                <Modal
                    ancho={window.innerWidth < window.innerHeight ? "90%" : "50%"}
                    mostrar={open}
                    contenido={this.modalContent(position)}
                    titulo={this.props.title}
                    nombre="modalMap"
                    onCerrarModal={this.handleCloseModal}
                    botones={[]}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        valor: state[ownProps.reducer].getIn(ownProps.path.split('.'))
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationField)