const formulajs = require('./formulajs/index')
const excelFormula = require('excel-formula')

module.exports.evalExcelFormula = (formula) => {
    const lib = formulajs
    const tokens = excelFormula.getTokens(formula);
    let convertedFormula = '';
    let aoaStatus = 0;

    for(let n = 0; n < tokens.length; n++) {
        if (tokens[n].type === 'function') {
            if (tokens[n].subtype === 'start') {
                convertedFormula += 'lib.' + tokens[n].value + '('
            } else if (tokens[n].subtype === 'stop') {
                convertedFormula += ')'
            }
        } else if (tokens[n].type === 'operand') {
            if (tokens[n].subtype === 'text') {
                convertedFormula += '"' + tokens[n].value + '"'
            } else if (tokens[n].subtype === 'range') {
                if (tokens[n].value.startsWith('[[')) {
                    aoaStatus = 1
                    tokens[n].value = tokens[n].value.replace('[[', '[')
                }
                if (tokens[n].value.endsWith(']]')) {
                    aoaStatus = -1
                    tokens[n].value = tokens[n].value.replace(']]', ']')
                }
                const regex = /\[(?:([A-Za-z0-9_.\-\+" ])(?:,)?(?:\s)?)+\]/g
                const isArray = (tokens[n].value.replace(/ /g,'').match(regex) != null)
                if (isArray) {
                    const subregex = /(?:([A-Za-z0-9_.\+\-]))+/g
                    let arr = tokens[n].value
                    let values = tokens[n].value.match(subregex)
                    let skip = tokens[n].value.match(/(["'])(?:(?=(\\?))\2.)*?\1/g)

                    for (let i = 0; i < values.length; i++) {
                        if (skip && skip.indexOf(`"${values[i]}"`) !== -1) continue
                        if (isNaN(values[i])) {
                            const replaceWith = `rw['${values[i]}']`
                            arr = arr.replace(values[i], replaceWith)
                        }
                    }
                    convertedFormula += `${(aoaStatus === 1) ? '[' : ''}${arr}${(aoaStatus === -1) ? ']' : ''}`
                    aoaStatus = 0
                } else {
                    convertedFormula += `${(aoaStatus === 1) ? '[' : ''}rw['${tokens[n].value}']${(aoaStatus === -1) ? ']' : ''}`
                    aoaStatus = 0
                }                
            } else if (tokens[n].subtype === 'number') {
                convertedFormula += `${tokens[n].value}`
            } else {
                convertedFormula += `rw['${tokens[n].value}']`
            }
        } else if (tokens[n].type === 'subexpression') {
            if (tokens[n].subtype === 'start') {
                convertedFormula += '('
            } else if (tokens[n].subtype === 'stop') {
                convertedFormula += ')'
            }
        } else if (tokens[n].type === 'operator-infix') {
            if (tokens[n].subtype === 'logical') {
                if (tokens[n].value === '=') {
                    convertedFormula += '=='
                } else if (tokens[n].value === '<>') {
                    convertedFormula += '!=='
                } else {
                    convertedFormula += tokens[n].value
                }
            } else {
                convertedFormula += tokens[n].value
            }
        } else {
            convertedFormula += tokens[n].value
        }
    }

    convertedFormula = convertedFormula.replace(/""/g, 'null')
    convertedFormula = convertedFormula.replace(/''/g, 'null')
    convertedFormula = convertedFormula.replace(/rw\['null'\]/, 'null')

    let fn1 = () => '#ERROR'
    try {
        // eslint-disable-next-line no-new-func
        const fn = new Function('lib', 'rw', `return ${convertedFormula}`)
        fn1 = (item) => fn(lib, item)
    } catch (err) {}
    return fn1
}