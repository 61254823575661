import * as types from '../../types'
import {
    API_URL
} from '../../utils/constants'
import {
    createAxiosInstance
} from '../../utils/helpers'
import {
    displayMessages,
    setSavingState
} from '../Layout/actions'
import objectifyArray from 'objectify-array'

export function obtenerFuentes() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const fn1 = () => axios.get(`${API_URL}/fuentes`)

        Promise.all([fn1()])
            .then((res) => {
                const fuentes = objectifyArray(res[0].data, {
                    by: ['ID'],
                    recursive: true
                })
                const fn3 = d => d({
                    type: types.FUENTES_POBLAR_FUENTES,
                    payload: {
                        fuentes
                    }
                })
                fn3(dispatch)
            })
            .catch((err) => {
                console.log(err)
                displayMessages(dispatch, err)
            })
    }
}

export function displayModalCreate(show, title, mode, id = null) {
    return (dispatch) => {
        dispatch({
            type: types.FUENTES_DISPLAY_MODAL_CREACION,
            payload: {
                show,
                title,
                mode,
                id
            }
        })
    }
}

export function displayModalEdit(show, title, mode, id = null) {
    return (dispatch) => {
        dispatch({
            type: types.FUENTES_DISPLAY_MODAL_EDITAR,
            payload: {
                show,
                title,
                mode,
                id
            }
        })
    }
}

export function displayModalCreateFolder(show, title, mode, id = null) {
    return (dispatch) => {
        dispatch({
            type: types.FUENTES_DISPLAY_MODAL_CREACION_FOLDER,
            payload: {
                show,
                title,
                mode,
                id
            }
        })
    }
}

export function seleccionarFuente(id) {
    return (dispatch) => {
        dispatch({
            type: types.FUENTES_SELECCIONAR_FUENTE,
            payload: id
        })
    }
}

export function conmutarSeleccionRegistros(checked) {
    return (dispatch) => {
        dispatch({
            type: types.FUENTES_CONMUTAR_SELECCION_FUENTES,
            payload: checked
        })
    }
}

export function almacenarFuente(mode) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('fuentes', true))
        const formdata = new FormData();
        let data = {};

        if (mode === 'create') {
            dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: true })
            data = getState().fuentes.getIn(['modalCreate', 'data', 'FUENTES']).valueSeq().forEach((f, idx) => {
                formdata.append(`NOMBRE[${idx}]`, f.get('NOMBRE'))
                formdata.append(`DESCRIPCION[${idx}]`, f.get('DESCRIPCION'))
                formdata.append(`CARPETA[${idx}]`, f.get('CARPETA'))
                formdata.append('file[]', f.get('file'))
            })
            axios.post(`${API_URL}/fuentes`, formdata)
                .then(res => {
                    const fuentes = objectifyArray(res.data, {
                        by: ['ID'],
                        recursive: true
                    })

                    dispatch({
                        type: types.FUENTES_ACTUALIZAR_FUENTE,
                        payload: fuentes
                    })
                    dispatch(displayModalCreate(false))
                    dispatch(setSavingState('fuentes', false))
                    displayMessages(dispatch, {
                        success: 'La fuente ha sido guardada exitosamente'
                    })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('fuentes', false))
                    displayMessages(dispatch, err)
                })
                .finally(() => dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: false }))
        } else if (mode === 'edit') {
            data = getState().fuentes.getIn(['modalEdit', 'data'])
            dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: true })
            formdata.append('NOMBRE', data.get('NOMBRE'))
            formdata.append('DESCRIPCION', data.get('DESCRIPCION'))
            formdata.append('CARPETA', data.get('CARPETA'))
            formdata.append('file', data.get('file'))

            axios.put(`${API_URL}/fuentes/${data.get('ID')}`, formdata)
                .then(res => {
                    const fuente = objectifyArray([res.data], {
                        by: ['ID'],
                        recursive: true
                    })
                    dispatch({
                        type: types.FUENTES_ACTUALIZAR_FUENTE,
                        payload: fuente
                    })
                    dispatch(displayModalEdit(false))
                    dispatch(setSavingState('fuentes', false))
                    displayMessages(dispatch, {
                        success: 'La fuente ha sido guardada exitosamente'
                    })
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setSavingState('fuentes', false))
                    displayMessages(dispatch, err)
                })
                .finally(() => dispatch({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: false }))
        }
    }
}

export function eliminarFuentes() {
    return (dispatch, getState) => {
        let toDelete = getState().fuentes.get('fuentes').valueSeq().filter(x => x.get('SELECTED') === true)
        const total = toDelete.count()
        toDelete = toDelete.filter(x => x.get('ID') > 0)
        if (toDelete.count() > 0) {
            const axios = createAxiosInstance()
            const fns = []
            toDelete.map(x => x.get('ID')).forEach(x => {
                const fn = () => axios.delete(`${API_URL}/fuentes/${x}`)
                fns.push(fn)
            })

            const toResultObject = (promise) => {
                return promise
                    .then(result => ({
                        success: true,
                        result
                    }))
                    .catch(error => ({
                        success: false,
                        error
                    }));
            }

            Promise.all(fns.map(x => x()).map(toResultObject))
                .then(res => {
                    const succeded = []
                    const failed = []
                    res.forEach(x => {
                        if (!x.success) {
                            failed.push(x.error.response.data.ExceptionMessage)
                        } else {
                            succeded.push(1)
                            dispatch({
                                type: types.FUENTES_ELIMINAR_FUENTE,
                                payload: x.result.data.ID
                            })
                        }
                    })
                    if (succeded.length > 0) displayMessages(dispatch, {
                        success: `Se han eliminado ${succeded.length + (total - toDelete.count())} de los ${total} registros`
                    })
                    if (failed.length > 0) displayMessages(dispatch, {
                        response: {
                            status: 500,
                            data: failed.join('\n')
                        }
                    })
                })
        } else {
            displayMessages(dispatch, {
                warning: 'No seleccionó ningún registro para eliminar'
            })
        }
    }
}

export function filtrarFuentes(termino) {
    return (dispatch) => {
        dispatch({
            type: types.FUENTES_MODIFICAR_FILTRO,
            payload: termino
        })
    }
}

export function ordenarPor(columna) {
    return (dispatch) => {
        dispatch({
            type: types.FUENTES_ORDENAR_COLUMNA,
            payload: columna
        })
    }
}

export function showModalConfirmAction(open, message = null, action = null) {
    return (dispatch) => {
        dispatch({
            type: types.LAYOUT_MOSTRAR_MODAL_CONFIRMACION_ACCION,
            payload: {
                open,
                message,
                action
            }
        })
    }
}

export function massiveUploadFiles(files, index=null) {
    return (dispatch, getState) => {
        if (files.length > 1) {
            displayMessages(dispatch, {
                message: 'No es posible cargar más de 1 fuente'
            })
        } else {
            const fn1 = (d) => d({
                type: types.FUENTES_CARGAR_FUENTE,
                payload: {
                    file: files[0],
                    index
                }
            })
            fn1(dispatch)
        }
    }
}

export function getFolders() {
    const axios = createAxiosInstance()
    return (dispatch) => {
        axios.get(`${API_URL}/fuentes/folder`)
            .then(res => {
                const folders = res.data.map(f => ({
                    value: f,
                    caption: f
                }))

                dispatch({
                    type: types.FUENTES_OBTENER_CARPETAS,
                    payload: folders
                })
            }).catch(err => displayMessages(dispatch, err))
    }
}

export function createFolder() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('fuentes', true))
        const data = getState().fuentes.getIn(['modalCreateFolder','data']).toJS()
        axios.post(`${API_URL}/fuentes/folder`, data)
            .then(res => {
                dispatch(getFolders())
                dispatch(displayModalCreateFolder(false))
                displayMessages(dispatch, { success: 'Se ha creado la carpeta exitosamente' })
            })
            .catch(err => displayMessages(dispatch, err))
            .finally(() => {
                dispatch(setSavingState('fuentes', false))
            })
    }
}

export function addFileCreate() {
    return {
        type: types.FUENTES_AGREGAR_ARCHIVO
    }
}
export function deleteFileCreate() {
    return {
        type: types.FUENTES_ELIMINAR_ARCHIVO
    }
}

export function actualizarTreeFuentes(newTreeFuentes) {
    return {
        type: types.FUENTES_UPDATE_TREE_FUENTES,
        payload: newTreeFuentes
    }
}