/* eslint-disable */

var error = require('./error');

Array.prototype.isSorted = function() {
  return (function(direction) {
    return this.reduce(function(prev, next, i, arr) {
      if (direction === undefined)
        return (direction = prev <= next ? 1 : -1) || true;
      else
        return (direction + 1 ?
          (arr[i-1] <= next) : 
          (arr[i-1] >  next));
    }) ? Number(direction) : false;
  }).call(this);
}

Array.prototype.last = function(){
  return this[this.length - 1];
};

exports.MATCH = function(lookupValue, lookupArray, matchType) {
  if (!lookupValue && !lookupArray) {
    return error.na;
  }

  if (arguments.length === 2) {
    matchType = 1;
  }
  if (!(lookupArray instanceof Array)) {
    return error.na;
  }

  if (matchType !== -1 && matchType !== 0 && matchType !== 1) {
    return error.na;
  }
  var index;
  var indexValue;
  for (var idx = 0; idx < lookupArray.length; idx++) {
    if (matchType === 1) {
      if (lookupArray[idx] === lookupValue) {
        index = idx + 1;
      } else if (lookupArray[idx] < lookupValue) {
        if (!indexValue) {
          index = idx + 1;
          indexValue = lookupArray[idx];
        } else if (lookupArray[idx] > indexValue) {
          index = idx + 1;
          indexValue = lookupArray[idx];
        }
      }
    } else if (matchType === 0) {
      if (typeof lookupValue === 'string') {
        lookupValue = lookupValue.replace(/\?/g, '.');
        if (lookupArray[idx].toLowerCase().match(lookupValue.toLowerCase())) {
          return idx + 1;
        }
      } else {
        if (lookupArray[idx] === lookupValue) {
          return idx + 1;
        }
      }
    } else if (matchType === -1) {
      if (lookupArray[idx] === lookupValue) {
        return idx + 1;
      } else if (lookupArray[idx] > lookupValue) {
        if (!indexValue) {
          index = idx + 1;
          indexValue = lookupArray[idx];
        } else if (lookupArray[idx] < indexValue) {
          index = idx + 1;
          indexValue = lookupArray[idx];
        }
      }
    }
  }

  return index ? index : error.na;
};

exports.HLOOKUP = function(lookupValue, lookupTableArray, rowIndexNum, rangeLookup) {
  if (!lookupValue && !lookupTableArray && !rowIndexNum) {
    return error.na;
  }

  if (arguments.length === 3) {
    rangeLookup = 1;
  }

  if (!(lookupTableArray instanceof Array)) {
    return error.na;
  }

  if (!(lookupTableArray[0] instanceof Array)) {
    return error.na;
  }

  rowIndexNum = rowIndexNum - 1
  if (rowIndexNum > lookupTableArray.length) {
    return error.ref;
  }

  if (rangeLookup !== 0 && rangeLookup !== 1) {
    return error.na;
  }

  const isSorted = (lookupTableArray[0].isSorted() === 1)

  for (let n = 0; n < lookupTableArray[0].length; n++) {
    if (rangeLookup === 0) {
      if (typeof lookupValue === 'string') {
        lookupValue = lookupValue.replace(/\?/g, '.');
        if (lookupTableArray[0][n].toLowerCase().match(lookupValue.toLowerCase())) {
          return lookupTableArray[rowIndexNum][n];
        }
      } else {
        if (lookupTableArray[0][n] === lookupValue) {
          return lookupTableArray[rowIndexNum][n]
        }
      }
    } else {
      if (isSorted) {
        if (lookupTableArray[0][n] > lookupValue) {
          return lookupTableArray[rowIndexNum][n - 1] || error.na
        }
      }
    }
  }

  return error.na;
}

exports.VLOOKUP = function(lookupValue, lookupTableArray, rowIndexNum, rangeLookup) {
  if (!lookupValue && !lookupTableArray && !rowIndexNum) {
    return error.na;
  }

  if (arguments.length === 3) {
    rangeLookup = 1;
  }

  if (!(lookupTableArray instanceof Array)) {
    return error.na;
  }

  if (!(lookupTableArray[0] instanceof Array)) {
    return error.na;
  }

  rowIndexNum = rowIndexNum - 1;
  if (rowIndexNum > lookupTableArray.length) {
    return error.ref;
  }

  if (rangeLookup !== 0 && rangeLookup !== 1) {
    return error.na;
  }

  const isSorted = (lookupTableArray[0].isSorted() === 1)
  let variables = [];

  lookupTableArray.map(x => {
    variables.push(x[0]);
  })  
  
  for (let n = 0; n < variables.length; n++) {
    if (rangeLookup === 0) {
      if (typeof lookupValue === 'string' && typeof variables[n] === 'string') {
        lookupValue = lookupValue.replace(/\?/g, '.');
        if (variables[n].toLowerCase() === lookupValue.toLowerCase()) { 
          return lookupTableArray[n][rowIndexNum];
        }         
      }
      else {
        if (variables[n] === lookupValue) {       
          return lookupTableArray[n][rowIndexNum];
        }
      }
    }else{
      if (isSorted) {
        if (variables[n - 1] > lookupValue) {
          return lookupTableArray[n - 1][rowIndexNum] || error.na
        }
      }
    }     
  }

  return error.na;
}


exports.OFFSET = function(lookupTableArray, rows, cols) {
  if (!lookupTableArray && !rows && !cols) {
    return error.na;
  }

  if (!(lookupTableArray instanceof Array)) {
    return error.na;
  }

  if (rows > lookupTableArray.length - 1) {
    return error.ref;
  }

  for(let n = 0; n < lookupTableArray.length; n++) {
    if (cols > lookupTableArray[n].length - 1) {
      return error.ref;
    }
  }

  return lookupTableArray[rows][cols]
}