import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import Dropzone from "react-dropzone";
import Modal from '@mawesi-sas/mws_react_component_modal';
import Input from '@mawesi-sas/mws_react_component_input';
import { toast } from "react-toastify";
import BoxButton from "../../Layout/components/BoxButton";

import {
  displayModalCreate,
  almacenarScript,
  massiveUploadFiles,
  adicionarArgumento,
  eliminarArgumento,
  showModalConfirmAction,
} from "../actions";

class ModalCreate extends Component {
  handleCloseModal = (e) => {
    const modal = document.getElementById("modalCreate");
    modal.classList.remove("fadeIn");
    modal.classList.add("fadeOut");

    const modalDialog = document.querySelector(
      `#modalCreate .mws-modal-dialog`
    );
    modalDialog.classList.remove("zoomIn");
    modalDialog.classList.add("zoomOut");
    setTimeout(() => this.props.displayModalCreate(false), 300);
  };

  handleOnDrop = (files) => {
    this.props.massiveUploadFiles(files);
  };

  handleOnRejected = (files) => {
    files.forEach((x) => {
      toast.warn(`El archivo ${x.name} no es del tipo esperado`, {
        bodyClassName: "toastify-content toastify-warning",
      });
    });
  };

  modalContent = (modalCreate, boxActions, saving) => {
    return (
      <form
        id="form"
        onSubmit={(e) => {
          e.preventDefault();
          this.props.almacenarScript();
        }}
        noValidate
      >
        <div className="buttonstrip">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <button className="box-button" title="Guardar" disabled={saving}>
                <FontAwesome name="floppy-o" tag="i" />
              </button>
            </div>
          </div>
        </div>
        <div className="modal-dialog" style={{ width: "90%" }}>
          <div>
            <div className="dropzone1" style={{ marginBottom: "15px" }}>
              <Dropzone onDropAccepted={this.handleOnDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>
                        Arrastre los archivos a esta área o haga click para
                        seleccionarlos
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">Nombre</label>
                <Input
                  type="text"
                  reducer="scripts"
                  path="modalCreate.data.NOMBRE"
                  maxLength={100}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">Descripción</label>
                <Input
                  type="textarea"
                  reducer="scripts"
                  path="modalCreate.data.DESCRIPCION"
                  rows="3"
                  maxLength={1000}
                />
              </div>
            </div>
          </div>

          {/*
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="switch"
                                reducer="scripts"
                                path="modalCreate.data.SHINY"
                                label="Shiny"
                                commentario="Marque esta propiedad si el script utiliza Shiny"
                            />
                        </div>
                    </div>
                    */}

          <div
            style={{
              padding: "15px",
              border: "1px solid #ddd",
              marginTop: "10px",
            }}
          >
            <div className="buttonstrip">
              <div>
                <span
                  className="modal-box-title"
                  style={{ lineHeight: "30px" }}
                >
                  Argumentos
                </span>
              </div>
              <div>{boxActions}</div>
            </div>
            <div
              className="box-content"
              style={{
                height: "188px",
                overflow: "auto",
                background: "#f5f5f5",
              }}
            >
              <table className="table table-condensed table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "25px" }}></th>
                    <th>Nombre</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {modalCreate
                    .getIn(["data", "ARGUMENTOS"])
                    .valueSeq()
                    .map((x, n) => {
                      const path = `modalCreate.data.ARGUMENTOS.${n}`;
                      return (
                        <tr
                          key={`tr6[${x.get("ID") || x.get("GUID")}]`}
                          className={x.get("SELECTED") ? "info" : ""}
                        >
                          <td>
                            <Input
                              type="checkbox"
                              reducer="scripts"
                              path={`${path}.SELECTED`}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              reducer="scripts"
                              path={`${path}.NOMBRE`}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              reducer="scripts"
                              path={`${path}.VALOR`}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    );
  };

  render() {
    const { modalCreate, saving } = this.props;
    if (!modalCreate.get("show")) return null;

    const boxActions = [
      <BoxButton
        key="ba[0][0]"
        name="plus-circle"
        onClick={(e) => {
          e.preventDefault();
          this.props.adicionarArgumento();
        }}
        title="Nueva"
      />,
      <BoxButton
        key="ba[0][1]"
        name="trash"
        onClick={(e) => {
          e.preventDefault();
          this.props.showModalConfirmAction(
            true,
            "Está seguro que desea eliminar los registros seleccionados?",
            () => this.props.eliminarArgumento()
          );
        }}
        title="Eliminar"
        disabled={
          modalCreate
            .getIn(["data", "ARGUMENTOS"])
            .filter((x) => x.get("SELECTED"))
            .count() === 0
        }
        danger
      />,
    ];

    return (
      <Modal
        ancho={this.props.width < this.props.height ? "75%" : "45%"}
        mostrar={modalCreate.get("show")}
        contenido={this.modalContent(modalCreate, boxActions, saving)}
        titulo={modalCreate.get("title")}
        nombre="modalCreate"
        onCerrarModal={(e) => this.handleCloseModal(e)}
      />
    );
  }
}

const mapStateToProps = (state, onwProps) => {
  return {
    modalCreate: state.scripts.get("modalCreate"),
    saving: state.scripts.get("saving"),
    width: state.layout.get("width"),
    height: state.layout.get("height"),
  };
};

const mapDispatchToProps = {
  displayModalCreate,
  almacenarScript,
  massiveUploadFiles,
  adicionarArgumento,
  eliminarArgumento,
  showModalConfirmAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreate);
