import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import numeral from "numeral";

import ButtonStrip from "../Layout/components/ButtonStrip";
import BoxButton from "../Layout/components/BoxButton";
import ModalCreate from "./components/ModalCreate";
import DetalleFormulario from "./components/DetalleFormulario";
import ModalClone from "./components/ModalClone";

import {
  displayModalCreate,
  obtenerFormularios,
  seleccionarFormulario,
  displayModalClone,
  eliminarFormulario
} from "./actions";
import { showModalConfirmAction } from "../Layout/actions";
import Box from "../Layout/components/Box";
import BoxSection from "../Layout/components/BoxSection";

export const tableColumns = [
  {
    ID: 1,
    CAPTION: "Nombre",
    VALUE: "NOMBRE",
    TYPE: "string",
    FORMAT: "",
    WIDTH: "30%"
  },
  {
    ID: 2,
    CAPTION: "Descripción",
    VALUE: "DESCRIPCION",
    TYPE: "string",
    FORMAT: "",
    WIDTH: "70%"
  }
];

export function findValues(c, x) {
  let value = x.getIn(c.VALUE.split("."));
  return value;
}

class Formularios extends Component {
  componentWillMount() {
    if (this.props.compania) {
      this.props.obtenerFormularios();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.compania !== this.props.compania) {
      this.props.obtenerFormularios();
    }
  }

  render() {
    const { formularios, selected, compania, elementos } = this.props;
    if (!compania || !elementos) return null;

    const buttons = [];
    if (this.props.elementos.filter(x => x === "vEIMCgyp").count())
      buttons.push(
        <BoxButton
          key={`action[0][0]`}
          name="plus"
          onClick={e =>
            this.props.displayModalCreate(
              true,
              "Creación de nuevo formulario",
              "create"
            )
          }
          title="Nuevo formulario"
        />
      );
    if (this.props.elementos.filter(x => x === "tsddAKZ7").count())
      buttons.push(
        <BoxButton
          key={`action[0][1]`}
          name="pencil"
          onClick={e =>
            this.props.displayModalCreate(
              true,
              "Modificación de formulario",
              "edit"
            )
          }
          title="Modificar formulario"
          disabled={!!!selected}
        />
      );
    if (this.props.elementos.filter(x => x === "2d6RwRfX").count())
      buttons.push(
        <BoxButton
          key={`action[0][3]`}
          name="copy"
          onClick={e =>
            this.props.displayModalClone(true, "Clonación de formularios")
          }
          title="Clonar"
          disabled={!!!selected}
        />
      );
    if (this.props.elementos.filter(x => x === "yJ27c8LE").count())
      buttons.push(
        <BoxButton
          key={`action[0][2]`}
          name="trash"
          onClick={e =>
            this.props.showModalConfirmAction(
              true,
              "Esta seguro que desea eliminar este registro?",
              () => this.props.eliminarFormulario()
            )
          }
          title="Eliminar formulario"
          disabled={!!!selected}
          danger
        />
      );

    return (
      <div id="formularios_config"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "hidden"
        }}
      >
        <Box title="Formularios" stretch>
          <div className="row" style={{ height: "100%" }}>
            <div className="col-md-4">
              <div className="row" style={{ height: "100%" }}>
                <div
                  className="col-md-12"
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <div
                    className="widget"
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginBottom: "0"
                    }}
                  >
                    <BoxSection title="Listado de formularios">
                      <div style={{ padding: "0 5px", marginBottom: "10px" }}>
                        <ButtonStrip actions={buttons} />
                      </div>
                      <div className="table-scrolled">
                        <table className="table table-condensed table-striped table-bordered table-hover">
                          <thead>
                            <tr>
                              {tableColumns.map(x => {
                                const style = { width: `${x.WIDTH}` };
                                return (
                                  <th key={`th1[${x.ID}]`} style={style}>
                                    {x.CAPTION}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {formularios.valueSeq().count() > 0 ? (
                              formularios.valueSeq().map((a, b) => {
                                return (
                                  <tr
                                    key={`tr2[${a.get("ID")}]`}
                                    className={`${
                                      selected === a.get("ID") ? "info" : ""
                                    }`}
                                    onClick={() =>
                                      this.props.seleccionarFormulario(
                                        a.get("ID")
                                      )
                                    }
                                  >
                                    {tableColumns.map((c, d) => {
                                      let alignment = "left";
                                      let value = findValues(c, a);
                                      if (c.TYPE) {
                                        switch (c.TYPE) {
                                          case "datetime":
                                            value = moment(value).format(
                                              c.FORMAT
                                            );
                                            alignment = "right";
                                            break;
                                          case "bool":
                                            if (typeof value === "string")
                                              value = parseInt(value, 10);
                                            value = (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "1.1em"
                                                }}
                                              >
                                                <i
                                                  className={`fa fa-${
                                                    value ? "check-" : ""
                                                  }square`}
                                                />
                                              </div>
                                            );
                                            break;
                                          case "number":
                                            value = numeral(value).format(
                                              c.Format
                                            );
                                            alignment = "right";
                                            break;
                                          default:
                                        }
                                      }
                                      const style = { textAlign: alignment };

                                      if (c.LINK) {
                                        return (
                                          <td
                                            key={`td1[${a.get("ID")}][${c.ID}]`}
                                            className="LinkToEdit"
                                            style={style}
                                            onClick={e =>
                                              this.props.displayModalCreate(
                                                true,
                                                "Modificación de areas",
                                                "edit",
                                                a.get("ID")
                                              )
                                            }
                                          >
                                            {value}
                                          </td>
                                        );
                                      } else {
                                        return (
                                          <td
                                            key={`td1[${a.get("ID")}][${c.ID}]`}
                                            style={style}
                                          >
                                            {value}
                                          </td>
                                        );
                                      }
                                    })}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={tableColumns.length + 1}
                                  style={{ textAlign: "center" }}
                                >
                                  No existen registros
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </BoxSection>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row" style={{ height: "100%" }}>
                <DetalleFormulario />
              </div>
            </div>
          </div>
        </Box>
        <ModalCreate />
        <ModalClone />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formularios: state.formularios.get("estructuras"),
    selected: state.formularios.get("selected"),
    compania: state.signin.get("compania"),
    elementos: state.layout.get("elementos")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    obtenerFormularios: () => dispatch(obtenerFormularios()),
    displayModalCreate: (show, title, mode) =>
      dispatch(displayModalCreate(show, title, mode)),
    seleccionarFormulario: id => dispatch(seleccionarFormulario(id)),
    eliminarFormulario: () => dispatch(eliminarFormulario()),
    displayModalClone: (show, title) =>
      dispatch(displayModalClone(show, title)),
    showModalConfirmAction: (show, message, action) =>
      dispatch(showModalConfirmAction(show, message, action))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Formularios);
