import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Input from '@mawesi-sas/mws_react_component_input'
import BoxSection from '../../Layout/components/BoxSection'

import { obtenerOpciones, isNullOrEmpty } from '../../../utils/helpers'

class Resumen extends Component {
    render() {
        const { resumen, maestras, editable } = this.props
        if (!resumen) return null;

        return (
            <div id="resumen" className="col-md-12 col-sm-12 col-xs-12">
                <div className="widget">
                    <BoxSection title="Información General">
                            <div className="table-scrolled">
                                {
                                    (resumen.get('VARIABLES') && resumen.get('VARIABLES').valueSeq().sortBy(x => x.get('ORDEN_VARIABLE')).map(x => {
                                        const path = `secciones.${resumen.get('ID')}.VARIABLES.${x.get('ID')}.VALOR`
                                        const attrs = { reducer: 'crearregistro', path }
                                        let estado = x.get('ESTADO')
                                        if (!resumen.get('ESTADO_SECCION')) estado = false
                                        if (!estado) attrs.disabled = true
                                        if (!x.get('EDITABLE')) attrs.disabled = true
                                        if (!editable) attrs.disabled = true

                                        switch (x.get('TIPO_CAMPO')) {
                                            case 'Caja texto':
                                                if (x.get('TIPO_DATO') === 'Alfanumerico') {
                                                    attrs.type = 'text'
                                                    if (x.get('TAMANO')) attrs.maxLength = x.get('TAMANO')
                                                } else if (x.get('TIPO_DATO') === 'Fecha') {
                                                    attrs.type = 'date'
                                                } else {
                                                    if (x.get('TAMANO')) attrs.maxLength = x.get('TAMANO')
                                                    attrs.type = 'number'
                                                }
                                                break
                                            case 'Area texto':
                                                attrs.type = 'textarea'
                                                if (x.get('TAMANO')) attrs.maxLength = x.get('TAMANO')
                                                break
                                            case 'Lista maestra':
                                                attrs.type = 'select'
                                                if (resumen.getIn(`VARIABLES.${x.get('ID')}.VARIABLE_PADRE_ID`.split('.'))) {
                                                    attrs.options = maestras.count() ? 
                                                        obtenerOpciones(x.get('MAESTRA_ID'), false, resumen.getIn(`VARIABLES.${x.get('VARIABLE_PADRE_ID')}.VALOR`.split('.')))
                                                        : []
                                                } else {
                                                    attrs.options = maestras.count() ? obtenerOpciones(x.get('MAESTRA_ID'), false) : []
                                                }
                                                attrs.afterChange = () => this.props.resetValorVariablesDependientes(resumen.get('ID'), x.get('ID'))
                                                break
                                            case 'Panel color':
                                                attrs.type = 'color'
                                                break
                                        }

                                        const labelStyle = { fontSize: 'inherit', color: '#444' }
                                        if (!estado) {
                                            labelStyle.color = '#999'
                                            labelStyle.fontStyle = 'italic'
                                            labelStyle.textDecoration = 'line-through'
                                        }
                                        return (
                                            <div key={`var[${x.get('ID')}]`} className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group" style={{ marginBottom: '8px' }}>
                                                    {
                                                        (!isNullOrEmpty(x.get('FORMULA')) &&
                                                            <span style={{ marginRight: '4px', cursor: 'pointer' }} title={x.get('FORMULA')}><FontAwesome name="calculator" style={{ color: '#444' }} /></span>
                                                        )
                                                    }
                                                    <label className="control-label" style={labelStyle}>
                                                        {x.get('NOMBRE_VARIABLE')}
                                                    </label>
                                                    {
                                                        (x.get('AYUDA') &&
                                                            <span style={{ marginLeft: '4px', cursor: 'pointer' }} title={`${x.get('AYUDA_CAMPO')}`}><FontAwesome name="info-circle" style={{ color: '#2196f3' }} /></span>
                                                        )
                                                    }
                                                    <Input {...attrs} />
                                                </div>
                                            </div>
                                        )
                                    }))
                                }
                            </div>
                    </BoxSection>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        resumen: state.crearregistro.get('secciones').filter(x => x.get('RESUMEN')).first(),
        maestras: state.maestras.get('maestras')
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Resumen)