import FileDownload from 'react-file-download'
import moment from 'moment'
import * as types from '../../types'
import { API_URL, acceptedFiles } from '../../utils/constants'
import { createAxiosInstance } from '../../utils/helpers'
import { displayMessages, setSavingState } from '../Layout/actions'

export function displayModalDownload(show, title) {
    return { type: types.CARGUEMASIVO_MOSTRAR_MODAL_DESCARGA, payload: { show } }
}

export function seleccionarFormulario() {
    const axios = createAxiosInstance()
    
    return (dispatch, getState) => {
        const show = getState().carguemasivo.getIn(['modalDownload', 'show'])
        const title = getState().carguemasivo.getIn(['modalDownload', 'title'])
        const formularioId = getState().carguemasivo.getIn(['modalDownload', 'selectedFormulario'])

        axios.get(`${API_URL}/formularios?id=${formularioId}`)
            .then((res) => {
                let { data } = res
                data.SECCIONES_VARIABLES = data.SECCIONES_VARIABLES.filter(x => x.ESTADO)

                data.SECCIONES_VARIABLES = data.SECCIONES_VARIABLES
                    .filter(x => x.SECCION.ESTADO && x.VARIABLE.ESTADO && !x.VARIABLE.FORMULA)
                    .sort((a, b) => {
                        if (a.ORDEN_SECCION > b.ORDEN_SECCION) return 1
                        if (a.ORDEN_SECCION < b.ORDEN_SECCION) return -1
                        if (a.ORDEN_VARIABLE > b.ORDEN_VARIABLE) return 1
                        if (a.ORDEN_VARIABLE < b.ORDEN_VARIABLE) return -1
                        return 0
                    })
                dispatch({ type: types.CARGUEMASIVO_MOSTRAR_MODAL_DESCARGA, payload: { show, title, estructura: data } })
            })
            .catch((err) => {
                console.log(err)
                displayMessages(dispatch, err)
            })
    }
}

export function obtenerFormularios() {
    const axios = createAxiosInstance()

    return dispatch => {
        axios.get(`${API_URL}/formularios`).then(res => {
            const formularios = res.data.map(x => ({ value: x.ID, caption: x.NOMBRE }))
            dispatch({ type: types.CARGUEMASIVO_POBLAR_OPCION_FORMULARIOS, payload: { formularios } })
    }).catch (err => displayMessages(dispatch, err))
}
}

export function toggleSelectAllItems(value) {
    return (dispatch, getState) => {
        getState().carguemasivo.getIn(['modalDownload', 'estructura', 'SECCIONES_VARIABLES']).forEach((x, n) => {
            const path = `modalDownload.estructura.SECCIONES_VARIABLES.${n}.SELECTED`
            dispatch({ type: types.CARGUEMASIVO_MODIFICAR_INPUTS, payload: { path, value } })
        })
    }
}

export function descargarPlantilla() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        dispatch(setSavingState('carguemasivo_descarga', true))
        const formularioId = getState().carguemasivo.getIn(['modalDownload', 'selectedFormulario'])
        const variables = getState().carguemasivo.getIn(['modalDownload', 'estructura', 'SECCIONES_VARIABLES'])
                                    .filter(x => x.get('SELECTED')).map(x => `${x.get('SECCION_ID')}_${x.get('VARIABLE_ID')}`)
        const registros = getState().carguemasivo.getIn(['modalDownload', 'registros'])
        
        axios.post(`${API_URL}/archivos/descargar`, { formulario: formularioId, registros, variables }, { responseType: 'arraybuffer' })
            .then((res) => {
                FileDownload(res.data, `Plantilla_${moment().format('YYYYMMDDHHmmSS')}.xlsx`)
                dispatch(setSavingState('carguemasivo_descarga', false))
            })
            .catch((err) => {
                const encodedString = String.fromCharCode.apply(null, new Uint8Array(err.response.data))
                const decodedString = JSON.parse(decodeURIComponent(escape(encodedString)))
                const obj = {
                    response: {
                        data: decodedString,
                        status: 500
                    }
                }
                displayMessages(dispatch, obj)
                dispatch(setSavingState('carguemasivo_descarga', false))
            })
    }
}

export function displayModalUpload(show, title) {
    return dispatch => {
        dispatch({ type: types.CARGUEMASIVO_MOSTRAR_MODAL_CARGA, payload: { show, title } })
    }
}

export function seleccionArchivo(file) {
    return (dispatch) => {
        const fileType = acceptedFiles.filter(x => x.type === file.type)
        let icon = '_blank.png'
        if (fileType.length === 1) icon = fileType[0].icon
        const fn0 = (d) => d({ type: types.CARGUEMASIVO_MODIFICAR_INPUTS, payload: { path: 'modalUpload.data.nombre', value: file.name } })
        fn0(dispatch)
        const fn1 = (d) => d({ type: types.CARGUEMASIVO_MODIFICAR_INPUTS, payload: { path: 'modalUpload.data.file', value: file, immutable: false } })
        fn1(dispatch)
        const fn2 = (d) => d({ type: types.CARGUEMASIVO_MODIFICAR_INPUTS, payload: { path: 'modalUpload.data.icono', value: icon } })
        fn2(dispatch)
    }

}

export function cargarArchivo() {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        const file = getState().carguemasivo.getIn(['modalUpload', 'data', 'file'])
        const formularioId = getState().carguemasivo.getIn(['modalUpload', 'selectedFormulario'])
        if (!formularioId) {
            return displayMessages(dispatch, { warning: 'Por favor seleccione un formulario.' })
        }
        dispatch(displayModalUpload(false))
        const fn0 = (d) => d({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: true })
        const fn1 = (d) => d({ type: types.LAYOUT_MOSTRAR_MODAL_ESPERA, payload: false })
        fn0(dispatch)
        const data = new FormData()
        data.append(`Archivo`, file)
        axios.post(`${API_URL}/archivos/cargar?formulario=${formularioId}`, data)
            .then(res => {
                fn1(dispatch)
                displayMessages(dispatch, { success: `El archivo se ha cargado exitosamente` })
                const fn2 = (d) => d(obtenerCargues(1))
                fn2(dispatch)
            })
            .catch((err) => {
                fn1(dispatch)
                displayMessages(dispatch, err)
            })
    }
}

export function obtenerCargues(page = 1) {
    const axios = createAxiosInstance()
    return (dispatch, getState) => {
        // const compania = getSelectedCompany()

        const size = getState().carguemasivo.get('size')
        const orderBy = getState().carguemasivo.get('orderBy')
        const filters = getState().carguemasivo.getIn(['modalFilter', 'filters']).filter(x => x.get('apply')).toJS().map(x => JSON.stringify(x))

        axios.post(`${API_URL}/cargues`, { page, size, orderBy, filters })
            .then(res => {
                const registros = res.data.registros

                const fn1 = d => d({ type: types.CARGUEMASIVO_POBLAR_CARGUEMASIVO, payload: { registros, total: res.data.total, page } })
                fn1(dispatch)
            })
            .catch(err => {
                console.log(err)
                displayMessages(dispatch, err)
            })
    }
}

export function actualizarMostrarRegistros(size) {
    return dispatch => {
        const fn0 = (d) => d({ type: types.CARGUEMASIVO_ACTUALIZAR_MOSTRAR_REGISTROS, payload: size })
        const fn1 = (d) => d(obtenerCargues(1))
        fn0(dispatch)
        fn1(dispatch)
    }
}

export function showHideModalFilter(state) {
    return (dispatch) => {
        dispatch({ type: types.CARGUEMASIVO_MOSTRAR_MODAL_CRITERIOS_FILTRADO, payload: state })
    }
}

export function addModalFilterCriteria() {
    return (dispatch) => {
        dispatch({ type: types.CARGUEMASIVO_ADICIONAR_CRITERIO_FILTRADO })
    }
}

export function removeCheckedModalFilterCriterias() {
    return (dispatch) => {
        dispatch({ type: types.CARGUEMASIVO_ELIMINAR_CRITERIO_FILTRADO })
    }
}

export function toggleCheckedModalFilterCriterias(index, checked) {
    return (dispatch) => {
        dispatch({ type: types.CARGUEMASIVO_CONMUTAR_SELECCION_CRITERIO_FILTRADO, payload: { index, checked } })
    }
}

export function updateModalFilterCriteria(index, attr, value) {
    return (dispatch) => {
        dispatch({ type: types.CARGUEMASIVO_MODIFICAR_CRITERIO_FILTRADO, payload: { index, attr, value } })
    }
}

export function applyFilterCriterias() {
    return (dispatch) => {
        const fn0 = (d) => d({ type: types.CARGUEMASIVO_APLICAR_CRITERIOS_FILTRADO })
        const fn1 = (d) => d(obtenerCargues(1))
        fn0(dispatch)
        fn1(dispatch)
    }
}

export function updateInputs(path, value) {
    return (dispatch) => {
        const fn0 = (d) => d({ type: types.CARGUEMASIVO_MODIFICAR_INPUTS, payload: { path, value } })
        fn0(dispatch)
    }
}

export function setOrderBy(field) {
    return (dispatch, getState) => {
        let current = getState().carguemasivo.get('orderBy')
        current = current.set('field', field.VALUE)
        if (field === null) {
            current = current.set('asc', false)
        } else {
            current = current.set('asc', !current.get('asc'))
        }

        const fn0 = (d) => d({ type: types.CARGUEMASIVO_ORDENAR_TABLA, payload: { orderBy: current } })
        const fn1 = (d) => d(obtenerCargues(1))
        fn0(dispatch)
        fn1(dispatch)
    }
}