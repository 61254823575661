import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import numeral from "numeral";

import { signOut } from "../SignIn/actions";
import { clearMessage, getUserConfig, setDimension, displayModalChangePassword, showHideModalPoliticas } from "./actions";

import ModuleButton from "./components/ModuleButton";

import logo from "../../img/logo1.png";
import bckg from "../../img/bckg1.png";
import elogo from "../../img/E.png";

import "../../css/simple-sidebar.css";
import ModalChangePassword from "./components/ModalChangePassword";
import ModalPoliticas from "./components/ModalPoliticas";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSignOut: false,
      showSideBar: props.width > 768
    };
  }
  componentWillMount() {
    if (!this.props.isAuthenticated) {
      this.props.signOut();
    } else {
      if (!this.props.user) {
        this.props.getUserConfig();
      }
    }
  }

  componentDidMount() {
    window.root.addEventListener("click", this.handleDocumentClick);
    window.addEventListener("resize", this.handleWindowResize)
  }

  componentWillUnmount() {
    window.root.removeEventListener("click", this.handleDocumentClick);
    window.removeEventListener("resize", this.handleWindowResize);
  }

  componentWillUpdate(nextProps) {
    if (!nextProps.isAuthenticated) {
      this.props.signOut();
    }

    if (nextProps.user && !nextProps.user.get('ACCEPT_POLICY')) {
      this.props.showHideModalPoliticas(true)
    }
  }

  componentDidUpdate() {
    this.props.clearMessage();
  }

  handleDocumentClick = e => {
    const dd1 = ReactDOM.findDOMNode(this.dd1);
    if (!dd1.contains(e.target)) {
      this.setState({ showSignOut: false });
    }
  };

  handleWindowResize = () => {
    this.props.setDimension(window.innerWidth, window.innerHeight)
  }

  render() {
    const { user } = this.props;
    if (!user) return null;

    if (this.props.message) {
      const { message } = this.props;
      switch (message.get("type")) {
        case "danger":
          toast.error(message.get("message"), {
            bodyClassName: "toastify-content toastify-danger"
          });
          break;
        case "warning":
          toast.warn(message.get("message"), {
            bodyClassName: "toastify-content toastify-warning"
          });
          break;
        case "success":
          toast.success(message.get("message"), {
            bodyClassName: "toastify-content toastify-success"
          });
          break;
        default:
          toast.info(message.get("message"), {
            bodyClassName: "toastify-content toastify-info"
          });
      }
    }

    let { modulos } = this.props;

    return (
      <div className="body">
        <nav
          className="navbar navbar-default navbar-fixed-top"
          style={{
            backgroundColor: "#F7F9FA",
            borderBottom: "1px solid #d2d6de"
          }}
        >
          <div className="container-fluid">
            <div style={{ background: "#F7F9FA" }}>
              <a className="navbar-brand">
                <img
                  src={logo}
                  style={{ width: "100%", height: "100%" }}
                  alt="logo"
                />
              </a>
            </div>
            <div
              className="header-link hide-menu"
              onClick={() =>
                this.setState({ showSideBar: !this.state.showSideBar })
              }
            >
              <i className="fa fa-bars"></i>
            </div>
            <div className="user-info">
              <div>
                <div>
                  <b
                    style={{
                      display: "block",
                      padding: "0 10px",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {user.get("name")}
                  </b>
                </div>
                <div>
                  <small
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "300",
                      color: "#666"
                    }}
                  >
                    {/^\d+$/g.test(user.get("USERNAME"))
                      ? numeral(user.get("USERNAME")).format("0,0")
                      : user.get("USERNAME")}
                  </small>
                </div>
                <div className="usertype">
                  <div className="dropdown">
                    <a
                      className="text-center dropdown-toggle"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ showSignOut: true });
                      }}
                    >
                      <small>
                        {this.props.user.getIn("ROLE.NOMBRE".split("."))}&nbsp;
                        <i className="fa fa-caret-down"></i>
                      </small>
                    </a>
                    <ul
                      className="dropdown-menu animated flipInX"
                      ref={dd1 => (this.dd1 = dd1)}
                      style={{
                        margin: "0 5px",
                        display: this.state.showSignOut ? "block" : "none",
                        left: "-50%",
                        textAlign: "center"
                      }}
                    >
                      <li>
                        <a
                          onClick={e => {
                            e.preventDefault();
                            this.props.displayModalChangePassword(true);
                          }}
                        >
                          Cambiar contraseña
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={e => {
                            e.preventDefault();
                            this.props.signOut();
                          }}
                        >
                          Cerrar sesión
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div
          id="wrapper"
          className={`${this.state.showSideBar ? "" : "toggled"}`}
        >
          <div id="sidebar-wrapper">
            <ul className="sidebar-nav">

              {modulos
                .filter(
                  x =>
                    x.get("visible") &&
                    (x.get("roles").count()
                      ? x
                          .get("roles")
                          .filter(
                            r =>
                              r.get("nombre") ===
                              this.props.user.getIn(["ROLE", "NOMBRE"])
                          )
                          .count()
                      : true)
                )
                .map(x => {
                  return (
                    <ModuleButton
                      key={x.get("id")}
                      id={x.get("id")}
                      caption={x.get("caption")}
                      expandible={x.get("subitems").count() > 0}
                      options={x.get("subitems").filter(
                        x =>
                          x.get("visible") &&
                          (x.get("roles").count()
                            ? x
                                .get("roles")
                                .filter(
                                  r =>
                                    r.get("nombre") ===
                                    this.props.user.getIn(["ROLE", "NOMBRE"])
                                )
                                .count()
                            : true)
                      )}
                      url={x.get("url") || ""}
                      scripts={x.get('scripts') || false}
                    />
                  );
                })}
            </ul>
            <img
              src={elogo}
              width="100%"
              style={{
                verticalAlign: "middle",
                position: "absolute",
                bottom: "0",
                zIndex: "-1"
              }}
              alt="logo"
            />
          </div>

          <div
            id="page-content-wrapper"
            style={{ backgroundImage: `url(${bckg})` }}
          >
            {this.props.children}
            <div
              className="text-center"
              style={{
                width: "100%",
                position: "absolute",
                bottom: "0px",
                fontSize: "0.9em"
              }}
            >
              <span style={{ color: "#c8c8c8" }}>
                Desarrollado por{" "}
                <a
                  href="https://www.lonjacali.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#b5b5b5" }}
                >
                  la Lonja de Propiedad Raíz de Cali y Valle del Cauca
                </a>{" "}
                en colaboración con{" "}
                <a
                  href="https://www.mawesi.net"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#b5b5b5" }}
                >
                  MAWESI S.A.S.
                </a>
              </span>
            </div>
          </div>
        </div>
        <ModalChangePassword />
        <ModalPoliticas />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.layout.get("message"),
    isAuthenticated: state.signin.get("isAuthenticated"),
    user: state.signin.get("user"),
    modulos: state.layout.get("modulos"),
    width: state.layout.get("width"),
    height: state.layout.get("height"),
    scripts: state.layout.get('scripts')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(signOut()),
    displayModalChangePassword: (show) => dispatch(displayModalChangePassword(show)),
    showHideModalPoliticas: (show) => dispatch(showHideModalPoliticas(show)),
    clearMessage: () => dispatch(clearMessage()),
    getUserConfig: () => dispatch(getUserConfig()),
    setDimension: (w, h) => dispatch(setDimension(w, h))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
