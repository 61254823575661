import React, { Component } from 'react'

export default class ShowRecords extends Component {
	render() {
        const { showRecords, totalRecords, changeRecordsToShow } = this.props

		return (
            <div>
                <span>Mostrar </span>
                <select className="input-control input-sm" style={{ display: 'inline-block' }} value={ showRecords } onChange={ (e) => changeRecordsToShow(e.target.value) }>
                    <option value={ 20 }>20</option>
                    <option value={ 50 }>50</option>
                    <option value={ 100 }>100</option>
                    <option value={ 200 }>200</option>
                </select>
                <span> de <strong>{ totalRecords }</strong> registros</span>                
            </div>
		)
	}
}
