import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import numeral from 'numeral'
import Pagination from 'react-js-pagination'

import ButtonStrip from '../Layout/components/ButtonStrip'
import BoxButton from '../Layout/components/BoxButton'
import ModalDownload from './components/ModalDownload'
import ModalUpload from './components/ModalUpload'
import ModalFilter from './components/ModalFilter'

import {
    displayModalDownload,
    displayModalUpload,
    obtenerCargues,
    setOrderBy,
    actualizarMostrarRegistros,
    obtenerFormularios
} from './actions'
import Box from '../Layout/components/Box'
import BoxSection from '../Layout/components/BoxSection'

export const tableColumns = [
    { ID: 2, CAPTION: 'Archivo', VALUE: 'ARCHIVO', TYPE: 'string', FORMAT: '' },
    { ID: 3, CAPTION: 'Registros creados', VALUE: 'REGISTROS_CREADOS', TYPE: 'number', FORMAT: '' },
    { ID: 4, CAPTION: 'Registros actualizados', VALUE: 'REGISTROS_ACTUALIZADOS', TYPE: 'number', FORMAT: '' },
    { ID: 5, CAPTION: 'Fecha', VALUE: 'FECHA', TYPE: 'datetime', FORMAT: 'YYYY-MM-DD HH:mm:ss' },
    { ID: 6, CAPTION: 'Usuario', VALUE: 'USUARIO', TYPE: 'string', FORMAT: '' },
]

export function findValues(c, x) {
    let value = x.getIn(c.VALUE.split('.'))
    return value
}

class CargueMasivo extends Component {
    componentWillMount() {
        this.props.obtenerCargues(1)
        this.props.obtenerFormularios()
    }

    render() {
        if (!this.props.elementos) return null
        const { records, orderBy, page, totalRegistros, size } = this.props

        const pagination = <Pagination
            activePage={page}
            itemsCountPerPage={size}
            totalItemsCount={totalRegistros}
            onChange={(pg) => this.props.obtenerCargues(pg)}
            firstPageText={<FontAwesome name="angle-double-left" />}
            prevPageText={<FontAwesome name="angle-left" />}
            nextPageText={<FontAwesome name="angle-right" />}
            lastPageText={<FontAwesome name="angle-double-right" />}
            pageRangeDisplayed={this.props.width < 1000 ? 5 : 10}
        />

        const buttons = []
        if (this.props.elementos.filter(x => x === "EYcfVzH8").count()) buttons.push(<BoxButton key={`action[6][0]`} name="cloud-download" onClick={() => this.props.displayModalDownload(true, 'Descarga de plantillas para cargue de archivos')} title="Descargar plantilla" />)
        if (this.props.elementos.filter(x => x === "GCe4XeZn").count()) buttons.push(<BoxButton key={`action[6][1]`} name="cloud-upload" onClick={() => this.props.displayModalUpload(true, 'Carga masiva de formularios')} title="Cargar formularios" />)

        return (
            <div id="cargue_masivo" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box title="Cargue masivo de formularios" stretch>
                    <div className="row" style={{ height: '100%' }}>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="row" style={{ height: '100%' }}>
                                <div className="col-md-12 col-sm-12 col-xs-12" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <BoxSection title="Historial de cargues">
                                        <div style={{ padding: "0 5px", marginBottom: "10px" }}>
                                            <ButtonStrip
                                                actions={buttons}
                                                pagination={pagination}
                                                totalRecords={totalRegistros}
                                                showRecords={size}
                                                changeRecordsToShow={(records) => this.props.actualizarMostrarRegistros(records)}
                                            />
                                        </div>
                                        <div className="table-scrolled">
                                            <table className="table table-condensed table-striped table-bordered table-hover table-sorted">
                                                <thead>
                                                    <tr>
                                                        {
                                                            tableColumns.map(x => {
                                                                const style = { width: `${x.WIDTH}`, cursor: 'pointer' }
                                                                return (
                                                                    <th key={`th1[${x.ID}]`} style={style} onClick={() => this.props.setOrderBy(x)} className={(orderBy.get('field') === x.VALUE) ? (orderBy.get('asc') ? 'asc' : 'desc') : ''}>{x.CAPTION}</th>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        records.count() > 0 ? records.map((a, b) => {
                                                            return (
                                                                <tr key={`tr2[${a.get('ID')}]`} className={a.get('SELECTED') ? 'info' : ''}>
                                                                    {
                                                                        tableColumns.map((c, d) => {
                                                                            let alignment = 'left'
                                                                            let value = findValues(c, a)
                                                                            if (c.TYPE) {
                                                                                switch (c.TYPE) {
                                                                                    case 'datetime':
                                                                                        value = moment(value).format(c.FORMAT)
                                                                                        alignment = 'right'
                                                                                        break
                                                                                    case 'bool':
                                                                                        if (typeof value === 'string') value = parseInt(value, 10)
                                                                                        value = <div style={{ textAlign: 'center', fontSize: '1.1em' }}><i style={{ color: '#41afbe' }} className={`fa fa-${value ? 'check-' : ''}square`} /></div>
                                                                                        break
                                                                                    case 'color':
                                                                                        let icon = 'circle'
                                                                                        if (value === null) icon = 'circle-thin'
                                                                                        value = <div style={{ textAlign: 'center', fontSize: '1.1em' }}><i style={{ color: value, textShadow: '0px 0px 1px #555' }} className={`fa fa-${icon}`} /></div>
                                                                                        break
                                                                                    case 'number':
                                                                                        value = value !== null ? numeral(value).format(c.Format) : null
                                                                                        alignment = 'right'
                                                                                        break
                                                                                    default:
                                                                                }
                                                                            }
                                                                            const style = { textAlign: alignment }

                                                                            if (c.LINK) {
                                                                                return (
                                                                                    <td key={`td1[${a.get('ID')}][${c.ID}]`} className='LinkToEdit' style={style} onClick={e => this.props.displayModalCreate(true, 'Modificación de secciones', 'edit', a.get('ID'))}>{value}</td>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <td key={`td1[${a.get('ID')}][${c.ID}]`} style={style}>{value}</td>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        }) : <tr><td colSpan={tableColumns.length} style={{ textAlign: 'center' }}>No existen registros</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </BoxSection>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
                <ModalDownload />
                <ModalUpload />
                <ModalFilter />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        records: state.carguemasivo.get('records'),
        orderBy: state.carguemasivo.get('orderBy'),
        totalRegistros: state.carguemasivo.get('totalRegistros'),
        size: state.carguemasivo.get('size'),
        page: state.carguemasivo.get('page'),
        elementos: state.layout.get('elementos'),
        width: state.layout.get('width')
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actualizarMostrarRegistros: (records) => dispatch(actualizarMostrarRegistros(records)),
        displayModalDownload: (show, title) => dispatch(displayModalDownload(show, title)),
        displayModalUpload: (show, title) => dispatch(displayModalUpload(show, title)),
        obtenerCargues: (page) => dispatch(obtenerCargues(page)),
        setOrderBy: (campo) => dispatch(setOrderBy(campo)),
        obtenerFormularios: () => dispatch(obtenerFormularios()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CargueMasivo)