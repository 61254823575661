import * as types from '../../types'
import Immutable from 'immutable'
import diff from 'immutablediff'
import patch from 'immutablepatch'

import { isPrimitive } from '../../utils/helpers'
import { variableEntity } from '../../utils/constants';

const INITIAL_STATE = Immutable.fromJS({
    variables: {},
    selected: null,
    filtro: '',
    sortBy: 'ID',
    asc: true,
    modalCreate: {
        show: false,
        title: '',
        mode: null,
        data: null,
    },
    saving: false,
})

export default function (state = INITIAL_STATE, action) {
    let ops
    switch (action.type) {
        case types.VARIABLES_POBLAR_VARIABLES:
            state = state.set('variables', Immutable.fromJS(action.payload.variables))
            return state
        case types.VARIABLES_DISPLAY_MODAL_CREACION:
            if (action.payload.show) {
                state = state.setIn(['modalCreate', 'show'], true)
                state = state.setIn(['modalCreate', 'title'], action.payload.title)
                if (action.payload.mode === 'edit' && action.payload.id) {
                    let variable = state.getIn(['variables', String(action.payload.id)])
                    state = state.setIn(['modalCreate', 'data'], variable)
                } else {
                    state = state.setIn(['modalCreate', 'data'], Immutable.fromJS(variableEntity()))
                }
                state = state.setIn(['modalCreate', 'mode'], action.payload.mode)
            } else {
                state = state.set('modalCreate', INITIAL_STATE.get('modalCreate'))
            }
            return state
        case types.VARIABLES_MODIFICAR_INPUTS:
            if (isPrimitive(action.payload.value)) {
                state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
            } else {
                if (action.payload.immutable === false) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                }
            }
            return state
        case types.VARIABLES_ACTUALIZAR_VARIABLE:
            ops = diff(state.getIn(['variables', String(action.payload.ID)]), Immutable.fromJS(action.payload))
            state = state.setIn(['variables', String(action.payload.ID)], patch(state.getIn(['variables', String(action.payload.ID)]), ops))
            return state
        case types.VARIABLES_ELIMINAR_VARIABLE:
            state = state.deleteIn(['variables', String(action.payload)])
            return state
        case types.VARIABLES_CONMUTAR_SELECCION_VARIABLES:
            state.get('variables').keySeq().forEach(x => {
                state = state.setIn(['variables', String(x), 'SELECTED'], action.payload)
            })
            return state
        case types.VARIABLES_MODIFICAR_FILTRO:
            state = state.set('filtro', action.payload)
            return state
        case types.VARIABLES_ORDENAR_COLUMNA:
            state = state.set('sortBy', action.payload)
            if (action.payload === state.get('sortBy')) {
                state = state.set('asc', !state.get('asc'))
            } else {
                state = state.set('asc', true)
            }
            return state
        case types.VARIABLES_GUARDANDO:
            state = state.set('saving', action.payload)
            return state
        default:
            return state
   }
}
