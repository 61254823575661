import * as types from '../../types'
import Immutable from 'immutable'
import { TreeState } from 'cp-react-tree-table';

import {
    isPrimitive, createTreeFuentes,
} from '../../utils/helpers'
import {
    fuenteEntity,
    folderEntity
} from '../../utils/constants';
import {
    act
} from 'react-dom/test-utils'

const INITIAL_STATE = Immutable.fromJS({
    fuentes: {},
    folders: [],
    selected: null,
    filtro: '',
    sortBy: 'ID',
    asc: true,
    modalCreate: {
        show: false,
        title: '',
        mode: null,
        data: {
            FUENTES: []
        },
    },
    modalEdit: {
        show: false,
        title: '',
        mode: null,
        data: null,
    },
    modalCreateFolder: {
        show: false,
        title: '',
        mode: null,
        data: null,
    },
    saving: false,
    treeFuentes: {}
})

export default function (state = INITIAL_STATE, action) {
    let data, selected, ops
    switch (action.type) {
        case types.FUENTES_POBLAR_FUENTES:
            state = state.set('fuentes', Immutable.fromJS(action.payload.fuentes))
            state = state.set("treeFuentes", TreeState.create(createTreeFuentes(state.get('fuentes'), "")))
            return state
        case types.FUENTES_OBTENER_CARPETAS:
            return state.set('folders', action.payload)
        case types.FUENTES_DISPLAY_MODAL_CREACION:
            if (action.payload.show) {
                state = state.setIn(['modalCreate', 'show'], true)
                state = state.setIn(['modalCreate', 'title'], action.payload.title)
            } else {
                state = state.set('modalCreate', INITIAL_STATE.get('modalCreate'))
            }
            return state
        case types.FUENTES_DISPLAY_MODAL_EDITAR:
            if (action.payload.show) {
                state = state.setIn(['modalEdit', 'show'], true)
                state = state.setIn(['modalEdit', 'title'], action.payload.title)
                if (action.payload.mode === 'edit' && action.payload.id) {
                    let fuente = state.getIn(['fuentes', String(action.payload.id)])
                    state = state.setIn(['modalEdit', 'data'], fuente)
                } else {
                    state = state.setIn(['modalEdit', 'data'], Immutable.fromJS(fuenteEntity()))
                }
                state = state.setIn(['modalEdit', 'mode'], action.payload.mode)
            } else {
                state = state.set('modalEdit', INITIAL_STATE.get('modalEdit'))
            }
            return state
        case types.FUENTES_DISPLAY_MODAL_CREACION_FOLDER:
            if (action.payload.show) {
                state = state.setIn(['modalCreateFolder', 'show'], true)
                state = state.setIn(['modalCreateFolder', 'title'], action.payload.title)
                state = state.setIn(['modalCreateFolder', 'mode'], action.payload.mode)
                state = state.setIn(['modalCreateFolder', 'data'], Immutable.fromJS(folderEntity()))
                return state
            } else {
                return state.set('modalCreateFolder', INITIAL_STATE.get('modalCreateFolder'))
            }
            case types.FUENTES_MODIFICAR_INPUTS:
                if (isPrimitive(action.payload.value)) {
                    state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                } else {
                    if (action.payload.immutable === false) {
                        state = state.setIn(`${action.payload.path}`.split('.'), action.payload.value)
                    } else {
                        state = state.setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value))
                    }
                }
                return state
            case types.FUENTES_ACTUALIZAR_FUENTE:
                state = state.update('fuentes', l => l.merge(Immutable.fromJS(action.payload)))
                state = state.set("treeFuentes", TreeState.create(createTreeFuentes(state.get('fuentes'), "")))
                return state
            case types.FUENTES_ELIMINAR_FUENTE:
                state = state.deleteIn(['fuentes', String(action.payload)])
                state = state.set("treeFuentes", TreeState.create(createTreeFuentes(state.get('fuentes'), "")))
                return state
            case types.FUENTES_CONMUTAR_SELECCION_FUENTES:
                state.get('fuentes').keySeq().forEach(x => {
                    state = state.setIn(['fuentes', String(x), 'SELECTED'], action.payload)
                })
                return state
            case types.FUENTES_MODIFICAR_FILTRO:
                state = state.set('filtro', action.payload)
                state = state.set("treeFuentes", TreeState.create(createTreeFuentes(state.get('fuentes'), action.payload)))
                if (state.get("treeFuentes").hasData) {
                    state = state.set("treeFuentes", TreeState.expandAll(state.get("treeFuentes")))
                }
                return state
            case types.FUENTES_ORDENAR_COLUMNA:
                state = state.set('sortBy', action.payload)
                if (action.payload === state.get('sortBy')) {
                    state = state.set('asc', !state.get('asc'))
                } else {
                    state = state.set('asc', true)
                }
                return state
            case types.FUENTES_GUARDANDO:
                state = state.set('saving', action.payload)
                return state
            case types.FUENTES_CARGAR_FUENTE:
                if (action.payload.index !== null) {
                    state = state.setIn(['modalCreate', 'data', 'FUENTES', action.payload.index, 'file'], action.payload.file)
                    state = state.setIn(['modalCreate', 'data', 'FUENTES', action.payload.index, 'NOMBRE'], action.payload.file.name)
                } else {
                    state = state.setIn('modalEdit.data.file'.split('.'), action.payload.file)
                    let { file } = action.payload
                    state = state.setIn('modalEdit.data.NOMBRE'.split('.'), file.name)
                }
                return state
            case types.FUENTES_AGREGAR_ARCHIVO:
                return state.updateIn(['modalCreate', 'data', 'FUENTES'], l => l.push(Immutable.fromJS(fuenteEntity())))
            case types.FUENTES_ELIMINAR_ARCHIVO:
                return state.updateIn(['modalCreate', 'data', 'FUENTES'], l => l.filter(x => !x.get('SELECTED')))
            case types.FUENTES_UPDATE_TREE_FUENTES:
                return state.set('treeFuentes', action.payload)
            default:
                return state
    }
}