import Immutable from 'immutable'
import * as types from '../../types'

const INITIAL_STATE = Immutable.fromJS({
    message: null,
    msgBox: {
        show: false,
        message: null
    },
    expanded: null,
    modulos: [],
    elementos: [],
    scripts: [],
    modalConfirmAction: {
        show: false,
        message: '',
        action: null
    },
    modalSelector: {
        show: false,
        title: '',
        fields: [],
        data: [],
        action: null,
        include: [],
        filter: '',
        filters: [],
        pages: [],
        take: 100,
        url: '',
        loading: true,
    },
    modalPoliticas: {
        show: false,
        title: '',
        acepta_terminos_condiciones: false,
        acepta_politica_privacidad: false
    },
    modalChangePassword: {
        show: false,
        title: "Cambiar contraseña",
        data: {},
        saving: false
    },
    busy: false,
    waiting: false,
    width: window.innerWidth,
    height: window.innerHeight
})

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.LAYOUT_MOSTRAR_MENSAJE:
            state = state.set('message', Immutable.fromJS(action.payload))
            return state
        case types.LAYOUT_LIMPIAR_MENSAJES:
            state = state.set('message', null)
            return state
        case types.LAYOUT_MOSTRAR_MODAL_ERROR:
            state = state.setIn(['msgBox', 'show'], action.payload.show)
            state = state.setIn(['msgBox', 'message'], action.payload.message)
            return state
        case types.LAYOUT_MOSTRAR_MODAL_CAMBIO_CONTRASENA:
            return state.setIn(['modalChangePassword', 'show'], action.payload.show)
        case types.LAYOUT_POBLAR_MODULOS:
            state = state.set('modulos', Immutable.fromJS(action.payload.modulos))
            state = state.set('elementos', Immutable.fromJS(action.payload.elementos))
            state = state.set('scripts', Immutable.fromJS(action.payload.scripts))
            return state
        case types.LAYOUT_EXPANDIR_CONTRAER_MODULO:
            state = state.set('expanded', action.payload)
            return state
        case types.LAYOUT_MOSTRAR_MODAL_CONFIRMACION_ACCION:
            state = state.setIn(['modalConfirmAction', 'show'], action.payload.open)
            state = state.setIn(['modalConfirmAction', 'action'], action.payload.action)
            state = state.setIn(['modalConfirmAction', 'message'], action.payload.message)
            return state
        case types.LAYOUT_MOSTRAR_MODAL_POLITICAS:
            state = state.setIn(['modalPoliticas', 'show'], action.payload.show)
            state = state.setIn(['modalPoliticas', 'title'], action.payload.title)
            return state
        case types.LAYOUT_IS_BUSY:
            state = state.set('busy', action.payload)
            return state
        case types.LAYOUT_MOSTRAR_MODAL_SELECTOR:
            state = state.setIn(['modalSelector', 'show'], action.payload.show)
            if (action.payload.show) {
                state = state.setIn(['modalSelector', 'title'], action.payload.title)
                state = state.setIn(['modalSelector', 'fields'], Immutable.fromJS(action.payload.fields))
                state = state.setIn(['modalSelector', 'url'], action.payload.url)
                state = state.setIn(['modalSelector', 'action'], action.payload.action)
            } else {
                state = state.set('modalSelector', INITIAL_STATE.get('modalSelector'))
            }
            return state
        case types.LAYOUT_MODIFICAR_PAGINA_MODAL_SELECTOR:
            state = state.setIn(['modalSelector', 'pages'], state.getIn(['modalSelector', 'pages']).concat(action.payload.page))
            return state
        case types.LAYOUT_REINICIAR_LISTA_MODAL_SELECTOR:
            state = state.setIn(['modalSelector', 'pages'], Immutable.fromJS([]))
            state = state.setIn(['modalSelector', 'data'], Immutable.fromJS([]))
            return state
        case types.LAYOUT_ACTUALIZAR_LISTADO_SELECTOR:
            state = state.setIn(['modalSelector', 'data'], state.getIn(['modalSelector', 'data']).concat(Immutable.fromJS(action.payload.list)))
            return state
        case types.LAYOUT_MOSTRAR_LOADING_SELECTOR:
            state = state.setIn(['modalSelector', 'loading'], action.payload)
            return state
        case types.LAYOUT_MOSTRAR_MODAL_ESPERA:
            return state.set('waiting', action.payload)
        case types.LAYOUT_SET_DIMENSION:
            state = state.set('height', action.payload.height)
            return state.set('width', action.payload.width)
        default:
            return state
    }
}